export function regionValidation(data) {
    const errors = {};

   // Validate regionName
    if (!data.regionName || data.regionName.trim() === '' || data.regionName === undefined) {
        errors.regionName = 'Region name is required.';
    }

    // Validate uploadedKML
    if (!data.uploadedKML || data.uploadedKML.trim() === '' || data.uploadedKML === undefined) {
        errors.uploadedKML = 'Uploaded KML is required.';
    }
    const isValid = Object.keys(errors).length === 0;

    return { errors, isValid };
}