import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { fetchRetailerMembersAPI } from "../../../redux/apis/retailer/members";

export const fetchRetailerMembers = createAsyncThunk(
    'retailer/members',
    async ( {filters, retailerId} , { rejectWithValue }) => {
        try {
            const res = await fetchRetailerMembersAPI( retailerId, filters);
            return res
        } catch(error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)

//Create a slice
const retailerMembersSlice = createSlice({
    name: 'retailerMembers',
    initialState: {
        members: [],
        memberDetails: {},
        loading: false,
        error: null,
    },
    reducers: {
    // Define additional reducers here if needed

    },
    extraReducers: (builder) => {
        builder.addCase(fetchRetailerMembers.fulfilled, (state,action) => {
            state.loading = false;
            state.members = action.payload;
            state.memberDetails = {}
            state.error = null;
        });

        builder.addCase(fetchRetailerMembers.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        builder.addCase(fetchRetailerMembers.pending, (state) => {
            state.loading = true;
        })
    }
})

export const { /* Any additional actions */ } = retailerMembersSlice.actions;

export default retailerMembersSlice.reducer;