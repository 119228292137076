import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { addMemberShipAPI, deleteMemberShipAPI, fetchMemberShipDetailsAPI, updateMemberShipAPI } from '../apis/memberships'
import { fetchMemberShipAPI } from "../../redux/apis/memberships";

export const fetchMemberships = createAsyncThunk(
    'memberships/fetchMemberships',
    async (filters, { rejectWithValue }) => {
        try {
            const res = await fetchMemberShipAPI(filters);
            return res
        } catch (error) {
             
            return rejectWithValue(error?.response?.data)
        }
    }
)


export const fetchMemberShipDetails = createAsyncThunk(
    'memberships/fetchMemberShipDetails',
    async (memberId, { rejectWithValue }) => {
        try {
            const res = await fetchMemberShipDetailsAPI(memberId)
            return res
        } catch (error) {
             
            return rejectWithValue(error?.response?.data)
        }
    }
)

export const createMemberShip = createAsyncThunk(
    'memberships/createMemberShip',
    async (data, { rejectWithValue }) => {
        try {
            const res = await addMemberShipAPI(data)
            return res
        } catch (error) {
             
            return rejectWithValue(error?.response?.data)
        }
    }
)


export const updateMemberShip = createAsyncThunk(
    'memberships/updateMemberShip',
    async ({ data, memberShipId }, { rejectWithValue }) => {
        try {
            const res = await updateMemberShipAPI(data, memberShipId)
            return res
        } catch (error) {
             
            return rejectWithValue(error?.response?.data)
        }
    }
)

export const deleteMemberShip = createAsyncThunk(
    'memberships/deleteMemberShip',
    async (membershipId, { rejectWithValue }) => {
        try {
            const res = await deleteMemberShipAPI(membershipId)
            return res
        } catch (error) {
             
            return rejectWithValue(error?.response?.data)
        }
    }
)

// Create a slice with reducers
const membersSlice = createSlice({
    name: 'memberships',
    initialState: {
        memberships: [],
        memberShipDetails: {},
        loading: false,
        error: null,
    },
    reducers: {
        // Define additional reducers here if needed
    },
    extraReducers: (builder) => {
        builder.addCase(fetchMemberships.fulfilled, (state, action) => {
            state.loading = false;
            state.memberships = action.payload; // Update state with the received data
            state.memberShipDetails = {}
            state.error = null;
        });

        // Extra reducer for the rejected action of fetchMemberships
        builder.addCase(fetchMemberships.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload; // Update state with the error message
        });

        // Extra reducer for the pending action of fetchMemberships
        builder.addCase(fetchMemberships.pending, (state) => {
            state.loading = true;
        });


        builder.addCase(fetchMemberShipDetails.fulfilled, (state, action) => {
            state.loading = false;
            state.memberShipDetails = action.payload; // Update state with the received details
            state.error = null;
        });

        // Extra reducer for the rejected action of fetchMemberShipDetails
        builder.addCase(fetchMemberShipDetails.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload; // Update state with the error message
        });

        // Extra reducer for the pending action of fetchMemberShipDetails
        builder.addCase(fetchMemberShipDetails.pending, (state) => {
            state.loading = true;
        });

        builder
            .addCase(createMemberShip.pending, (state) => {
                state.loading = true;
                state.error = null;
            })

        builder.addCase(createMemberShip.fulfilled, (state, action) => {
            state.loading = false;
            state.error = null;
        });

        // Extra reducer for the rejected action of createMemberShip
        builder.addCase(createMemberShip.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload; // Update state with the error message
        });


        builder
            .addCase(updateMemberShip.pending, (state, action) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateMemberShip.fulfilled, (state, action) => {
                state.loading = false;
                // You can update your state with the data received from the API if needed
            })
            .addCase(updateMemberShip.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload; // The error message returned from the API
            });

        builder
            .addCase(deleteMemberShip.pending, (state, action) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(deleteMemberShip.fulfilled, (state, action) => {
                state.loading = false;
                // You can update your state with the data received from the API if needed
            })
            .addCase(deleteMemberShip.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload; // The error message returned from the API
            });
    },
});

export const { /* Any additional actions */ } = membersSlice.actions;

export default membersSlice.reducer;