import axiosClient from "axiosClient";

export async function fetchInventoryAPI() {
    return await axiosClient.get('/api/v1/admin/inventory/get');
}

export async function fetchInventoryDetailsAPI(inventoryId) {
    return await axiosClient.get(`/api/v1/admin/inventory/${inventoryId}`);
}

export async function addInventoryItemAPI(data) {
    return await axiosClient.post(`/api/v1/admin/inventory`, data);
}

export async function updateInventoryItemAPI(data, inventoryId) {
    return await axiosClient.put(`/api/v1/admin/inventory/update/${inventoryId}`, data);
}

export async function deleteInventoryItemAPI(inventoryId) {
    return await axiosClient.delete(`/api/v1/admin/inventory/${inventoryId}`);
}