import React from 'react';

export default function TimeIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <g clipPath="url(#clip0_8528_8884)">
        <path d="M8 0C6.41775 0 4.87104 0.469192 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.00346629 6.40034 -0.15496 8.00887 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C15.9977 5.87897 15.1541 3.84547 13.6543 2.34568C12.1545 0.845886 10.121 0.00229405 8 0V0ZM8 9.33333C7.70674 9.33433 7.42138 9.23832 7.18837 9.06025C6.95535 8.88219 6.78775 8.63207 6.71168 8.34885C6.6356 8.06562 6.65532 7.76519 6.76776 7.49434C6.8802 7.22348 7.07905 6.99742 7.33334 6.85133V4.66667C7.33334 4.48986 7.40358 4.32029 7.5286 4.19526C7.65362 4.07024 7.82319 4 8 4C8.17682 4 8.34638 4.07024 8.47141 4.19526C8.59643 4.32029 8.66667 4.48986 8.66667 4.66667V6.85133C8.92096 6.99742 9.11981 7.22348 9.23225 7.49434C9.34469 7.76519 9.3644 8.06562 9.28833 8.34885C9.21225 8.63207 9.04466 8.88219 8.81164 9.06025C8.57862 9.23832 8.29327 9.33433 8 9.33333Z" fill="#A9A9B7"/>
      </g>
      <defs>
        <clipPath id="clip0_8528_8884">
          <rect width="16" height="16" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
}