import {
  Card,
  Typography,
  Grid,
  Box,
  CardContent,
  Stack,
} from '@mui/material';
import CommonLayout from 'layouts/DashboardLayout';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import AngleRightIcon from 'common/customIcons/angle_right';
import MDButton from 'components/MDButton';
import EditIcon from 'common/customIcons/edit';
import iconDriverPlaceholder from '../../assets/images/add-driver-placeholder.svg';
import colors from 'assets/theme/base/colors';
import moment from 'moment';
import { formatUsPhoneNumber } from 'utilities/common';
import Zoom from 'react-medium-image-zoom';
import defaultImage from 'assets/images/placeholder-image.png';
import { fetchDriverDetailsHemp } from '../../redux/slices/driverHemp';

export default function DriverDetailsHemp() {
  const { grey } = colors;
  const { driverId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data = {} } = useSelector((state) => state.adminDriversHemp.driverDetails?.data) || {};

  const {
    fleetDisplayId,
    fleetImage,
    fleetName,
    fleetStatus,
    fleetPhone,
    fleetEmail,
    regionData = {},
    terminalData = {},
    transportationDetail = {},
    fleetVehicleDetails = {},
    idFile,
    policyFile,
    fleetOtherInfo = {},
  } = data;


  useEffect(() => {
    if (driverId) {
      dispatch(fetchDriverDetailsHemp(driverId));
    }
  }, [driverId]);


  return (
    <>
      <CommonLayout>
        <Box p={{ xs: 2, sm: 3, lg: 4 }}>

          <Grid container spacing={2.5} mb={2}>
            <Grid item xs={12} md={12} lg={12}>
              <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Link to="/drivers">
                  <Box display="flex" alignItems="center" color="text.main" lineHeight={1}>
                    <AngleRightIcon fontSize="14px" />
                    <Typography variant="h6" fontWeight="semibold" ml={0.75}>
                      Drivers
                    </Typography>
                  </Box>
                </Link>
                <MDButton
                  type="button"
                  variant="outlined"
                  color="dark"
                  size="small"
                  startIcon={<EditIcon width="14px" height="14px" />}
                  sx={{ borderRadius: 30 }}
                  onClick={() => navigate(`/driver/${driverId}`)}
                >
                  Edit
                </MDButton>
              </Stack>
            </Grid>
          </Grid>

          <Grid container spacing={2.5} mb={4}>
            <Grid item xs={12} md={12} lg={12}>
              <Card variant="detailsCard" sx={{ height: '100%', overflow: 'hidden' }}>                
                <CardContent sx={{ p: { xs: 3, md: 4.5 }, pb: 0 }}>
                  <Grid container alignItems="center" spacing={1.5} mb={1}>

                    <Grid item xs={12} md={12} lg={12} className="pt-0 pb-3">
                      <Stack direction="row" alignItems="center" justifyContent="space-between">
                        <Typography variant="h4">Driver Detail</Typography>

                        <Stack
                          direction="row"
                          alignItems="center"
                          gap={0.75}
                          lineHeight={1}
                          p={0.75}
                          borderRadius={2}
                        >
                          {fleetStatus === 'active' && (
                            <MDButton
                              sx={{ minWidth: '92px', borderRadius: 1.75, p: 0.25, fontWeight: 500 }}
                              style={{ opacity: 1 }}
                              type="button"
                              variant={fleetStatus === 'active' ? 'contained' : 'text'}
                              color={fleetStatus === 'active' ? 'success' : 'dark'}
                              size="small"
                              disabled
                            >
                              Active
                            </MDButton>
                          )}

                          {fleetStatus === 'inactive' && (
                            <MDButton
                              sx={{ minWidth: '92px', borderRadius: 1.75, p: 0.25, fontWeight: 500 }}
                              type="button"
                              variant={fleetStatus === 'inactive' ? 'contained' : 'text'}
                              color={fleetStatus === 'inactive' ? 'error' : 'dark'}
                              size="small"
                              style={{ opacity: 1 }}
                              disabled
                            >
                              Inactive
                            </MDButton>
                          )}

                          {fleetStatus === 'pending' && (
                            <MDButton
                              sx={{ minWidth: '92px', borderRadius: 1.75, p: 0.25, fontWeight: 500 }}
                              type="button"
                              variant={fleetStatus === 'pending' ? 'contained' : 'text'}
                              color={fleetStatus === 'pending' ? 'error' : 'dark'}
                              size="small"
                              style={{ opacity: 1, backgroundColor: '#8E0CF5', color: '#ffffff' }}
                              disabled
                            >
                              Pending
                            </MDButton>
                          )}

                        </Stack>
                      </Stack>
                    </Grid>

                    <Grid item xs={12} md={12} lg={12}>
                      <Stack flexWrap="wrap" spacing={1.25} mb={2}>
                        <Zoom classDialog="custom-zoom">
                          {' '}
                          <img
                            style={{ borderRadius: '100px', width: '108px', height: '108px' }}
                            src={fleetImage ? fleetImage : iconDriverPlaceholder}
                            alt="fleet Image"
                          />
                        </Zoom>
                      </Stack>
                    </Grid>

                    <Grid container alignItems="center" spacing={2.5} mt={2} mb={4}>
                      <Grid item xs={12} md={6} lg={3}>
                        <Typography variant="body2" color={grey[600]}>
                          Driver ID
                        </Typography>
                        <Typography
                          variant="h6"
                          fontWeight="medium"
                          sx={{ textTransform: 'capitalize' }}
                        >
                          {fleetDisplayId}
                        </Typography>
                      </Grid>
                      
                      <Grid item xs={12} md={6} lg={3}>
                        <Typography variant="body2" color={grey[600]}>
                          Driver Name
                        </Typography>
                        <Typography
                          variant="h6"
                          fontWeight="medium"
                          sx={{ textTransform: 'capitalize' }}
                        >
                          {fleetName?.toLowerCase()}
                        </Typography>
                      </Grid>

                      <Grid item xs={12} md={6} lg={3}>
                        <Typography variant="body2" color={grey[600]}>
                          Phone
                        </Typography>
                        <Typography
                          variant="h6"
                          fontWeight="medium"
                          sx={{ textTransform: 'capitalize' }}
                        >
                          {fleetPhone ? formatUsPhoneNumber(fleetPhone) : ''}
                        </Typography>
                      </Grid>

                      <Grid item xs={12} md={6} lg={3}>
                        <Typography variant="body2" color={grey[600]}>
                          Email
                        </Typography>
                        <Typography
                          variant="h6"
                          fontWeight="medium"
                          // sx={{ textTransform: "capitalize" }}
                        >
                          {fleetEmail}
                        </Typography>
                      </Grid>

                      <Grid item xs={12} md={6} lg={3}>
                        <Typography variant="body2" color={grey[600]}>
                          Assigned Region
                        </Typography>
                        <Typography
                          variant="h6"
                          fontWeight="medium"
                          sx={{ textTransform: 'capitalize' }}
                        >
                          {regionData.regionName || '-'}
                        </Typography>
                      </Grid>

                      <Grid item xs={12} md={6} lg={3}>
                        <Typography variant="body2" color={grey[600]}>
                          Assigned Terminal
                        </Typography>
                        <Typography
                          variant="h6"
                          fontWeight="medium"
                          sx={{ textTransform: 'capitalize' }}
                        >
                          {terminalData.terminalName || '-'}
                        </Typography>
                      </Grid>

                    </Grid>

                    <Grid container spacing={2.5} mb={4.5}>
                      <Grid item xs={12} md={12} lg={12}>
                        <Typography variant='h5' fontWeight="semibold" color="secondary">Transportation</Typography>
                        <Typography variant="body2" color="secondary" mb={1}>Used for ETAs, Optimizations, and notification</Typography>
                        <Typography
                          variant="h6"
                          fontWeight="medium"
                          sx={{ textTransform: 'capitalize' }}
                          className="d-flex align-item-center gap-2"
                        >
                          <img src={transportationDetail.imageUrl} />
                          <span>{transportationDetail.name}</span>
                        </Typography>
                      </Grid>

                      <Grid item xs={12} md={6} lg={3}>
                        <Typography variant="body2" color={grey[600]}>
                          License Plate
                        </Typography>
                        <Typography
                          variant="h6"
                          fontWeight="medium"
                          sx={{ textTransform: 'capitalize' }}
                        >
                          {fleetVehicleDetails.licensePlate}
                        </Typography>
                      </Grid>

                      <Grid item xs={12}md={6} lg={3}>
                        <Typography variant="body2" color={grey[600]}>
                          Color
                        </Typography>
                        <Typography
                          variant="h6"
                          fontWeight="medium"
                          sx={{ textTransform: 'capitalize' }}
                        >
                          {fleetVehicleDetails.color}
                        </Typography>
                      </Grid>

                      <Grid item xs={12} md={6} lg={3}>
                        <Typography variant="body2" color={grey[600]}>
                          Year
                        </Typography>
                        <Typography
                          variant="h6"
                          fontWeight="medium"
                          sx={{ textTransform: 'capitalize' }}
                        >
                          {fleetVehicleDetails.year}
                        </Typography>
                      </Grid>

                      <Grid item xs={12} md={6} lg={3}>
                        <Typography variant="body2" color={grey[600]}>
                          Make
                        </Typography>
                        <Typography
                          variant="h6"
                          fontWeight="medium"
                          sx={{ textTransform: 'capitalize' }}
                        >
                          {fleetVehicleDetails.make}
                        </Typography>
                      </Grid>

                      <Grid item xs={12} md={6} lg={3}>
                        <Typography variant="body2" color={grey[600]}>
                          Model
                        </Typography>
                        <Typography
                          variant="h6"
                          fontWeight="medium"
                          sx={{ textTransform: 'capitalize' }}
                        >
                          {fleetVehicleDetails.model}
                        </Typography>
                      </Grid>

                      <Grid item xs={12} md={6} lg={3}>
                        <Typography variant="body2" color={grey[600]}>
                          Insurance Provider
                        </Typography>
                        <Typography
                          variant="h6"
                          fontWeight="medium"
                          sx={{ textTransform: 'capitalize' }}
                        >
                          {fleetVehicleDetails.insuranceProvider || '-'}
                        </Typography>
                      </Grid>

                      <Grid item xs={12} md={6} lg={3}>
                        <Typography variant="body2" color={grey[600]}>
                          Policy Number
                        </Typography>
                        <Typography
                          variant="h6"
                          fontWeight="medium"
                          sx={{ textTransform: 'capitalize' }}
                        >
                          {fleetVehicleDetails.policyNumber || '-'}
                        </Typography>
                      </Grid>

                      <Grid item xs={12} md={6} lg={3}>
                        <Typography variant="body2" color={grey[600]}>
                          Expiration Date
                        </Typography>
                        <Typography
                          variant="h6"
                          fontWeight="medium"
                          sx={{ textTransform: 'capitalize' }}
                        >
                          {fleetVehicleDetails.expirationDate
                            ? moment(parseInt(fleetVehicleDetails?.expirationDate,10)).format('MM/DD/YYYY') : '-'}
                        </Typography>
                      </Grid>

                      <Grid item xs={12} md={12} lg={12}>
                        <Stack direction="column" spacing={1.25} mb={2}>
                          <Typography variant="body2" mb={2} color={grey[600]}>
                            Policy File(Image/pdf)
                          </Typography>
                          {policyFile?.endsWith('.pdf') ?
                            <Link to={policyFile} style={{ color: 'inherit' }}><Typography variant='body2' fontWeight="medium" sx={{ wordBreak: 'break-all' }}>{policyFile}</Typography></Link> 
                            :
                            <Zoom classDialog="custom-zoom">
                              {' '}
                              <img
                                style={{ borderRadius: '20px', width: '160px', height: '160px', objectFit: 'cover' }}
                                src={policyFile || defaultImage}
                                alt="policy file"
                              />
                            </Zoom>
                          }
                        </Stack>
                      </Grid>

                      <Grid item xs={12} md={6} lg={3}>
                        <Typography variant="body2" color={grey[600]}>
                          Driver&rsquo;s License Number
                        </Typography>
                        <Typography
                          variant="h6"
                          fontWeight="medium"
                          sx={{ textTransform: 'capitalize' }}
                        >
                          {fleetOtherInfo.licenseNumber || '-'}
                        </Typography>
                      </Grid>

                      <Grid item xs={12} md={6} lg={3}>
                        <Typography variant="body2" color={grey[600]}>
                          Date of birth
                        </Typography>
                        <Typography
                          variant="h6"
                          fontWeight="medium"
                          sx={{ textTransform: 'capitalize' }}
                        >
                          {fleetOtherInfo.dob ? moment(parseInt(fleetOtherInfo?.dob,10)).format('MM/DD/YYYY') : '-'}
                        </Typography>
                      </Grid>

                      <Grid item xs={12} md={6} lg={3}>
                        <Typography variant="body2" color={grey[600]}>
                          Expiration Date
                        </Typography>
                        <Typography
                          variant="h6"
                          fontWeight="medium"
                          sx={{ textTransform: 'capitalize' }}
                        >
                          {fleetOtherInfo.licenseExpirationDate ? moment(parseInt(fleetOtherInfo.licenseExpirationDate, 10)).format('MM/DD/YYYY') : '-'}
                        </Typography>
                      </Grid>

                      <Grid item xs={12} md={12} lg={12}>
                        <Typography variant="body2" mb={2} color={grey[600]}>
                          ID File(Image/pdf)
                        </Typography>
                        <Stack direction="row" spacing={1.25}>
                          {idFile?.endsWith('.pdf') ?
                            <Link to={idFile} style={{ color: 'inherit' }}><Typography variant='body2' fontWeight="medium" sx={{ wordBreak: 'break-all' }}>{idFile}</Typography></Link> 
                            :
                            <Zoom classDialog="custom-zoom">
                              {' '}
                              <img
                                style={{ borderRadius: '20px', width: '160px', height: '160px', objectFit: 'cover' }}
                                src={idFile || defaultImage}
                                alt="id file"
                              />
                            </Zoom>
                          }
                        </Stack>
                      </Grid>

                    </Grid>

                  </Grid>

                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </CommonLayout>
    </>
  );
}
