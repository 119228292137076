import React, { useState, useEffect } from 'react';
import {  Typography, CircularProgress, Grid, TextField, Box, Card } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { isEmptyObject } from 'utilities/common';
import { validateEmployeeData } from 'validations/hyperwolf/employee';
import { addEmployee } from '../../../redux/slices/hyperwolf/employee';
import InputMask from 'react-input-mask';
import CommonLayout from 'layouts/DashboardLayout';
import MDButton from 'components/MDButton';

const initialEmployee = {
  name: '',
  email: '',
  phone: '',
};

function AddEmployee() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { employeeId } = useParams();
  const { isLoading = false, singleEmployee } = useSelector(state => state.employee);
  const [phoneError, setPhoneError] = useState('');
  const [user, setUser] = useState({ ...initialEmployee });
  const [errors, setErrors] = useState({});
  const isEditdata = !isEmptyObject(singleEmployee);


  useEffect(() => {
    if (isEditdata) {
      const formattedPhone = singleEmployee.phone || '';
      setUser({ ...singleEmployee, phone: formattedPhone });
    }
  }, [isEditdata, singleEmployee]);

  const onChangehandler = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
    if (name === 'phone') {
      setPhoneError('');
    }
  };


  const addUser = () => {
    const { errors: validationErrors, isValid: isDataValid } = validateEmployeeData(user);
    setErrors(validationErrors);

    const isPhoneValid = validatePhoneNumber(user?.phone);
    if (!isPhoneValid) {
      setPhoneError('Invalid phone number');
    } else {
      setPhoneError('');
    }

    let phoneToSend = user.phone;

    if (isDataValid && isPhoneValid) {
      phoneToSend = phoneToSend.replace(/^\+1/, '');
      const userForPayload = { ...user, phone: phoneToSend };
      dispatch(addEmployee({ data: userForPayload, id: (user._id || '') })).unwrap().then(() => {
        navigate('/hyperwolf/manage-employess');
      });
    }
  };

  const validatePhoneNumber = (phone) => {
    const numericPhone = phone.replace(/\D/g, ''); // Extract numeric digits only
    return numericPhone.length > 8 && numericPhone.length <= 11;
  };

  return (
    <CommonLayout>
      <Box p={{ xs: 2, sm: 3, lg: 4 }}>
        <Card sx={{ p: { xs: 2, md: 3 }, borderRadius: 2.5, boxShadow: 'none' }}>
          <Typography variant="h4" className="d-flex jc-ctr">{employeeId || singleEmployee?._id ? 'Update' : 'Add'} Employee</Typography>
          <Grid container spacing={2} component="main" className={'add-zipcode-modal-container'}>
            <Grid item xs={12} md={6} lg={6}>
              <TextField
                name="name"
                value={user.name || ''}
                onChange={onChangehandler}
                label="Name"
                className="w-100"
                margin="normal"
                error={Boolean(errors.name)}
                helperText={errors?.name}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6} >
              <TextField
                disabled={isEditdata}
                name="email"
                value={user?.email || ''}
                onChange={onChangehandler}
                label="Email"
                className="w-100"
                margin="normal"
                error={Boolean(errors.email)}
                helperText={errors?.email}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <InputMask
                className="form-input"
                fullWidth
                error={phoneError}
                helperText={phoneError}
                label="Phone*"
                name="phone"
                value={user?.phone}
                onChange={onChangehandler}
                mask='(999) 999-9999'
                maskChar=''
              >
                {(inputProps) => <TextField {...inputProps} disableUnderline />}
              </InputMask>

            </Grid>
          </Grid>
          <Box mt={2} textAlign='end'>
            <MDButton onClick={addUser} variant="contained" color="primary" >{isLoading ? <CircularProgress size={22} style={{ color: 'white' }} /> : 'Submit'}</MDButton>
          </Box>
        </Card>
      </Box>

    </CommonLayout>
  );
}

export default AddEmployee;