import React from 'react'
import { Riple } from 'react-loading-indicators'
import logo from "../assets/images/logo.png"
import { Backdrop } from '@mui/material'

const HyperdriveLoading = () => {
    return (
        <div className='loading-Spinner'>
            {/* <Riple color="#3135cc" size="medium" text="" textColor="" /> */}
            {/* <img src={logo} height={100} /> */}
            <Backdrop
                sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
                open={true}
            >
               <Riple color="#3135cc" size="medium" text="" textColor="" /> 
            </Backdrop>
        </div>
    )
}

export default HyperdriveLoading