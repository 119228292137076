import React from 'react';
import TextField from '@mui/material/TextField';
import { Autocomplete } from '@mui/material';

export default function CustomMultiSelect(props) {

    const { options = [], name, handleChange = () => { }, value, label, placeholder, className, multiple = true, required = false, ...rest } = props
    return (
        <Autocomplete
            multiple={multiple}
            className={className}
            id="checkboxes-tags-demo"
            options={options}
            disableCloseOnSelect
            getOptionLabel={(option) => { return (option && option[name]) || "" }}
            onChange={(e, val) => { handleChange(e, val) }}
            value={value}
            renderInput={(params) => (
                <TextField {...params} variant="outlined" label={label} placeholder={placeholder} required={required} />
            )}
            {...rest}
        />
    );
}