import { Avatar, Box,  ButtonGroup, Card, CardActions, CardContent, Grid,   Stack,  Typography } from '@mui/material';
import MDButton from 'components/MDButton';
import Search from 'examples/Search';
import CommonLayout from 'layouts/DashboardLayout';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {  openSnackbar } from '../../redux/slices/common';
import { closeCustomModalDialog, openCustomModalDialog, setModalLoader } from '../../redux/slices/modalSlice';
import { deleteCategory, fetchCategoriesList } from '../../redux/slices/category';
import { useDebounce } from 'hooks/useDebounce';
import EditIcon from 'common/customIcons/edit';
import DeleteIcon from 'common/customIcons/delete';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import StatusText from 'common/component/StatusText';
import MDBox from 'components/MDBox';
import NoDataFound from 'common/component/NoDataFound';
import ManageCategories from 'layouts/manageCategories';
import CommonLoading from 'components/common/CommonLoading';

export default function Categories() {
  const [currentTab, setCurrentTab] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { categoriesList, loading = false } = useSelector(state => state.category);
  const pageRef = useRef(0);
  const { search } = useLocation();
  const searchTermRef = useRef('');
  const [searchTerm, setSearchTerm] = useState('');
  const handleSearch = useDebounce(() => {
    // Perform search operation with the debounced term
    pageRef.current = 0;
    searchFn();
  }, 500);

  const [mount, setMount] = useState(false);
  useEffect(() => {
    if (!mount) return;
    if (search) {
      const selectedTab = search?.split('=')?.[1] || '';
      if (selectedTab !== currentTab) {
        navigate(`/categories?filter=${selectedTab}`);
        setCurrentTab(selectedTab);
      }
    }
    else {
      navigate('/categories?filter=categories');
      setCurrentTab('');
    }
  },[search,mount]);

  useEffect(() => {
    setMount(true);
    return () => {
      setMount(false);
    };
  }, []);


  const deleteHandler = (categoryId) => {
    dispatch(setModalLoader(true));
    dispatch(deleteCategory(categoryId)).unwrap().then((res) => {
      showError(res?.message, 'success');
      dispatch(closeCustomModalDialog());
      pageRef.current = 0;
      getCategories();
    }).catch((err) => {
      dispatch(setModalLoader(false));
      showError(err?.message, 'error');
    });
  };

  const showError = (message, errortype) => {
    if (errortype === 'success') {
      dispatch(openSnackbar({ content: message, color: 'success', icon: 'check' }));
    }
    if (errortype === 'error') {
      dispatch(openSnackbar({ content: message, color: 'error', icon: 'warning' }));
    }
  };

  const getCategories = () => {
    dispatch(fetchCategoriesList({ skip: pageRef.current, limit: 100, userType: 'admin', searchTerm: searchTermRef.current })).unwrap();
  };

  const searchHandler = (event) => {
    const { value } = event.target;
    setSearchTerm(value);
    searchTermRef.current = value;
    handleSearch(value);
    // Debounce the search callback
  };
  const searchFn = () => {
    getCategories();
  };

  useEffect(() => {
    getCategories();
  }, []);
  return (<>

    { currentTab === 'manage_categories' ? <ManageCategories />  :
      <CommonLayout>
        <Box p={{xs: 2, sm: 3, lg: 4}}>
          <Grid container alignItems="center" rowSpacing={2} columnSpacing={2} mb={3}>
            <Grid item xs={12} sm={4}>
              <Typography variant='h4'>Sub Categories</Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Stack direction="row" justifyContent={{xs: 'start', sm: 'end'}} flexWrap="wrap" alignItems="stretch" gap={2}>
                <Search placeholder="Search" label="" value={searchTerm} onChange={searchHandler} />
                <MDButton
                  startIcon={<AddRoundedIcon fontSize='large' />}
                  variant="contained" color="primary"
                  onClick={() => navigate('/categories/add')} >
                                Add Category
                </MDButton>
              </Stack>
            </Grid>
          </Grid>
          {
            categoriesList?.categories?.length > 0 ?  <Grid container spacing={2} mb={4}>
              {categoriesList?.categories?.map((item, index) => (
                <Grid item xs={12} sm={6} xl={4} key={index}>
                  <Card sx={{ boxShadow: 0, height: '100%', justifyContent: 'space-between' }}>
                    <CardContent sx={{ p: 2.5 }}>
                      <Stack direction="row" alignItems="flex-start" justifyContent="space-between">
                        <Box display="flex">
                          <Avatar
                            alt="Product Name"
                            src={item?.categoryImage}
                            sx={{ width: 68, height: 68, borderRadius: 2 }}
                          />
                          <MDBox textAlign="left" ml={1.5} lineHeight={1}>
                            <Typography variant="h5" fontSize={14} color="dark" fontWeight="semibold">{item?.categoryName}</Typography>
                            <Typography variant="p" fontSize={12} color="secondary">ID: {item?.categoryId}</Typography>
                          </MDBox>
                        </Box>

                        <Box color="#24CA49" display="flex" alignItems="center">
                          <StatusText status={item?.status?.toLowerCase()} />
                        </Box>
                      </Stack>

                      <Box sx={{ bgcolor: '#F2F3F7', px: 1.75, py: 1.25, borderRadius: 2.5, mt: 2.5 }}>
                        <Typography variant="body2" fontSize={12} color="dark" className="truncate line-3">{item?.description?.replace(/<\/?[^>]+(>|$)/g, '')}</Typography>
                      </Box>
                    </CardContent>
                    <CardActions sx={{ p: 0 }}>
                      <ButtonGroup className='card-footer-action' fullWidth variant="outlined">
                        <MDButton
                          type='button'
                          variant='outlined'
                          color='dark'
                          size="small"
                          onClick={() => navigate(`/categories/${item?.categoryId}`)}
                          startIcon={<EditIcon width='15px' height='15px' color="#0163FF" />}
                        >
                                                Edit
                        </MDButton>
                        <MDButton
                          type='button'
                          variant='outlined'
                          color='dark'
                          size="small"
                          onClick={() => {
                            dispatch(openCustomModalDialog({ modalData: { deleteHandler, deleteId: item?.categoryId }, modal: 'DELETE_MODAL' }));
                          }}
                          startIcon={<DeleteIcon width='15px' height='15px' color='#FD4438' />}
                        >
                                                Delete
                        </MDButton>
                      </ButtonGroup>
                    </CardActions>
                  </Card>
                </Grid>
              ))} 
            </Grid> :
                    
              <Grid item xs={12}>
                {loading ? <CommonLoading isLoading={loading} /> :  <NoDataFound text="No Category Found" />}
              </Grid>}
        </Box>
      </CommonLayout>
    }
  </>);
}