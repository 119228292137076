import React from 'react';

export default function BreakTaskIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
      <g clipPath="url(#clip0_11258_23983)">
        <path d="M11.2409 4.16748L11.6668 3.74165C11.8945 3.51386 11.8946 3.14456 11.6668 2.91674C11.439 2.68886 11.0696 2.68883 10.8418 2.91667L10.3576 3.40083C9.53841 2.8203 8.58247 2.4625 7.58342 2.3625V1.16667H8.16675C8.48892 1.16667 8.75009 0.9055 8.75009 0.583333C8.75009 0.261167 8.48892 0 8.16675 0H5.83342C5.51125 0 5.25008 0.261167 5.25008 0.583333C5.25008 0.9055 5.51125 1.16667 5.83342 1.16667H6.41675V2.3625C5.4177 2.4625 4.46176 2.8203 3.64256 3.40083L3.1584 2.91667C2.93056 2.68883 2.56114 2.68886 2.33334 2.91674C2.1056 3.14456 2.10563 3.51386 2.33342 3.74165L2.75925 4.16748C1.9768 4.99593 1.45413 6.03542 1.25579 7.15757C1.05745 8.27973 1.19213 9.4354 1.6432 10.4819C2.09426 11.5283 2.84196 12.4198 3.79395 13.0461C4.74594 13.6724 5.86054 14.0062 7.00009 14.0062C8.13963 14.0062 9.25423 13.6724 10.2062 13.0461C11.1582 12.4198 11.9059 11.5283 12.357 10.4819C12.808 9.4354 12.9427 8.27973 12.7444 7.15757C12.546 6.03542 12.0234 4.99593 11.2409 4.16748ZM7.00009 12.8333C6.07711 12.8333 5.17485 12.5596 4.40742 12.0469C3.63999 11.5341 3.04186 10.8052 2.68865 9.95253C2.33544 9.0998 2.24302 8.16149 2.42309 7.25625C2.60315 6.351 3.04761 5.51948 3.70025 4.86684C4.3529 4.21419 5.18442 3.76973 6.08966 3.58967C6.99491 3.40961 7.93322 3.50202 8.78594 3.85523C9.63866 4.20844 10.3675 4.80658 10.8803 5.57401C11.3931 6.34144 11.6668 7.24369 11.6668 8.16667C11.6654 9.40392 11.1733 10.5901 10.2984 11.465C9.42351 12.3398 8.23734 12.8319 7.00009 12.8333Z" fill="#05EFE1"/>
        <path d="M7.1999 4.67222C7.08962 4.66591 7 4.75605 7 4.8665V8.1665H3.7C3.58954 8.1665 3.4994 8.25612 3.50571 8.3664C3.54129 8.9884 3.74238 9.59097 4.08986 10.111C4.47444 10.6866 5.02107 11.1352 5.66061 11.4001C6.30015 11.665 7.00388 11.7343 7.68282 11.5993C8.36175 11.4642 8.98539 11.1309 9.47487 10.6414C9.96436 10.1519 10.2977 9.52825 10.4327 8.84932C10.5678 8.17039 10.4985 7.46665 10.2336 6.82711C9.96867 6.18757 9.52007 5.64095 8.9445 5.25636C8.42447 4.90889 7.8219 4.70779 7.1999 4.67222Z" fill="#05EFE1"/>
      </g>
      <defs>
        <clipPath id="clip0_11258_23983">
          <rect width="14" height="14" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
}