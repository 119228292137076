import { useEffect, useState } from 'react';

// @mui material components
import Grid from '@mui/material/Grid';

import MDButton from 'components/MDButton';

import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { setLayout } from '../../../redux/slices/materialUISlice';
import { useDispatch, useSelector } from 'react-redux';

// Images
import { Box,  IconButton, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import {  resetPassword } from '../../../redux/slices/authSlice';
import { openSnackbar } from '../../../redux/slices/common';
import hyperwolfLogo from 'assets/images/hyperwolf.svg';
import authImg from 'assets/images/auth-bg.jpg';
import colors from 'assets/theme/base/colors';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { isEmptyObject } from 'utilities/common';

function extractTokenFromURL(url) {
  const params = new URLSearchParams(url);
  return params.get('token');
}

function validatePasswords(passwordObj) {
  const errors = {};

  // Check if password field is empty
  if (!passwordObj.password) {
    errors.password = 'Password is required';
  } else {
    // Check password strength
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/;
    if (!passwordRegex.test(passwordObj.password)) {
      errors.password = 'Password must contain at least 8 characters, including one uppercase letter, one lowercase letter, one number, and one special character';
    }
  }

  // Check if confirmPassword field is empty
  if (!passwordObj.confirmPassword) {
    errors.confirmPassword = 'Confirm password is required';
  } else if (passwordObj.password !== passwordObj.confirmPassword) {
    errors.confirmPassword = 'Passwords do not match';
  }

  // Return errors object if there are any errors, otherwise return null
  return Object.keys(errors).length ? { error: errors } : {};
}

function Basic() {
  const location = useLocation();
  const navigate = useNavigate();
  const { loading = false } = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const [userData, setUserData] = useState({});
  const [errors, setErrors] = useState({});

  const handleResetPasword = async () => {
    const { error } = validatePasswords(userData);
    if (!isEmptyObject(error)) {
      setErrors({ ...error });
      return;
    }
    setErrors({});
    const payload = {
      password: userData?.password,
      token: extractTokenFromURL(location?.search)
    };
    dispatch(resetPassword(payload)).unwrap().then((res) => {
      navigate('/login');
      showError(res?.message, 'success');
    }).catch((err) => {
      showError(err?.message, 'error');
    });
  };

  const showError = (message, errortype) => {
    if (errortype === 'success') {
      dispatch(openSnackbar({ content: message, color: 'success', icon: 'check' }));
    }
    if (errortype === 'error') {
      dispatch(openSnackbar({ content: message, color: 'error', icon: 'warning' }));
    }
  };
  const onChangeHandler = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };
  const { pathname } = useLocation();

  useEffect(() => {
    dispatch(setLayout('singup'));
  }, [pathname]);

  const { primary } = colors;

  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Grid className="auth-pg" alignItems="center" container>
      <Grid item xs={12} md={6} p={2.5} pt={{ xs: 8, md: 2.5 }}>
        <Stack textAlign="center" direction="column" alignItems="center" justifyContent="center" maxWidth="500px" mx="auto" height="100%">
          <Link to='/login'>
            <img src={hyperwolfLogo} alt="HYPERWOLF" height="32px" />
          </Link>

          <Box textAlign="center" mt={6} mb={4}>
            <Typography variant="h2" fontWeight="semibold">Reset Password</Typography>
          </Box>

          <TextField sx={{mb: 3}} error={Boolean(errors?.password)} helperText={errors?.password} fullWidth label="Enter Password" onChange={onChangeHandler} type={showPassword ? 'text' : 'password'} name="password"
            InputProps={{
              endAdornment: (
                <InputAdornment position="center">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="center"
                  >
                    {!showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField error={Boolean(errors?.confirmPassword)} helperText={errors?.confirmPassword} fullWidth label="Confirm Password" onChange={onChangeHandler} type={showConfirmPassword ? 'text' : 'password'} name="confirmPassword"
            InputProps={{
              endAdornment: (
                <InputAdornment position="center">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowConfirmPassword((showConfirmPassword) => !showConfirmPassword)}
                    onMouseDown={handleMouseDownPassword}
                    edge="center"
                  >
                    {!showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
         
          <MDButton onClick={handleResetPasword} sx={{ mt: 3, minWidth: '100%' }} disabled={loading} variant="contained" color="primary">Submit</MDButton>
          <Typography  mt={2} variant='h5' fontWeight="medium">
            Back to login? &nbsp;
            <Link to='/login' style={{color: primary.main}}>Log in</Link>
          </Typography>
        </Stack>
      </Grid>

      <Grid item xs={12} md={6} p={2.5}>
        <img src={authImg} alt="HYPERWOLF" className="auth-banner-img" />
      </Grid>
    </Grid>
  );
}

export default Basic;
