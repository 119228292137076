
export function validateBannerData(data = {}) {
    let errors = {};

    if (!data?.desktop_image) {
        errors.desktop_image = "This  is required";
    }

    if (!data?.mobile_image) {
        errors.mobile_image = "This is required";
    }
    if (!data?.url) {
        errors.url = "URL is required";
    }

    if (data.url && !validURL(data.url)) {
        errors.url = "Please add valid url";
    }

    if (!data?.media_type) {
        errors.media_type = "Media Type is required";
    }

    return {
        isValid: Object.keys(errors).length === 0,
        errors
    }
}

export function validURL(str) {
    var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(str);
  }