import { axiosHyperwolfDevClient } from "axiosClient";
import axiosClientHyperDrive from "axiosClient/indexHyperdrive";
import { getQueryString } from "utilities/common";

// Main Setting API
export function addStartTaskAPI(data) {    
    return axiosClientHyperDrive.post('api/v1/admin/tasks/create',data);
}

export async function fetchHyperwolfTasksRegionsAPI(filters) {
    return await axiosClientHyperDrive.get(`/api/v1/admin/tasks/start/list?${getQueryString(filters)}`);
}

export async function updateTaskRegionInformationAPI(data,regionId) {
    return await axiosClientHyperDrive.put(`api/v1/admin/tasks/start/update/${regionId}`,data);
}

export async function fetchHyperWolfFirstRenderApi() {
    return await axiosHyperwolfDevClient.get(`api/v1/sync/region/start/task`);
}

export async function fetchHyperWolfFirstRenderHQApi() {
    return await axiosClientHyperDrive.get(`api/v1/admin/headquarters/sync/region/task`);
}

export async function fetchAddressHQApi(filters) {
    return await axiosClientHyperDrive.get(`api/v1/admin/headquarters/returnToHQ/address`);
}

export async function updateAddressReturnHQApi(data) {
    return await axiosClientHyperDrive.put(`api/v1/admin/headquarters/returnToHQ/address`,data);
}

export async function fetchHQRegionsApi(filters) {
    return await axiosClientHyperDrive.get(`api/v1/admin/headquarters/returnToHQ/List?${getQueryString(filters)}`);
}

export async function updateHQRegionInformationApi(data,regionId) {
    return await axiosClientHyperDrive.put(`api/v1/admin/headquarters/returnToHQ/update/${regionId}`,data);
}


