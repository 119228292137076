import { axiosHyperwolfClient } from "axiosClient";
import { getQueryString } from "utilities/common";

export function getAllAdminAPI(filters) {
    return axiosHyperwolfClient.get(`api/v1/admin/get?${getQueryString(filters)}`);
}

export function addUserAPI(data, id = '') {
    if (id) {
        return axiosHyperwolfClient.put(`api/v1/admin/edit/${id}`, data);
    }
    return axiosHyperwolfClient.post('api/v1/admin/create', data);
}

export function deleteUserAPI(id) {
    return axiosHyperwolfClient.delete(`api/v1/admin/delete/${id}`)
}
