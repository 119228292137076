import { Box,  Grid, Stack, Typography } from '@mui/material';
import MDButton from 'components/MDButton';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { submitNotification } from '../../../redux/slices/hyperwolf/driver/approvals';
import { closeCustomModalDialog, openCustomModalDialog } from '../../../redux/slices/modalSlice';
import { openSnackbar } from '../../../redux/slices/common';
import { convertToTimestampWithMoment } from 'utilities/common';
import { formatTimestampToDateView } from 'utilities/common';
import { capitalizeText } from 'utilities/common';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import ActiveIcon from 'common/customIcons/active';
import InActiveIcon from 'common/customIcons/inactive';
import { formatUsPhoneNumber } from 'utilities/common';

const DriverApprovalDrawer = ({ details, resetData}) => {
  const dispatch = useDispatch();
  const[arrayDetail, setArrayDetail] = useState([]);
    
  const statusAction = details.notificationStatus === 'pending' ? 'pending-class' : 'default-class';

  useEffect(() => {

    if (details) {
      setArrayDetail([details.fleetDetails] );
    } else {
      setArrayDetail([]);
    }
  }, [details]);


  const handleApproveNotification = () => {
    dispatch(submitNotification({notificationId: details.notificationId , notificationStatus: 'approved' }))
      .unwrap().then(res => { 
        showResponseMessage(res.data.message, 'success');
        resetData();
      });
  };

  const showResponseMessage = (message, errortype) => {
    if (errortype === 'success') {
      dispatch(openSnackbar({ content: message, color: 'success', icon: 'check' }));
    }
    if (errortype === 'error') {
      dispatch(openSnackbar({ content: message, color: 'error', icon: 'warning' }));
    }
  };

  const approvalStatusHandler=(notificationId, reason)=> {
    dispatch(submitNotification({notificationId: details.notificationId, notificationStatus:'declined', cancellationReason:reason})).unwrap().then((res) => {
      showResponseMessage(res?.data.message, 'success');
      dispatch(closeCustomModalDialog());
    }).catch((err) => {
      showResponseMessage(err?.message, 'error');
    });
  };

  const handleDeclineHandler = (notificationId) => {
    dispatch(openCustomModalDialog({
      modal: 'DECLINE_DRIVER_REQUEST_REASON', modalData: {
        notificationId: details.notificationId, resetData, approvalStatusHandler: (reason) => {
          approvalStatusHandler(notificationId, reason);
        }
      }
    }));
  };
   
  return (
    <>
      {
        arrayDetail?.map((approval,index) => (
          <div className="approval-sidebar" key={index}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" p={2.5} gap={2} style={{ borderBottom: '1px solid #F1F1F1', minHeight: '65px' }}>
              <Typography className="text-grey-color text-14" fontWeight="semibold">Approval</Typography>
              <Typography style={{ color: details.notificationStatus === 'pending' ? '#8E0CF5' : details.notificationStatus === 'approved' ? '#24CA49' : '#FD4438' }} className="text-12" mr={4} pr={1} display="inline-flex" alignItems="center" gap={0.5}>
                {details.notificationStatus === 'pending' ? <AccessTimeFilledIcon fontSize="" style={{fontSize: '15px', marginTop: '-1px'}} /> :

                  details.notificationStatus === 'approved'  ? <ActiveIcon width="15px" height="15px" flex="none" />  : 
                                
                    details.notificationStatus === 'declined'  ? <InActiveIcon width="15px" height="15px" flex="none" /> : ''
                                
                } {capitalizeText(details.notificationStatus)}
              </Typography>
            </Stack>

            <Box className={`drawer-inner-scroll ${statusAction}`} p={2.5}>
              <Grid container alignItems="center" rowSpacing={3} columnSpacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography className="text-grey-color text-14">Driver Name</Typography> 
                  <Typography variant="h5" className="text-color text-16" fontWeight="600">{approval?.fleetName}</Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography className="text-grey-color text-14">Phone</Typography>
                  <Typography variant="h5" className="text-color text-16" fontWeight="600">{formatUsPhoneNumber(approval?.fleetPhone)}</Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography className="text-grey-color text-14">Request Date</Typography>
                  <Typography variant="h5" className="text-color text-16" fontWeight="600">{formatTimestampToDateView(convertToTimestampWithMoment(details?.createdDate))}</Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography className="text-grey-color text-14">Vehicle</Typography>
                  <Typography variant="h5" className="text-color text-16" fontWeight="600">Car</Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography className="text-grey-color text-14">Model</Typography>
                  <Typography variant="h5" className="text-color text-16" fontWeight="600">{approval?.fleetVehicleDetails?.model}</Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography className="text-grey-color text-14">Make</Typography>
                  <Typography variant="h5" className="text-color text-16" fontWeight="600">{approval?.fleetVehicleDetails?.make}</Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography className="text-grey-color text-14">License Plate</Typography>
                  <Typography variant="h5" className="text-color text-16" fontWeight="600">{approval?.fleetVehicleDetails?.licensePlate}</Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography className="text-grey-color text-14">Color</Typography>
                  <Typography variant="h5" className="text-color text-16" fontWeight="600">{approval?.fleetVehicleDetails?.color}</Typography>
                </Grid>

                {details.notificationStatus !== 'approved' &&
                                    <Grid item xs={12} sm={12}>
                                      <div className="drawer-inner-bg">
                                        <Stack direction="column" gap={2.5}>
                                          <Typography className="text-grey-color text-14" mt={-0.5}>Request Type</Typography>
                                          {
                                            (approval.fleetVehicleDetails.year !== details.notificationObject.fleetVehicleDetails.year) &&
                                                    <>
                                                      <Grid container alignItems="center" columnSpacing={2}>
                                                        <Grid item xs={12}>
                                                          <Typography variant="h5" className="text-color text-16" mb={1} fontWeight="600">Year</Typography> 
                                                        </Grid>
                                                        <Grid item xs={12} sm={6}>
                                                          <Grid bgcolor="#ffffff" className="border-radius-8 px-3 py-2">
                                                            <Typography className="text-grey-color text-14">Previous Value</Typography>
                                                            <Typography variant="h5" className="text-color text-16" fontWeight="600">{approval.fleetVehicleDetails.year}</Typography>
                                                          </Grid>
                                                        </Grid>
                                                        <Grid item xs={12} sm={6}>
                                                          <Grid bgcolor="#ffffff" className="border-radius-8 px-3 py-2">
                                                            <Typography className="text-grey-color text-14">Requested Value</Typography>
                                                            <Typography variant="h5" className="text-color text-16" fontWeight="600">{details.notificationObject.fleetVehicleDetails.year}</Typography>
                                                          </Grid>
                                                        </Grid>
                                                      </Grid>
                                                    </>
                                          }   

                                          {
                                            (approval.fleetVehicleDetails.color !== details.notificationObject.fleetVehicleDetails.color) &&
                                                    <>
                                                      <Grid container alignItems="center" columnSpacing={2}>
                                                        <Grid item xs={12}>
                                                          <Typography variant="h5" className="text-color text-16" mb={1} fontWeight="600">Color</Typography> 
                                                        </Grid>
                                                        <Grid item xs={12} sm={6}>
                                                          <Grid bgcolor="#ffffff" className="border-radius-8 px-3 py-2">
                                                            <Typography className="text-grey-color text-14">Previous Value</Typography>
                                                            <Typography variant="h5" className="text-color text-16" fontWeight="600">{approval.fleetVehicleDetails.color}</Typography>
                                                          </Grid>
                                                        </Grid>
                                                        <Grid item xs={12} sm={6}>
                                                          <Grid bgcolor="#ffffff" className="border-radius-8 px-3 py-2">
                                                            <Typography className="text-grey-color text-14">Requested Value</Typography>
                                                            <Typography variant="h5" className="text-color text-16" fontWeight="600">{details.notificationObject.fleetVehicleDetails.color}</Typography>
                                                          </Grid>
                                                        </Grid>
                                                      </Grid>
                                                    </>
                                          }   

                                          {
                                            (approval.fleetVehicleDetails.make !== details.notificationObject.fleetVehicleDetails.make) &&
                                                    <>
                                                      <Grid container alignItems="center" columnSpacing={2}>
                                                        <Grid item xs={12}>
                                                          <Typography variant="h5" className="text-color text-16" mb={1} fontWeight="600">Make</Typography>      
                                                        </Grid>
                                                        <Grid item xs={12} sm={6}>
                                                          <Grid bgcolor="#ffffff" className="border-radius-8 px-3 py-2">
                                                            <Typography className="text-grey-color text-14">Previous Value</Typography>
                                                            <Typography variant="h5" className="text-color text-16" fontWeight="600">{approval.fleetVehicleDetails.make}</Typography>
                                                          </Grid>
                                                        </Grid>
                                                        <Grid item xs={12} sm={6}>
                                                          <Grid bgcolor="#ffffff" className="border-radius-8 px-3 py-2">
                                                            <Typography className="text-grey-color text-14">Requested Value</Typography>
                                                            <Typography variant="h5" className="text-color text-16" fontWeight="600">{details.notificationObject.fleetVehicleDetails.make}</Typography>
                                                          </Grid>
                                                        </Grid>
                                                      </Grid>
                                                    </>
                                          }  

                                          {
                                            (approval.fleetVehicleDetails.model !== details.notificationObject.fleetVehicleDetails.model) &&
                                                    <>
                                                      <Grid container alignItems="center" columnSpacing={2}>
                                                        <Grid item xs={12}>
                                                          <Typography variant="h5" className="text-color text-16" mb={1} fontWeight="600">Model</Typography>    
                                                        </Grid>
                                                        <Grid item xs={12} sm={6}>
                                                          <Grid bgcolor="#ffffff" className="border-radius-8 px-3 py-2">
                                                            <Typography className="text-grey-color text-14">Previous Value</Typography>
                                                            <Typography variant="h5" className="text-color text-16" fontWeight="600">{approval.fleetVehicleDetails.model}</Typography>
                                                          </Grid>
                                                        </Grid>
                                                        <Grid item xs={12} sm={6}>
                                                          <Grid bgcolor="#ffffff" className="border-radius-8 px-3 py-2">
                                                            <Typography className="text-grey-color text-14">Requested Value</Typography>
                                                            <Typography variant="h5" className="text-color text-16" fontWeight="600">{details.notificationObject.fleetVehicleDetails.model}</Typography>
                                                          </Grid>
                                                        </Grid>
                                                      </Grid>
                                                    </>
                                          }

                                          {
                                            (approval.fleetVehicleDetails.licensePlate !== details.notificationObject.fleetVehicleDetails.licensePlate) &&
                                                    <>
                                                      <Grid container alignItems="center" columnSpacing={2}>
                                                        <Grid item xs={12}>
                                                          <Typography variant="h5" className="text-color text-16" mb={1} fontWeight="600">License Plate</Typography>      
                                                        </Grid>
                                                        <Grid item xs={12} sm={6}>
                                                          <Grid bgcolor="#ffffff" className="border-radius-8 px-3 py-2">
                                                            <Typography className="text-grey-color text-14">Previous Value</Typography>
                                                            <Typography variant="h5" className="text-color text-16" fontWeight="600">{approval.fleetVehicleDetails.licensePlate}</Typography>
                                                          </Grid>
                                                        </Grid>
                                                        <Grid item xs={12} sm={6}>
                                                          <Grid bgcolor="#ffffff" className="border-radius-8 px-3 py-2">
                                                            <Typography className="text-grey-color text-14">Requested Value</Typography>
                                                            <Typography variant="h5" className="text-color text-16" fontWeight="600">{details.notificationObject.fleetVehicleDetails.licensePlate}</Typography>
                                                          </Grid>
                                                        </Grid>
                                                      </Grid>
                                                    </>
                                          }
                                        </Stack>
                                      </div>
                                    </Grid>
                }

                {details.cancellationReason &&
                                    <Grid item xs={12}>
                                      <Typography className="text-grey-color text-14">Decline Reason</Typography>
                                      <Typography variant="h5" className="text-color text-16" fontWeight="600" style={{whiteSpace: 'normal'}}>{details.cancellationReason}</Typography>
                                    </Grid>
                }
              </Grid>
            </Box>
          </div> 
        ))
      }

      {details.notificationStatus === 'pending' &&         
                <Stack direction="row" justifyContent="space-between" alignItems="center" p={2.5} gap={2} bgcolor="#F2F3F7" style={{ borderRadius: '16px 16px 0 0', minHeight: '85px' }} mt="auto">
                  <MDButton onClick={handleApproveNotification} style={{ border: '1px solid #24CA49', backgroundColor: '#24CA49', color: '#ffffff', flex: '1' }}>
                        Accept
                  </MDButton>
                  <MDButton onClick={()=>handleDeclineHandler(details.notificationId)} style={{ border: '1px solid #FD4438', backgroundColor: '#ffffff', color: '#FD4438', flex: '1' }}>
                        Decline
                  </MDButton>
                </Stack>
      }
    </>
  );
};

export default DriverApprovalDrawer;