import axios from 'axios';
import { getData } from 'utilities/common';

// Create an instance of Axios with default configurations
const axiosClientHyperDrive = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL_HYPER_DRIVE, // Replace with your axiosClient base URL
    headers: {
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        'Pragma': 'no-cache',
        'Expires': 0
    }
});

// Request interceptor: Modify requests before they are sent
axiosClientHyperDrive.interceptors.request.use(
    (config) => {
        const user = getData('login-user-info')
        if (user) {
            const parseUser = JSON.parse(user)
            config.headers['Authorization'] = 'Bearer ' + parseUser?.access_token
        }
        return config
    },
    (error) => {
        // Handle request errors here
        return Promise.reject(error);
    }
);

// Response interceptor: Modify responses before they are handled by the calling code
axiosClientHyperDrive.interceptors.response.use(
    (response) => {
        // Modify response data or perform actions before passing it to the calling code
        return response?.data;
    },
    (error) => {
        const originalRequest = error.config
        if (
            error?.response?.status === 401 || error?.response?.data?.message === 'Invalid token!'
        ) {
            window.location.href = "/login"
            localStorage.clear()
            return Promise.reject(error)
        }
      
        return Promise.reject(error)
    }
);

export default axiosClientHyperDrive;