import { axiosHempDevClient } from "axiosClient";
import { getQueryString } from "utilities/common";

export async function fetchBreaksHempAPI(filters) {
    return await axiosHempDevClient.get(`/api/v1/break/list?${getQueryString(filters)}`, )
}

export async function AddBreakHempAPI(data){
  return await axiosHempDevClient.post('/api/v1/break/create',data,
  )
}

export async function DeleteBreakHempAPI(id){
  return await axiosHempDevClient.delete(`/api/v1/break/delete/${id}`,)
}

export async function UpdateBreakHempAPI(id,data){
  return await axiosHempDevClient.put(`/api/v1/break/edit/${id}`,data,
    )
}

export async function fetchBreakDetailsHempAPI(id) {
  return await axiosHempDevClient.get(`/api/v1/break/show/${id}`,
  )
}

export async function exportBreaksHempAPI() {
  return await axiosHempDevClient.get('/api/v1/break/export')
}
