import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid, IconButton, Stack, Typography } from '@mui/material';
import { AgGridTable } from 'components/tables/AgGridTable';
import { useNavigate } from 'react-router-dom';
import MDButton from 'components/MDButton';
import { formatDate } from 'utilities/common';
import { closeCustomModalDialog, openCustomModalDialog, setModalLoader } from '../../../../redux/slices/modalSlice';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import EditIcon from 'common/customIcons/edit';
import DeleteIcon from 'common/customIcons/delete';
import { openSnackbar } from '../../../../redux/slices/common';
import { deleteCannabinoidBanner, getHomepageCannabinoidBanners } from '../../../../redux/slices/cannabinoids';

export default function ManageMainCannabinoidBanners() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pageRef = useRef(0);
  const searchTermRef = useRef('');
  const mainGridApiRef = useRef('');
  const gridRefApi = useRef(null);

  const { cannabinoidBanners: banners = {}, isLoading: loading = false } = useSelector(state => state.cannabinoids);
   
  const deleteHandler = (bannerId) => {
    dispatch(setModalLoader(true));
    dispatch(deleteCannabinoidBanner(bannerId)).unwrap().then((res) => {
      showError(res?.message, 'success');
      dispatch(closeCustomModalDialog());
      pageRef.current = 0;
      const dataSource = {
        getRows
      };
      mainGridApiRef.current.ensureIndexVisible(0, null);
      mainGridApiRef.current.setGridOption('datasource', dataSource);
    }).catch((err) => {
      dispatch(setModalLoader(false));
      showError(err?.message, 'error');
    });
  };

  const showError = (message, errortype) => {
    if (errortype === 'success') {
      dispatch(openSnackbar({ content: message, color: 'success', icon: 'check' }));
    }
    if (errortype === 'error') {
      dispatch(openSnackbar({ content: message, color: 'error', icon: 'warning' }));
    }
  };

  const thumbnail = {
    maxWidth: '120px',
    height: '80px',
    borderRadius: '6px',
    objectFit: 'cover'
  };

  const columnDefs= [

    {
      headerName: 'Desktop',
      field: 'desktop_image',
      suppressMenu: false,
      minWidth: 340,
      cellRenderer: (props) => {
        const { data: item } = props;
        if (!item?.desktop_image) return null;
        return item?.media_type === 'video' ? <video style={thumbnail} loop autoPlay playsInline muted>
          <source key={item.desktop_image} src={item.desktop_image} style={{ borderRadius: '10px' }} type="video/mp4" />
        </video> : <img src={item.desktop_image} style={thumbnail} />;
      },

    },
    {
      headerName: 'Mobile',
      field: 'mobile_image',
      suppressMenu: false,
      minWidth: 340,
      cellRenderer: (props) => {
        const { data: item } = props;
        if (!item?.media_type) return null;
        return item?.media_type === 'video' ? <video style={thumbnail} loop autoPlay playsInline muted>
          <source key={item.mobile_image} src={item.mobile_image} style={{ borderRadius: '10px' }} type="video/mp4" />
        </video> : <img src={item.mobile_image} style={thumbnail} />;
      },

    },
    {
      headerName: 'URL',
      field: 'url',
      suppressMenu: false,
    },
    {
      headerName: 'Media Type',
      field: 'media_type',
      suppressMenu: false,
    },
    {
      headerName: 'Actions',
      cellRenderer: (props) => {
        return <Box display="inline-flex" alignItems="center">
          <IconButton onClick={(e) => {
            e.stopPropagation();
            onEditClicked(props);
          }} color='primary' size='small' variant="primary">
            <EditIcon width='16px' height='16px' />
          </IconButton>
          <IconButton onClick={(e) => {
            e.stopPropagation();
            dispatch(openCustomModalDialog({ modalData: { deleteHandler, deleteId: props.data?._id, deleteType: 'banner' }, modal: 'DELETE_MODAL' }));
          }} color='error' size='small' variant="primary" sx={{ marginLeft: 1 }}>
            <DeleteIcon width='16px' height='16px' />
          </IconButton>
        </Box>;
      },
      cellStyle: { textAlign: 'right', justifyContent: 'end' },
      headerClass: 'right-aligned-cell-header',
      minWidth: 120,
      maxWidth: 100,
      pinned: 'right'
    },

  ];

  function getSortInfo(data) {
    const result = {};
    data.forEach(item => {
      if (item?.colId === 'memberType') {
        result[item.colId] = item.sort === 'asc' ? 'AdultUse' : 'MedicinalUser';
      } else {
        result[item.colId] = item.sort === 'asc';
      }

    });

    return result;
  }

  const getRows = async (params) => {
    gridRefApi.current = params;
    let filter = {};
    if (params?.sortModel.length) {
      filter = { ...getSortInfo(params?.sortModel) };
    }
    dispatch(getHomepageCannabinoidBanners({ skip: pageRef.current,limit:20, searchTerm: searchTermRef.current, ...filter })).unwrap().then(res => {
      const { banners = [], total = 0 } = res;
      const membersArray = banners?.map((item => {
        const date = item?.createdDate ? new Date(item?.createdDate) : '';
        return { ...item, status: item?.status ? 'Active' : 'Inactive', joinedDate: date ? formatDate(date) : '-', id: item.memberId, address: `${item?.deliveryAddress?.address} ${item?.deliveryAddress?.city} ${item?.deliveryAddress?.state} ${item?.deliveryAddress?.zipcode}` };
      }));
      params.successCallback(banners, total);
      pageRef.current = pageRef.current + 20;
    }).catch(() => {
      params.successCallback([], 0);
    });
  };

  const onEditClicked = (row) => {
    navigate(`/manage-cannabinoids/add-main-cannabinoid-banner/${row?.data._id}`);
  };

  useEffect(() => {
    if (mainGridApiRef?.current) {
      if (loading) {
        mainGridApiRef.current.showLoadingOverlay();
      } else if (banners?.total === 0) {
        mainGridApiRef.current.showNoRowsOverlay();
      }
      else {
        mainGridApiRef.current.hideOverlay();
      }
    }

  }, [banners, loading]);

  return (
    <Box p={{ xs: 2, sm: 3, lg: 4 }}>
      <Grid container alignItems="center" rowSpacing={2} columnSpacing={2} mb={3}>
        <Grid item xs={12} sm={4}>
          <Typography variant='h4' fontWeight="semibold">Manage Banners </Typography>
        </Grid>

        <Grid item xs={12} sm={8}>
          <Stack direction="row" justifyContent={{ xs: 'start', sm: 'end' }} flexWrap="wrap" alignItems="stretch" gap={2}>
            <MDButton
              startIcon={<AddRoundedIcon fontSize='large' />}
              variant="contained" color="primary"
              onClick={() => navigate('/manage-cannabinoids/add-main-cannabinoid-banner')} >
                            Add Banner
            </MDButton>
          </Stack>
        </Grid>
      </Grid>

      <AgGridTable col={columnDefs} maxLimit={20} getRows={getRows} mainGridApiRef={mainGridApiRef} pageRef={pageRef} isLoading={loading} style={{ height: '58vh' }}
        gridOptions={{
          rowHeight: 100,
        }}
      />
    </Box>
  );
}