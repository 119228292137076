import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { addMemberAPI,  fetchMemberAPI, fetchMemberDetailsAPI, fetchMemberOrdersAPI } from '../apis/members'
import axiosClient from "../../axiosClient/index";
import { getQueryString } from "utilities/common";
import { openSnackbar } from "./common";
import { addAdminAPI, deleteAdminAPI, fetchAdminAPI, fetchAdminDetailsAPI } from "../../redux/apis/admin";
import { updateAdminAPI } from "../../redux/apis/admin";

export const fetchAdmins = createAsyncThunk(
  'members/fetchAdmins',
  async (filters, { rejectWithValue }) => {
    try {
      const res = await fetchAdminAPI(filters)
      return res
    } catch (error) {
       
      return rejectWithValue(error?.response?.data)
    }
  }
)


export const fetchMemberOrders = createAsyncThunk(
  'members/fetchMemberOrders',
  async ({ filters, adminId }, { rejectWithValue }) => {
    try {
      const res = await fetchMemberOrdersAPI(filters, adminId)
      return res
    } catch (error) {
       
      return rejectWithValue(error?.response?.data)
    }
  }
)

export const fetchAdminDetails = createAsyncThunk(
  'members/fetchAdminDetails',
  async (adminId, { rejectWithValue }) => {
    try {
      const res = await fetchAdminDetailsAPI(adminId)
      return res
    } catch (error) {
       
      return rejectWithValue(error?.response?.data)
    }
  }
)

export const addAdmin = createAsyncThunk(
  'members/addAdmin',
  async (data, { rejectWithValue }) => {
    try {
      const res = await addAdminAPI(data)
      return res
    } catch (error) {
       
      return rejectWithValue(error?.response?.data)
    }
  }
)


export const updateAdmin = createAsyncThunk(
  'members/updateAdmin',
  async ({ data, adminId }, { rejectWithValue }) => {
    try {
      const res = await updateAdminAPI(data, adminId)
      return res
    } catch (error) {
       
      return rejectWithValue(error?.response?.data)
    }
  }
)

export const deleteAdmin = createAsyncThunk(
  'members/deleteAdmin',
  async (adminId, { rejectWithValue }) => {
    try {
      const res = await deleteAdminAPI(adminId)
      return res
    } catch (error) {
       
      return rejectWithValue(error?.response?.data)
    }
  }
)

// Create a slice with reducers
const adminSlice = createSlice({
  name: 'admins',
  initialState: {
    admins: [],
    adminDetails: {},
    loading: false,
    error: null,
  },
  reducers: {
    // Define additional reducers here if needed
  },
  extraReducers: (builder) => {
    // Extra reducer for the fulfilled action of fetchAdmins
    builder.addCase(fetchAdmins.fulfilled, (state, action) => {
      state.loading = false;
      state.admins = action.payload; // Update state with the received data
      state.adminDetails = {}
      state.error = null;
    });

    // Extra reducer for the rejected action of fetchAdmins
    builder.addCase(fetchAdmins.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload; // Update state with the error message
    });

    // Extra reducer for the pending action of fetchAdmins
    builder.addCase(fetchAdmins.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchAdminDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.adminDetails = action.payload.adminData; // Update state with the received details
      state.error = null;
    });

    // Extra reducer for the rejected action of fetchAdminDetails
    builder.addCase(fetchAdminDetails.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload; // Update state with the error message
    });

    // Extra reducer for the pending action of fetchAdminDetails
    builder.addCase(fetchAdminDetails.pending, (state) => {
      state.loading = true;
    });

    builder
      .addCase(fetchMemberOrders.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchMemberOrders.fulfilled, (state, action) => {
        state.loading = false;
        state.memberOrders = action.payload.memberOrders; // Assuming the response is the payload
        state.ordersTotal = action?.payload?.total || 0
      })
      .addCase(fetchMemberOrders.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // Using the error returned from rejectWithValue
      });
    builder
      .addCase(addAdmin.pending, (state) => {
        state.loading = true;
        state.error = null;
      })

    builder.addCase(addAdmin.fulfilled, (state, action) => {
      state.loading = false;
      state.addedAdmin = action.payload; // Update state with the added member data
      state.error = null;
    });

    // Extra reducer for the rejected action of addAdmin
    builder.addCase(addAdmin.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload; // Update state with the error message
    });

    builder
      .addCase(updateAdmin.pending, (state,action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateAdmin.fulfilled, (state, action) => {
        state.loading = false;
        // You can update your state with the data received from the API if needed
      })
      .addCase(updateAdmin.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // The error message returned from the API
      });


    builder
    .addCase(deleteAdmin.pending, (state,action) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(deleteAdmin.fulfilled, (state, action) => {
      state.loading = false;
      // You can update your state with the data received from the API if needed
    })
    .addCase(deleteAdmin.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload; // The error message returned from the API
    });
  },
});

export const { /* Any additional actions */ } = adminSlice.actions;

export default adminSlice.reducer;