import { Close } from '@mui/icons-material';
import { Box, Button, Checkbox, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, Menu, Radio, RadioGroup, Stack, TextField, Typography } from '@mui/material';
import MDButton from 'components/MDButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import GoogleSuggestion from 'components/googleMaps/GoogleSuggestion';
import moment from 'moment';
import InputMask from 'react-input-mask';
import ReassignDriverIcon from 'common/customIcons/reassigndriver';
import MapDriveListDrawer from './MapDriveListDrawer';
import { addStartTask } from '../../../redux/slices/hyperdrive/createTask';
import { openSnackbar } from '../../../redux/slices/common';
import { useSelector } from 'react-redux';

export default function DropOffTask({ closeModal }) {
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});
  const { loading: loading = false } = useSelector(state => state?.taskHyperDrive);

  const addressError = useRef(false);
  const [checkBoxes, setCheckBoxes] = useState({
    no_recipient: '',
    coordinates: ''
  });

  const [formData, setFormData] = useState({
    taskDescription: '',
    address: {
      notes: ''
    },
    taskStartTime: '',
    taskEndTime: '',
    taskAssignmentMode: 'auto',
    taskType: 'deliveryTask',
    taskTag: '',
    fleetId: '',
    recipientDetails: {
      name: '',
      phone: '',
      notes: ''
    },
    orderDetails: '',
    driverAppRequirements: {
      customerSignature: false,
      deliveryPhoto: false,
      addNotes: false
    }
  });

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showManualList, setShowManualList] = useState(false);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleRecipientDetails = (event) => {
    const { name, value } = event.target;
    setFormData(prevFormData => {
      const updatedFormData = { ...prevFormData };
      if (name.startsWith('recipient')) {
        const fieldName = name.replace('recipient', '').toLowerCase();
        updatedFormData.recipientDetails = {
          ...updatedFormData.recipientDetails,
          [fieldName]: value
        };
      } else if (name.startsWith('address')) {
        const fieldName = name.replace('address', '').toLowerCase();
        updatedFormData.address = {
          ...updatedFormData.address,
          [fieldName]: value
        };
      }
      return updatedFormData;
    });
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setFormData(prevState => ({
      ...prevState,
      driverAppRequirements: {
        ...prevState.driverAppRequirements,
        [name]: checked
      }
    }));
  };

  const handleCheckRecipient = (event) => {
    const { name, checked } = event.target;
    setCheckBoxes(prevState => ({
      ...prevState,
      [name]: checked
    }));
  };

  const startTaskHandler = (event) => {
    const { name, value } = event.target;
    const formatDateString = (dateString) => {
      const date = moment(dateString);
      if (!date.isValid()) {
        return '';
      }
      return date.format('YYYY-MM-DD HH:mm:ss');
    };
    const formattedValue = (name === 'taskAssignmentMode' || name === 'taskTag') ? value : (name === 'taskStartTime' || name === 'taskEndTime') ? formatDateString(value) : value;
    setFormData(prevData => {
      const newData = {
        ...prevData,
        [name]: formattedValue
      };

      if (name === 'taskAssignmentMode') {
        if (value === 'manual') {
          setShowManualList(true);
        }
        else {
          setErrors({ fleetId: '' });
          setShowManualList(false);
        }
      }
      const startDate = moment(newData.taskStartTime);
      const endDate = moment(newData.taskEndTime);
      if (name === 'taskStartTime' && newData.taskEndTime) {
        if (endDate.isBefore(startDate)) {
          setErrors(prevData => ({ ...prevData, taskStartTime: 'Start date must be before the end date.' }));
          return prevData;
        } else if (endDate.isSame(startDate)) {
          setErrors(prevData => ({ ...prevData, taskStartTime: 'Start date and end date cannot be the same.' }));
          return prevData;
        }
      }
      if (name === 'taskEndTime' && newData.taskStartTime) {
        if (endDate.isBefore(startDate)) {
          setErrors(prevData => ({ ...prevData, taskEndTime: 'End date must be after the start date.' }));
          return prevData;
        } else if (endDate.isSame(startDate)) {
          setErrors(prevData => ({ ...prevData, taskEndTime: 'Start date and end date cannot be the same.' }));
          return prevData;
        }
      }
      setErrors({ taskStartTime: '', taskEndTime: '' });
      return newData;
    });
  };

  const validateTask = () => {
    let isError = false;
    let errors = {};
    if (!formData?.orderDetails?.trim()) {
      isError = true;
      errors = { ...errors, orderDetails: 'Order Detail is required' };
    }
    if (!formData?.taskDescription?.trim()) {
      isError = true;
      errors = { ...errors, taskDescription: 'Task Description is required' };
    }
    if (!formData?.taskStartTime?.trim()) {
      isError = true;
      errors = { ...errors, taskStartTime: 'Start Date is required' };
    }
    if (!formData?.taskEndTime?.trim()) {
      isError = true;
      errors = { ...errors, taskEndTime: 'End Date is required' };
    }
    if (!checkBoxes?.no_recipient) {
      if (!formData?.recipientDetails?.name?.trim()) {
        isError = true;
        errors = { ...errors, recipientName: 'Name is required' };
      }
      if (!formData?.recipientDetails?.phone?.trim()) {
        isError = true;
        errors = { ...errors, recipientPhone: 'Phone is required' };
      }
    }
    if (!formData?.taskTag?.trim()) {
      isError = true;
      errors = { ...errors, taskTag: 'Tag is required' };
    }
    return { errors, isError };
  };

  const submitHandler = () => {
    const validationResult = validateTask();
    let isError = validationResult.isError;
    let errors = { ...validationResult.errors };
    setErrors(errors);
    if (isError) return;
    if (formData?.taskAssignmentMode === 'manual') {
      if (!formData?.fleetId?.trim()) {
        isError = true;
        errors = { ...errors, fleetId: 'Please select Driver' };
        setErrors(errors);
        return false;
      }
    }
    dispatch(addStartTask({ data: formData }))
      .unwrap()
      .then(res => {
        showResponseMessage(res?.message, 'success');
        closeModal();
      })
      .catch(err => {
        showResponseMessage(err?.message, 'error');
      });
  };

  const showResponseMessage = (message, errortype) => {
    if (errortype === 'success') {
      dispatch(openSnackbar({ content: message, color: 'success', icon: 'check' }));
    }
    if (errortype === 'error') {
      dispatch(openSnackbar({ content: message, color: 'error', icon: 'warning' }));
    }
  };

  const googleAddressErrorHandler = (status = false) => {
    addressError.current = status;
    if (status) {
      setErrors({ ...errors, address: 'Address is required' });
    }
    else {
      setErrors(prevErrors => ({ ...prevErrors, address: '' }));
    }
  };

  function handleDataFromChild() {
    setShowManualList(false);
  }

  const handleFleetValueChange = (value) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      fleetId: value
    }));
  };

  const handleAddressChange = (addressed) => {
    const address = {
      address1: addressed?.address || '',
      city: addressed?.city || '',
      state: addressed?.state || '',
      zipcode: addressed?.zipcode || '',
      country: addressed?.country || 'USA',
      latitude: addressed?.lat ? addressed.lat.toFixed(8) : '',
      longitude: addressed?.long ? addressed.long.toFixed(8) : '',
    };
    setFormData({ ...formData, address });
  };

  return (
    <Box className="cus-wrapper-drawer">
      <Box display="flex" alignItems="center" justifyContent="space-between" p={2} style={{ borderBottom: '1px solid  #1E1E24' }} className="drawer-head-sec">
        <Typography className="text-14 text-white-700">Create Drop Off Task</Typography>
        <Close onClick={closeModal} style={{ cursor: 'pointer', color: '#ffffff' }} />
      </Box>
      <Box className="drawer-scroll-sec">
        <Box px={2} py={4}>
          <Stack direction='column' mb={4}>
            <Typography mb={1} fontWeight={600} className='text-20 text-white-700'>Importance of Task</Typography>
            <FormControl className='drawer-radio-sec'>
              <FormLabel sx={{ fontSize: 16 }} className='text-black-600'>If the order is highly critical and cannot be delayed click <br /> the checkbox</FormLabel>
              <RadioGroup sx={{ mt: 1 }} row aria-labelledby="demo-row-radio-buttons-group-label" name="taskTag" value={formData?.taskTag}
                onChange={(e) => startTaskHandler(e)} className='text-black-600' >
                <FormControlLabel value="critical" className='text-black-600' control={<Radio />} label="Critical" />
                <FormControlLabel value="high" className='text-black-600' control={<Radio />} label="High" />
                <FormControlLabel value="medium" className='text-black-600' control={<Radio />} label="Medium" />
                <FormControlLabel value="low" className='text-black-600' control={<Radio />} label="Low" />
              </RadioGroup>
              <span style={{ color: '#FD4438' }}>{errors?.taskTag}</span>
            </FormControl>
          </Stack>
          <Stack direction='column' mb={4}>
            <Typography mb={1} fontWeight={600} className='text-20 text-white-700'>Task Info</Typography>
            <Stack direction='column' gap={2} mb={1} className='dark-field'>
              <TextField
                fullWidth
                helperText={errors?.taskDescription}
                error={errors?.taskDescription}
                label={'Task Description'}
                name="taskDescription"
                onChange={(e) => startTaskHandler(e)}
                className="form-input"
                value={formData?.taskDescription || ''}
                minRows={4}
              />
            </Stack>
          </Stack>
          <Stack direction='column' mb={4}>
            <Stack direction='row' justifyContent='space-between'>
              <Typography mb={1} fontWeight={600} className='text-20 text-white-700'>Recipient Details</Typography>
              <FormControl className='drawer-checkbox-sec'>
                <FormControlLabel className='text-black-400' control={<Checkbox name="no_recipient" onChange={handleCheckRecipient} checked={checkBoxes?.no_recipient} />} label="No Recipient" />
              </FormControl>
            </Stack>
            {!checkBoxes?.no_recipient && <Grid container spacing={2} className='dark-field'>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <TextField
                  fullWidth
                  helperText={errors?.recipientName}
                  error={Boolean(errors?.recipientName)}
                  label={'Name'}
                  name="recipientName"
                  onChange={handleRecipientDetails}
                  className="form-input"
                  value={formData?.recipientDetails?.name || ''}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <InputMask
                  fullWidth
                  error={errors?.recipientPhone}
                  helperText={errors.recipientPhone}
                  label="Phone*"
                  name="recipientPhone"
                  value={formData?.recipientDetails?.phone || ''}
                  onChange={handleRecipientDetails}
                  mask='+19999999999'
                  maskChar=''   >
                  {(inputProps) => <TextField {...inputProps} disableUnderline
                    InputProps={{
                      ...inputProps.InputProps,
                    }} />}
                </InputMask>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextField
                  fullWidth
                  helperText={errors?.recipientNotes}
                  error={Boolean(errors?.recipientNotes)}
                  label={'Note about the recipient'}
                  name="recipientNotes"
                  onChange={handleRecipientDetails}
                  className="form-input"
                  value={formData.recipientDetails.notes || ''}
                />
              </Grid>
            </Grid>}
          </Stack>
          <Stack direction='column' mb={4}>
            <Typography mb={1} fontWeight={600} className='text-20 text-white-700'>Order Details</Typography>
            <Grid container spacing={2} className='dark-field'>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextField
                  fullWidth
                  helperText={errors?.orderDetails}
                  error={errors?.orderDetails}
                  label={'Details About the order'}
                  name="orderDetails"
                  onChange={(e) => startTaskHandler(e)}
                  className="form-input"
                  value={formData?.orderDetails || ''}
                />
              </Grid>
            </Grid>
          </Stack>
          <Stack direction='column' mb={4}>
            <Stack direction='row' justifyContent='space-between'>
              <Typography mb={1} fontWeight={600} className='text-20 text-white-700'>Destination</Typography>
            </Stack>
            <Grid container spacing={2} className='dark-field hd-google-suggestions'>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <GoogleSuggestion addressValue={formData?.address?.address} googleAddressErrorHandler={googleAddressErrorHandler}
                  onClearAddress={(address) => setFormData({ ...formData, address })}
                  addressChangeHandler={(address) => handleAddressChange(address)} />
                {errors?.address && <FormHelperText error>{errors?.address}</FormHelperText>}
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <TextField fullWidth label={'Apartment, Building, Suite'} name="taskDescription" className="form-input" disabled={true} />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextField
                  fullWidth
                  helperText={errors.addressNotes}
                  error={errors?.addressNotes}
                  label={'Destination Notes'}
                  onChange={handleRecipientDetails}
                  name="addressNotes"
                  className="form-input"
                  value={formData?.address?.notes || ''}
                />
              </Grid>
            </Grid>
          </Stack>
          <Stack direction='column' mb={4}>
            <Typography fontWeight={600} className='text-20 text-white-700'>Time Window</Typography>
            <Typography mb={2} fontWeight={400} className='text-16 text-black-600'>For tasks with a schedule or estimated fulfilment time.</Typography>
            <Grid container spacing={2} className='drawer-date-field dark-field dark-calendar'>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <TextField
                  fullWidth
                  helperText={errors.taskStartTime}
                  error={errors?.taskStartTime}
                  type='datetime-local'
                  name="taskStartTime"
                  label="Start"
                  onChange={(e) => startTaskHandler(e)}
                  value={formData?.taskStartTime ? moment(formData?.taskStartTime).format('YYYY-MM-DDTHH:mm') : ''}
                  InputLabelProps={{ shrink: true }}
                  sx={{ width: 220 }}
                  inputProps={{ min: moment().format('YYYY-MM-DDTHH:mm') }}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12} className='cal-end'>
                <TextField
                  fullWidth
                  helperText={errors?.taskEndTime}
                  error={errors?.taskEndTime}
                  type='datetime-local'
                  name="taskEndTime"
                  label="End"
                  onChange={(e) => startTaskHandler(e)}
                  value={formData?.taskEndTime ? moment(formData?.taskEndTime).format('YYYY-MM-DDTHH:mm') : ''}
                  InputLabelProps={{ shrink: true }}
                  sx={{ width: 220 }}
                  inputProps={{
                    min: moment().format('YYYY-MM-DDTHH:mm') // Disable past dates
                  }}
                />
              </Grid>
            </Grid>
          </Stack>
          <Stack mb={4}>
            <Typography fontWeight={600} className='text-20 text-white-700'>Driver App Requirements</Typography>
            <Typography mb={2} fontWeight={400} className='text-16 text-black-600'>Require actions in the driver app to complete this task. Will override admin selection</Typography>
            <FormControl className='drawer-checkbox-sec d-flex align-item-center jc-s-btwn flex-row gap-2'>
              <FormControlLabel control={<Checkbox name="customerSignature" checked={formData?.driverAppRequirements.customerSignature} onChange={handleCheckboxChange} />} label="Customer Signature" />
              <FormControlLabel control={<Checkbox name="deliveryPhoto" checked={formData?.driverAppRequirements?.deliveryPhoto} onChange={handleCheckboxChange} />} label="Photo of delivery" />
              <FormControlLabel control={<Checkbox name="addNotes" checked={formData?.driverAppRequirements?.addNotes} onChange={handleCheckboxChange} />} label="Add Notes" />
            </FormControl>
          </Stack>
          <Stack>
            <Typography fontWeight={600} className='text-20 text-white-700'>Assigned To</Typography>
            <Typography mb={2} fontWeight={400} className='text-16 text-black-600'>Donec vitae mi vulputate suscipit urna in malesuada nisl.</Typography>
            <div className='d-flex reassign-driver-btn'>
              <Button
                aria-controls={open ? '' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                className='d-flex gap-2 text-white-900'
              >
                <ReassignDriverIcon />
                <Typography fontWeight="semibold" className='text-12 text-white-900 text-transform-none'>Assign Driver</Typography>
                <KeyboardArrowDownIcon />
                <span style={{ color: '#FD4438' }}>{errors?.fleetId}</span>
              </Button>
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{ className: 'hd-dropdown reassign-driver-dropdown' }}
              >
                <FormControl>
                  <RadioGroup
                    value={formData?.taskAssignmentMode}
                    defaultValue="auto"
                    name="taskAssignmentMode"
                    className='radio-colors'
                    onChange={(e) => startTaskHandler(e)}  >
                    <FormControlLabel className='field-off-duty' value="manual" control={<Radio />} label="Manual" />
                    <FormControlLabel className='field-idle' value="auto" control={<Radio />} label="Automatic" />
                  </RadioGroup>
                </FormControl>
              </Menu>
            </div>
          </Stack>
        </Box>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="space-between" p={2} gap={2} style={{ borderTop: '1px solid  #1E1E24' }} className="drawer-bottom-btns">
        <Button
          variant="outlined"
          color="secondary"
          className='d-flex align-item-center gap-2'
          onClick={closeModal} >
          Cancel
        </Button>
        <MDButton
          variant="contained"
          color="primary"
          className='d-flex align-item-center gap-2 green-btn'
          onClick={() => submitHandler()}
          disabled={loading}
          loading={loading} >
          Create Task
        </MDButton>
      </Box>
      {showManualList &&
        <MapDriveListDrawer sendDataToParent={handleDataFromChild} onValueChange={handleFleetValueChange} />
      }
    </Box>
  );
}