import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import React from 'react';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  tabs: (props) => ({
    backgroundColor: '#3f51b5',
    color: props.color,
    '& .MuiTabs-indicator': {
      backgroundColor: `${props.selectedColor} !important`,
      color: `${props.selectedColor} !important`,
    },
  }),
  tab: (props) => ({
    '&.Mui-selected': {
      color: `${props.selectedColor} !important`,
      borderBottom: `2px solid ${props.selectedColor} !important`
    },
  }),
}));


export default function FilterTabs({ tabs = [], tabHandler, selectedTab = '' }) {
  const classes = useStyles({ selectedColor: tabs?.find((tab => tab?.value === selectedTab))?.color || '' });
  return (
    <div>
      <TabContext value={selectedTab}>
        <Box sx={{ borderBottom: 1, borderColor: '#E2E2EA', background: '#FFFFFF' }}>
          <TabList onChange={(e, tab) => {
            tabHandler(tab);
          }} aria-label="" className="cus-filter-tabs">
            {
              tabs?.map((tab => {
                return <Tab className={classes.tab} key={tab.value} label={tab?.key} value={tab.value} />;
              }))
            }
          </TabList>
        </Box>
      </TabContext>
    </div>
  );
}