import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axiosClient from "axiosClient"
import { fetchDriversRegionsAPI } from "../../../apis/driverRegions"
import { fetchDriversTerminalsAPI } from "../../../apis/hyperwolf/Driver/driverTerminal"

export const fetchDriversTerminals = createAsyncThunk(
    'drivers/fetchDriverRegions',
    async( filters, {rejectWithValue}) => {
        try {
            const res = await fetchDriversTerminalsAPI(filters)
            return res
        } catch(error) {
            console.error("erro", error.response)
            return rejectWithValue(error?.response?.data)
        }
    }
)