import React, { useRef, useState } from 'react';
import { Box, Button, CircularProgress, DialogActions, DialogContent, DialogTitle, IconButton, Stack, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';
import MDButton from 'components/MDButton';
import { base64ToBlob } from 'utilities/common';

const UploadIdImageModal = ({ closeModal }) => {
    const [selectedImage, setImageSrc] = useState(null);
    const { modalData } = useSelector(state => state.modal)
    const { isUploading = false } = useSelector(state => state.common)
    const { uploadingProof = false } = useSelector(state => state.orders)
    const { updateIdHandler, imageType = '' ,title=''} = modalData || {}
    const [selectedMode, setSelectedMode] = useState('')
    const videoRef = useRef(null);
    const canvasRef = useRef(null);
    const streamRef = useRef(null);
    const uploadImageRef = useRef(null)
    
    // const handleCameraOpen = () => {
    //     document.getElementById('cameraInput').click();
    // };

    // const handleGalleryOpen = () => {
    //     document.getElementById('galleryInput').click();
    // };

    const startCamera = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({
                video: {
                    facingMode: { exact: 'environment' }
                }
            });
            if (videoRef.current) {
                videoRef.current.srcObject = stream;
                videoRef.current.play();
                streamRef.current = stream; // Save the stream reference for later use
            }
        } catch (err) {
            console.error('Error accessing the camera: ', err);
        }
    };


    const captureImage = () => {
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
        if (videoRef.current && canvas) {
            canvas.width = videoRef.current.videoWidth;
            canvas.height = videoRef.current.videoHeight;
            context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
            const imageSrc = canvas.toDataURL('image/jpeg');
            setImageSrc(imageSrc);
        }
        setSelectedMode('')
        stopCamera()
    };



    const stopCamera = () => {
        if (streamRef.current) {
            const tracks = streamRef.current.getTracks();
            tracks.forEach(track => track.stop());
            streamRef.current = null;
            if (videoRef.current) {
                videoRef.current.srcObject = null;
            }
        }
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImageSrc(reader.result);
                setSelectedMode('')
                // handleUpload(reader.result)
            };
            reader.readAsDataURL(file);
        }
    };

    const handleUpload = (uploadedImage = '') => {
        const blob = base64ToBlob(uploadedImage ? uploadedImage : selectedImage, 'image/png');
        const formData = new FormData();
        formData.append('filePath', blob, 'image.png');
        if (imageType) {
            updateIdHandler(formData, imageType)
            return;
        }
        updateIdHandler(formData)
    }
    return (
        <>
            <DialogTitle display='flex' alignItems='center' justifyContent='space-between'>
               {title || 'Upload ID' } 
            </DialogTitle>
            <IconButton
                onClick={() => {
                    closeModal()
                    stopCamera()
                }}
                aria-label='close'
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent>
                {
                    ((!selectedMode && !selectedImage) || selectedMode === 'imageUpload') && 
                    <Box display='flex' justifyContent='space-around' alignItems='center' mb={2} className='upload-id-sec'>
                        <Box textAlign='center'>
                            <div className='upload-wrapper cur-pointer'
                                onClick={() => {
                                    setSelectedMode('camera')
                                    startCamera()
                                    setImageSrc('')
                                }}
                            >
                                <svg xmlns='http://www.w3.org/2000/svg' width='37' height='36' viewBox='0 0 37 36' fill='none'>
                                    <path fillRule='evenodd' clipRule='evenodd' d='M16.1313 6.75586C15.0909 6.75586 14.1335 7.34799 13.6682 8.27856L13.3057 9.00586H10.625C8.77436 9.00586 7.25 10.5302 7.25 12.3809V23.6309C7.25 25.4815 8.77436 27.0059 10.625 27.0059H26.375C28.2256 27.0059 29.75 25.4815 29.75 23.6309V12.3809C29.75 10.5302 28.2256 9.00586 26.375 9.00586H23.6943L23.3318 8.27856C22.8665 7.34799 21.9091 6.75586 20.8687 6.75586H16.1313ZM16.1313 9.00586H20.8687C21.0616 9.00586 21.2328 9.11239 21.3191 9.28491L21.9937 10.634C22.0871 10.8209 22.2308 10.978 22.4085 11.0879C22.5862 11.1977 22.7911 11.2559 23 11.2559H26.375C27.0096 11.2559 27.5 11.7462 27.5 12.3809V23.6309C27.5 24.2655 27.0096 24.7559 26.375 24.7559H10.625C9.99035 24.7559 9.5 24.2655 9.5 23.6309V12.3809C9.5 11.7462 9.99035 11.2559 10.625 11.2559H14C14.2089 11.2559 14.4138 11.1977 14.5915 11.0879C14.7692 10.978 14.9129 10.8209 15.0063 10.634L15.6809 9.28491C15.7672 9.11234 15.9384 9.00586 16.1313 9.00586Z' fill='#0163FF' />
                                    <path fillRule='evenodd' clipRule='evenodd' d='M3.875 2.25586C3.57664 2.25589 3.29051 2.37443 3.07954 2.5854C2.86857 2.79637 2.75003 3.0825 2.75 3.38086V7.88086C2.75 8.17923 2.86853 8.46538 3.0795 8.67636C3.29048 8.88733 3.57663 9.00586 3.875 9.00586C4.17337 9.00586 4.45952 8.88733 4.6705 8.67636C4.88147 8.46538 5 8.17923 5 7.88086V4.50586H8.375C8.67337 4.50586 8.95952 4.38733 9.1705 4.17636C9.38147 3.96538 9.5 3.67923 9.5 3.38086C9.5 3.08249 9.38147 2.79634 9.1705 2.58536C8.95952 2.37438 8.67337 2.25586 8.375 2.25586H3.875Z' fill='#0163FF' />
                                    <path fillRule='evenodd' clipRule='evenodd' d='M3.875 27.0059C3.57663 27.0059 3.29048 27.1244 3.0795 27.3354C2.86853 27.5463 2.75 27.8325 2.75 28.1309V32.6309C2.75003 32.9292 2.86857 33.2153 3.07954 33.4263C3.29051 33.6373 3.57664 33.7558 3.875 33.7559H8.375C8.67337 33.7559 8.95952 33.6373 9.1705 33.4264C9.38147 33.2154 9.5 32.9292 9.5 32.6309C9.5 32.3325 9.38147 32.0463 9.1705 31.8354C8.95952 31.6244 8.67337 31.5059 8.375 31.5059H5V28.1309C5 27.8325 4.88147 27.5463 4.6705 27.3354C4.45952 27.1244 4.17337 27.0059 3.875 27.0059Z' fill='#0163FF' />
                                    <path fillRule='evenodd' clipRule='evenodd' d='M28.625 2.25586C28.3266 2.25586 28.0405 2.37438 27.8295 2.58536C27.6185 2.79634 27.5 3.08249 27.5 3.38086C27.5 3.67923 27.6185 3.96538 27.8295 4.17636C28.0405 4.38733 28.3266 4.50586 28.625 4.50586H32V7.88086C32 8.17923 32.1185 8.46538 32.3295 8.67636C32.5405 8.88733 32.8266 9.00586 33.125 9.00586C33.4234 9.00586 33.7095 8.88733 33.9205 8.67636C34.1315 8.46538 34.25 8.17923 34.25 7.88086V3.38086C34.25 3.0825 34.1314 2.79637 33.9205 2.5854C33.7095 2.37443 33.4234 2.25589 33.125 2.25586H28.625Z' fill='#0163FF' />
                                    <path fillRule='evenodd' clipRule='evenodd' d='M33.125 27.0059C32.8266 27.0059 32.5405 27.1244 32.3295 27.3354C32.1185 27.5463 32 27.8325 32 28.1309V31.5059H28.625C28.3266 31.5059 28.0405 31.6244 27.8295 31.8354C27.6185 32.0463 27.5 32.3325 27.5 32.6309C27.5 32.9292 27.6185 33.2154 27.8295 33.4264C28.0405 33.6373 28.3266 33.7559 28.625 33.7559H33.125C33.4234 33.7558 33.7095 33.6373 33.9205 33.4263C34.1314 33.2153 34.25 32.9292 34.25 32.6309V28.1309C34.25 27.8325 34.1315 27.5463 33.9205 27.3354C33.7095 27.1244 33.4234 27.0059 33.125 27.0059Z' fill='#0163FF' />
                                    <path fillRule='evenodd' clipRule='evenodd' d='M18.5 13.5059C16.028 13.5059 14 15.5339 14 18.0059C14 20.4778 16.028 22.5059 18.5 22.5059C20.972 22.5059 23 20.4778 23 18.0059C23 15.5339 20.972 13.5059 18.5 13.5059ZM18.5 15.7559C19.756 15.7559 20.75 16.7499 20.75 18.0059C20.75 19.2618 19.756 20.2559 18.5 20.2559C17.244 20.2559 16.25 19.2618 16.25 18.0059C16.25 16.7499 17.244 15.7559 18.5 15.7559Z' fill='#0163FF' />
                                </svg>
                            </div>
                            <Typography className='text-grey-600 font-weight-700 fs-medium'>Open Camera</Typography>
                        </Box>
                        <Box textAlign='center'>
                            <div className='upload-wrapper cur-pointer'
                                onClick={() => {
                                    setSelectedMode('imageUpload')
                                    setImageSrc('')
                                    uploadImageRef.current.click()
                                }}
                            >
                                {
                                   ( isUploading || uploadingProof) ? <CircularProgress size={23} /> : <svg xmlns='http://www.w3.org/2000/svg' width='41' height='40' viewBox='0 0 41 40' fill='none'>
                                        <path fillRule='evenodd' clipRule='evenodd' d='M36.4998 14.2003C36.4998 12.8673 35.9695 11.5891 35.0278 10.6456C33.2669 8.88646 29.7853 5.40482 28.0261 3.64389C27.0826 2.70217 25.8044 2.17188 24.4714 2.17188C22.0851 2.17188 17.3033 2.17188 13.1853 2.17188C10.8813 2.17188 8.67235 3.08617 7.04308 4.71544C5.41381 6.34472 4.49951 8.55365 4.49951 10.8577V29.1436C4.49951 33.94 8.38892 37.8294 13.1853 37.8294H27.814C32.6104 37.8294 36.4998 33.94 36.4998 29.1436C36.4998 23.8608 36.4998 17.1553 36.4998 14.2003ZM24.614 4.91842C24.5665 4.91659 24.5189 4.91476 24.4714 4.91476C22.0851 4.91476 17.3033 4.91476 13.1853 4.91476C11.6091 4.91476 10.0968 5.54014 8.98321 6.65558C7.86777 7.76919 7.2424 9.28143 7.2424 10.8577V29.1436C7.2424 32.4259 9.903 35.0865 13.1853 35.0865H27.814C31.0964 35.0865 33.757 32.4259 33.757 29.1436C33.757 23.8608 33.757 17.1553 33.757 14.2003C33.757 14.1528 33.7551 14.1053 33.7533 14.0577H29.6426C28.3096 14.0577 27.0296 13.5274 26.086 12.5857C25.1443 11.6421 24.614 10.3621 24.614 9.02909V4.91842ZM31.8168 11.3148L27.3569 6.85489V9.02909C27.3569 9.63618 27.5983 10.2158 28.0261 10.6456C28.4559 11.0735 29.0355 11.3148 29.6426 11.3148H31.8168Z' fill='#0163FF' />
                                        <path fillRule='evenodd' clipRule='evenodd' d='M20.4998 14.9707H20.5328C20.6589 14.9744 20.7814 14.9945 20.8966 15.0292L20.9003 15.031C21.1088 15.0932 21.3044 15.2084 21.469 15.373L25.1262 19.0302C25.662 19.5641 25.662 20.4345 25.1262 20.9685C24.5922 21.5043 23.7218 21.5043 23.1879 20.9685L21.8713 19.6537V25.4851C21.8713 26.2421 21.2569 26.8565 20.4998 26.8565C19.7428 26.8565 19.1284 26.2421 19.1284 25.4851V19.6537L17.8118 20.9685C17.2779 21.5043 16.4075 21.5043 15.8735 20.9685C15.3377 20.4345 15.3377 19.5641 15.8735 19.0302L19.5307 15.373C19.6953 15.2084 19.8909 15.0932 20.0994 15.031L20.103 15.0292C20.2182 14.9945 20.3408 14.9744 20.4669 14.9707H20.4998Z' fill='#0163FF' />
                                    </svg>
                                }
                            </div>
                            <Typography className='text-grey-600 font-weight-700 fs-medium'>Upload Image</Typography>
                        </Box>

                    </Box>
                }
                {/* {
                    selectedMode === 'imageUpload' && <Box textAlign='center'>
                        <div className='upload-wrapper cur-pointer'
                            onClick={() => {
                                setSelectedMode('imageUpload')
                                setImageSrc('')
                                uploadImageRef.current.click()
                            }}
                        >
                            <svg xmlns='http://www.w3.org/2000/svg' width='41' height='40' viewBox='0 0 41 40' fill='none'>
                                <path fillRule='evenodd' clipRule='evenodd' d='M36.4998 14.2003C36.4998 12.8673 35.9695 11.5891 35.0278 10.6456C33.2669 8.88646 29.7853 5.40482 28.0261 3.64389C27.0826 2.70217 25.8044 2.17188 24.4714 2.17188C22.0851 2.17188 17.3033 2.17188 13.1853 2.17188C10.8813 2.17188 8.67235 3.08617 7.04308 4.71544C5.41381 6.34472 4.49951 8.55365 4.49951 10.8577V29.1436C4.49951 33.94 8.38892 37.8294 13.1853 37.8294H27.814C32.6104 37.8294 36.4998 33.94 36.4998 29.1436C36.4998 23.8608 36.4998 17.1553 36.4998 14.2003ZM24.614 4.91842C24.5665 4.91659 24.5189 4.91476 24.4714 4.91476C22.0851 4.91476 17.3033 4.91476 13.1853 4.91476C11.6091 4.91476 10.0968 5.54014 8.98321 6.65558C7.86777 7.76919 7.2424 9.28143 7.2424 10.8577V29.1436C7.2424 32.4259 9.903 35.0865 13.1853 35.0865H27.814C31.0964 35.0865 33.757 32.4259 33.757 29.1436C33.757 23.8608 33.757 17.1553 33.757 14.2003C33.757 14.1528 33.7551 14.1053 33.7533 14.0577H29.6426C28.3096 14.0577 27.0296 13.5274 26.086 12.5857C25.1443 11.6421 24.614 10.3621 24.614 9.02909V4.91842ZM31.8168 11.3148L27.3569 6.85489V9.02909C27.3569 9.63618 27.5983 10.2158 28.0261 10.6456C28.4559 11.0735 29.0355 11.3148 29.6426 11.3148H31.8168Z' fill='#0163FF' />
                                <path fillRule='evenodd' clipRule='evenodd' d='M20.4998 14.9707H20.5328C20.6589 14.9744 20.7814 14.9945 20.8966 15.0292L20.9003 15.031C21.1088 15.0932 21.3044 15.2084 21.469 15.373L25.1262 19.0302C25.662 19.5641 25.662 20.4345 25.1262 20.9685C24.5922 21.5043 23.7218 21.5043 23.1879 20.9685L21.8713 19.6537V25.4851C21.8713 26.2421 21.2569 26.8565 20.4998 26.8565C19.7428 26.8565 19.1284 26.2421 19.1284 25.4851V19.6537L17.8118 20.9685C17.2779 21.5043 16.4075 21.5043 15.8735 20.9685C15.3377 20.4345 15.3377 19.5641 15.8735 19.0302L19.5307 15.373C19.6953 15.2084 19.8909 15.0932 20.0994 15.031L20.103 15.0292C20.2182 14.9945 20.3408 14.9744 20.4669 14.9707H20.4998Z' fill='#0163FF' />
                            </svg>
                        </div>
                        <Typography className='text-grey-600 font-weight-700 fs-medium'>Upload Image</Typography>
                    </Box>
                } */}
                {selectedImage && selectedMode !== 'imageUpload' && (
                    <Box textAlign='center'>
                        <img src={selectedImage} alt='Selected' style={{ maxWidth: '100%', height: 'auto', border: '1px solid #d2d6da', borderRadius: '8px' }} />
                    </Box>
                )}
                {/* {
                    selectedMode === 'imageUpload' && <>
                        <Stack direction='row' justifyContent='space-between' alignItems='center'>
                            <Button onClick={captureImage} variant='contained' color='primary' sx={{ textAlign: 'center', mt: 2 }}>
                                Capture
                            </Button>
                            <Button variant='contained' onClick={() => {
                                stopCamera()
                                setSelectedMode('')
                            }} color='success' sx={{ textAlign: 'center', mt: 2 }}>
                                Cancel
                            </Button>
                        </Stack>
                    </>
                } */}
                <input ref={uploadImageRef} style={{ display: 'none' }} type='file' accept='image/*' onChange={handleFileChange} />

                {
                    selectedMode === 'camera' && <>
                        <video ref={videoRef} width='100%' height='auto' style={{ border: '1px solid #d2d6da', borderRadius: '8px' }}></video>
                        <canvas ref={canvasRef} style={{ display: 'none' }}></canvas>
                        {/* <div className='w-100 d-flex align-items-center justify-content-center mt-3 gap-3'>
                            <Button
                                onClick={captureImage}
                                variant='contained'
                                color='primary'
                                sx={{ textAlign: 'center', width: '140px' }}
                                type='button'
                            >
                                Capture
                            </Button>
                        </div> */}
                    </>
                }
                {/* {
                    selectedMode === 'imageUpload' && <Stack direction='row' justifyContent='space-between' alignItems='center'>
                        <Button onClick={() => {
                            setImageSrc('')
                            setSelectedMode('')
                        }} variant='contained' color='primary' sx={{ textAlign: 'center', mt: 2 }}>
                            Cancel
                        </Button>
                        <Button variant='contained' onClick={() => {
                            // setSelectedMode('')
                        }} color='primary' sx={{ textAlign: 'center', mt: 2 }}>
                            Upload
                        </Button>
                    </Stack>
                } */}
            </DialogContent>
            <DialogActions>
                {
                    selectedMode === 'camera' && <Stack direction='row' justifyContent='space-between' alignItems='center' className='w-100' mt={1}>
                        <Button 
                            onClick={captureImage} 
                            variant='contained' 
                            color='primary' 
                            style={{ width: 160 }}
                            sx={{ textAlign: 'center' }}
                        >
                            Capture
                        </Button>
                        <Button 
                            variant='outlined' 
                            onClick={() => {
                                stopCamera()
                                setSelectedMode('')
                            }} 
                            color='primary' 
                            sx={{ textAlign: 'center' }}
                            style={{ width: 160 }}
                            className='dark-outlined-btn'
                        >
                            Cancel
                        </Button>
                    </Stack>
                }
                {
                    selectedImage && selectedMode !== 'imageUpload' && <Stack direction='row' justifyContent='space-between' alignItems='center' className='w-100' mt={1}>
                        <MDButton 
                            loading={isUploading || uploadingProof} 
                            disabled={isUploading || uploadingProof} 
                            onClick={()=>handleUpload('')} 
                            variant='contained' 
                            color='primary'
                            style={{ width: 190 }}
                        >
                            Upload
                        </MDButton>
                        <Button 
                            onClick={() => {
                                setSelectedMode('')
                                setImageSrc('')
                            }} 
                            variant='outlined' 
                            color='primary' 
                            style={{ width: 190 }}
                            className='dark-outlined-btn'
                        >
                            Cancel
                        </Button>
                    </Stack>
                }

            </DialogActions>
        </>
    );
};


export default UploadIdImageModal;
