import React from 'react';

export default function UserGuestIcon({width = '20px', height = '20px', flex = 'none'}) {
  return (
    <svg style={{width, height, flex}} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.99976 6.37183C11.6963 6.37183 13.0717 4.94544 13.0717 3.18591C13.0717 1.42638 11.6963 0 9.99976 0C8.30317 0 6.92787 1.42638 6.92787 3.18591C6.92787 4.94544 8.30317 6.37183 9.99976 6.37183Z" fill="currentColor"/>
      <path d="M6.37364 13.8056C5.55283 13.8056 4.87991 13.0968 4.87991 12.2126V11.0498C4.87991 11.0339 4.88176 11.0179 4.88176 11.004C4.8984 9.10242 6.28121 7.55324 8.02265 7.4338H11.977C13.7184 7.55324 15.1012 9.10242 15.1179 11.004C15.1179 11.0199 15.1197 11.0359 15.1197 11.0498V12.2126C15.1197 13.0968 14.4468 13.8056 13.626 13.8056H6.37364Z" fill="currentColor"/>
      <path d="M20 14.9085V15.8497H18.7469V19.9002H17.8319V15.8497H16.5854V14.9085H20Z" fill="currentColor"/>
      <path d="M14.8188 19.9999C14.3591 19.9999 13.9723 19.8906 13.6585 19.6719C13.3446 19.4485 13.1237 19.1466 12.9955 18.7663L13.7779 18.2743C13.959 18.7829 14.3149 19.0372 14.8453 19.0372C15.1017 19.0372 15.2895 18.9873 15.4089 18.8875C15.5282 18.7877 15.5879 18.6617 15.5879 18.5095C15.5879 18.3336 15.515 18.1982 15.3691 18.1031C15.2232 18.0033 14.9625 17.8963 14.5867 17.7822C14.379 17.7157 14.2021 17.6491 14.0563 17.5826C13.9149 17.516 13.7712 17.4281 13.6254 17.3187C13.4839 17.2046 13.3756 17.062 13.3005 16.8909C13.2253 16.7197 13.1878 16.52 13.1878 16.2918C13.1878 15.8402 13.3358 15.4813 13.6319 15.2151C13.9326 14.9441 14.2928 14.8086 14.7127 14.8086C15.0884 14.8086 15.4178 14.9084 15.7006 15.1081C15.9879 15.303 16.2111 15.5764 16.3703 15.9282L15.6011 16.406C15.4155 15.9781 15.1193 15.7641 14.7127 15.7641C14.5227 15.7641 14.3723 15.8117 14.2618 15.9068C14.1558 15.9971 14.1028 16.1159 14.1028 16.2634C14.1028 16.4202 14.1624 16.5486 14.2817 16.6484C14.4055 16.7434 14.6398 16.8481 14.9845 16.9621C15.126 17.0097 15.2321 17.0477 15.3028 17.0763C15.378 17.1 15.4774 17.1405 15.6011 17.1975C15.7293 17.2498 15.8266 17.2997 15.8929 17.3472C15.9636 17.3948 16.0432 17.459 16.1316 17.5398C16.22 17.6206 16.2863 17.7038 16.3305 17.7894C16.3791 17.875 16.4189 17.9795 16.4499 18.1031C16.4852 18.2219 16.5029 18.3527 16.5029 18.4953C16.5029 18.9564 16.346 19.3225 16.0321 19.5935C15.7227 19.8645 15.3183 19.9999 14.8188 19.9999Z" fill="currentColor"/>
      <path d="M10.6334 18.9589H12.5893V19.9002H9.71836V14.9085H12.5562V15.8497H10.6334V16.9123H12.3904V17.8393H10.6334V18.9589Z" fill="currentColor"/>
      <path d="M7.1173 20C6.60016 20 6.17583 19.8431 5.84431 19.5293C5.5128 19.2156 5.34704 18.7949 5.34704 18.2672V14.9085H6.25539V18.1887C6.25539 18.4501 6.3239 18.657 6.46092 18.8091C6.60237 18.9612 6.82118 19.0373 7.1173 19.0373C7.41345 19.0373 7.6301 18.9612 7.76709 18.8091C7.90855 18.657 7.97927 18.4501 7.97927 18.1887V14.9085H8.89427V18.2672C8.89427 18.7949 8.72846 19.2156 8.39699 19.5293C8.06544 19.8431 7.63888 20 7.1173 20Z" fill="currentColor"/>
      <path d="M4.76717 17.2189V17.604C4.76717 18.317 4.555 18.8946 4.13067 19.3368C3.70632 19.7789 3.15822 19.9999 2.48635 19.9999C1.77028 19.9999 1.17577 19.7504 0.702812 19.2512C0.23427 18.7473 0 18.134 0 17.4114C0 16.684 0.23206 16.0684 0.696179 15.5645C1.16472 15.0606 1.74376 14.8086 2.43331 14.8086C2.86649 14.8086 3.2621 14.9156 3.62013 15.1295C3.97817 15.3386 4.25664 15.6192 4.45555 15.971L3.67317 16.4558C3.56267 16.2515 3.3947 16.085 3.16927 15.9567C2.94826 15.8283 2.70074 15.7641 2.42668 15.7641C1.98466 15.7641 1.62 15.921 1.33268 16.2348C1.0498 16.5438 0.908347 16.936 0.908347 17.4114C0.908347 17.882 1.052 18.2719 1.33932 18.5809C1.63105 18.8852 2.01561 19.0372 2.49299 19.0372C2.8466 19.0372 3.13833 18.9541 3.36818 18.7877C3.60245 18.6165 3.76158 18.3836 3.84556 18.0888H2.43994V17.2189H4.76717Z" fill="currentColor"/>
    </svg>
  );
}