import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { fetchMasterCatalogProductsAPI } from "../apis/masterCatalog";


const initialState = {
    products: [],
    loading: false,
    error: null,
    singleProduct: null,
    productTerpenoids: {}
};

export const fetchMasterCatalogProducts = createAsyncThunk(
    'masterCatalog/fetchProducts',
    async({ filters, payload },{ rejectWithValue }) => {
        try {
            const res = await fetchMasterCatalogProductsAPI(filters, payload);
            return res;
        } catch (error) {
            
            return rejectWithValue(error.response?.data);
        }
    }
)

//Create a slice
const masterCatalogProductsSlice = createSlice({
    name: 'masterCatalogProducts',
    initialState,
    reducers: {
      // Define extra reducers here
      clearProducts: (state) => {
        state.products = [];
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(fetchMasterCatalogProducts.pending, (state) => {
          state.loading = true;
          state.error = null;
          state.singleProduct = {}
        })
        .addCase(fetchMasterCatalogProducts.fulfilled, (state, action) => {
          state.loading = false;
          state.products = action.payload;
        })
        .addCase(fetchMasterCatalogProducts.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload;
        });
    }
})


export const { clearProducts } = masterCatalogProductsSlice.actions;
export default masterCatalogProductsSlice.reducer;