// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import { Paper, Typography } from "@mui/material";

function Footer({ company, links }) {

  return (
    <Paper elevation={0} square sx={{px: 3, py: 2, position: 'relative', zIndex: 1201}}>
      <Typography variant="body2" color="secondary">© 2024 Hyperwolf</Typography>
    </Paper>
     );
}

// Setting default values for the props of Footer
Footer.defaultProps = {
  company: { href: "https://www.creative-tim.com/", name: "Creative Tim" },
  links: [
    { href: "https://www.creative-tim.com/", name: "Creative Tim" },
    { href: "https://www.creative-tim.com/presentation", name: "About Us" },
    { href: "https://www.creative-tim.com/blog", name: "Blog" },
    { href: "https://www.creative-tim.com/license", name: "License" },
  ],
};

// Typechecking props for the Footer
Footer.propTypes = {
  company: PropTypes.objectOf(PropTypes.string),
  links: PropTypes.arrayOf(PropTypes.object),
};

export default Footer;
