 

/**
 * The base box-shadow styles for the Material Dashboard 2 React.
 * You can add new box-shadow using this file.
 * You can customized the box-shadow for the entire Material Dashboard 2 React using thie file.
 */

// Material Dashboard 2 React Base Styles
import colors from "assets/theme/base/colors";

// Material Dashboard 2 React Helper Functions
import boxShadow from "assets/theme/functions/boxShadow";

const { black, white, tabs, coloredShadows } = colors;

const boxShadows = {
  xs: boxShadow([0, 2], [9, -5], black.main, 0.15),
  sm: boxShadow([0, 5], [10, 0], black.main, 0.12),
  md: `${boxShadow([0, 4], [6, -1], black.main, 0.1)}, ${boxShadow(
    [0, 2],
    [4, -1],
    black.main,
    0.06
  )}`,
  lg: `${boxShadow([0, 10], [15, -3], black.main, 0.1)}, ${boxShadow(
    [0, 4],
    [6, -2],
    black.main,
    0.05
  )}`,
  xl: `${boxShadow([0, 20], [25, -5], black.main, 0.1)}, ${boxShadow(
    [0, 10],
    [10, -5],
    black.main,
    0.04
  )}`,
  xxl: boxShadow([0, 20], [27, 0], black.main, 0.05),
  inset: boxShadow([0, 1], [2, 0], black.main, 0.075, "inset"),
  colored: {
    primary: `${boxShadow([0, 4], [20, 0], black.main, 0.14)}, ${boxShadow(
      [0, 7],
      [10, -5],
      coloredShadows.primary,
      0.4
    )}`,
    secondary: `${boxShadow([0, 4], [20, 0], black.main, 0.14)}, ${boxShadow(
      [0, 7],
      [10, -5],
      coloredShadows.secondary,
      0.4
    )}`,
    info: `${boxShadow([0, 4], [20, 0], black.main, 0.14)}, ${boxShadow(
      [0, 7],
      [10, -5],
      coloredShadows.info,
      0.4
    )}`,
    success: `${boxShadow([0, 4], [20, 0], black.main, 0.14)}, ${boxShadow(
      [0, 7],
      [10, -5],
      coloredShadows.success,
      0.4
    )}`,
    warning: `${boxShadow([0, 4], [20, 0], black.main, 0.14)}, ${boxShadow(
      [0, 7],
      [10, -5],
      coloredShadows.warning,
      0.4
    )}`,
    error: `${boxShadow([0, 4], [20, 0], black.main, 0.14)}, ${boxShadow(
      [0, 7],
      [10, -5],
      coloredShadows.error,
      0.4
    )}`,
    light: `${boxShadow([0, 4], [20, 0], black.main, 0.14)}, ${boxShadow(
      [0, 7],
      [10, -5],
      coloredShadows.light,
      0.4
    )}`,
    dark: `${boxShadow([0, 4], [20, 0], black.main, 0.14)}, ${boxShadow(
      [0, 7],
      [10, -5],
      coloredShadows.dark,
      0.4
    )}`,
    iconShadowInfo: '0px 2.767px 2.352px 0px rgba(7, 87, 243, 0.04), 0px 6.65px 5.653px 0px rgba(7, 87, 243, 0.06), 0px 12.522px 10.643px 0px rgba(7, 87, 243, 0.07), 0px 22.336px 18.986px 0px rgba(7, 87, 243, 0.08), 0px 41.778px 35.511px 0px rgba(7, 87, 243, 0.10), 0px 100px 85px 0px rgba(7, 87, 243, 0.14);',
    iconShadowSuccess: '0px 2.767px 2.214px 0px rgba(7, 186, 98, 0.05), 0px 6.65px 5.32px 0px rgba(7, 186, 98, 0.07), 0px 12.522px 10.017px 0px rgba(7, 186, 98, 0.09), 0px 22.336px 17.869px 0px rgba(7, 186, 98, 0.10), 0px 41.778px 33.422px 0px rgba(7, 186, 98, 0.12), 0px 100px 80px 0px rgba(7, 186, 98, 0.17);',
    iconShadowWarning: '0px 2.767px 2.214px 0px rgba(253, 120, 18, 0.02), 0px 6.65px 5.32px 0px rgba(253, 120, 18, 0.03), 0px 12.522px 10.017px 0px rgba(253, 120, 18, 0.04), 0px 22.336px 17.869px 0px rgba(253, 120, 18, 0.04), 0px 41.778px 33.422px 0px rgba(253, 120, 18, 0.05), 0px 100px 80px 0px rgba(253, 120, 18, 0.07);',
    iconShadowDanger: '0px 2.767px 2.214px 0px rgba(248, 11, 11, 0.03), 0px 6.65px 5.32px 0px rgba(248, 11, 11, 0.04), 0px 12.522px 10.017px 0px rgba(248, 11, 11, 0.05), 0px 22.336px 17.869px 0px rgba(248, 11, 11, 0.07), 0px 41.778px 33.422px 0px rgba(248, 11, 11, 0.08), 0px 100px 80px 0px rgba(248, 11, 11, 0.11);',
    iconShadowViolet: '0px 2.767px 2.214px 0px rgba(142, 12, 245, 0.03), 0px 6.65px 5.32px 0px rgba(142, 12, 245, 0.04), 0px 12.522px 10.017px 0px rgba(142, 12, 245, 0.05), 0px 22.336px 17.869px 0px rgba(142, 12, 245, 0.07), 0px 41.778px 33.422px 0px rgba(142, 12, 245, 0.08), 0px 100px 80px 0px rgba(142, 12, 245, 0.11);',
  },

  navbarBoxShadow: `${boxShadow([0, 0], [1, 1], white.main, 0.9, "inset")}, ${boxShadow(
    [0, 20],
    [27, 0],
    black.main,
    0.05
  )}`,
  customNavbarBoxShadow: '0px 2.767px 2.214px 0px rgba(178, 183, 219, 0.01), 0px 6.65px 5.32px 0px rgba(178, 183, 219, 0.02), 0px 12.522px 10.017px 0px rgba(178, 183, 219, 0.03), 0px 22.336px 17.869px 0px rgba(178, 183, 219, 0.03), 0px 41.778px 33.422px 0px rgba(178, 183, 219, 0.04), 0px 100px 80px 0px rgba(178, 183, 219, 0.05)',
  sliderBoxShadow: {
    thumb: boxShadow([0, 1], [13, 0], black.main, 0.2),
  },
  tabsBoxShadow: {
    indicator: boxShadow([0, 1], [5, 1], tabs.indicator.boxShadow, 1),
  },
};

export default boxShadows;
