import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  TextField,
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Card,
  Select,
  Typography,
  Box,
  FormHelperText,
  Stack,
  Badge,
  createSvgIcon,
  CircularProgress,
  InputAdornment,
} from '@mui/material';
import MDButton from 'components/MDButton';
import { addMember, deleteMember, updateMember } from '../../redux/slices/member';
import { useDispatch, useSelector } from 'react-redux';
import { openSnackbar, uploadImage } from '../../redux/slices/common';
import MDInput from 'components/MDInput';
import { Link, useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import {  memberValidation } from '../../validations/member';
import { isUnder21 } from 'utilities/common';
import { isEmptyObject } from 'utilities/common';
import { closeCustomModalDialog, openCustomModalDialog, setModalLoader } from '../../redux/slices/modalSlice';
import { DatePicker } from '@mui/x-date-pickers';
import CalendarIcon from 'common/customIcons/calendar';
import { AccountCircle } from '@mui/icons-material';
import InputMask from 'react-input-mask';
import Zoom from 'react-medium-image-zoom';
import GoogleSuggestion from 'components/googleMaps/GoogleSuggestion';
import { formatDeliveryAddress } from 'utilities/common';

const AddMemberForm = () => {
  const { memberDetails, loading = false } = useSelector(state => state.members);
  const { isUploading = false } = useSelector(state => state.common);
  const { user } = useSelector(state => state.auth);
  const uploadedFileField = useRef(null);
  const addressError = useRef(false);

  const { memberId } = useParams();
  const [formData, setFormData] = useState({
    dob: '',
    email: '',
    phone: '',
    password: '',
    firstName: '',
    lastName: '',
    licenseNumber: '',
    gender: '',
    idImage: '',
    createdBy: user?.name || '',
    status: 'active',
    city: '',
    state: '',
    address: '',
    memberType: '',
    recId: '',
    memberShipGroup: '',
    userType: ''
  });
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const banMemberSubmitHandler = useCallback((banMemberPayload) => {
    const payload = {
      dob: dayjs(formData?.dob).valueOf(),
      email: formData.email,
      phone: formData?.phone?.replace(/[^\d]/g, ''),
      firstName: formData.firstName,
      lastName: formData.lastName,
      licenseNumber: formData?.licenseNumber,
      gender: formData?.gender,
      deliveryAddress: {
        address: formData?.address,
        city: formData?.city,
        state: formData?.state,
        zipcode: formData?.zipcode,
        latitude: formData?.lat,
        longitude: formData?.lng,
        apartment: formData?.apartment,
      },
      idImage: formData?.idImage,
      createdBy: formData?.createdBy,
      recId: formData?.recId,
      memberType: formData?.memberType,
      memberShipGroup: formData?.memberShipGroup,
      verifyMethod: 'admin',
      memberNotesEmployee: formData?.createdBy,
      notesTime: dayjs().valueOf(),
      ...banMemberPayload
    };
    dispatch(updateMember({ data: payload, memberId })).unwrap().then((res) => {
      showError(res?.message, 'success');
      dispatch(closeCustomModalDialog({}));
      navigate('/members');
    }).catch((err) => {
      showError(err?.message, 'error');
    });
  }, [formData]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (addressError.current) {
      return;
    }
    if (!memberId) {
      const { errors, isValid } = memberValidation(formData, isMedicnalOr21Less);
      if (!isValid) {
        setErrors(errors);
        return;
      }
    }
    setErrors({});

    const payload = {
      dob: dayjs(formData?.dob).valueOf(),
      email: formData.email,
      phone: formData?.phone?.replace(/[^\d]/g, ''),
      firstName: formData.firstName,
      lastName: formData.lastName,
      licenseNumber: formData?.licenseNumber,
      gender: formData?.gender,
      status: formData?.status === 'active' ? true : false,
      deliveryAddress: {
        address: formData?.address,
        city: formData?.city,
        state: formData?.state,
        zipcode: formData?.zipcode,
        latitude: formData?.lat,
        longitude: formData?.lng,
        apartment: formData?.apartment,

      },
      idImage: formData?.idImage,
      createdBy: formData?.createdBy,
      recId: formData?.recId,
      memberType: formData?.memberType,
      memberShipGroup: formData?.memberShipGroup,
      verifyMethod: 'admin'
    };
    if (memberId) {
      dispatch(updateMember({ data: payload, memberId })).unwrap().then((res) => {
        showError(res?.message, 'success');
        navigate('/members');
      }).catch((err) => {
        showError(err?.message, 'error');
      });
    } else {
      dispatch(addMember(payload)).unwrap().then((res) => {
        showError(res?.message, 'success');
        navigate('/members');
      }).catch((err) => {
        showError(err?.message, 'error');
      });
    }

  };

  const showError = (message, errortype) => {
    if (errortype === 'success') {
      dispatch(openSnackbar({ content: message, color: 'success', icon: 'check' }));
    }
    if (errortype === 'error') {
      dispatch(openSnackbar({ content: message, color: 'error', icon: 'warning' }));
    }
  };


  const memberIdImageUploader = async (e) => {
    uploadedFileField.current = 'IdImages';
    if (e.target.files.length) {
      const file = e.target.files[0];
      const body = new FormData();
      body.append('filePath', file);
      // res.fileUrl
      dispatch(uploadImage(body)).unwrap().then((res => {
        setFormData({ ...formData, idImage: res.fileUrl });
      }));
    }
  };

  const handleUploadMemberDocument = (e) => {
    // setSelectedFile(e.target.files[0]);
    const file = e.target.files[0];
    if (file.type !== 'application/pdf') {
      showError('Only PDF format is required', 'error');
      return;
    }

    uploadedFileField.current = 'document';
    if (e.target.files.length) {
      const file = e.target.files[0];
      const body = new FormData();
      body.append('filePath', file);
      // res.fileUrl
      dispatch(uploadImage(body)).unwrap().then((res => {
        setFormData({ ...formData, recId: res.fileUrl });
      }));
    }
  };

  const isMedicnalOr21Less = useMemo(() => {
    if (formData?.memberType === 'MedicinalUser') return true;
    if (!formData?.dob) return false;
    return isUnder21(formData?.dob);
  }, [formData?.dob, formData?.memberType]);

  const deleteHandler = (memberId) => {
    dispatch(setModalLoader(true));
    dispatch(deleteMember(memberId)).unwrap().then((res) => {
      showError(res?.message, 'success');
      dispatch(closeCustomModalDialog());
      navigate('/members');
    }).catch((err) => {
      dispatch(setModalLoader(false));
      showError(err?.message, 'error');
    });
  };

  const googleAddressErrorHandler = (status = false) => {
    addressError.current = status;
    if (status) {
      setErrors({ ...errors, address: 'Address is required' });
    }
  };

  useEffect(() => {
    if (!isEmptyObject(memberDetails)) {
      let payload = {
        ...memberDetails, ...memberDetails.deliveryAddress, address: formatDeliveryAddress(memberDetails?.deliveryAddress), dob: memberDetails?.dob ? dayjs(memberDetails?.dob) : '', status: memberDetails?.status ? 'active' : 'inactive'
      };
      setFormData({ ...payload, createdBy: user?.name });
      if (memberDetails?.deliveryAddress?.zipcode) {
        setFormData({ ...payload, zipcode: memberDetails?.deliveryAddress?.zipcode, createdBy: user?.name });
      }
    }
  }, [memberDetails]);

  const PlusIcon = createSvgIcon(
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
      <path d="M5.47 5.47a.75.75 0 0 1 1.06 0L12 10.94l5.47-5.47a.75.75 0 1 1 1.06 1.06L13.06 12l5.47 5.47a.75.75 0 1 1-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 0 1-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 0 1 0-1.06Z" />
    </svg>,
    'Plus',
  );
  const uploadThumbnailImage = {
    borderRadius: '20px',
    width: '160px',
    height: '160px',
    objectFit: 'cover',
    boxShadow: '0 0 1px rgb(0 0 0 / 20%)'
  };
  const circularProgressIcon = {
    backgroundColor: '#fff',
    borderRadius: 10,
    p: .5,
    position: 'absolute'
  };
  const badgeCircularProgressIcon = {
    backgroundColor: '#fff',
    borderRadius: 10,
    p: .5,
    mr: 14,
    mt: 14
  };

  const cancelBanMemberModal = useCallback(() => {
    setFormData({ ...formData, status: memberDetails?.status ? 'active' : 'inactive' });
  }, [formData, memberDetails]);

  useEffect(() => {
    setFormData({ ...formData, createdBy: user?.name });
  }, [user?.name]);

  return (
    <Box p={{ xs: 2, sm: 3, lg: 4 }}>
      <Card sx={{ p: { xs: 2, md: 3 }, borderRadius: 2.5, boxShadow: 'none' }}>
        <Stack direction={{ xs: 'column', sm: 'row' }} alignItems={{ sm: 'center' }} justifyContent="space-between" gap={2} mb={4.5}>
          <Typography variant='h4' fontWeight="semibold" color="secondary">{memberId ? 'Update' : 'Add'} Member</Typography>
          <Box display="flex" alignItems="center" gap={1.25}>
            <Typography variant='h5' fontWeight="semibold" color="secondary">Status</Typography>
            <Stack direction="row" alignItems="center" gap={.75} lineHeight={1} p={.75} borderRadius={2} bgcolor="#F2F3F7">
              <MDButton
                sx={{ minWidth: '92px', borderRadius: 1.75, minHeight: '28px', p: .25, fontWeight: 500 }}
                type='button'
                variant={formData?.status === 'active' ? 'contained' : 'text'}
                color={formData?.status === 'active' ? 'success' : 'dark'}
                size="small"
                onClick={() => {
                  setFormData({ ...formData, status: 'active' });
                }}
              >
                                Active
              </MDButton>
              <MDButton
                sx={{ minWidth: '92px', borderRadius: 1.75, minHeight: '28px', p: .25, fontWeight: 500 }}
                type='button'
                variant={formData?.status !== 'active' ? 'contained' : 'text'}
                color={formData?.status !== 'active' ? 'error' : 'dark'}
                size="small"
                onClick={() => {
                  if (memberId && formData?.status !=='inactive') {
                    dispatch(openCustomModalDialog({ modal: 'BAN_MEMBER', modalData: { banMemberSubmitHandler, cancelHandler: cancelBanMemberModal, memberStatus: 'inactive' } }));
                    setFormData({ ...formData, status: 'inactive' });
                  } else {
                    setFormData({ ...formData, status: 'inactive' });

                  }
                }}
              >
                                Inactive
              </MDButton>
            </Stack>
          </Box>
        </Stack>

        <Typography variant='h5' fontWeight="semibold" mb={2}>Personal Details</Typography>
        <Grid container spacing={2.5} mb={4.5}>
          <Grid item xs={12} md={6} lg={3}>
            <TextField className="form-input" error={errors?.firstName} helperText={errors.firstName} fullWidth label="First Name*" name="firstName" value={formData?.firstName || ''} onChange={handleChange} />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <TextField className="form-input" error={errors?.lastName} helperText={errors.lastName} fullWidth label="Last Name*" name="lastName" value={formData?.lastName} onChange={handleChange} />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <TextField className="form-input" type='email' error={errors?.email} helperText={errors.email} fullWidth label="Email*" name="email" value={formData.email} onChange={handleChange} />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <InputMask
              className="form-input"
              fullWidth
              error={errors?.phone}
              helperText={errors.phone}
              label="Phone*"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              mask='(999) 999-9999'
              maskChar=''
            >
              {(inputProps) => <TextField {...inputProps} disableUnderline />}
            </InputMask>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <FormControl fullWidth>
              <DatePicker
                sx={{ width: '100%' }}
                label="Date of birth*"
                error={errors?.dob}
                helperText={errors?.dob}
                name="dob"
                value={formData?.dob || null}
                onChange={(value) => setFormData({ ...formData, dob: value })}
                slots={{
                  openPickerIcon: CalendarIcon
                }}
                disableFuture
                slotProps={{
                  openPickerButton: {
                    color: 'secondary',
                  },
                }}
                onError={() => {
                  return Boolean(errors?.dob);
                }}
                format='MM/DD/YYYY'
              />
              {Boolean(errors?.dob) && <FormHelperText error>{errors?.dob}</FormHelperText>}
            </FormControl>
          </Grid >
          <Grid item xs={12} md={6} lg={3}>
            <FormControl error={Boolean(errors?.userType)} fullWidth>
              <InputLabel id="user-type-label">User Type*</InputLabel>
              <Select
                className="form-select"
                labelId="user-type-label"
                id="user-type-select"
                value={formData?.memberType}
                label="User Type*"
                name='memberType'
                onChange={handleChange}
              >
                <MenuItem value={'MedicinalUser'}>Medicinal User</MenuItem>
                <MenuItem value={'AdultUse'}>Adult Use</MenuItem>
              </Select>
              {errors?.userType && <FormHelperText error>{errors?.userType}</FormHelperText>}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <FormControl error={Boolean(errors?.memberShipGroup)} fullWidth>
              <InputLabel id="option-label">Membership Group*</InputLabel>
              <Select
                className="form-select"

                labelId="option-label"
                id="option-select"
                value={formData?.memberShipGroup}
                label="Membership Group*"
                onChange={handleChange}
                name='memberShipGroup'
              >
                <MenuItem value={'Delivery'}>Delivery</MenuItem>
                <MenuItem value={'Seniors'}>Seniors</MenuItem>
                <MenuItem value={'State Rec-card'}>State Rec-card</MenuItem>
                <MenuItem value={'Veteran'}>Veteran</MenuItem>
                <MenuItem value={'Walk-In'}>Walk-In</MenuItem>
              </Select>
              {errors?.memberShipGroup && <FormHelperText error>{errors?.memberShipGroup}</FormHelperText>}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <TextField className="form-input" fullWidth error={errors?.licenseNumber} helperText={errors.licenseNumber} label="License Number" name="licenseNumber" value={formData.licenseNumber} onChange={handleChange} />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <FormControl error={errors?.gender} fullWidth>
              <InputLabel>Gender</InputLabel>
              <Select className="form-select" helperText={errors.gender} label="Gender" name="gender" value={formData.gender?.toLocaleLowerCase()} onChange={handleChange}>
                <MenuItem value="male">Male</MenuItem>
                <MenuItem value="female">Female</MenuItem>
                <MenuItem value="other">Other</MenuItem>
              </Select>
              {errors?.gender && <FormHelperText error>{errors?.gender}</FormHelperText>}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <TextField
              className="form-input created-by"
              type='text'
              error={errors?.createdBy}
              helperText={errors.createdBy}
              fullWidth
              disabled
              label="Created By"
              name="createdBy"
              value={formData.createdBy}
              onChange={handleChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircle fontSize='medium' color='secondary' />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>

        <Typography variant='h5' fontWeight="semibold" mb={2}>Apartment Number</Typography>
        <Grid container spacing={2.5} mb={4.5}>
          <Grid item xs={12} className="address-field">
            <TextField className="form-input" name='memo' value={formData?.apartment || ''} error={Boolean(errors?.fieldError?.apartment)} helperText={errors?.fieldError?.apartment} onChange={(e) => setFormData({ ...formData, apartment: e.target.value })} fullWidth label="Apartment Number" />
          </Grid>
        </Grid>

        <Typography variant='h5' fontWeight="semibold" mb={2}>Address</Typography>
        <Grid container spacing={2.5} mb={4.5}>
          <Grid item xs={12} className="address-field">
            { <GoogleSuggestion addressValue={formData?.address} googleAddressErrorHandler={googleAddressErrorHandler} onClearAddress={(address) => setFormData({ ...formData, ...address })} addressChangeHandler={(address) => setFormData({ ...formData, ...address })} />}
            {errors?.address && <FormHelperText error>{errors?.address}</FormHelperText>}
          </Grid>
        </Grid>

        <Box>
          <Typography variant='h5' fontWeight="semibold" mb={1.5}>Upload ID Image</Typography>
          <Stack direction="row" flexWrap="wrap" gap={1.25} mb={4.5}>
            {formData?.idImage &&
                            <Badge
                              overlap="circular"
                              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                              badgeContent={
                                <>
                                  {
                                    !isUploading && <PlusIcon color='white' onClick={() => setFormData({ ...formData, idImage: '' })} fontSize='small' sx={{ backgroundColor: '#FD4438', borderRadius: 10, p: .25, mt: -4, mr: -4, cursor: 'pointer' }} />
                                  }
                                  {
                                    isUploading && uploadedFileField.current === 'IdImages' ? <CircularProgress size={32} color='primary' sx={badgeCircularProgressIcon} /> : ''
                                  }
                                </>
                              }
                            >
                              {formData?.idImage && <Zoom classDialog='custom-zoom' zoomImg={formData?.idImage} zoomMargin={10}   ><img style={uploadThumbnailImage} src={formData?.idImage} alt="documentId" /></Zoom>}
                            </Badge>}

            <label htmlFor="Id-image">
              <Box bgcolor="#F1F1F1" display="flex" alignItems="center" justifyContent="center" flexDirection="column" minWidth="160px" minHeight="160px" borderRadius="20px" p={2} sx={{ cursor: 'pointer' }}>
                {
                  !formData?.idImage && isUploading && uploadedFileField.current === 'IdImages' ? <CircularProgress size={32} color='primary' sx={circularProgressIcon} /> : <Typography variant='h5' fontWeight="medium" textAlign="center">+<br />Upload<br />Image</Typography>
                }
              </Box>
            </label>

            <MDInput
              id="Id-image"
              type="file"
              name="ID"
              onChange={memberIdImageUploader}
              fullWidth
              style={{ display: 'none' }}
              accept=".png, .jpg, .jpeg"
            />
            {
              errors?.idImage && <FormHelperText sx={{ width: '100%', ml: 0, mt: -.5 }} error={errors?.idImage}>{errors?.idImage}</FormHelperText>
            }
          </Stack>
        </Box>
        {
          isMedicnalOr21Less && <Box>
            <Typography variant='h5' fontWeight="semibold" mb={1}>Upload PDF Document</Typography>
            <Stack direction="column" alignItems="start" spacing={1} mb={4.5}>
              <input
                type="file"
                id="document-upload"
                style={{ display: 'none' }}
                accept=".pdf"
                onChange={handleUploadMemberDocument}
              />
              <label htmlFor="document-upload">
                <Box bgcolor="#F1F1F1" display="flex" alignItems="center" justifyContent="center" flexDirection="column" minWidth="160px" minHeight="160px" borderRadius="20px" p={2} sx={{ cursor: 'pointer' }}>
                  {
                    isUploading && uploadedFileField.current === 'document' ? <CircularProgress size={32} color='primary' sx={circularProgressIcon} /> : <Typography variant='h5' fontWeight="medium" textAlign="center">+<br />Upload<br />Document (.pdf)</Typography>
                  }

                </Box>
              </label>
              {
                formData?.recId && <>
                  <Link to={formData.recId} style={{ color: 'inherit' }} >
                    <Typography variant='body2' fontWeight="medium" sx={{ wordBreak: 'break-all' }}>{formData.recId}</Typography>
                  </Link>
                </>
              }
              {
                errors?.recId && <FormHelperText error={errors?.recId}>{errors?.recId}</FormHelperText>
              }
            </Stack>
          </Box>
        }
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1.25} mt={4} mb={2}>
          <MDButton
            sx={{ minWidth: '140px' }}
            type='button'
            variant='contained'
            color='primary'
            onClick={handleSubmit}
            disabled={isUploading || loading}
            loading={loading}

          >
            {memberId ? 'Update' : 'Save'}
          </MDButton>
          {
            memberId && <MDButton
              sx={{ minWidth: '140px', backgroundColor: '#F1F1F1', color: '#FD4438' }}
              type='button'
              variant='contained'
              color='error'
              onClick={() => dispatch(openCustomModalDialog({ modalData: { deleteHandler, deleteId: memberId, deleteType: 'member' }, modal: 'DELETE_MODAL' }))}
            >
                            Delete
            </MDButton>
          }

          <MDButton
            sx={{ minWidth: '140px' }}
            type='button'
            variant='outlined'
            color='dark'
            onClick={() => navigate('/members')}
          >
                        Cancel
          </MDButton>
        </Stack>
      </Card>
    </Box >
  );
};

export default AddMemberForm;