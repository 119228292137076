import axiosClient from "axiosClient";

export async function fetchRetailersAPI(){
    return await axiosClient.get('/api/v1/admin/retailer/all/');
}

export async function fetchRetailerDetailsAPI(retailerId) {
    return await axiosClient.get(`/api/v1/admin/retailer/${retailerId}`)
}

export async function addRetailerAPI(data) {
    return await axiosClient.post(`/api/v1/admin/retailer`, data);
}

export async function updateRetailerAPI(data,retailerId) {
    return await axiosClient.put(`/api/v1/admin/retailer/${retailerId}`, data);
}

export async function updateRetailerBranchAPI(data, branchId) {
    return await axiosClient.put(`/api/v1/admin/retailer/settings/branch/${branchId}`, data)
}

export async function deleteRetailerAPI(retailerId) {
    return await axiosClient.delete(`/api/v1/admin/retailer/${retailerId}`);
}

