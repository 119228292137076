import { addRoleAPI, deleteRoleAPI, fetchRoleAndPermissionsListAPI, fetchRoleDetailsAPI, fetchRolesAPI, fetchRolesListAPI, updateRoleAPI } from "../../redux/apis/roleAndPermissions";
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Your fetchOrders async thunk
export const fetchRoleList = createAsyncThunk(
    'orders/fetchRoleList',
    async (filters, { rejectWithValue }) => {
        try {
            const res = await fetchRolesListAPI(filters);
            return res;
        } catch (error) {
             
            return rejectWithValue(error.response?.data);
        }
    }
);

export const fetchRoles = createAsyncThunk(
    'orders/fetchRoles',
    async (filters, { rejectWithValue }) => {
        try {
            const res = await fetchRolesAPI(filters);
            return res;
        } catch (error) {
             
            return rejectWithValue(error.response?.data);
        }
    }
);

export const fetchRoleDetails = createAsyncThunk(
    'members/fetchRoleDetails',
    async (roleId, { rejectWithValue }) => {
        try {
            const res = await fetchRoleDetailsAPI(roleId)
            return res
        } catch (error) {
             
            return rejectWithValue(error?.response?.data)
        }
    }
)

export const addRole = createAsyncThunk(
    'members/addRole',
    async (data, { rejectWithValue }) => {
        try {
            const res = await addRoleAPI(data)
            return res
        } catch (error) {
             
            return rejectWithValue(error?.response?.data)
        }
    }
)


export const updateRole = createAsyncThunk(
    'members/updateRole',
    async ({ data, adminId }, { rejectWithValue }) => {
        try {
            const res = await updateRoleAPI(data, adminId)
            return res
        } catch (error) {
             
            return rejectWithValue(error?.response?.data)
        }
    }
)

export const deleteRole = createAsyncThunk(
    'members/deleteRole',
    async (adminId, { rejectWithValue }) => {
        try {
            const res = await deleteRoleAPI(adminId)
            return res
        } catch (error) {
             
            return rejectWithValue(error?.response?.data)
        }
    }
)

export const rolesAndPermissionList = createAsyncThunk(
    'members/rolesAndPermissionList',
    async (filters, { rejectWithValue }) => {
        try {
            const res = await fetchRoleAndPermissionsListAPI(filters)
            return res
        } catch (error) {
             
            return rejectWithValue(error?.response?.data)
        }
    }
)


// Create a slice for orders
const roleAndPermissionsSlice = createSlice({
    name: 'roleAndPermissions',
    initialState: {
        roles: [],
        isLoading: false, // Flag to track loading status
        error: null,
        isUpdatingStatus: false
    },
    reducers: {
        // You can define additional reducers here if needed
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchRoleList.pending, (state) => {
                state.isLoading = true; // Set isLoading to true when fetching starts
                state.error = null; // Reset error when starting a new fetch
                state.roleDetails = {}
            })
            .addCase(fetchRoleList.fulfilled, (state, action) => {
                state.isLoading = false; // Set isLoading to false when fetching completes
                state.rolesList = action.payload; // Update orders with the fetched data
            })
            .addCase(fetchRoleList.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload; // Update error in case of failure
            });

        builder
            .addCase(fetchRoles.pending, (state) => {
                state.isLoading = true; // Set isLoading to true when fetching starts
                state.error = null; // Reset error when starting a new fetch
                state.roleDetails = {}
            })
            .addCase(fetchRoles.fulfilled, (state, action) => {
                state.isLoading = false; // Set isLoading to false when fetching completes
                state.roles = action.payload; // Update orders with the fetched data
            })
            .addCase(fetchRoles.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload; // Update error in case of failure
            });

        builder.addCase(fetchRoleDetails.fulfilled, (state, action) => {
            state.isLoading = false;
            state.roleDetails = action.payload.adminData; // Update state with the received details
            state.error = null;
        });

        // Extra reducer for the rejected action of fetchRoleDetails
        builder.addCase(fetchRoleDetails.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload; // Update state with the error message
        });

        // Extra reducer for the pending action of fetchRoleDetails
        builder.addCase(fetchRoleDetails.pending, (state) => {
            state.isLoading = true;
        });

        builder
            .addCase(addRole.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })

        builder.addCase(addRole.fulfilled, (state, action) => {
            state.isLoading = false;
            state.addedRole = action.payload; // Update state with the added member data
            state.error = null;
        });

        // Extra reducer for the rejected action of addRole
        builder.addCase(addRole.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload; // Update state with the error message
        });

        builder
            .addCase(updateRole.pending, (state, action) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(updateRole.fulfilled, (state, action) => {
                state.isLoading = false;
                // You can update your state with the data received from the API if needed
            })
            .addCase(updateRole.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload; // The error message returned from the API
            });


        builder
            .addCase(deleteRole.pending, (state, action) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(deleteRole.fulfilled, (state, action) => {
                state.isLoading = false;
                // You can update your state with the data received from the API if needed
            })
            .addCase(deleteRole.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload; // The error message returned from the API
            });

        builder
            .addCase(rolesAndPermissionList.pending, (state) => {
                state.isLoading = true; // Set isLoading to true when fetching starts
                state.error = null; // Reset error when starting a new fetch
            })
            .addCase(rolesAndPermissionList.fulfilled, (state, action) => {
                state.isLoading = false; // Set isLoading to false when fetching completes
                state.roleAndPermissions = action.payload; // Update orders with the fetched data
            })
            .addCase(rolesAndPermissionList.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload; // Update error in case of failure
            });

    },
});

export const { /* any additional actions */ } = roleAndPermissionsSlice.actions;
export default roleAndPermissionsSlice.reducer;
