import AddRegionForm from 'components/regions';
import CommonLayout from 'layouts/DashboardLayout';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchSingleRegion } from '../../../redux/slices/regions';

export default function AddRegion() {
  const dispatch = useDispatch();
  const { regionId = '' } = useParams();
  useEffect(() => {
    if (regionId) {
      dispatch(fetchSingleRegion(regionId));
    }
  }, [regionId]);
  return (
    <CommonLayout>
      <AddRegionForm />
    </CommonLayout>
  );
}
