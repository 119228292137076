 

// Material Dashboard 2 React base styles
import colors from "assets/theme/base/colors";

// Material Dashboard 2 React helper functions
import pxToRem from "assets/theme/functions/pxToRem";
import borders from "assets/theme/base/borders";

const { transparent, grey } = colors;
const { borderRadius } = borders;

const select = {
  styleOverrides: {
    select: {
      display: "grid",
      alignItems: "center",
      // padding: `0 ${pxToRem(12)} !important`,
      padding: `${pxToRem(12)} ${pxToRem(20)} !important`,
      // backgroundColor: grey[200],
      borderRadius: borderRadius.section,

      "& .Mui-selected": {
        backgroundColor: transparent.main,
      },
    },

    selectMenu: {
      background: "none",
      height: "none",
      minHeight: "none",
      overflow: "unset",
    },

    icon: {
      display: "none",
    },
  },
};

export default select;
