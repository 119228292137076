import { validatePassword } from "utilities/common";
import { isValidEmail } from "./member";

const { isEmptyObject } = require("utilities/common");

export function roleFormValidation(fields,isValidatePassword) {
    const errors = {};
    if (!fields.email || !isValidEmail(fields.email)) {
        errors.email = 'Enter a valid email address';
    }
    if(fields?.roles?.length ===0 ){
        errors.roles = 'Please select at least one role';
    }

    if (!fields.firstName || fields.firstName.trim() === '') {
        errors.firstName = 'First name is required';
    }

    if (!fields.lastName || fields.lastName.trim() === '') {
        errors.lastName = 'Last name is required';
    }

    if(isValidatePassword){
        if (!fields.password) {
            errors.password = "Password is required.";
        }
        if (!fields.confirmPassword) {
            errors.confirmPassword = "Confirm password is required.";
        }
    
        if ((fields.password && fields.confirmPassword) && fields.password !== fields.confirmPassword) {
            errors.confirmPassword = "Password must be same.";
        }
    
        if (fields.password && fields.confirmPassword) {
            if (!validatePassword(fields.password)) {
                errors.password = 'Please enter a valid password. Ensure it has a minimum length of 12 characters and includes a mix of uppercase letters, lowercase letters, numbers, and special characters.'
            }
        }
    }


    return { errors, isValid: isEmptyObject(errors) };
}