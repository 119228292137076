import { Card, Typography, Grid, Box, CardContent, Stack, IconButton } from '@mui/material';
import AngleRightIcon from 'common/customIcons/angle_right';
import EditIcon from 'common/customIcons/edit';
import MDButton from 'components/MDButton';
import CommonLayout from 'layouts/DashboardLayout';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { fetchRetailerDetails, updateRetailerBranch } from '../../../redux/slices/retailerSlice';
import colors from 'assets/theme/base/colors';
import { formatUsPhoneNumber } from 'utilities/common';
import RetailerOrders from 'components/retailers/Orders';
import RetailerProducts from 'components/retailers/Products';
import RetailerMembers from 'components/retailers/Members';
import RetailerApprovals from 'components/retailers/Approvals';
import { formatTimestampToWithoutTime } from 'utilities/common';
import { UploadFile } from '@mui/icons-material';
import MapWithPolygon from 'components/googleMaps';
import { openSnackbar, uploadImage } from '../../../redux/slices/common';
import { orange } from '@mui/material/colors';
import { isValidFileExtension } from 'utilities/common';

export default function RetailerDetails() {

  const dispatch = useDispatch();
  const { search } = useLocation();
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState('retailerDetails');
  const [uploadedKML, setUploadedKML] = useState('');
  const [searchParams, setSearchParams] = useSearchParams(search);
  const { grey } = colors;
  const kmlRefs = useRef({});
  const { retailerDetails = {} } = useSelector(state => state.retailers) || {};
  const {
    retailerName,
    firstName,
    lastName,
    phone,
    address = {},
    email,
    status,
    subscription,
    valiDateFrom,
    valiDateTo,
    isViewUser,
    retailerBranches = []
  } = retailerDetails;

  const { retailerId } = useParams();

  useEffect(() => {
    if (retailerId) {
      dispatch(fetchRetailerDetails(retailerId));
    }
  }, [retailerId]);


  useEffect(() => {
    if (search) {
      setCurrentTab(searchParams.get('filter'));
    }
  }, [search]);

  const showMessage = (message, errortype) => {
    if (errortype === 'success') {
      dispatch(openSnackbar({ content: message, color: 'success', icon: 'check' }));
    }
    if (errortype === 'error') {
      dispatch(openSnackbar({ content: message, color: 'error', icon: 'warning' }));
    }
  };

  const uploadKMLHandler = async (e, branchId) => {
    if (e.target.files.length) {
      if (!isValidFileExtension(e.target.files[0], '.kml')) {
        showMessage('Upload only KML file', 'error');
        return;
      }
      const file = e.target.files[0];
      const body = new FormData();
      body.append('filePath', file);
      body.append('retailerId', retailerId);
      try {
        const res = await dispatch(uploadImage(body)).unwrap();

        const updateData = {
          kml: res?.fileUrl,
          retailerId: retailerId
        };

        await dispatch(updateRetailerBranch({ data: updateData, branchId }))
          .unwrap().then((res) => {
            dispatch(fetchRetailerDetails(retailerId));
            showMessage(res?.message, 'success');
          });

      } catch (error) {
      }
      e.target.value = '';
    }
  };

  return (<>
    <CommonLayout>
      <Box p={{ xs: 2, sm: 3, lg: 4 }}>

        <Link to='/retailers'>
          <Box display="flex" alignItems="center" color='text.main' lineHeight={1} mb={3.5}>
            <AngleRightIcon fontSize="14px" />
            <Typography variant='h6' fontWeight="semibold" ml={.75}>Retailers</Typography>

          </Box>
        </Link>


        {
          currentTab === 'retailerOrders' ?
            <RetailerOrders retailerId={retailerId} /> :

            currentTab === 'retailerProducts' ?
              <RetailerProducts /> :

              currentTab === 'retailerMembers' || currentTab.includes('retailerMembers') ?
                <RetailerMembers retailerId={retailerId} /> :

                currentTab === 'retailerApprovals' ?
                  <RetailerApprovals retailerId={retailerId} /> :

                  <Grid container spacing={2.5} mb={4}>

                    <Grid item xs={12} md={12} lg={12}>

                      <Stack direction="row" alignItems="center" justifyContent="space-between">
                        <Typography variant='h4'>Retailer Details</Typography>

                        <Stack direction="row" alignItems="center" gap={.75} lineHeight={1} p={.75} borderRadius={2} >
                          {
                            status === 'active' && <MDButton
                              sx={{ minWidth: '92px', borderRadius: 1.75, p: .25, fontWeight: 500 }}
                              style={{ opacity: 1 }}
                              type='button'
                              variant={status === 'active' ? 'contained' : 'text'}
                              color={status === 'active' ? 'success' : 'dark'}
                              size="small"
                              disabled

                            >
                              Active
                            </MDButton>
                          }

                          {
                            status === 'inactive' && <MDButton
                              sx={{ minWidth: '92px', borderRadius: 1.75, p: .25, fontWeight: 500 }}
                              type='button'
                              variant={status === 'inactive' ? 'contained' : 'text'}
                              color={status === 'inactive' ? 'error' : 'dark'}
                              size="small"
                              style={{ opacity: 1 }}
                              disabled
                            >
                              Inactive
                            </MDButton>
                          }

                          {
                            status === 'pending' && <MDButton
                              sx={{ minWidth: '92px', borderRadius: 1.75, p: .25, fontWeight: 500 }}
                              type='button'
                              variant={status === 'pending' ? 'contained' : 'text'}
                              // color={status === "pending" ? 'error' : 'dark'}
                              size="small"
                              style={{ opacity: 1, backgroundColor: '#8E0CF5', color: '#ffffff' }}
                              disabled
                            >
                              Pending
                            </MDButton>
                          }

                          <MDButton
                            type='button'
                            variant='outlined'
                            color='dark'
                            size="small"
                            startIcon={<EditIcon width='14px' height='14px' />}
                            sx={{ borderRadius: 30 }}
                            onClick={() => navigate(`/retailers/${retailerId}`)}
                          >
                            Edit
                          </MDButton>
                        </Stack>
                      </Stack>
                    </Grid>



                    <Grid item xs={12} md={12} lg={12}>
                      <Card variant='detailsCard' sx={{ height: '100%', overflow: 'hidden' }}>
                        <CardContent sx={{ p: { xs: 2, md: 3.5 }, pb: 0 }}>
                          <Grid container alignItems="center" spacing={3.5} mb={1}>
                            <Grid item xs={12} md={4}>
                              <Typography variant='body2' color={grey[600]}>ID</Typography>
                              <Typography variant='h6' fontWeight="medium" sx={{ textTransform: 'capitalize' }}>{retailerId}</Typography>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <Typography variant='body2' color={grey[600]}>Retailer Name</Typography>
                              <Typography variant='h6' fontWeight="medium" sx={{ textTransform: 'capitalize' }}>{retailerName?.toLowerCase()}</Typography>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <Typography variant='body2' color={grey[600]}>Contact Name</Typography>
                              <Typography variant='h6' fontWeight="medium" sx={{ textTransform: 'capitalize' }}>{firstName?.toLowerCase() + ' ' + lastName?.toLowerCase()}</Typography>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <Typography variant='body2' color={grey[600]}>Email</Typography>
                              <Typography variant='h6' fontWeight="medium">{email}</Typography>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <Typography variant='body2' color={grey[600]}>Phone</Typography>
                              <Typography variant='h6' fontWeight="medium">{phone ? formatUsPhoneNumber(phone) : '-'}</Typography>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <Typography variant='body2' color={grey[600]}>Store Address</Typography>
                              <Typography variant='h6' fontWeight="medium">{address.address}</Typography>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <Typography variant='body2' color={grey[600]}>City</Typography>
                              <Typography variant='h6' fontWeight="medium">{address.city}</Typography>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <Typography variant='body2' color={grey[600]}>State</Typography>
                              <Typography variant='h6' fontWeight="medium">{address.state}</Typography>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <Typography variant='body2' color={grey[600]}>Zipcode</Typography>
                              <Typography variant='h6' fontWeight="medium">{address.zipcode}</Typography>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <Typography variant='body2' color={grey[600]}>Permission to view user details</Typography>
                              <Typography variant='h6' fontWeight="medium">{isViewUser ? 'Yes' : 'No'}</Typography>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <Typography variant='body2' color={grey[600]} className='d-flex' >Subscription  <div className='text-color font-w-600 ml-1'> {valiDateFrom ? (`(Valid Upto ${formatTimestampToWithoutTime(valiDateTo)})`) : '(Lifetime)'}</div> </Typography>
                              <Typography variant='h6' fontWeight="medium" color={orange[600]} className={`${subscription === 'Paid' ? 'paid-badge' : ''}`}><p>{subscription}</p></Typography>
                            </Grid>

                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>



                    <Grid item xs={12} md={12} lg={12}>

                      <Stack direction="row" alignItems="center" justifyContent="space-between">
                        <Typography variant='h4'>Branches({retailerBranches.length})</Typography>
                      </Stack>
                    </Grid>
                    {retailerBranches.map((branch, index) => (

                      <Grid item xs={12} md={12} lg={12} key={index}>

                        <Card>

                          <CardContent sx={{ p: { xs: 2, md: 3.5 }, pb: 0 }}>
                            <Grid container alignItems="center" spacing={3.5} mb={1} className='align-item-top'>


                              <Grid item xs={12} md={4}>
                                <Typography variant='body2' color={grey[600]}>Name</Typography>
                                <Typography variant='h6' fontWeight="medium" sx={{ textTransform: 'capitalize' }}>{branch.branchName?.toLowerCase()}</Typography>
                              </Grid>
                              <Grid item xs={12} md={4}>
                                <Typography variant='body2' color={grey[600]}>Phone Number</Typography>
                                <Typography variant='h6' fontWeight="medium">{branch.phone ? formatUsPhoneNumber(branch.phone) : ''}</Typography>

                              </Grid>
                              <Grid item xs={12} md={4}>
                                <Typography variant='body2' color={grey[600]}>E-mail</Typography>
                                <Typography variant='h6' fontWeight="medium">{branch.email}</Typography>
                              </Grid>
                              <Grid item xs={12} md={4}>
                                <Typography variant='body2' color={grey[600]}>Address</Typography>
                                <Typography variant='h6' fontWeight="medium">{branch.address.address}</Typography>
                              </Grid>
                              <Grid item xs={12} md={4}>
                                <Typography variant='body2' color={grey[600]}>City</Typography>
                                <Typography variant='h6' fontWeight="medium">{address.city}</Typography>
                              </Grid>
                              <Grid item xs={12} md={4}>
                                <Typography variant='body2' color={grey[600]}>State</Typography>
                                <Typography variant='h6' fontWeight="medium">{address.state}</Typography>
                              </Grid>
                              <Grid item xs={12} md={4}>
                                <Typography variant='body2' color={grey[600]}>Zipcode</Typography>
                                <Typography variant='h6' fontWeight="medium">{address.zipcode}</Typography>
                              </Grid>

                              <Grid item xs={12} md={2}>
                                <Typography variant='body2' color={grey[600]}>KML</Typography>
                                {branch?.kml ?

                                  <Box className="map-custom" sx={{
                                    borderRadius: 0, overflow: 'hidden',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center', justifyContent: 'center'
                                  }}>
                                    {
                                      <>
                                        <MapWithPolygon kmlUrl={branch?.kml} />
                                        <div className='w-100 d-flex align-item-center jc-center mt-4 update-btn'>
                                          <IconButton color='secondary' onClick={
                                            (e) => {
                                              e.stopPropagation();
                                              // kmlRef.current.click()
                                              kmlRefs.current[branch.branchId].click();
                                            }
                                          }>
                                            <UploadFile />
                                            <Typography>Update</Typography>
                                          </IconButton>
                                        </div>
                                      </>
                                    }

                                  </Box> :
                                  <IconButton color='secondary' onClick={
                                    (e) => {
                                      e.stopPropagation();
                                      // kmlRef.current.click()
                                      kmlRefs.current[branch.branchId].click();
                                    }
                                  }>
                                    <UploadFile />
                                  </IconButton>

                                }

                                <input
                                  id={`${'product-image-input' - branch.branchId}`}
                                  type="file"
                                  name="productImageFile"
                                  onChange={(e) => uploadKMLHandler(e, branch.branchId)}
                                  ref={(el) => kmlRefs.current[branch.branchId] = el}
                                  accept='.kml'
                                  style={{ display: 'none' }}
                                />

                                {

                                  uploadedKML[branch.branchId] &&
                                  <Grid item xs={12} md={2}>

                                    <Box className="map-custom" sx={{
                                      borderRadius: 4, overflow: 'hidden', height: 230, m: 2
                                      , display: 'flex',
                                      flexDirection: 'column',
                                      alignItems: 'center', justifyContent: 'center'
                                    }}>
                                      <MapWithPolygon kmlUrl={uploadedKML[branch.branchId]} />
                                    </Box>
                                  </Grid>
                                }
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>

                      </Grid>
                    ))}

                  </Grid>
        }
      </Box>
    </CommonLayout>
  </>);
}