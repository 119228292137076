import { axiosHyperwolfClient } from 'axiosClient';
import { getQueryString } from 'utilities/common';

export function getEmployeesAPI(data) {
    return axiosHyperwolfClient.get(`api/v1/hyperdrive/get/employees?${getQueryString(data)}`);
}

export function addEmployeeAPI(data, id = '') {
    if (id) {
        return axiosHyperwolfClient.put(`api/v1/hyperdrive/update/${id}`, data);
    }
    return axiosHyperwolfClient.post('api/v1/hyperdrive/create/employee', data);
}

export function deleteEmployeeAPI(id) {
    return axiosHyperwolfClient.delete(`api/v1/hyperdrive/delete/employee/${id}`)
}
