import { Box, Card, FormControl, FormHelperText, Grid, IconButton, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import MDButton from 'components/MDButton';
import React, { useEffect,  useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { openSnackbar } from '../../../../redux/slices/common';
import { assignTerpenoidToProduct, fetchTerpenoids, updateTerpenoidToProduct } from '../../../../redux/slices/terpenoids';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import DeleteIcon from 'common/customIcons/delete';
import { validateTerpeneArray } from 'validations/terpenoids';
import { isEmptyObject } from 'utilities/common';
import { isValidNumber } from 'utilities/common';
import { getCannabinoidsList } from '../../../../redux/slices/cannabinoids';

const useStyles = makeStyles((theme) => ({
  form: {
    marginTop: theme.spacing(4),
  },
  inputField: {
    marginBottom: theme.spacing(2),
  },
}));


export default function AddProductTerpenoid({ terpenoidHandler, editTerpenoid, productId, resetEditTerpenoid }) {
  const classes = useStyles();
  const { isLoading = false, cannabinoidList = {} } = useSelector(state => state.cannabinoids);
  const [terpenoidData, setTerpenoidData] = useState([
    {
      'terpeneName': '',
      'terpeneValue': '',
      'terpeneImage': ''
    }
  ]);
  const [errors, setErrors] = useState([]);

  const dispatch = useDispatch();

  const handleChange = (event, terpenoidIdx = 0) => {
    if (event?.target.value && event?.target.name === 'terpeneValue' && !isValidNumber(event?.target.value)) return;
    let terpenoid = terpenoidData?.find(((terpenoid, idx) => terpenoidIdx === idx));
    if (terpenoid) {
      const terpeneData = cannabinoidList?.cannabinoids?.find((terpenoid => terpenoid.terpeneName === event.target.value));
      const terpenoidIdx = terpenoidData?.indexOf(terpenoid);
      terpenoid = {
        ...terpenoid,
        terpeneImage: terpeneData?.terpeneImage,
        [event.target.name]: event.target.value
      };
      let updatedTerpenoidData = [...terpenoidData];
      updatedTerpenoidData[terpenoidIdx] = terpenoid;
      setTerpenoidData(updatedTerpenoidData);
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const { isValid = false, errors = [] } = validateTerpeneArray(terpenoidData);
    if (!isValid) {
      setErrors(errors);
      return;
    }
    setErrors([]);
    if (!isEmptyObject(editTerpenoid) && productId) {
      dispatch(updateTerpenoidToProduct({ data: { newTerpeneName: terpenoidData[0]?.terpeneName, terpeneId: editTerpenoid?._id, newTerpeneValue: terpenoidData[0]?.terpeneValue }, productId })).unwrap().then((res) => {
        showError(res?.message, 'success');
        terpenoidHandler();
        resetEditTerpenoid();
      }).catch((err) => {
        showError(err?.message, 'error');
      });
    } else {
      dispatch(assignTerpenoidToProduct({ data: terpenoidData, productId })).unwrap().then((res) => {
        showError(res?.message, 'success');
        terpenoidHandler();
        resetEditTerpenoid();
      }).catch((err) => {
        showError(err?.message, 'error');
      });
    }
  };

  const showError = (message, errortype) => {
    if (errortype === 'success') {
      dispatch(openSnackbar({ content: message, color: 'success', icon: 'check' }));
    }
    if (errortype === 'error') {
      dispatch(openSnackbar({ content: message, color: 'error', icon: 'warning' }));
    }
  };

  const addMoreTerpene = () => {
    setTerpenoidData([...terpenoidData, {
      'terpeneName': '',
      'terpeneValue': '',
      'terpeneImage': ''
    }]);
  };

  const removeTerpene = (e, terpeneIdx) => {
    let updatedTerpeneData = terpenoidData?.filter(((terpene, idx) => idx !== terpeneIdx));
    setTerpenoidData(updatedTerpeneData);
  };

  useEffect(() => {
    dispatch(fetchTerpenoids({ limit: 100, skip: 0, userType: 'admin' }));
    dispatch(getCannabinoidsList({ limit: 100, skip: 0, userType: 'admin' }));
  }, []);



  useEffect(() => {
    if (!isEmptyObject(editTerpenoid)) {
      setTerpenoidData([{
        terpeneName: editTerpenoid?.terpeneName,
        terpeneValue: editTerpenoid?.terpeneValue,
      }]);
    }
  }, [editTerpenoid]);

  return (
    <Card sx={{ p: 3, mb: 4, borderRadius: 2.5, boxShadow: 'none' }}>
      <Typography variant='h4' fontWeight="semibold" color="secondary" mb={3.5}>Select Cannabinoid</Typography>
      <form className={classes.form} onSubmit={handleSubmit}>
        {
          terpenoidData?.map(((form, idx) => {
            return <Grid key={idx} container columnSpacing={3} rowSpacing={5} mb={4.5}>
              <Grid item xs={12} md={6} lg={3}>
                <FormControl fullWidth>
                  <InputLabel>Cannabinoid Name</InputLabel>
                  <Select
                    className="form-select"
                    fullWidth
                    onChange={(e) => handleChange(e, idx)}
                    value={form.terpeneName}
                    name='terpeneName'
                    label='Cannabinoid Name'
                    error={Boolean(errors[idx]?.terpeneName)}
                  >
                                   
                    {
                      cannabinoidList?.
                        cannabinoids?.map((cannabinoidItem => {
                          return <MenuItem disabled={terpenoidData?.find((_item => _item.terpeneName === cannabinoidItem?.cannabinoidName)) || cannabinoidList?.cannabinoids?.find((_item => _item.cannabinoidName === cannabinoidItem?.terpeneName))} key={cannabinoidItem?._id} value={cannabinoidItem?.cannabinoidName}>{cannabinoidItem?.cannabinoidName}</MenuItem>;
                        }))
                    }

                  </Select>
                  {Boolean(errors[idx]?.terpeneName) && <FormHelperText error>{errors[idx]?.terpeneName}</FormHelperText>}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  className="form-input"
                  fullWidth
                  value={form.terpeneValue}
                  name='terpeneValue'
                  onChange={(e) => handleChange(e, idx)}
                  label='Cannabinoid Value'
                  error={Boolean(errors[idx]?.terpeneValue)}
                  helperText={errors[idx]?.terpeneValue}
                />
              </Grid>
              {
                isEmptyObject(editTerpenoid) && <>
                  {
                    terpenoidData?.length === idx + 1 ? <Grid item xs={12} md={6} lg={3}>
                      <Box display='flex' gap={2}>
                        {
                          idx !== 0 && <IconButton aria-label="delete" size="small" onClick={(e) => removeTerpene(e, idx)}>
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        }
                        <MDButton
                          startIcon={<AddRoundedIcon fontSize='large' />}
                          variant='contained'
                          color='primary'
                          onClick={addMoreTerpene}
                        >
                                                    Add More
                        </MDButton>

                      </Box>
                    </Grid> : <Grid item xs={12} md={6} lg={3}>
                      <IconButton aria-label="delete" size="small" onClick={(e) => removeTerpene(e, idx)}>
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </Grid>
                  }
                </>
              }
            </Grid>;
          }))
        }

        <Stack direction="row" alignItems="center" spacing={1.25} mt={7} mb={2}>
          <MDButton
            sx={{ minWidth: '140px' }}
            type='submit'
            variant='contained'
            color='primary'
            loading={isLoading}
            disabled={isLoading}
          >
                        Save
          </MDButton>

          <MDButton
            sx={{ minWidth: '140px' }}
            type='button'
            variant='outlined'
            color='dark'
            onClick={() => {
              resetEditTerpenoid();
              terpenoidHandler();
            }}
          >
                        Cancel
          </MDButton>
        </Stack>
      </form >
    </Card>
  );
}