import CommonLayout from 'layouts/DashboardLayout';
import React, { useEffect } from 'react';
import { Box, Card, CardContent, Chip, Grid, Stack,  Typography } from '@mui/material';
import AngleRightIcon from 'common/customIcons/angle_right';
import colors from 'assets/theme/base/colors';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { closeCustomModalDialog, openCustomModalDialog } from '../../../redux/slices/modalSlice';
import { openSnackbar } from '../../../redux/slices/common';
import ActiveIcon from 'common/customIcons/active';
import InActiveIcon from 'common/customIcons/inactive';
import { fetchApprovalDetails, updateApproval } from '../../../redux/slices/approvals';
import { formatTimestampToWithoutTime } from 'utilities/common';
import { formatUsPhoneNumber } from 'utilities/common';

export default function RetailerApprovalDetails() {
  const { grey } = colors;
  const { approvalId='' } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { approvalDetail } = useSelector(state => state.approvalSlice);
  const { productDetails, retailerDetails} = approvalDetail;
  useEffect(() => {
    if (approvalId) {
      dispatch(fetchApprovalDetails(approvalId));
    }
  },[approvalId]);

  const showResponseMessage = (message, errortype) => {
    if (errortype === 'success') {
      dispatch(openSnackbar({ content: message, color: 'success', icon: 'check' }));
    }
    if (errortype === 'error') {
      dispatch(openSnackbar({ content: message, color: 'error', icon: 'warning' }));
    }
  };
  
  const approvalStatusHandler = (approvalId, reason) => {
    dispatch(updateApproval({isDeclined: true, isAccept:false, isOverride:false, approvalId:approvalId, declinedReason:reason})).unwrap().then((res)=>{
      dispatch(closeCustomModalDialog());
      showResponseMessage(res?.message, 'success');
    }).catch((err) => {
      showResponseMessage(err?.message, 'error');
    });
    navigate('/approvals');
  };
  
  const approvalDialogHandler = (approvalId) => {
    dispatch(openCustomModalDialog({ modal: 'DECLINE_APPROVAL_REASON', modalData: {  approvalId: approvalId, approvalStatusHandler: (reason)=>{
      approvalStatusHandler(approvalId, reason);
    }  } }));
  };
  
  const approveRetailerProduct = (approvalId) => {
    dispatch(updateApproval({isDeclined:false , isAccept:true , isOverride:false, approvalId: approvalId})).unwrap().then(res=>{
      showResponseMessage(res?.message, 'success');
    });
    navigate('/approvals');
  };

  const approvalOverrideDialogHandler =async (approvalId, retailerId,   productId) => {
    dispatch(openCustomModalDialog({modal: 'OVERRIDE_APPROVAL', modalData: { approvalId, retailerId, productId }}));
  };

  return (
    <CommonLayout>
      <Box p={{ xs: 2, sm: 3, lg: 4 }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2} mb={3}>
          <Link to={`/retailers/detail/${retailerDetails?.retailerId}?filter=retailerApprovals`}>
            <Box display="flex" alignItems="center" color='text.main' lineHeight={1}>
              <AngleRightIcon fontSize="14px" />
              <Typography variant='h6' fontWeight="semibold" ml={.75}>Approval Details</Typography>
            </Box>

          </Link>
          <Box display="inline-flex" alignItems="center">
            <Box display="inline-flex" gap={1} className="invite-action">
              <Chip
                label='Accept'
                size='small'
                color='success'
                variant="outlined"
                onClick={() => approveRetailerProduct(approvalId)}
              />
              <Chip
                label='Decline'
                size='small'
                color='error'
                variant="outlined"
                onClick={() => approvalDialogHandler(approvalId)}
              />
              <Chip
                label='Override'
                size='small'
                color='warning'
                variant="outlined"
                onClick={() => approvalOverrideDialogHandler(approvalId, retailerDetails.retailerId, productDetails.productId)}
              />
            </Box>
          </Box>
        </Stack>

        <Grid container spacing={2.5} mb={4}>
          <Grid item xs={12} lg={6}>
            <Card variant='detailsCard' sx={{ height: '100%', overflow: 'hidden' }}>

              <CardContent sx={{ p: { xs: 2, md: 3.5 }, pb: 0 }}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2} mb={3}>
                  <Typography variant='h4'>Product Detail</Typography>
                  <Typography variant='h6' fontWeight="medium">{productDetails?.status  ?
                    <Box color="#24CA49" display="flex" alignItems="center">
                      <ActiveIcon width="16px" height="16px" flex="none" />
                      <Typography variant='body2' fontWeight="medium" ml={.5} textTransform="capitalize">Active</Typography>
                    </Box> : <Box color="#FD4438" display="flex" alignItems="center">
                      <InActiveIcon width="16px" height="16px" flex="none" />
                      <Typography variant='body2' fontWeight="medium" ml={.5} textTransform="capitalize">Inactive</Typography>
                    </Box>    
                  }</Typography>
                </Stack>

                <Grid container spacing={3.5} mb={1}>
                  <Grid item xs={12} md={6}>
                    <Typography variant='body2' color={grey[600]}>Product Id</Typography>
                    <Typography variant='h6' fontWeight="medium">{productDetails?.productId || '-'}</Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant='body2' color={grey[600]}>Product Name</Typography>
                    <Typography variant='h6' fontWeight="medium">{productDetails?.productName || '-'}</Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant='body2' color={grey[600]}>Strain Type</Typography>
                    <Typography variant='h6' fontWeight="medium">{productDetails?.strainType || '-'}</Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant='body2' color={grey[600]}>Brand Name</Typography>
                    <Typography variant='h6' fontWeight="medium">{productDetails?.brandName}</Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant='body2' color={grey[600]}>Category</Typography>
                    <Typography variant='h6' fontWeight="medium">{productDetails?.categoryName || '-'}</Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant='body2' color={grey[600]}>Available</Typography>
                    <Typography variant='h6' fontWeight="medium">{approvalDetail?.promotionType || '-'}</Typography>
                  </Grid>
                  {
                    approvalDetail?.promotionType === 'Cart' &&   <Grid item xs={12} md={6}>
                      <Typography variant='body2' color={grey[600]}>Auto Apply</Typography>
                      <Typography variant='h6' fontWeight="medium">{approvalDetail?.autoApply ? 'Yes' : 'No'}</Typography>
                    </Grid>
                  }
                                  
                  <Grid item xs={12} md={6}>
                    <Typography variant='body2' color={grey[600]}>Product Weight(Unit)</Typography>
                    <Typography variant='h6' fontWeight="medium">{productDetails?.customWeight || '-'}</Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant='body2' color={grey[600]}>THC(Unit)</Typography>
                    <Typography variant='h6' fontWeight="medium">{productDetails?.thcData || '-'}</Typography>
                  </Grid>
                                    
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} lg={6}>
            <Card variant='detailsCard' sx={{ height: '100%', overflow: 'hidden' }}>
              <CardContent sx={{ p: { xs: 2, md: 3.5 }, pb: 0 }}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2} mb={3}>

                  <Typography variant='h4'>Retailer Detail</Typography>
                  <Typography variant='h6' fontWeight="medium">{productDetails?.status  ?
                    <Box color="#24CA49" display="flex" alignItems="center">
                      <ActiveIcon width="16px" height="16px" flex="none" />
                      <Typography variant='body2' fontWeight="medium" ml={.5} textTransform="capitalize">Active</Typography>
                    </Box> : <Box color="#FD4438" display="flex" alignItems="center">
                      <InActiveIcon width="16px" height="16px" flex="none" />
                      <Typography variant='body2' fontWeight="medium" ml={.5} textTransform="capitalize">Inactive</Typography>
                    </Box>    
                  }</Typography>
                </Stack>

                <Grid container alignItems="center" spacing={3.5} mb={1}>

                  <Grid item xs={12} md={6}>
                    <Typography variant='body2' color={grey[600]}>Retailer Id</Typography>
                    <Typography variant='h6' fontWeight="medium">{retailerDetails?.retailerId || '-'}</Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant='body2' color={grey[600]}>Retailer Name</Typography>
                    <Typography variant='h6' fontWeight="medium">{retailerDetails?.retailerName || '-'}</Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant='body2' color={grey[600]}>Contact Name</Typography>
                    <Typography variant='h6' fontWeight="medium">{retailerDetails?.contactName || '-'}</Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant='body2' color={grey[600]}>Email</Typography>
                    <Typography variant='h6' fontWeight="medium">{retailerDetails?.email || '-'}</Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant='body2' color={grey[600]}>Phone</Typography>
                    <Typography variant='h6' fontWeight="medium">{retailerDetails?.phone ? formatUsPhoneNumber(retailerDetails?.phone) : '-'}</Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant='body2' color={grey[600]}>Address</Typography>
                    <Typography variant='h6' fontWeight="medium">{retailerDetails?.address.address || '-'}</Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant='body2' color={grey[600]}>Subscription</Typography>
                    <Typography variant='h6' fontWeight="medium">{retailerDetails?.subscription || '-'}</Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant='body2' color={grey[600]}>Valid Upto</Typography>
                    <Typography variant='h6' fontWeight="medium">{retailerDetails?.isLifeTime === true ? 
                      'Lifetime': 
                      formatTimestampToWithoutTime(retailerDetails?.valiDateTo) 
                    }</Typography>
                  </Grid>

                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

      </Box>
    </CommonLayout>
  );
}