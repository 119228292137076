import axios from "axios";
import { axiosHyperwolfDevClient } from "axiosClient";
import { axiosHyperwolfClient } from "axiosClient";
import axiosClientHyperDrive from "axiosClient/indexHyperdrive";
import { getQueryString } from "utilities/common";

export async function fetchDriversAPI(filters) {
    return await axiosHyperwolfDevClient.get(`/api/v1/fleet/list?${getQueryString(filters)}`)
}

export async function addDriverAPI(data) {
    return await axiosHyperwolfDevClient.post('/api/v1/fleet/register',data)
}
// Rb2BmBfgxZL8dk34
export async function deleteDriverAPI(data) {
    return await axiosHyperwolfDevClient.delete('/api/v1/fleet/delete', {data: data})
}

export async function bulkDeleteDriverAPI(data) {
  return await axiosHyperwolfDevClient.delete('/api/v1/fleet/bulkDelete', {data: data})
}

export async function fetchDriverDetailsAPI(fleetId){
  return await axiosHyperwolfDevClient.get(`/api/v1/fleet/details?fleetId=${fleetId}`,
  )
}

export async function updateDriverAPI(data){
  return await axiosHyperwolfDevClient.put('/api/v1/fleet/edit',data,)
}

export async function getTransportationsAPI(){
  return await axiosHyperwolfDevClient.get(`/api/v1/transportationTypes/get`, 
  )
}

export async function fetchAdminsAPI(){
  return await axiosHyperwolfDevClient.get('/api/v1/fleet/getAdmins')
}

export async function exportCsvAPI() {
  return await axiosHyperwolfDevClient.get('/api/v1/fleet/exportDrivers')
}

export async function importFleetsAPI(data) {
  return await axiosHyperwolfDevClient.post('/api/v1/fleet/import',data)
}

export async function getReassignDriverListAPI(filters){
  return await axiosClientHyperDrive.get(`api/v1/admin/analytics/list?${getQueryString(filters)}`)
}

export async function reassignDriverAPI(data) {
  return await axiosHyperwolfDevClient.post(`api/v1/orders/reassign/driver`,data )
}

export async function getServiceTimeAPI(){
  return await axiosHyperwolfDevClient.get('api/v1/get/service/time')
}

export async function updateServiceTimeAPI(data) {
  return await axiosHyperwolfDevClient.post('api/v1/update/service/time', data)
}

export async function getProcessTimeAPI() {
  return await axiosHyperwolfDevClient.get('api/v1/get/process/time')
}

export async function updateProcessTimeAPI(data) {
  return await axiosHyperwolfDevClient.post('api/v1/update/process/time', data )
}

export async function getPackageTimeAPI() {
  return await axiosHyperwolfDevClient.get('api/v1/get/package/time', data)
}

export async function updatePackageTimeAPI(data) {
  return await axiosHyperwolfDevClient.post('api/v1/update/package/time', data)
}