import { useState, useEffect, useMemo } from "react";

// react-router components
import { useLocation, Link, useParams, useSearchParams } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import NotificationItem from "examples/Items/NotificationItem";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import SettingsIcon from '@mui/icons-material/Settings';
import ZoomOutMapOutlinedIcon from '@mui/icons-material/ZoomOutMapOutlined';
import ZoomInMapIcon from '@mui/icons-material/ZoomInMap';

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarMobileMenu,
  navbarIconButton
} from "examples/Navbars/DashboardNavbar/styles";

// Material Dashboard 2 React context
import {
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
  setDarkMode
} from "../../../redux/slices/materialUISlice";
import { useDispatch, useSelector } from "react-redux";
import { Button, MenuItem, Stack, Typography } from "@mui/material";

// Images
import hyperwolfLogo from "assets/images/hyperwolf.svg";
import hyperwolfWhiteLogo from "assets/images/hyperwolf-white.svg";
import { logoutUserData } from "../../../redux/slices/authSlice";
import { orderFilterTabs } from "utilities/common";
import MDButton from "components/MDButton";
import { strainsTabs } from "utilities/common";
import { brandTabs } from "utilities/common";
import { roleAndPermissionTabs } from "utilities/common";
import NotificationCount from "components/notifications/NotificationCount";
import { getData } from "utilities/common";
import { setData } from "utilities/common";
import { driverFilterTabs } from "utilities/common";
import { hyperdriveTaskOptions } from "utilities/common";
import { driverDataCustom, openCustomDrawer, regionDataCustom } from "../../../redux/slices/modalSlice";
import { HYPERDRIVE } from "utilities/constants";
import AddIcon from '@mui/icons-material/Add';
import { cannabinoidsTabs } from "utilities/common";
import { HYPERWOLF } from "utilities/constants";
import { HEMP } from "utilities/constants";
import MenuTabs from "components/hyperdrive/menuTabs";
import { setFullScreen } from "../../../redux/slices/common";
import { strainsTab } from "utilities/common";
import { categoryTabs } from "utilities/common";

const createTaskDrawerOptions = {
  pick_up_task: 'PICKUP_TASK',
  drop_off_task: 'DROP_OFF_TASK',
  break_task: 'BREAK_TASK',
  start_task: 'START_TASK',
  return_to_hq_task: 'RETURN_HQ_TASK'
}


function DashboardNavbar({ absolute, light, isMini }) {
  const { user = {} } = useSelector(state => state.auth)
  const { adminsMode = '', isFullScreen = false } = useSelector(state => state.common)

  const [navbarType, setNavbarType] = useState();
  const controller = useSelector((state) => state.materialUI);
  const params = useParams()
  const { pathname, search } = useLocation()

  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator, darkMode } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const [openProfileMenu, setOpenProfileMenu] = useState(false);
  const [adminsMenu, setOpenAdminsMenu] = useState(false);
  const [taskMenu, setOpenTaskMenu] = useState(false);
  const [mount, setMount] = useState(false)

  const count = useSelector(state => state.driverApprovalSlice.pendingCount)

  const { account } = useSelector((state) => state.auth)
  let productFilter = searchParams.get('orderFilter')
  let brandTab = searchParams.get('brandTab')
  let roleTab = searchParams.get('roleTab')
  const basePath = pathname.split('/').slice(0, 4).join('/');


  let productTab = searchParams.get('filter')
  let retailerTab = searchParams.get('filter')
  let driverFilter = searchParams.get('driverFilter')
  let cannabinoidsFilter = searchParams.get('cannabinoidTab')
  let strainsFilter = searchParams.get('strainTab')
  let categoriesFilter = searchParams.get('filter')
  let orderDetailsTabFilter = searchParams.get('orderDetailsTab')

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      dispatch(setTransparentNavbar((fixedNavbar && window.scrollY === 0) || !fixedNavbar));
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => dispatch(setMiniSidenav(!miniSidenav));
  const handleOpenProfileMenu = (event) => setOpenProfileMenu(event.currentTarget);
  const handleOpenTaskMenu = (event) => {
    setOpenTaskMenu(event.currentTarget)
    dispatch(driverDataCustom({
      driver: 'DRIVER_DATA',
      driverData: []
    }))
    dispatch(regionDataCustom({
      region: 'REGION_DATA',
      regionData: []
    }))
  };


  const handleCloseMenu = () => setOpenMenu(false);
  const handleCloseProfileMenu = () => setOpenProfileMenu(false);
  const handleCloseTaskMenu = () => setOpenTaskMenu(false);

  const deviceToken = getData('firebaseDeviceToken');
  const cleanDeviceToken = deviceToken?.replace(/^"(.*)"$/, '$1').replace(/\\(.)/g, '$1');


  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <NotificationItem title="Notification Here" />
    </Menu>
  );
  // Render the notifications menu
  const renderProfileMenu = () => (
    <Menu
      anchorEl={openProfileMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openProfileMenu)}
      onClose={handleCloseProfileMenu}
      sx={{ mt: 2 }}
      MenuListProps={{ className: "hd-dropdown-sec user-dropdown" }}
    >
      <NotificationItem className="popover-dropdown" fontSize="14px" style={{ color: "#F5F5FF" }} onClick={() => { dispatch(logoutUserData({ email: user?.email, deviceToken: cleanDeviceToken, isSuperAdmin: true })); }} icon={<Icon><ExitToAppIcon /></Icon>} title="Logout" />
    </Menu>
  );

  const adminsSelectHandler = (value = '') => {
    if (value) {
      setData('adminsMode', value)
      window.location.href = '/'
    }
  }

  const taskSelectHandler = (value = '') => {
    if (value) {
      dispatch(openCustomDrawer({ drawer: createTaskDrawerOptions[value], drawerData: {} }))
      handleCloseTaskMenu()
    }
  }

  const renderTaskMenu = () => (
    <>
      <Menu
        anchorEl={taskMenu}
        anchorReference={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={Boolean(taskMenu)}
        onClose={handleCloseTaskMenu}
        MenuListProps={{ className: "hd-dropdown-sec" }}
      >
        {
          hyperdriveTaskOptions?.map((taskOption => {
            return <MenuItem onClick={() => taskSelectHandler(taskOption.value)} key={taskOption.value} icon={taskOption.icon}>
              <Typography className="d-flex align-item-center gap-2" fontSize="14px" variant='body1'>
                {taskOption.icon}
                {taskOption?.key}
              </Typography>
            </MenuItem>
          }))
        }
      </Menu>

    </>
  );


  // Styles for the navbar icons
  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  const user_detail = useMemo(() => {
    return account?.find((account) => account.name)

  }, [account])
  const content = (
    <div>
      {`Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus id dignissim justo.
   Nulla ut facilisis ligula. Interdum et malesuada fames ac ante ipsum primis in faucibus.
   Sed malesuada lobortis pretium.`}
    </div>
  );

  const label = { inputProps: { 'aria-label': 'Switch demo' } };

  const handleDarkMode = () => dispatch(setDarkMode(!darkMode));

  useEffect(() => {
    if (adminsMode === HYPERDRIVE && !darkMode) {
      handleDarkMode()
    }
  }, [adminsMode, darkMode])

  // sidenav type buttons styles
  const sidenavTypeButtonsStyles = ({
    functions: { pxToRem },
    palette: { white, dark, background },
    borders: { borderWidth },
  }) => ({
    height: pxToRem(39),
    background: darkMode ? background.sidenav : white.main,
    color: darkMode ? white.main : dark.main,
    border: `${borderWidth[1]} solid ${darkMode ? white.main : dark.main}`,

    "&:hover, &:focus, &:focus:not(:hover)": {
      background: darkMode ? background.sidenav : white.main,
      color: darkMode ? white.main : dark.main,
      border: `${borderWidth[1]} solid ${darkMode ? white.main : dark.main}`,
    },
  });

  // sidenav type active button styles
  const sidenavTypeActiveButtonStyles = ({
    functions: { pxToRem, linearGradient },
    palette: { white, gradients, background },
  }) => ({
    height: pxToRem(39),
    background: darkMode ? white.main : linearGradient(gradients.dark.main, gradients.dark.state),
    color: darkMode ? background.sidenav : white.main,

    "&:hover, &:focus, &:focus:not(:hover)": {
      background: darkMode ? white.main : linearGradient(gradients.dark.main, gradients.dark.state),
      color: darkMode ? background.sidenav : white.main,
    },
  });
  const tabLink = {
    textDecoration: "none"
  };
  const activeTabLink = {
    textDecoration: "underline",
    textUnderlineOffset: "4px"
  };

  useEffect(() => {
    setTimeout(() => {
      setMount(true)
    }, 1000)
    return () => {
      setMount(false)
    }
  }, [])

  useEffect(() => {
    function onFullscreenChange() {
      dispatch(setFullScreen(Boolean(document.fullscreenElement)));
    }
    document.addEventListener('fullscreenchange', onFullscreenChange);
    return () => document.removeEventListener('fullscreenchange', onFullscreenChange);
  }, []);


  const handleZoomHandler = (event) => {
    if (isFullScreen) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) { // Firefox
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) { // Chrome, Safari, and Opera
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) { // IE/Edge
        document.msExitFullscreen();
      } else if (document.webkitExitFullscreen) { // iOS Safari
        document.webkitExitFullscreen();
      }
    } else {
      if (document.body.requestFullscreen) {
        document.body.requestFullscreen()
      } else if (document.mozRequestFullScreen) { // Firefox
        document.mozRequestFullScreen();
      } else if (document.webkitRequestFullscreen) { // Chrome, Safari, and Opera
        document.webkitRequestFullscreen();
      } else if (document.msRequestFullscreen) { // IE/Edge
        document.msRequestFullscreen();
      } else if (document.webkitEnterFullscreen) { // iOS Safari
        document.webkitEnterFullscreen();
      }
    }
  };
  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
      className="header-sec"
    >
      <Toolbar sx={(theme) => navbarContainer(theme)} className="cus-toolbar">
        <MDBox display="flex" alignItems="center" gap={1} width={{ xs: "auto" }}>
          {
            adminsMode !== 'hyperdrive' && <IconButton
              size="large"
              disableRipple
              color="inherit"
              style={{ padding: 0 }}
              sx={navbarMobileMenu}
              onClick={handleMiniSidenav}
            >
              <Icon sx={iconsStyle}>
                {/* {miniSidenav ? "menu_open" : "menu"} */}
                {!miniSidenav ? "menu" : "menu_open"}
              </Icon>
            </IconButton>
          }

          <img height="16px" src={darkMode ? hyperwolfWhiteLogo : hyperwolfLogo} alt="HYPERWOLF" />
        </MDBox>

        {
          adminsMode === HYPERDRIVE && <MenuTabs />
        }

        {
          params?.productId && !params?.retailerId && <Stack direction="row" flexWrap="wrap" alignItems="center" justifyContent="center" order={{ xs: 2, md: "unset" }} width={{ xs: "100%", md: "auto" }}>
            <Link to={`${pathname}?filter=products`}>
              <Button size="medium" variant="text" color={productTab === 'products' ? "primary" : "inherit"} style={productTab === 'products' ? activeTabLink : tabLink}>Product</Button>
            </Link>
            <Link to={`${pathname}?filter=inventory`}>
              <Button size="medium" variant="text" color={productTab === 'inventory' ? "primary" : "inherit"} style={productTab === 'inventory' ? activeTabLink : tabLink}>Inventory</Button>
            </Link>
            <Link to={`${pathname}?filter=batches`}>
              <Button size="medium" variant="text" color={productTab === 'batches' ? "primary" : "inherit"} style={productTab === 'batches' ? activeTabLink : tabLink}>Batches</Button>
            </Link>
            <Link to={`${pathname}?filter=terpenoids`}>
              <Button size="medium" variant="text" color={productTab === 'terpenoids' ? "primary" : "inherit"} style={productTab === 'terpenoids' ? activeTabLink : tabLink}>Cannabinoids</Button>
            </Link>
            <Link to={`${pathname}?filter=activity_logs`}>
              <Button size="medium" variant="text" color={productTab === 'activity_logs' ? "primary" : "inherit"} style={productTab === 'activity_logs' ? activeTabLink : tabLink}>Logs</Button>
            </Link>
          </Stack>
        }

        {

          params?.retailerId && !params?.productId && searchParams.get('filter') && <Stack direction="row" flexWrap="wrap" alignItems="center" justifyContent="center" order={{ xs: 2, md: "unset" }} width={{ xs: "100%", md: "auto" }}>
            <Link to={`${basePath}?filter=retailerDetails`}>
              <Button size="medium" variant="text" color={retailerTab === 'retailerDetails' ? "primary" : "inherit"} style={retailerTab === 'retailerDetails' ? activeTabLink : tabLink}>Retailer Details</Button>
            </Link>
            <Link to={`${basePath}?filter=retailerProducts`}  >
              <Button size="medium" variant="text" color={retailerTab === 'retailerProducts' ? "primary" : "inherit"} style={retailerTab === 'retailerProducts' ? activeTabLink : tabLink}>Products</Button>
            </Link>
            <Link to={`${basePath}?filter=retailerOrders`}  >
              <Button size="medium" variant="text" color={retailerTab === 'retailerOrders' ? "primary" : "inherit"} style={retailerTab === 'retailerOrders' ? activeTabLink : tabLink}>Orders</Button>
            </Link>
            <Link to={`${basePath}?filter=retailerMembers`}  >
              <Button size="medium" variant="text" color={retailerTab === 'retailerMembers' ? "primary" : "inherit"} style={retailerTab === 'retailerMembers' ? activeTabLink : tabLink}>Members</Button>
            </Link>
            <Link to={`${basePath}?filter=retailerApprovals`}  >
              <Button size="medium" variant="text" color={retailerTab === 'retailerApprovals' ? "primary" : "inherit"} style={retailerTab === 'retailerApprovals' ? activeTabLink : tabLink}>Approvals</Button>
            </Link>
          </Stack>
        }

        {
          search.includes('orderDetailsTab') && <Stack direction="row" flexWrap="wrap" alignItems="center" justifyContent="center" order={{ xs: 2, md: "unset" }} width={{ xs: "100%", md: "auto" }}>
            <Link to={`${pathname}?orderDetailsTab=orderDetails`}>
              <Button size="medium" variant="text" color={orderDetailsTabFilter === 'orderDetails' ? "primary" : "inherit"} style={orderDetailsTabFilter === 'orderDetails' ? activeTabLink : tabLink}>Order Details</Button>
            </Link>
            <Link to={`${pathname}?orderDetailsTab=orderProof`}  >
              <Button size="medium" variant="text" color={orderDetailsTabFilter === 'orderProof' ? "primary" : "inherit"} style={orderDetailsTabFilter === 'orderProof' ? activeTabLink : tabLink}>Order Proof</Button>
            </Link>

          </Stack>
        }

        {
          pathname === '/categories' && <Stack direction="row" flexWrap="wrap" alignItems="center" justifyContent="center" order={{ xs: 2, md: "unset" }} width={{ xs: "100%", md: "auto" }}>
            {
              categoryTabs?.map((category => {
                return <Link key={category.value} to={`${pathname}?filter=${category.value}`}>
                  <Button size="medium" variant="text" color={categoriesFilter === category.value ? "primary" : "inherit"} style={categoriesFilter === category.value ? activeTabLink : tabLink}>{category.key}</Button>
                </Link>
              }))
            }
          </Stack>
        }

        {
          pathname === '/manage-cannabinoids' && <Stack direction="row" flexWrap="wrap" alignItems="center" justifyContent="center" order={{ xs: 2, md: "unset" }} width={{ xs: "100%", md: "auto" }}>
            {
              cannabinoidsTabs?.map((cannabinoid => {
                return <Link key={cannabinoid?.value} to={`${pathname}?cannabinoidTab=${cannabinoid.value}`}>
                  <Button size="medium" variant="text" color={cannabinoidsFilter === cannabinoid.value ? "primary" : "inherit"} style={cannabinoidsFilter === cannabinoid.value ? activeTabLink : tabLink}>{cannabinoid?.key}</Button>
                </Link>
              }))
            }
          </Stack>
        }

        {
          pathname === '/hyperwolf/manage_main_strain' && <Stack direction="row" flexWrap="wrap" alignItems="center" justifyContent="center" order={{ xs: 2, md: "unset" }} width={{ xs: "100%", md: "auto" }}>
            {
              strainsTab?.map((strain => {
                return <Link key={strain?.value} to={`${pathname}?strainTab=${strain.value}`}>
                  <Button size="medium" variant="text" color={strainsFilter === strain.value ? "primary" : "inherit"} style={strainsFilter === strain.value ? activeTabLink : tabLink}>{strain.key}</Button>
                </Link>
              }))
            }
          </Stack>
        }

        {
          (pathname === '/orders' || pathname.includes('/orders/member')) && <Stack direction="row" flexWrap="wrap" alignItems="center" justifyContent="center" order={{ xs: 2, md: "unset" }} width={{ xs: "100%", md: "auto" }}>
            {
              orderFilterTabs?.map((orderTab => {
                return <Link key={orderTab?.value} to={`${pathname}?orderFilter=${orderTab?.value}&sortby=${searchParams?.get('sortby') || 'today'}&searchTerm=${searchParams?.get('searchTerm') || ''}`}>
                  <Button size="medium" variant="text" color={productFilter === orderTab.value ? "primary" : "inherit"} style={productFilter === orderTab.value ? activeTabLink : tabLink}>{orderTab?.key}</Button>
                </Link>
              }))
            }
          </Stack>
        }

        {
          (pathname === '/drivers') && <Stack direction="row" flexWrap="wrap" alignItems="center" justifyContent="center" order={{ xs: 2, md: "unset" }} width={{ xs: "100%", md: "auto" }}>
            {
              driverFilterTabs?.map((driverTab => {
                return <Link key={driverTab?.value} to={`${pathname}?driverFilter=${driverTab.value}&searchTerm=${searchParams?.get('searchTerm') || ''}`}>
                  <Button size="medium" variant="text" color={driverFilter === driverTab.value ? "primary" : "inherit"} style={driverFilter === driverTab.value ? activeTabLink : tabLink}>
                    {driverTab?.key == "Approvals" ? <>
                      {
                        driverTab.key
                      }
                      {
                        count > 0 ? <span className="count-num">{count}</span> : ''
                      }

                    </> : driverTab.key}
                  </Button>
                </Link>
              }))
            }
          </Stack>
        }
        {
          (pathname === '/hyperwolf/driver') && <Stack direction="row" flexWrap="wrap" alignItems="center" justifyContent="center" order={{ xs: 2, md: "unset" }} width={{ xs: "100%", md: "auto" }}>
            {
              driverFilterTabs?.map((driverTab => {
                return <Link key={driverTab?.value} to={`${pathname}?driverFilter=${driverTab.value}&searchTerm=${searchParams?.get('searchTerm') || ''}`}>
                  <Button size="medium" variant="text" color={driverFilter === driverTab.value ? "primary" : "inherit"} style={driverFilter === driverTab.value ? activeTabLink : tabLink}>
                    {driverTab?.key == "Approvals" ? <>
                      {
                        driverTab.key
                      }
                      {
                        count > 0 ? <span className="count-num">{count}</span> : ''
                      }

                    </> : driverTab.key}
                  </Button>
                </Link>
              }))
            }
          </Stack>
        }

        {
          pathname === '/strains' && <Stack direction="row" flexWrap="wrap" alignItems="center" justifyContent="center" order={{ xs: 2, md: "unset" }} width={{ xs: "100%", md: "auto" }}>
            {
              strainsTabs?.map((orderTab => {
                return <Link key={orderTab?.value} to={`${pathname}?orderFilter=${orderTab.value}`}>
                  <Button size="medium" variant="text" color={productFilter === orderTab.value ? "primary" : "inherit"} style={productFilter === orderTab.value ? activeTabLink : tabLink}>{orderTab?.key}</Button>
                </Link>
              }))
            }
          </Stack>
        }

        {
          pathname === '/brands' && <Stack direction="row" flexWrap="wrap" alignItems="center" justifyContent="center" order={{ xs: 2, md: "unset" }} width={{ xs: "100%", md: "auto" }}>
            {
              brandTabs?.map((orderTab => {
                return <Link key={orderTab?.value} to={`${pathname}?brandTab=${orderTab.value}`}>
                  <Button size="medium" variant="text" color={brandTab === orderTab.value ? "primary" : "inherit"} style={brandTab === orderTab.value ? activeTabLink : tabLink}>{orderTab?.key}</Button>
                </Link>
              }))
            }
          </Stack>
        }

        {
          pathname === '/user-roles' && <Stack direction="row" flexWrap="wrap" alignItems="center" justifyContent="center" order={{ xs: 2, md: "unset" }} width={{ xs: "100%", md: "auto" }}>
            {
              roleAndPermissionTabs?.map((roleAndPermission => {
                return <Link key={roleAndPermission?.value} to={`${pathname}?roleTab=${roleAndPermission.value}`}>
                  <Button size="medium" variant="text" color={roleTab === roleAndPermission.value ? "primary" : "inherit"} style={roleTab === roleAndPermission.value ? activeTabLink : tabLink}>{roleAndPermission?.key}</Button>
                </Link>
              }))
            }
          </Stack>
        }

        {isMini ? null : (
          <MDBox sx={(theme) => navbarRow(theme, { isMini })} width={{ xs: "auto" }}>
            <Stack direction="row" alignItems="center" justifyContent="end" width="100%" gap={3}>
              {renderMenu()}
              {
                adminsMode && mount && adminsMode === HYPERWOLF && <MDButton
                  variant="contained"
                  size="small"
                  color='primary'
                  onClick={() => adminsSelectHandler(HYPERDRIVE)}
                >
                  <Typography fontSize={13} fontWeight={600} className="text-white-900">HyperDrive</Typography>
                </MDButton>
              }

              {
                (adminsMode && mount && (adminsMode === HYPERDRIVE || adminsMode === HEMP)) && <MDButton
                  variant="contained"
                  color="primary"
                  size="small"
                  sx={{ px: 3 }}
                  onClick={() => adminsSelectHandler(HYPERWOLF)}
                  className="yellow-btn"
                >
                  Hyperwolf
                </MDButton>
              }

              {
                adminsMode && mount && adminsMode === HYPERWOLF && <MDButton
                  variant="contained"
                  color="primary"
                  size="small"
                  sx={{ px: 3 }}
                  onClick={() => adminsSelectHandler(HEMP)}
                  className="grey-btn"
                >
                  Hemp
                </MDButton>
              }

              {
                adminsMode !== HYPERDRIVE && <NotificationCount />
              }
              {
                adminsMode === HYPERDRIVE && <>

                  <IconButton
                    size="medium"
                    disableRipple
                    color="secondary"
                    sx={navbarIconButton}
                    aria-controls="zoom-icon"
                    aria-haspopup="true"
                    variant=""
                    onClick={handleZoomHandler}
                  >
                    {
                      isFullScreen ? <ZoomInMapIcon style={{ fill: "#9A9AAF", cursor: "pointer", width: "20px", height: "20px" }} /> : <ZoomOutMapOutlinedIcon style={{ fill: "#9A9AAF", cursor: "pointer", width: "20px", height: "20px" }} />
                    }
                  </IconButton>

                  <Link to={`/${process.env.REACT_APP_MODE}/hyperdrive/site-setting`} style={{ color: "#9A9AAF", display: "flex" }}>
                    <SettingsIcon />
                  </Link>

                  <AddIcon
                    onClick={handleOpenTaskMenu}
                    style={{ fill: "#9A9AAF", cursor: "pointer", width: "25px", height: "25px" }}
                  />

                </>
              }
              {renderTaskMenu()}

              <MDButton
                variant="contained"
                color="light"
                size="small"
                onClick={handleOpenProfileMenu}
                style={{ background: "transparent" }}
                className="d-flex align-item-center gap-2 box-shadow-none p-0 user-sec"
              >
                <span
                  className="d-flex align-item-center jc-center border-radius-50 text-12 font-w-500 user-first-chr"
                  style={{ width: "24px", height: "24px", backgroundColor: "#70707C" }}
                >{user?.name?.charAt(0) || user?.email?.charAt(0)}</span>
                <span className="user-name">{user?.name || user?.email}</span>
              </MDButton>
              {renderProfileMenu()}
            </Stack>
          </MDBox>
        )}
      </Toolbar>
    </AppBar >
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;