import { Box, Grid, IconButton,  Stack,  Typography } from '@mui/material';
import MDButton from 'components/MDButton';
import { AgGridTable } from 'components/tables/AgGridTable';
import Search from 'examples/Search';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { deleteBrand, fetchBrands } from '../../../redux/slices/brand';
import { sortAndFilter } from 'components/tables/AgGridTable';
import { closeCustomModalDialog, openCustomModalDialog, setModalLoader } from '../../../redux/slices/modalSlice';
import { useDebounce } from 'hooks/useDebounce';
import EditIcon from 'common/customIcons/edit';
import DeleteIcon from 'common/customIcons/delete';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import StatusText from 'common/component/StatusText';
import { openSnackbar } from '../../../redux/slices/common';
import { formatTimestampToDateView } from 'utilities/common';
import ActiveIcon from 'common/customIcons/active';
import InActiveIcon from 'common/customIcons/inactive';

export default function BrandsTable() {
  const dispatch = useDispatch();
  const { isLoading = false, brandsData = {} } = useSelector(state => state.brands);
  const navigate = useNavigate();
  const pageRef = useRef(0);
  const searchTermRef = useRef('');
  const mainGridApiRef = useRef('');
  const gridRefApi = useRef(null);
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = useDebounce(() => {
    // Perform search operation with the debounced term
    pageRef.current = 0;
    searchFn();
  }, 500);

  const columnDefs= [
    {
      headerName: 'Brand Name',
      field: 'brandName',
      key: 'fullName',
      suppressMenu: false,
    },
    { field: 'createdBy', suppressMenu: false, valueFormatter: ({ value }) => (value ? value : '-') },
    { field: 'createdDate', headerName: 'Created On', suppressMenu: false, valueFormatter: ({ value }) => (value ? value : '-') },
    { field: 'productCount', headerName: 'Total Products', suppressMenu: false, valueFormatter: ({ value }) => (value ? value : 0), },

    {
      headerName: 'Status',
      field: 'status',
      suppressMenu: false,
      cellRenderer: (props) => {
        return <StatusText status={props?.data?.status} />;
      },
    },
    {
      headerName: 'Popular Brand',
      field: 'isPopularBrand',
      key: 'fullName',
      suppressMenu: false,
      cellRenderer: (props) => {
        if (!props?.data?._id) return;
        return props?.value ? <Box color="#24CA49" display="flex" alignItems="center">
          <ActiveIcon width="18px" height="18px" flex="none" />
        </Box> : <Box color="#FD4438" display="flex" alignItems="center">
          <InActiveIcon width="18px" height="18px" flex="none" />
        </Box>;
      },
    },
    {
      headerName: 'Top Brand',
      field: 'isBestBrand',
      key: 'fullName',
      suppressMenu: false,
      cellRenderer: (props) => {
        if (!props?.data?._id) return;
        return props?.value ? <Box color="#24CA49" display="flex" alignItems="center">
          <ActiveIcon width="18px" height="18px" flex="none" />
        </Box> : <Box color="#FD4438" display="flex" alignItems="center">
          <InActiveIcon width="18px" height="18px" flex="none" />
        </Box>;
      },
    },
    {
      headerName: 'Action',
      cellRenderer: (props) => {
        return <Box>
          <IconButton onClick={() => onRowClicked(props)} color='primary' size='small' variant="primary">
            <EditIcon width='16px' height='16px' />
          </IconButton>
          <IconButton onClick={() => dispatch(openCustomModalDialog({ modalData: { deleteHandler, deleteId: props.data?.brandId }, modal: 'DELETE_MODAL' }))} color='error' size='small' variant="primary" sx={{ marginLeft: 1 }}>
            <DeleteIcon width='16px' height='16px' />
          </IconButton>
        </Box>;
      },
      cellStyle: { textAlign: 'right', justifyContent: 'end' },
      headerClass: 'right-aligned-cell-header',
    },
  ];

  const deleteHandler = (brandId) => {
    dispatch(setModalLoader(true));
    dispatch(deleteBrand(brandId)).unwrap().then((res) => {
      showError(res?.message, 'success');
      dispatch(closeCustomModalDialog());
      pageRef.current = 0;
      getRows(gridRefApi.current);
    }).catch((err) => {
      dispatch(setModalLoader(false));
      showError(err?.message, 'error');
    });
  };

  const showError = (message, errortype) => {
    if (errortype === 'success') {
      dispatch(openSnackbar({ content: message, color: 'success', icon: 'check' }));
    }
    if (errortype === 'error') {
      dispatch(openSnackbar({ content: message, color: 'error', icon: 'warning' }));
    }
  };

  const getRows = async (params) => {
    gridRefApi.current = params;
    dispatch(fetchBrands({ skip: pageRef.current, limit: 20, userType: 'admin', searchTerm: searchTermRef.current })).unwrap().then(res => {
      const { brands = [], total } = res;
      const brandsArray = brands?.map((item => {
        return { ...item, id: item._id, createdDate: formatTimestampToDateView(item?.createdDate) };
      }));
      const dataAfterSortingAndFiltering = sortAndFilter(
        brandsArray,
        params.sortModel,
        params.filterModel
      );
      params.successCallback(dataAfterSortingAndFiltering, total);
      pageRef.current = pageRef.current + 20;
    });
  };

  const onRowClicked = (row) => {
    navigate(`/brands/${row?.data.brandId}`);
  };

  const searchHandler = (event) => {
    const { value } = event.target;
    setSearchTerm(value);
    searchTermRef.current = value;
    handleSearch(value);
  };
  const searchFn = () => {
    const dataSource = {
      getRows
    };
    mainGridApiRef.current.ensureIndexVisible(0, null);
    mainGridApiRef.current.setGridOption('datasource', dataSource);
  };
  useEffect(() => {
    if (mainGridApiRef?.current) {
      if (isLoading) {
        mainGridApiRef.current.showLoadingOverlay();
      } else if (brandsData?.total === 0) {
        mainGridApiRef.current.showNoRowsOverlay();
      }
      else {
        mainGridApiRef.current.hideOverlay();
      }
    }

  }, [brandsData, isLoading]);

  return (
    <Box p={{ xs: 2, sm: 3, lg: 4 }}>
      <Grid container alignItems="center" rowSpacing={2} columnSpacing={2} mb={3}>
        <Grid item xs={12} sm={4}>
          <Typography variant='h4'>Brands</Typography>
        </Grid>

        <Grid item xs={12} sm={8}>
          <Stack direction="row" justifyContent={{ xs: 'start', sm: 'end' }} flexWrap="wrap" alignItems="stretch" gap={2}>
            <Search placeholder="Search" label="" value={searchTerm} onChange={searchHandler} />
            <MDButton
              startIcon={<AddRoundedIcon fontSize='large' />}
              variant="contained" color="primary"
              onClick={() => navigate('/brands/add')} >
                            Add Brand
            </MDButton>
          </Stack>
        </Grid>
      </Grid>

      <AgGridTable col={columnDefs} mainGridApiRef={mainGridApiRef} getRows={getRows} pageRef={pageRef} isLoading={isLoading} style={{ height: '70vh' }} />
    </Box>
  );

}