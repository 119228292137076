import axiosClient from "axiosClient";
import { getQueryString } from "utilities/common";

export async function fetchRetailerProductsAPI(filters, data ) {
    return await axiosClient.post(`/api/v1/admin/retailer/products/all?${getQueryString(filters)}`, data);
}


//get Retailer product by Id
export async function fetchRetailerProductDetailsAPI({productId, retailerId}) {
    return await axiosClient.get(`/api/v1/admin/retailer/products/${retailerId}?productId=${productId}`);
}

//delete Retailer product
export async function deleteRetailerProductAPI({productId, retailerId}) {
    return await axiosClient.delete(`api/v1/admin/retailer/products/delete`, {productId, retailerId})
}

//add retailer product
export async function addRetailerProductFromMasterCatalogAPI(data ,retailerId) {
    return await axiosClient.post(`api/v1/admin/retailer/products`, { products: data, retailerId })
}

//get retailer product batch by ID
export async function getRetailerProductBatchAPI({productId, retailerId}) {
    return await axiosClient.get(`/api/v1/admin/retailer/products/batches/${retailerId}?productId=${productId}`)
}