import axiosClient from "axiosClient";
import { getQueryString } from "utilities/common";

export async function fetchNotificationCountDataAPI(filters){
    return await axiosClient.get(`/api/v1/retailer/notification/count/data?${getQueryString(filters)}`)
}

export async function fetchNotificationCountAPI(filters) {
    return await axiosClient.get(`api/v1/retailer/notification/count?${getQueryString(filters)}`)
}

export async function fetchReadSingleNotificationAPI(filters){
    return await axiosClient.get(`/api/v1/retailer/notification/read/single?${getQueryString(filters)}`)
}

export async function fetchReadAllNotificationAPI(filters){
    return await axiosClient.get(`api/v1/retailer/notification/read/all?${getQueryString(filters)}`)
}