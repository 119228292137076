import {  RemoveRedEyeRounded } from '@mui/icons-material';
import { Avatar, Box,  Dialog, DialogContent, DialogContentText, DialogTitle, Divider,  Grid, IconButton, List, ListItem,  Slide, Stack,  Tooltip, Typography } from '@mui/material';
import MDButton from 'components/MDButton';
import { AgGridTable } from 'components/tables/AgGridTable';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link,  useSearchParams } from 'react-router-dom';
import { formatTimestampToDate } from 'utilities/common';
import MDBox from 'components/MDBox';
import { addCommas } from 'utilities/common';
import defaultProductImage from '../../assets/images/placeholderproduct.svg';
import CloseIcon from '@mui/icons-material/Close';
import { capitalizeText } from 'utilities/common';
import { fetchApprovalsHistory } from '../../redux/slices/approvals';
import FilterTabs from 'components/common/FilterTabs';
import HistoryStatus from 'common/component/HistoryStatus';
import { formatAddress } from 'utilities/common';

export default function ApprovalsHistory({approvalRef={}}) {
  const dispatch = useDispatch();
  const { isUpdating = false, approvalsHistory = {} } = useSelector(state => state.approvalSlice);
  const { approvals ={}} = approvalsHistory;
  const pageRef = useRef(0);
  const searchTermRef = useRef('');
  const statusRef = useRef('');
  const mainGridApiRef = useRef('');
  const gridRefApi = useRef(null);
  const [ searchParams, setSearchParams] = useSearchParams();
  const [selectedTab, setSelectedTab] = useState('');
  const queryParams = new URLSearchParams(location.search);
  const filterParam = queryParams.get('statusFilter');
  const [reasonData, setReasonData] = useState('');

  useEffect(() => {
    setSelectedTab(filterParam || '');
  },[filterParam]);

  const statusFilterTabs = [
    { value:'', key: 'All'},
    { value:'approved', key:'Accepted' },
    { value:'declined', key:'Declined' },
    { value:'override', key:'Override' }
  ];

  const handleTabChange = (tabValue) => {
    setSearchParams({  statusFilter: tabValue || '', searchTerm: searchTermRef.current || '' });
    statusRef.current = tabValue;
    searchFn();
  };


  const myComponent = (props) => {
    return <>
      <Stack direction='row' gap={2}>
        <Typography variant='subtitle1' sx={{ fontSize: '12px', color: '#656575', lineHeight: 16, fontWeight: 400 }}>
          {props?.displayName}
        </Typography>
      </Stack>
    </>;
  };

  const columnDefs = [
    {
      headerName: 'Product Name/SKU',
      field: 'Product Name/SKU',
      checkboxSelection: false,
      wrapText: true,
      autoHeight: true,
      headerComponent: myComponent,
      cellRenderer: (props) => {
        return <Tooltip title={capitalizeText(props?.data?.productName)} arrow placement='top'>
          <Link to ={`/products/details/${props?.data?.productId}?filter=products`}>
            <Box display="flex" alignItems="center" sx={{ cursor : 'pointer' }}>
              <Avatar
                alt="Admin"
                src={props?.data?.productImage || defaultProductImage}
                sx={{ width: 32, height: 32}}
              />
              <MDBox textAlign="left" lineHeight={1.75} ml={1.25} mt={.125}>
                <Typography variant="h6" fontSize={14} color="dark" lineHeight={1.25} fontWeight="semibold" className="truncate line-1">{capitalizeText(props?.data?.productName)}</Typography>
                <Typography variant="p" fontSize={12} color="secondary" lineHeight={1.25}>{props?.data?.sku}</Typography>
              </MDBox>
            </Box>
          </Link>
        </Tooltip>;
      },
      minWidth: 280
    },
    {
      headerName: 'Brand',
      field: 'brandName',
      suppressMenu: false,
      minWidth: 140
    },
    {
      headerName: 'Available',
      field: 'productPlatform',
      suppressMenu: false,
      minWidth: 140,
      cellRenderer: (props) => {
        if (Array.isArray(props.value)) {
          return props.value?.map(item => capitalizeText(item)).join(', ');
        }
      }
    },
    {
      headerName: 'Retailer ID',
      field: 'retailerId',
      suppressMenu: false,
      minWidth: 140
    },
    {
      headerName: 'Retailer Name',
      field: 'retailerName',
      suppressMenu: false,
      minWidth: 140
    },
    {
      headerName: 'Address',
      field: 'address',
      suppressMenu: false,
      cellRenderer: (props) => {
        return <Tooltip title={props?.value} arrow placement="top">
          <Typography className="truncate line-2" variant="caption" display="block" fontWeight="regular" sx={{ whiteSpace: 'normal' }}>{props?.value}</Typography>
        </Tooltip>;
      },
      minWidth: 340
    },
    {
      headerName: 'Status',
      field: 'status',
      suppressMenu: false,
      minWidth: 140,
      cellRenderer:(props)=>{
        return <HistoryStatus status={props.value}/>;
      }
    },
    {
      headerName: '',
      field: 'notes',
      suppressMenu: false,
      cellRenderer: (props) => {
        return   <MDButton onClick={() => {
          setReasonData(props?.data);
        }} size="small" startIcon={<RemoveRedEyeRounded fontSize='small' />} variant="contained" color="primary" className="btn-primary-light">
          {props?.data?.status ==='declined' ? 'View Reason' : 'View Details'}
        </MDButton>;
      },
      minWidth: 140,
    },
  ];

  const getRows = useCallback(async (params) => {
    gridRefApi.current = params;
    const sortModel = params?.sortModel;
    let sortedColumnName = '';
    let sortDirection = '';
    if (sortModel.length > 0) {
      const sortedColumn = sortModel[0];
      sortedColumnName = sortedColumn.colId;
      sortDirection = sortedColumn.sort === 'asc' ? 'low' : 'high';
    }
    dispatch(fetchApprovalsHistory({ skip: pageRef.current, limit: 1000, userType: 'admin', searchTerm: searchTermRef.current, [sortedColumnName]: sortDirection, status: statusRef.current})).unwrap().then(res => {
      const { approvals, total } = res;
      const approvalsArray = approvals?.map((item => {
        return { ...item, id: item._id, retailerName: item?.retailerDetails?.retailerName, address:formatAddress(item?.retailerDetails?.address), totalQuantity: addCommas(item?.totalQuantity), createdDate: formatTimestampToDate(item?.createdDate) };
      }));
      params.successCallback(approvalsArray, total);
    });
  }, []);

  const searchFn = () => {
    const dataSource = {
      getRows
    };
    if (mainGridApiRef.current) {
      mainGridApiRef.current.ensureIndexVisible(0, null);
      mainGridApiRef.current.setGridOption('datasource', dataSource);
    }
  };


  useEffect(() => {
    approvalRef.current = searchFn;
  },[]);

  useEffect(() => {
    if (mainGridApiRef?.current) {
      if (isUpdating) {
        mainGridApiRef.current.showLoadingOverlay();
      } else if (approvals?.total === 0) {
        mainGridApiRef.current.showNoRowsOverlay();
      }
      else {
        mainGridApiRef.current.hideOverlay();
      }
    }

  }, [approvals, isUpdating]);

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
  });

  return (
    <>
      <Box p={{ xs: 2, sm: 3, lg: 4 }} pb={{ lg: 2 }}>
        <Grid container alignItems="center" rowSpacing={2} columnSpacing={2} mt={0} mb={1}>
          <Grid item xs={12} sm={2}>
            <Typography variant='h5'>History</Typography>
          </Grid>

          <Grid item xs={12} sm={10}>
            <Stack direction="row" justifyContent={{ xs: 'start', sm: 'end' }} flexWrap="wrap" alignItems="stretch" gap={2}>
            </Stack>
          </Grid>
        </Grid>
        <FilterTabs tabs={statusFilterTabs} tabHandler={handleTabChange} selectedTab={selectedTab}/>

        <AgGridTable
          col={columnDefs}
          mainGridApiRef={mainGridApiRef}
          getRows={getRows}
          pageRef={pageRef}
          isLoading={isUpdating}
          style={{ height: '34vh' }}
          gridOptions={{
            rowHeight: 64,
          }}
          maxLimit={1000}
        />
      </Box>
      {
        Boolean(reasonData) && <Dialog open={Boolean(reasonData)} TransitionComponent={Transition} keepMounted maxWidth='xs' fullWidth className="notes-modal"
          onClose={() => setReasonData('')}
          aria-describedby="alert-dialog-slide-description"
        >

          <DialogTitle id="draggable-dialog-title" display="flex" alignItems="center" justifyContent="space-between" mb={0.5}>
            {reasonData?.status ==='declined' ? 'Decline Reason' : reasonData?.status ==='override' ? 'Override Details': 'Approval Details'}
            <IconButton onClick={() => setReasonData('')} edge='end' size='small'>
              <CloseIcon fontSize='medium' />
            </IconButton>
          </DialogTitle>

          <DialogContent className='notes-modal-body'>
            <DialogContentText id="alert-dialog-slide-description">
              <List>
                <ListItem>
                  <Typography variant="body2" fontWeight="medium" mb={0.5} className='w-100 d-flex align-item-top jc-s-btwn gap-3'>
                    {reasonData?.status ==='approved' ? <>
                      <span className='text-grey-color white-space-nowrap lh-normal'>Approved On:</span>
                      <span className='text-color font-w-600 lh-normal'>{formatTimestampToDate(reasonData?.updatedDate)}</span>
                    </> :                  
                      reasonData?.status ==='declined' ? <>
                        <span className='text-grey-color white-space-nowrap lh-normal'>Decline Reason:</span>
                        <span className='text-color font-w-600 lh-normal'>{reasonData?.declinedReason}</span>
                      </> :
                        <>
                          <span className='text-grey-color white-space-nowrap lh-normal'>Overridden Product:</span>
                          <span className='text-color font-w-600 lh-normal'>{capitalizeText(reasonData?.overrideProductName)}</span>
                        </>                    
                    }
                  </Typography>
                </ListItem>
                <Divider component="li" />
                <ListItem>
                  <Typography variant="body2" fontWeight="medium" mb={0.5} className='w-100 d-flex align-item-top jc-s-btwn gap-3'>
                    <span className='text-grey-color white-space-nowrap lh-normal'>Product Addded By:</span>
                    <span className='text-color font-w-600 lh-normal'>
                      {capitalizeText(reasonData?.retailerDetails.retailerName)}
                    </span>
                  </Typography>
                </ListItem>
              </List>
            </DialogContentText>
          </DialogContent>

        </Dialog >
      }
    </>

  );

}