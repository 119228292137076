import { axiosHyperwolfClient } from "axiosClient";

export function loginAPI(data) {
    return axiosHyperwolfClient.post('api/v1/user/admin/login', data);
}

export function getLastSyncDateAPI() {
    return axiosHyperwolfClient.get('api/v1/admin/products/getLastSync');
}

// API's for Open and close shop timings:
export function shopOpenTimeAPI() {
    return axiosHyperwolfClient.get('api/v1/shop/get/variables');
}

export function shopUpdateTimeAPI(data) {
    return axiosHyperwolfClient.post('api/v1/shop/update/variables', data);
}