
import { useEffect, useState } from "react";

// react-router-dom components
import { useLocation, NavLink } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import SidenavCollapse from "examples/Sidenav/SidenavCollapse";

// Custom styles for the Sidenav
import SidenavRoot from "examples/Sidenav/SidenavRoot";

// Material Dashboard 2 React context
import {
  setMiniSidenav,
} from "../../redux/slices/materialUISlice";
import { useDispatch, useSelector } from "react-redux";

function Sidenav({ color, brand, brandName, routes, ...rest }) {
  const controller = useSelector((state) => state.materialUI);
  const [isOpen, setOpen] = useState({ id: -1, status: false });
  const { userRoleAndPermissions = {} } = useSelector(state => state.auth)
  const { permissions = [] } = userRoleAndPermissions || {}

  const dispatch = useDispatch();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode, sidenavColor } = controller;
  const location = useLocation();
  const collapseName = location.pathname.replace("/", "");

  let textColor = "white";

  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = "dark";
  } else if (whiteSidenav && darkMode) {
    textColor = "inherit";
  }

  const closeSidenav = () => dispatch(setMiniSidenav(true));

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      dispatch(setMiniSidenav(!window.innerWidth >= 1200));
    }

    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    if (window.innerWidth <= 1200) {
      handleMiniSidenav();
    }

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);

  const handleOpenCategory = (index) => {
    setOpen({
      id: index,
      status: isOpen?.id === index ? !isOpen?.status : true,
    });

  };

  if (collapseName === 'Products') {
  }

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = routes.map(({ type, name, icon, title, noCollapse, key, href, route, collapse, dropdown, roles, screen }, index) => {
    let returnValue;


    if (type === "collapse") {
      returnValue = href ? (
        <Link
          href={href}
          key={key}
          target="_blank"
          rel="noreferrer"
          sx={{ textDecoration: "none" }}
        >
          <SidenavCollapse
            name={name}
            icon={icon}
            active={key === collapseName}
            noCollapse={noCollapse}
            handleOpenCategory={() => handleOpenCategory(index)}
            isOpen={isOpen}
          />
        </Link>

      ) : (
        name === 'Products' && key !== 'hyperwolf_products' ? <SidenavCollapse className="collapse-menu-item" setOpen={setOpen} screen={screen} name={name} icon={icon}
          active={key === collapseName || collapse?.find((collapse => collapse?.key === collapseName))?.screen === screen
            || collapse?.find((collapse => collapse?.key === collapseName.split('/')[0]))?.screen === screen
          }
          dropdown={dropdown} collapse={collapse} index={index} handleOpenCategory={() => handleOpenCategory(index)}
          isOpen={isOpen} />

          :

          name === 'Manage Website' ? <SidenavCollapse className="collapse-menu-item" setOpen={setOpen} screen={screen} name={name} icon={icon}
            active={key === collapseName || collapse?.find((collapse => collapse?.key === collapseName))?.screen === screen
              || collapse?.find((collapse => collapse?.key === collapseName.split('/')[0]))?.screen === screen
            }
            dropdown={dropdown} collapse={collapse} index={index} handleOpenCategory={() => handleOpenCategory(index)}
            isOpen={isOpen} />

            :

            name === 'POS' ? <SidenavCollapse className="collapse-menu-item" setOpen={setOpen} screen={screen} name={name} icon={icon}
              active={key === collapseName || collapse?.find((collapse => collapse?.key === collapseName))?.screen === screen
                || collapse?.find((collapse => collapse?.key === collapseName.split('/')[0]))?.screen === screen
              }
              dropdown={dropdown} collapse={collapse} index={index} handleOpenCategory={() => handleOpenCategory(index)}
              isOpen={isOpen} />

              :

              name === 'Settings' && key !== 'hyperwolf/shop_timing' ? <SidenavCollapse className="collapse-menu-item" setOpen={setOpen} screen={screen} name={name} icon={icon}
                active={key === collapseName || collapse?.find((collapse => collapse?.key === collapseName))?.screen === screen
                  || collapse?.find((collapse => collapse?.key === collapseName.split('/')[0]))?.screen === screen
                }
                dropdown={dropdown} collapse={collapse} index={index} handleOpenCategory={() => handleOpenCategory(index)}
                isOpen={isOpen} />

                :

                name === 'Promotions' ? <SidenavCollapse className="collapse-menu-item" setOpen={setOpen} screen={screen} name={name} icon={icon}
                  active={key === collapseName || collapse?.find((collapse => collapse?.key === collapseName))?.screen === screen
                    || collapse?.find((collapse => collapse?.key === collapseName.split('/')[0]))?.screen === screen
                  }
                  dropdown={dropdown} collapse={collapse} index={index} handleOpenCategory={() => handleOpenCategory(index)}
                  isOpen={isOpen} />


                  : <NavLink key={key} to={route}>
                    <SidenavCollapse name={name} icon={icon} setOpen={setOpen} active={key === collapseName} screen={screen} dropdown={dropdown} collapse={collapse} index={index} handleOpenCategory={() => handleOpenCategory(index)}
                      isOpen={isOpen} />
                  </NavLink>


      );
    } else if (type === "title") {
      returnValue = (
        <MDTypography
          key={key}
          color={textColor}
          display="block"
          variant="caption"
          fontWeight="bold"
          textTransform="uppercase"
          pl={3}
          mt={2}
          mb={1}
          ml={1}
        >
          {title}
        </MDTypography>
      );
    } else if (type === "divider") {
      returnValue = (
        <Divider
          key={key}
          light={
            (!darkMode && !whiteSidenav && !transparentSidenav) ||
            (darkMode && !transparentSidenav && whiteSidenav)
          }
        />
      );
    }

    return returnValue;
  });

  return (
    <SidenavRoot
      {...rest}
      variant="permanent"
      ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
      className="cus-sidebar"
    >
      <MDBox py={3} px={1}>
        <MDBox
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: "pointer" }}
        >
        </MDBox>
      </MDBox>

      <List className="sidebar-nav" sx={{ my: 3.5 }}>{renderRoutes}</List>
    </SidenavRoot>
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: "info",
  brand: "",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;