import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { getApprovalsAPI, updateApprovalAPI, getApprovalDetailAPI, overrideApprovalAPI } from "../../redux/apis/approval"
import axiosClient from "axiosClient";
import { getQueryString } from "utilities/common";


export const fetchApprovals = createAsyncThunk(
    'retailers/approvals',
    async (filters, { rejectWithValue }) => {
        try {
            const res = await getApprovalsAPI(filters);
            return res
        } catch(error ){
             
            return rejectWithValue(error?.response?.data)
        }
    }
)

export const fetchApprovalDetails = createAsyncThunk(
    'retailers/approvalDetail',
    async (approvalId, { rejectWithValue }) => {
        try {
            const res = await getApprovalDetailAPI(approvalId);
            return res
        } catch (error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)

export const updateApproval = createAsyncThunk(
    'retailers/updateApproval',
    async (data, { rejectWithValue }) => {
        try {
            const res = await updateApprovalAPI(data)
            return res
        } catch (error) {
             
            return rejectWithValue(error?.response?.data)
        }
    }
)

export const fetchApprovalsHistory = createAsyncThunk(
    'retailers/getApprovalHistory',
    async(filters, { rejectWithValue }) => {
        try {
            const res = await axiosClient.get(`/api/v1/admin/retailer/products/approvals/history/details?${getQueryString(filters)}`)
            return res
        } catch (error) {
             
            return rejectWithValue(error?.response?.data)
        }
    }
)

export const overrideApproval = createAsyncThunk(
    'retailers/updateApprovals',
    async({approvalId,filters, data}, { rejectWithValue }) => {
        try {
            const res = await overrideApprovalAPI(approvalId, filters, data)
            return res
        } catch (error){
             
            return rejectWithValue(error?.response?.data)
        }
    }
)


const approvalsSlice = createSlice({
    name: 'approvals',
    initialState: {
        approvals: [],
        approvalsHistory: [],
        approvalDetail: {},
        isLoading: false,
        error: null,
        approval:{},
        isUpdating: false
    },
    reducers: {

    },
    extraReducers: (builder) => {
        builder
        .addCase(fetchApprovals.pending, (state) => {
            state.isLoading = true;
            state.error = null;
            state.approval = {}
        })
        .addCase(fetchApprovals.fulfilled, (state, action) => {
            state.isLoading = false;
            state.approvals = action.payload;
        })
        .addCase(fetchApprovals.rejected, (state,action) => {
            state.isLoading = false;
            state.error = action.payload
        })
        builder
        .addCase(fetchApprovalDetails.pending, (state) => {
            state.isLoading = true;
            state.error = null;
            state.approvalDetail = {}
        })
        .addCase(fetchApprovalDetails.fulfilled, (state,action) => {
            state.isLoading = false;
            state.approvalDetail = { ...action.payload}
        })
        .addCase(fetchApprovalDetails.rejected, (state,action) => {
            state.isLoading = false;
            state.error = action.payload
        })

        builder
        .addCase(updateApproval.pending, (state, action) => {
            state.isLoading = true;
            state.error = null;
        })
        .addCase(updateApproval.fulfilled, (state, action) => {
            state.isLoading = false;
        })
        .addCase(updateApproval.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        })
        builder.addCase(fetchApprovalsHistory.pending, (state) => {
            state.isUpdating =true;
            state.error = null;
        })
        .addCase(fetchApprovalsHistory.fulfilled, (state,action) => {
            state.isUpdating = false;
            state.approvalsHistory= action.payload
        })
        .addCase(fetchApprovalsHistory.rejected, (state,action)=> {
            state.isUpdating =false;
            state.error = action.payload
        })

        builder
        .addCase(overrideApproval.pending, (state,action) => {
            state.isLoading = true;
            state.error = null;
        })
        .addCase(overrideApproval.fulfilled, (state,action) => {
            state.isLoading = false;
        })
        .addCase(overrideApproval.rejected, (state,action) => {
            state.isLoading = false;
            state.error = action.payload
        })
    }
});

export const { } = approvalsSlice.actions;

export default approvalsSlice.reducer