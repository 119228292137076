 

/**
 * The base breakpoints for the Material Dashboard 2 React.
 * You can add new breakpoints using this file.
 * You can customized the breakpoints for the entire Material Dashboard 2 React using thie file.
 */

const breakpoints = {
  values: {
    // xs: 0,
    // sm: 576,
    // md: 768,
    // lg: 992,
    // xl: 1200,
    // xxl: 1400,
    xs: 0,
    sm: 768,
    md: 991,
    lg: 1025,
    xl: 1201,
    xxl: 1401,
    xxxl: 1620,
  },
};

export default breakpoints;
