import { Box, CircularProgress, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, IconButton, InputLabel, MenuItem, Select } from '@mui/material';
import MDButton from 'components/MDButton';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { bulkProductUpdate, openSnackbar } from '../../redux/slices/common';
import { closeCustomModalDialog } from '../../redux/slices/modalSlice';

export default function BulkProductStatusUpdate({ closeModal }) {
  const { modalData, isLoading = false } = useSelector(state => state.modal);
  const { isDeleting = false } = useSelector(state => state.common);
  const [productStatus, setProductStatus] = useState('');
  const [errors, setErrors] = useState({});

  const { bulkProductIds, resetData = () => { } } = modalData;
  const dispatch = useDispatch();

  const handleBulkUpdate = () => {
    if (!productStatus) {
      setErrors({ productStatus: 'Status should not be empty' });
      return;
    }
    setErrors({});
    dispatch(bulkProductUpdate({ productIds: bulkProductIds, status: productStatus })).unwrap().then((res => {
      dispatch(openSnackbar({ content: res?.message, color: 'success', icon: 'check' }));
      dispatch(closeCustomModalDialog({}));
      resetData();
    })).catch((err => {
      dispatch(openSnackbar({ content: err?.message, color: 'error', icon: 'warning' }));
    }));
  };
  return (
    <>
      <DialogTitle id="draggable-dialog-title" display="flex" alignItems="center" justifyContent="space-between">
        Update Product Status
        <IconButton onClick={closeModal} edge='end' size='small'>
          <CloseIcon fontSize='medium' />
        </IconButton>
      </DialogTitle>

      <DialogContent>

        <Box my={2}>
          <FormControl fullWidth>
            <InputLabel>Status</InputLabel>
            <Select
              className="form-select"
              error={Boolean(errors?.productStatus)}
              onChange={(e) => setProductStatus(e.target.value)} label="Status" name="productStatus" value={productStatus}>
              <MenuItem value='active'>Active</MenuItem>
              <MenuItem value='inactive'>Inactive</MenuItem>

            </Select>
            {Boolean(errors?.productStatus) && <FormHelperText error>{errors?.productStatus}</FormHelperText>}

          </FormControl>
        </Box>
      </DialogContent>

      <DialogActions>
        <MDButton onClick={closeModal} size="small" color="secondary">
          Cancel
        </MDButton>
        <MDButton onClick={handleBulkUpdate} size="small" color='error'>
          {
            isLoading || isDeleting ? <CircularProgress size={16} color='light' /> : ' Update'
          }
        </MDButton>
      </DialogActions>
    </>
  );
}