import AddProductForm from 'components/products/AddProduct';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchSingleProduct } from '../../../redux/slices/products';
import { useParams } from 'react-router-dom';
import CommonLayout from 'layouts/DashboardLayout';
import { Box } from '@mui/material';

export default function AddProduct() {
  const dispatch = useDispatch();
  const { productId = '' } = useParams();
  useEffect(() => {
    if(productId){
      dispatch(fetchSingleProduct(productId));

    }
  }, [productId]);
  return (
    <CommonLayout>
      <Box p={{xs: 2, sm: 3, lg: 4}}>
        <AddProductForm />
      </Box>
    </CommonLayout>
  );
}