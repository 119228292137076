import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import {  useState } from 'react';
import { useSelector } from 'react-redux';
import NotificationDrawer from './NotificationDrawer';

const NotificationCount = () => {
  const count = useSelector(state => state.notifications.notificationCount);
  const [showNotificationDrawer, setShowNotificationDrawer] = useState(false);

  const handleShowNotificationDrawer = () => {
    setShowNotificationDrawer(!showNotificationDrawer);
  };

  return (
    <div className='notifiction-dawer' onClick={handleShowNotificationDrawer}>
      <div className='icon-wrapper'>
        <NotificationsNoneIcon />
      </div>
      {count > 0 &&
                <div className='count-number'>{count}</div>
      }
      {showNotificationDrawer && <NotificationDrawer open={showNotificationDrawer} onClose={handleShowNotificationDrawer} /> }
    </div>
  );
};

export default NotificationCount;