import axiosClient from "axiosClient";
import { getQueryString } from "utilities/common";

export async function fetchPromotionListingAPI(filters) {
    return await axiosClient.get(`/api/v1/admin/promotion/all?${getQueryString(filters)}`);
}

export async function getSinglePromotionAPI(promotionId) {
    return await axiosClient.get(`/api/v1/admin/promotion/${promotionId}`);
}

export async function createPromotionAPI(data) {
    return await axiosClient.post(`/api/v1/admin/promotion`, data);
}

export async function updatePromotionAPI(data, promotionId) {
    return await axiosClient.put(`/api/v1/admin/promotion/${promotionId}`, data);
}

export async function deletePromotionAPI(promotionId) {
    return await axiosClient.delete(`/api/v1/admin/promotion/${promotionId}`);
}
export async function getPromotionCartRulesByTypeAPI() {
    return await axiosClient.get(`/api/v1/admin/cart/rules`);
}


export async function getPromotionProductRulesByTypeAPI() {
    return await axiosClient.get(`/api/v1/admin/product/rules`);
}

export async function downloadPromotionAsPdfAPI(promotionId) {
    return await axiosClient.get(`/api/v1/download/csv/promo/${promotionId}`);
}