import React from 'react';

export default function InProgressIcon({width = '20px', height = '20px', flex = 'none'}) {
  return ( 
    <svg style={{width, height, flex}} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.5 10.5625C17.3508 10.5625 17.2077 10.5032 17.1022 10.3977C16.9968 10.2923 16.9375 10.1492 16.9375 10C16.9376 8.62784 16.5307 7.28646 15.7684 6.14553C15.0061 5.00459 13.9226 4.11533 12.6549 3.59021C11.3872 3.0651 9.99224 2.92771 8.64645 3.19543C7.30065 3.46315 6.06447 4.12394 5.09424 5.09425C4.86167 5.32715 4.64565 5.576 4.44774 5.839C4.35823 5.95835 4.22498 6.03725 4.07729 6.05835C3.92961 6.07944 3.77959 6.04101 3.66024 5.9515C3.5409 5.86199 3.462 5.72873 3.4409 5.58105C3.4198 5.43336 3.45823 5.28335 3.54774 5.164C3.77793 4.85986 4.02851 4.57169 4.29774 4.3015C5.42509 3.17368 6.86156 2.40549 8.4255 2.09408C9.98944 1.78267 11.6106 1.94202 13.084 2.55198C14.5573 3.16195 15.8167 4.19513 16.7029 5.52087C17.5891 6.84661 18.0622 8.40536 18.0625 10C18.0625 10.1492 18.0032 10.2923 17.8977 10.3977C17.7923 10.5032 17.6492 10.5625 17.5 10.5625Z" fill="currentColor"/>
      <path d="M10 18.0625C8.94116 18.0627 7.89266 17.8543 6.91438 17.4492C5.93611 17.0441 5.04722 16.4502 4.29851 15.7015C3.5498 14.9528 2.95593 14.0639 2.55082 13.0856C2.14571 12.1073 1.9373 11.0588 1.9375 10C1.9375 9.85082 1.99676 9.70774 2.10225 9.60225C2.20774 9.49676 2.35082 9.4375 2.5 9.4375C2.64918 9.4375 2.79226 9.49676 2.89775 9.60225C3.00324 9.70774 3.0625 9.85082 3.0625 10C3.06242 11.3722 3.46926 12.7135 4.23155 13.8545C4.99385 14.9954 6.07737 15.8847 7.34508 16.4098C8.61279 16.9349 10.0078 17.0723 11.3535 16.8046C12.6993 16.5369 13.9355 15.8761 14.9058 14.9058C15.1382 14.6732 15.354 14.4246 15.5515 14.1617C15.644 14.0509 15.7754 13.9798 15.9188 13.963C16.0622 13.9463 16.2065 13.9853 16.322 14.0719C16.4375 14.1585 16.5153 14.2862 16.5394 14.4285C16.5635 14.5708 16.532 14.717 16.4515 14.8367C16.2216 15.1414 15.971 15.4298 15.7015 15.7C14.9545 16.4511 14.066 17.0467 13.0873 17.4522C12.1087 17.8577 11.0593 18.0651 10 18.0625Z" fill="currentColor"/>
      <path d="M16.3638 16.9262C16.2146 16.9262 16.0715 16.867 15.966 16.7615C15.8605 16.656 15.8013 16.5129 15.8013 16.3637V14.8052H14.242C14.0928 14.8052 13.9497 14.746 13.8443 14.6405C13.7388 14.535 13.6795 14.3919 13.6795 14.2427C13.6795 14.0936 13.7388 13.9505 13.8443 13.845C13.9497 13.7395 14.0928 13.6802 14.242 13.6802H16.3638C16.5129 13.6802 16.656 13.7395 16.7615 13.845C16.867 13.9505 16.9263 14.0936 16.9263 14.2427V16.3637C16.9263 16.5129 16.867 16.656 16.7615 16.7615C16.656 16.867 16.5129 16.9262 16.3638 16.9262Z" fill="currentColor"/>
      <path d="M5.75725 6.31976H3.63625C3.48706 6.31976 3.34399 6.2605 3.2385 6.15501C3.13301 6.04952 3.07375 5.90645 3.07375 5.75726V3.63626C3.07375 3.48708 3.13301 3.344 3.2385 3.23851C3.34399 3.13302 3.48706 3.07376 3.63625 3.07376C3.78543 3.07376 3.9285 3.13302 4.03399 3.23851C4.13948 3.344 4.19875 3.48708 4.19875 3.63626V5.19476H5.75725C5.90643 5.19476 6.0495 5.25402 6.15499 5.35951C6.26048 5.465 6.31975 5.60808 6.31975 5.75726C6.31975 5.90645 6.26048 6.04952 6.15499 6.15501C6.0495 6.2605 5.90643 6.31976 5.75725 6.31976Z" fill="currentColor"/>
      <path d="M13.7095 9.64825L14.7655 7.888L9.625 4.80099L8.5645 6.56124C8.38147 6.94978 8.2909 7.37542 8.29988 7.80482C8.30886 8.23421 8.41714 8.6557 8.61625 9.03625C8.62303 9.05679 8.62529 9.07857 8.62287 9.10007C8.62045 9.12158 8.61341 9.1423 8.60222 9.16083C8.59103 9.17935 8.57597 9.19524 8.55807 9.20739C8.54016 9.21955 8.51984 9.22768 8.4985 9.23125C8.06897 9.23464 7.64607 9.33751 7.26298 9.53179C6.87989 9.72607 6.54702 10.0065 6.2905 10.351L5.2345 12.112L10.3787 15.199L11.4355 13.4387C11.6185 13.0502 11.7091 12.6246 11.7002 12.1953C11.6914 11.7659 11.5833 11.3444 11.3845 10.9637C11.3777 10.9432 11.3754 10.9215 11.3778 10.9001C11.3802 10.8786 11.3872 10.8579 11.3983 10.8394C11.4094 10.8209 11.4243 10.805 11.4422 10.7928C11.46 10.7806 11.4802 10.7724 11.5015 10.7687C11.9311 10.7651 12.354 10.6621 12.737 10.4677C13.1201 10.2733 13.453 9.99282 13.7095 9.64825Z" fill="currentColor"/>
      <path d="M15.2688 7.03258L10.1268 3.94858C9.77184 3.73567 9.31148 3.85085 9.09857 4.20583C8.88566 4.56081 9.00084 5.02117 9.35582 5.23408L14.4978 8.31808C14.8528 8.53099 15.3132 8.41581 15.5261 8.06083C15.739 7.70585 15.6238 7.24549 15.2688 7.03258Z" fill="currentColor"/>
      <path d="M10.6442 14.7487L5.50219 11.6647C5.1472 11.4518 4.68684 11.5669 4.47394 11.9219C4.26103 12.2769 4.3762 12.7373 4.73119 12.9502L9.87319 16.0342C10.2282 16.2471 10.6885 16.1319 10.9014 15.7769C11.1143 15.4219 10.9992 14.9616 10.6442 14.7487Z" fill="currentColor"/>
    </svg>
  );
}