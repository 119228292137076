import React from 'react';
import { GoogleMap, KmlLayer } from '@react-google-maps/api';

const MapWithPolygon = ({ kmlUrl }) => {
  // Define the coordinates for the polygon

  return (
    // <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_KEY}>
    <GoogleMap
      mapContainerStyle={{ width: '100%', height: '700px' }}
      center={{ lat: 40.712, lng: -74.227 }} // Set your initial map center
      zoom={10} // Set initial zoom level
    >
      {/* Display the Polygon */}
      {/* <Polygon
    path={polygonCoords}
    options={{
      strokeColor: '#FF0000',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#FF0000',
      fillOpacity: 0.35,
    }}
  /> */}
      <KmlLayer url={kmlUrl} />
    </GoogleMap>
    // </LoadScript>
  );
};

export default React.memo(MapWithPolygon);
