import { axiosHyperwolfClient } from "axiosClient";
import { getQueryString } from "utilities/common";

export function getBannerTimingAPI(data) {
    return axiosHyperwolfClient.get(`api/v1/banner/timings?${getQueryString(data)}`);
}

export function updateBannerTimingAPI(data) {
    return axiosHyperwolfClient.post(`api/v1/banner/timings/update/variables`,data);
}


export function getBannersListsAPI(data) {
    return axiosHyperwolfClient.get('api/v1/admin/banners',data);
}


export function updateBannerListAPI(data,id) {
    if (id) {
        return axiosHyperwolfClient.put(`api/v1/admin/banners/${id}`, data);
    }
    return axiosHyperwolfClient.post('api/v1/admin/banners', data);
}

export function deleteBannerAPI(id) {
    return axiosHyperwolfClient.delete(`api/v1/admin/banners/${id}`)
}

export function updateFhlStatusAPI(data) {
    return axiosHyperwolfClient.post('api/v1/fhl/update/status',data);
}

export function getFhlStatusAPI(data) {
    return axiosHyperwolfClient.get('api/v1/fhl/status',data);
}