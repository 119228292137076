import CommonLayout from 'layouts/DashboardLayout'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Avatar, Box, ButtonGroup, Card, CardActions, CardContent, Grid,  Stack, Tooltip, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import MDButton from 'components/MDButton'
import { closeCustomModalDialog, openCustomModalDialog, setModalLoader } from '../../redux/slices/modalSlice'
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import Search from 'examples/Search'
import { useDebounce } from 'hooks/useDebounce'
import { deleteCannabinoid,  getCannabinoidsList } from '../../redux/slices/cannabinoids'
import EditIcon from 'common/customIcons/edit'
import DeleteIcon from 'common/customIcons/delete'
import StatusText from 'common/component/StatusText'
import { openSnackbar } from '../../redux/slices/common'
import MDBox from 'components/MDBox'
import NoDataFound from 'common/component/NoDataFound'
import GridViewIcon from '@mui/icons-material/GridView';
import ViewListIcon from '@mui/icons-material/ViewList';

export default function Cannabinoids({ isGridView = false, cannabinoidViewHandler = () => { }, cannabinoidListView = '' }) {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const pageRef = useRef(0)
  const searchTermRef = useRef("")
  const [searchTerm, setSearchTerm] = useState("")

  const handleSearch = useDebounce((term) => {
    // Perform search operation with the debounced term
    pageRef.current = 0
    searchFn()
  }, 500);

  const { isLoading = false, cannabinoidList = {} } = useSelector(state => state.cannabinoids)

  const deleteHandler = (cannabinoidId) => {
    dispatch(setModalLoader(true))
    dispatch(deleteCannabinoid(cannabinoidId)).unwrap().then((res) => {
      showError(res?.message, 'success')
      dispatch(closeCustomModalDialog())
      getCannabinoids()
      // getRows(gridRefApi.current)
    }).catch((err) => {
      dispatch(setModalLoader(false))
      showError(err?.message, 'error')
    })
  }

  const showError = (message, errortype) => {
    if (errortype === 'success') {
      dispatch(openSnackbar({ content: message, color: 'success', icon: 'check' }))
    }
    if (errortype === 'error') {
      dispatch(openSnackbar({ content: message, color: 'error', icon: 'warning' }))
    }
  }

  const getCannabinoids = () => {
    dispatch(getCannabinoidsList({ limit: 100, skip: pageRef.current, searchTerm: searchTermRef.current })).unwrap()
  }

  const searchFn = () => {
    getCannabinoids()
  }

  const searchHandler = (event) => {
    const { value } = event.target;
    setSearchTerm(value);
    searchTermRef.current = value
    handleSearch(value);

    // Debounce the search callback
  };

  useEffect(() => {
    getCannabinoids()
  }, [])

  return (
    isGridView ? <>
      <Grid container alignItems="center" rowSpacing={2} columnSpacing={2} mb={3}>
        <Grid item xs={12} sm={4}>
          <Stack direction='row' gap={1} alignItems='center'>
            <Typography variant='h4'>Cannabinoids</Typography>
            <Stack direction='row' gap={1} className='grid-list-view' ml={2}>
              <Tooltip title='Grid View'>
                <div className='gl-view'>
                  <GridViewIcon onClick={() => {
                    setSearchTerm('')
                    searchTermRef.current = ''
                    cannabinoidViewHandler('grid')
                  }} color={cannabinoidListView === 'grid' ? 'primary' : ''} />
                </div>
              </Tooltip>
              <Tooltip title='list View'>
                <div className='gl-view'>
                  <ViewListIcon onClick={() => {
                    setSearchTerm('')
                    searchTermRef.current = ''
                    cannabinoidViewHandler('list')
                  }} color={cannabinoidListView === 'list' ? 'primary' : ''} />
                </div>
              </Tooltip>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Stack direction="row" justifyContent={{ xs: "start", sm: "end" }} flexWrap="wrap" alignItems="stretch" gap={2}>
            <Search placeholder="Search" label="" value={searchTerm} onChange={searchHandler} />
            <MDButton
              startIcon={<AddRoundedIcon fontSize='large' />}
              variant="contained" color="primary"
              onClick={() => navigate('/cannabinoids/add')} >
              Add Cannabinoid
            </MDButton>
          </Stack>
        </Grid>
      </Grid>
      {
        cannabinoidList?.cannabinoids?.length > 0 ? <Grid container spacing={2} mb={4}>
          {cannabinoidList?.cannabinoids?.map((item, index) => {
            return (
              <Grid item xs={12} sm={6} xl={4} key={index}>
                <Card sx={{ boxShadow: 0, height: '100%', justifyContent: 'space-between' }}>
                  <CardContent sx={{ p: 2.5 }}>
                    <Stack direction="row" alignItems="flex-start" justifyContent="space-between">
                      <Box display="flex">
                        <Avatar
                          alt="Product Name"
                          src={item?.listImage}
                          sx={{ width: 68, height: 68, borderRadius: 2 }} />
                        <MDBox textAlign="left" ml={1.5} lineHeight={1}>
                          <Typography variant="h5" fontSize={14} color="dark" fontWeight="semibold">{item?.cannabinoidName}</Typography>
                          <Typography variant="p" fontSize={12} color="secondary">ID: {item?.cannabinoidId}</Typography>
                        </MDBox>
                      </Box>
                      <Box color="#24CA49" display="flex" alignItems="center">
                        <StatusText status={item?.status ? 'active' : "inactive"} />
                      </Box>
                    </Stack>

                    <Box sx={{ mt: 2.5, mb: 2 }}>
                      <Typography variant="body2" fontWeight="medium" color={'gray'} className='ellipsis-three'>
                        {item?.title || "-"}
                      </Typography>
                    </Box>

                    <Stack direction="row" alignItems="flex-start" justifyContent="flex-start" gap="10px" flexWrap="wrap">
                      {item?.tags?.map((tag => {
                        return <Box key={tag} sx={{ bgcolor: "#F2F3F7", px: 2, py: 0.75, borderRadius: 2 }}>
                          <Typography fontWeight="medium" color="dark" className='fs-x-small'>
                            {tag ? `#${tag}` : "-"}
                          </Typography>
                        </Box>
                      }))}
                    </Stack>

                  </CardContent>
                  <CardActions sx={{ p: 0 }}>
                    <ButtonGroup className='card-footer-action' fullWidth variant="outlined">
                      <MDButton
                        type='button'
                        variant='outlined'
                        color='dark'
                        size="small"
                        onClick={() => navigate(`/cannabinoids/${item?.cannabinoidSlug}`)}
                        startIcon={<EditIcon width='15px' height='15px' color="#0163FF" />}
                      >
                        Edit
                      </MDButton>
                      <MDButton
                        type='button'
                        variant='outlined'
                        color='dark'
                        size="small"
                        onClick={() => {
                          dispatch(openCustomModalDialog({ modalData: { deleteHandler, deleteId: item?._id }, modal: 'DELETE_MODAL' }))
                        }}
                        startIcon={<DeleteIcon width='15px' height='15px' color='#FD4438' />}
                      >
                        Delete
                      </MDButton>
                    </ButtonGroup>
                  </CardActions>
                </Card>
              </Grid>
            )
          })}
        </Grid> : <NoDataFound text="No Cannabinoids Found" />
      }

    </> : <>

      <CommonLayout>
        <Box p={{ xs: 2, sm: 3, lg: 4 }}>
          <Grid container alignItems="center" rowSpacing={2} columnSpacing={2} mb={3}>
            <Grid item xs={12} sm={4}>
              <Typography variant='h4'>Cannabinoids</Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Stack direction="row" justifyContent={{ xs: "start", sm: "end" }} flexWrap="wrap" alignItems="stretch" gap={2}>
                <Search placeholder="Search" label="" value={searchTerm} onChange={searchHandler} />
                <MDButton
                  startIcon={<AddRoundedIcon fontSize='large' />}
                  variant="contained" color="primary"
                  onClick={() => navigate('/cannabinoids/add')} >
                  Add Cannabinoid
                </MDButton>
              </Stack>
            </Grid>
          </Grid>

          {
            cannabinoidList?.cannabinoids?.length > 0 ? <Grid container spacing={2} mb={4}>
              {cannabinoidList?.cannabinoids?.map((item, index) => {
                return (
                  <Grid item xs={12} sm={6} xl={4} key={index}>
                    <Card sx={{ boxShadow: 0, height: '100%', justifyContent: 'space-between' }}>
                      <CardContent sx={{ p: 2.5 }}>
                        <Stack direction="row" alignItems="flex-start" justifyContent="space-between">
                          <Box display="flex">
                            <Avatar
                              alt="Product Name"
                              src={item?.listImage}
                              sx={{ width: 68, height: 68, borderRadius: 2 }} />
                            <MDBox textAlign="left" ml={1.5} lineHeight={1}>
                              <Typography variant="h5" fontSize={14} color="dark" fontWeight="semibold">{item?.cannabinoidName}</Typography>
                              <Typography variant="p" fontSize={12} color="secondary">ID: {item?.cannabinoidId}</Typography>
                            </MDBox>
                          </Box>
                          <Box color="#24CA49" display="flex" alignItems="center">
                            <StatusText status={item?.status ? 'active' : "inactive"} />
                          </Box>
                        </Stack>

                        <Box sx={{ mt: 2.5, mb: 2 }}>
                          <Typography variant="body2" fontWeight="medium" color={'gray'} className='ellipsis-three'>
                            {item?.title || "-"}
                          </Typography>
                        </Box>

                        <Stack direction="row" alignItems="flex-start" justifyContent="flex-start" gap="10px" flexWrap="wrap">
                          {item?.tags?.map((tag => {
                            return <Box key={tag} sx={{ bgcolor: "#F2F3F7", px: 2, py: 0.75, borderRadius: 2 }}>
                              <Typography fontWeight="medium" color="dark" className='fs-x-small'>
                                {tag ? `#${tag}` : "-"}
                              </Typography>
                            </Box>
                          }))}
                        </Stack>

                      </CardContent>
                      <CardActions sx={{ p: 0 }}>
                        <ButtonGroup className='card-footer-action' fullWidth variant="outlined">
                          <MDButton
                            type='button'
                            variant='outlined'
                            color='dark'
                            size="small"
                            onClick={() => navigate(`/cannabinoids/${item?.cannabinoidSlug}`)}
                            startIcon={<EditIcon width='15px' height='15px' color="#0163FF" />}
                          >
                            Edit
                          </MDButton>
                          <MDButton
                            type='button'
                            variant='outlined'
                            color='dark'
                            size="small"
                            onClick={() => {
                              dispatch(openCustomModalDialog({ modalData: { deleteHandler, deleteId: item?.cannabinoidId }, modal: 'DELETE_MODAL' }))
                            }}
                            startIcon={<DeleteIcon width='15px' height='15px' color='#FD4438' />}
                          >
                            Delete
                          </MDButton>
                        </ButtonGroup>
                      </CardActions>
                    </Card>
                  </Grid>
                )
              })}
            </Grid> : <NoDataFound text="No Cannabinoids Found" />
          }
        </Box>
      </CommonLayout>
    </>
  )
}