import React from 'react';

export default function AssignIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <g clipPath="url(#clip0_8528_4462)">
        <path d="M0.218 8.00008C0.237333 7.93808 0.246 7.87341 0.268 7.81208L2.06667 2.86075C2.30156 2.21828 2.72798 1.66339 3.28832 1.27102C3.84867 0.878662 4.51594 0.667739 5.2 0.666748H10.8C11.4842 0.667694 12.1515 0.878677 12.7119 1.27118C13.2723 1.66368 13.6986 2.21877 13.9333 2.86141L15.7333 7.81208C15.7553 7.87341 15.764 7.93808 15.7833 8.00008H0.218ZM13.3333 9.33342V10.6667C13.3333 10.8436 13.2631 11.0131 13.1381 11.1382C13.013 11.2632 12.8435 11.3334 12.6667 11.3334C12.4899 11.3334 12.3203 11.2632 12.1953 11.1382C12.0702 11.0131 12 10.8436 12 10.6667V9.33342H4V10.6667C4 10.8436 3.92976 11.0131 3.80474 11.1382C3.67971 11.2632 3.51014 11.3334 3.33333 11.3334C3.15652 11.3334 2.98695 11.2632 2.86193 11.1382C2.7369 11.0131 2.66667 10.8436 2.66667 10.6667V9.33342H0V10.6667C0.00085225 11.3146 0.19047 11.9481 0.545666 12.4899C0.900862 13.0317 1.40624 13.4583 2 13.7174V14.6667C2 15.0204 2.14048 15.3595 2.39052 15.6096C2.64057 15.8596 2.97971 16.0001 3.33333 16.0001C3.68696 16.0001 4.02609 15.8596 4.27614 15.6096C4.52619 15.3595 4.66667 15.0204 4.66667 14.6667V14.0001H11.3333V14.6667C11.3333 15.0204 11.4738 15.3595 11.7239 15.6096C11.9739 15.8596 12.313 16.0001 12.6667 16.0001C13.0203 16.0001 13.3594 15.8596 13.6095 15.6096C13.8595 15.3595 14 15.0204 14 14.6667V13.7174C14.5938 13.4583 15.0991 13.0317 15.4543 12.4899C15.8095 11.9481 15.9991 11.3146 16 10.6667V9.33342H13.3333Z" fill="#A9A9B7"/>
      </g>
      <defs>
        <clipPath id="clip0_8528_4462">
          <rect width="16" height="16" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
}