import React from 'react';

export default function MapPinIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <g clipPath="url(#clip0_8528_3390)">
        <path d="M8.00019 0.0283203C6.23615 0.030261 4.54491 0.731837 3.29748 1.97914C2.05005 3.22644 1.34831 4.91762 1.34619 6.68165C1.34619 8.39499 2.67286 11.0763 5.28952 14.651C5.60103 15.0777 6.0089 15.4249 6.47993 15.6643C6.95096 15.9036 7.47184 16.0283 8.00019 16.0283C8.52854 16.0283 9.04943 15.9036 9.52046 15.6643C9.99149 15.4249 10.3994 15.0777 10.7109 14.651C13.3275 11.0763 14.6542 8.39499 14.6542 6.68165C14.6521 4.91762 13.9503 3.22644 12.7029 1.97914C11.4555 0.731837 9.76423 0.030261 8.00019 0.0283203ZM8.00019 9.33365C7.47277 9.33365 6.9572 9.17726 6.51867 8.88424C6.08014 8.59122 5.73835 8.17475 5.53651 7.68748C5.33468 7.20021 5.28187 6.66403 5.38476 6.14675C5.48766 5.62946 5.74163 5.15431 6.11457 4.78137C6.48751 4.40843 6.96267 4.15445 7.47995 4.05156C7.99723 3.94867 8.53341 4.00148 9.02068 4.20331C9.50795 4.40514 9.92443 4.74694 10.2174 5.18547C10.5105 5.624 10.6669 6.13957 10.6669 6.66699C10.6669 7.37423 10.3859 8.05251 9.88581 8.55261C9.38571 9.0527 8.70744 9.33365 8.00019 9.33365Z" fill="#A9A9B7"/>
      </g>
      <defs>
        <clipPath id="clip0_8528_3390">
          <rect width="16" height="16" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
}