const { isEmptyObject } = require("utilities/common");

export function memberValidation(fields,isMedicnalOr21Less) {
    const errors = {};
    if (!fields.dob) {
        errors.dob = 'Date of birth is required';
    }

    if (!fields.email || !isValidEmail(fields.email)) {
        errors.email = 'Enter a valid email address';
    }

    if (!fields.phone) {
        errors.phone = 'Enter a valid phone number';
    }

    if(fields?.phone && !isPhoneNumberValid(fields?.phone)){
        errors.phone = 'Phone number must be 10 digits';
    }

        if (!fields.firstName || fields.firstName.trim() === '') {
        errors.firstName = 'First name is required';
    }

    if (!fields.lastName || fields.lastName.trim() === '') {
        errors.lastName = 'Last name is required';
    }

    if (!fields.gender || (fields.gender?.toLowerCase() !== 'male' && fields.gender?.toLowerCase() !== 'female' &&  fields.gender?.toLowerCase() !== 'other')) {
        errors.gender = 'Select a valid gender';
    }

    if (isMedicnalOr21Less && (!fields.recId || fields.recId.trim() === '')) {
        errors.recId = 'Rec Id is required';
    }

    if (!fields.createdBy || fields.createdBy.trim() === '') {
        errors.createdBy = 'Created by is required';
    }

    if (!fields.status || !['active', 'inactive'].includes(fields.status)) {
        errors.status = 'Select a valid status (active or inactive)';
    }


    if (!fields.address || fields.address.trim() === '') {
        errors.address = 'Address is required';
    }

    if (!fields.memberShipGroup || typeof fields.memberShipGroup !== 'string') {
        errors.memberShipGroup = 'Membership group is required';
    }

    // Validate userType
    if (!fields.memberType || typeof fields.memberType !== 'string') {
        errors.userType = 'User type is required';
    }

    return { errors, isValid: isEmptyObject(errors) };
}

export function isValidZipcode(zipcode) {
    const zipcodeRegex = /^\d{5}(?:[-\s]\d{4})?$/;
    return zipcodeRegex.test(zipcode);
}

export function isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
}

export function isValidPhone(phone) {
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(phone);
}


function isPhoneNumberValid(phoneNumber) {
    // Remove all non-digit characters
    const cleanedPhoneNumber = phoneNumber.replace(/\D/g, '');
  
    // Check if the cleaned phone number has a length of 10
    return cleanedPhoneNumber.length === 10;
  }