import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Typography, List, ListItem, TextField, IconButton, InputAdornment, Box } from "@mui/material";
import driverPic from "../../assets/images/driver-pic.svg"
import DriverDetails from './DriverDetails';
import { useDispatch } from 'react-redux';
import { fetchDrivers } from '../../redux/slices/hyperdrive/driverAnalytics';
import SearchIcon from 'common/customIcons/search';
import { CloseRounded } from '@mui/icons-material';
import { useDebounce } from 'hooks/useDebounce';
import noDataFound from 'assets/images/nothing-found.svg';
import Loader from 'common/Loader';
import { ThreeDot } from 'react-loading-indicators';
import TaskTruckIcon from 'common/customIcons/tasktruck';
import TaskBreakIcon from 'common/customIcons/taskbreak';
import driverMap from 'assets/images/driver-map.svg';

const MapDriveList = ({ dataPassComponent, driverZoomOnMapRef = {} }) => {
    // If no data is passed, show a loading message
    if (!dataPassComponent || Object.keys(dataPassComponent).length === 0) {
        return <div>Loading...</div>;
    }

    const dispatch = useDispatch();
    const [driversList, setDriversList] = useState([]); // State to store the list of drivers
    const [page, setPage] = useState(0); // State to store the current page for pagination
    const [loading, setLoading] = useState(false); // State to indicate if data is being loaded
    const [hasMore, setHasMore] = useState(true); // State to indicate if there are more drivers to load
    const observer = useRef(); // Ref to observe the last element for infinite scrolling
    const [detailsDriver, setDetailsDriver] = useState(null); // State to store the details of the selected driver
    const searchTermRef = useRef(""); // Ref to store the current search term
    const [searchTerm, setSearchTerm] = useState(""); // State to store the search term
    const [loadingFirst, setLoadingFirst] = useState(true); // loading first
    const isResetPagination = useRef(false)

    // Function to load more posts (drivers) from the server
    const loadMorePosts = useCallback(async (data) => {
        setLoading(true);
        try {
            const limit = 20; // Limit the number of drivers to fetch per request
            const skip = data ? 0 : page * limit; // Calculate the number of drivers to skip
            const res = await dispatch(fetchDrivers({
                skip,
                limit,
                driverName: searchTermRef.current,
                driverNameSorting: 'asc'
            })).unwrap();
            const newPosts = res?.data; // Get the new drivers from the response
            if (isResetPagination.current) {
                setDriversList(newPosts)
                isResetPagination.current = false
            }

            else if (newPosts?.length === res?.total || [...driversList, ...newPosts]?.length === res?.total) {
                setDriversList((prevPosts) => [...prevPosts, ...newPosts]); // Append new drivers to the existing list
                setHasMore(false);
            }
            else {
                setDriversList((prevPosts) => [...prevPosts, ...newPosts]); // Append new drivers to the existing list
            }
        } catch (err) {
            setLoadingFirst(false)
            setLoading(false); // Set loading state to false
            // Handle error
        } finally {
            setLoadingFirst(false)
            setLoading(false); // Set loading state to false
        }
    }, [dispatch, page]);

    // Effect to load more posts when there are more posts to load
    useEffect(() => {
        if (hasMore) {
            loadMorePosts();
        }
    }, [hasMore, loadMorePosts]);

    useEffect(() => {
        const interval = setInterval(() => {
            setHasMore(false); // Set hasMore to false
            // setDriversList([]); // Reset the driver list
            setPage(0); // Reset the page to 0
            isResetPagination.current = true
            // Set hasMore to true after a short delay
            setTimeout(() => {
                setHasMore(true); // Allow fetching new data
            }, 10); // Adjust the delay as needed
        }, 20000); // Interval for every 20 seconds
        return () => clearInterval(interval); // Cleanup on unmount
    }, []);

    // Callback to observe the last post element for infinite scrolling
    const lastPostElementRef = useCallback(
        (node) => {
            if (loading || !hasMore) return;
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting) {
                    setPage((prevPage) => prevPage + 1); // Increment the page number when the last element is visible
                }
            });
            if (node) observer.current.observe(node); // Observe the last element
        },
        [loading, hasMore]
    );

    // Debounced search handler
    const handleSearch = useDebounce(() => {
        setPage(0); // Reset the page number
        setDriversList([]); // Clear the existing list of drivers
        if (searchTermRef.current == "") {
            setHasMore(false);
            setTimeout(() => {
                setHasMore(true); // Allow fetching new data
            }, 10); // Adjust the delay as needed
        }
        else {
            setHasMore(false);
            setTimeout(() => {
                setHasMore(true); // Allow fetching new data
            }, 10); // Adjust the delay as needed
        }
    }, 500);

    // Function to handle search input changes
    const searchHandler = useCallback((searchValue) => {
        setSearchTerm(searchValue); // Update the search term state
        searchTermRef.current = searchValue; // Update the search term ref
        handleSearch(); // Trigger the debounced search handler
    }, [handleSearch]);

    // Function to handle data from child component
    function handleDataFromChild(data) {
        setDetailsDriver(null); // Clear the selected driver details
    }

    return (
        <>
            {/* Search input field */}
            <TextField className="custom-search-field dark-field w-100 p-3" placeholder="Search" label=""
                autoComplete={"off"}
                value={searchTerm} onChange={(e) => { searchHandler(e.target.value); setDetailsDriver(null) }}
                InputProps={{
                    startAdornment: <InputAdornment position="start">
                        <IconButton size='small'>
                            <SearchIcon width='18px' height='18px' />
                        </IconButton>
                    </InputAdornment>,
                    endAdornment: searchTerm?.length > 0 ? <InputAdornment>
                        <IconButton onClick={() => searchHandler('')} size='small'>
                            <CloseRounded />
                        </IconButton>
                    </InputAdornment> : null
                }}
            />
            <div className='hd-drivers-list-inner'>
                {
                    loadingFirst && driversList?.length === 0 ?
                        <Box className='w-100 d-flex align-item-center jc-center h-100 inner-scroll'>
                            <Loader />
                        </Box>
                        :
                        <List>
                            {(driversList?.length === 0 && !loading) ? (
                                <Box className='w-100 d-flex flex-column align-item-center jc-center h-100 no-data-found'>
                                    <img style={{ maxWidth: "120px" }} src={noDataFound} alt="No Results Found" />
                                    <p className='text-12'><strong>No Drivers Found</strong></p>
                                </Box>
                            ) : (
                                driversList?.map((data, idx) => (
                                    <>
                                        <ListItem key={idx}
                                            ref={driversList?.length === idx + 1 ? lastPostElementRef : null}
                                            className='d-flex align-item-center jc-s-btwn gap-3 driver-listing-rep'
                                            style={{ cursor: "pointer" }}
                                            onClick={() => { setDetailsDriver(data?._id) }}
                                        >
                                            <div className='w-100 d-flex align-item-center flex-row gap-2'>
                                                <div className='d-flex'>
                                                    <img className='border-radius-50' src={data?.driverImage ? data?.driverImage : driverPic} alt='' width={28} height={28} />
                                                </div>
                                                <div className='w-100 d-flex flex-column'>
                                                    <Typography variant='h6' className='w-100 d-flex align-item-center gap-2 text-14 text-white-700 text-transform-capitalize' style={{ maxWidth: "195px" }}>
                                                        <span className='ellipsis-one'>{data?.driverName}</span>
                                                        {
                                                            data?.taskStatus == "in_progress" ?
                                                                <TaskTruckIcon /> : data?.taskStatus == "onBreak" ?
                                                                    <TaskBreakIcon /> : ""
                                                        }
                                                        {
                                                            data?.status && <div onClick={(e) => {
                                                                e.stopPropagation()
                                                                driverZoomOnMapRef.current?.zoomOnDriver(data)
                                                            }}>
                                                                <img height={12} width={12} src={driverMap} />
                                                            </div>
                                                        }
                                                    </Typography>
                                                    <Typography className='text-12 text-black-200 text-transform-capitalize'>
                                                        Total Tasks-{data?.taskCount ? data?.taskCount : "0"}
                                                    </Typography>
                                                </div>
                                            </div>
                                            <Typography className='d-flex align-item-center gap-1 text-12 white-space-nowrap'>
                                                <span className={data?.status ? 'dot-duty' : "dot-duty dot-off"}></span>
                                                <span className={data?.status ? 'driver-duty-txt' : 'driver-off-duty-txt'}>
                                                    {data?.status ? "On Duty" : "Off Duty"}
                                                </span>
                                            </Typography>
                                        </ListItem>
                                    </>
                                ))
                            )}
                            {
                                loading &&
                                <div className='w-100 d-flex jc-center align-item-center'>
                                    <Typography className='w-100 h-100 d-flex jc-center text-white-900 text-14 align-item-center gap-2 pt-3 pb-3'>
                                        <span>Load more....</span>
                                        <ThreeDot className="dot_loadmore" color="#0163FF" size="small" text="" textColor="" />
                                    </Typography>
                                </div>
                            }
                        </List>
                }
                {
                    detailsDriver &&
                    <DriverDetails sendDataToParent={handleDataFromChild} driverId={detailsDriver} />
                }
            </div >
        </>
    );
};

export default MapDriveList;