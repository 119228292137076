
// @mui material components
import Grid from '@mui/material/Grid';
import { Box } from '@mui/material';
import StatsAnalyzeChart from 'components/hyperdrive/analyze/StatsAnalyzeChart';
import CompletedTaskChart from 'components/hyperdrive/analyze/CompletedTaskChart';
import { useDispatch } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';
import { fetchAnalyzeData, fetchAnalyzeGraphData } from '../../../redux/slices/hyperdrive/analyze';
import { useSelector } from 'react-redux';
import { subDays, format } from 'date-fns';
import { ThreeDot } from 'react-loading-indicators';
import { formatTimestampToMapOld } from 'utilities/common';
import moment from 'moment';

function Analyze() {
  const { analyzeData = {}, analyzeGraphData = {}, loading: loading = false } = useSelector(state => state.analyze);
  const dispatch = useDispatch();
  const [completedTaskGraphFilter, setCompletedTaskGraphFilter] = useState({
    groupBy: 'last24hour' // Default grouping for the graph
  });
  // State to hold filtered data
  const [dataWithFilter, setDataWithFilter] = useState(null);

  // Handler to change the graph filter
  const changeFilterHandler = (value) => {
    setCompletedTaskGraphFilter({ ...completedTaskGraphFilter, groupBy: value });
  };

  // Memoized task statistics derived from analyzeData
  const meomizeTaskStats = useMemo(() => {
    let data = {};
    // Structure for completed tasks data
    let completedTasks = {
      failed: analyzeData?.completedTasks?.failed,
      succeeded: analyzeData?.completedTasks?.succeeded,
      total: analyzeData?.completedTasks?.total,
      taskChart: {
        datasets: [
          {
            data: [analyzeData?.completedTasks?.succeeded, analyzeData?.completedTasks?.failed],
            backgroundColor: [
              '#60EDEF',
              '#3A49F9',
            ],
            barPercentage: 0.4,
            categoryPercentage: 0.5,
            borderWidth: 0,
            cutout: '90%',
          },
        ],
      },
      tasksPerHour: analyzeData?.completedTasks?.tasksPerHour
    };
    // Structure for delayed tasks data
    let delayedTask = {
      onTime: analyzeData?.delayedTask?.onTime,
      delayedLessThan10: analyzeData?.delayedTask?.delayedLessThan10,
      delayed10To60: analyzeData?.delayedTask?.delayed10To60,
      delayedMoreThan60: analyzeData?.delayedTask?.delayedMoreThan60,
      totalDelayedOrders: analyzeData?.delayedTask?.totalDelayedOrders,
      averageDelayTimePerTask: analyzeData?.delayedTask?.averageDelayTimePerTask,
      taskChart: {
        datasets: [
          {
            data: [analyzeData?.delayedTask?.onTime, analyzeData?.delayedTask?.delayedLessThan10, analyzeData?.delayedTask?.delayed10To60, analyzeData?.delayedTask?.delayedMoreThan60], // The values for each segment
            backgroundColor: [
              '#60EDEF',
              '#3A49F9',
              '#FC7D1A',
              '#C444FF',
            ],
            barPercentage: 0.4,
            categoryPercentage: 0.5,
            borderWidth: 0,
            cutout: '90%',
          },
        ],
      },
    };
    // Structure for tasks by service time data
    let tasksByServiceTime = {
      onTime: analyzeData?.tasksByServiceTime?.onTime,
      delayedLessThan10: analyzeData?.tasksByServiceTime?.delayedLessThan10,
      delayed10To20: analyzeData?.tasksByServiceTime?.delayed10To20,
      delayedMoreThan30: analyzeData?.tasksByServiceTime?.delayedMoreThan30,
      totalDelayedOrders: analyzeData?.tasksByServiceTime?.totalDelayedOrders,
      serviceTimePerTask: analyzeData?.tasksByServiceTime?.serviceTimePerTask,
      taskChart: {
        datasets: [
          {
            data: [analyzeData?.tasksByServiceTime?.onTime, analyzeData?.tasksByServiceTime?.delayedLessThan10, analyzeData?.tasksByServiceTime?.delayed10To20, analyzeData?.tasksByServiceTime?.delayedMoreThan30], // The values for each segment
            backgroundColor: [
              '#60EDEF',
              '#3A49F9',
              '#FC7D1A',
              '#C444FF',
            ],
            barPercentage: 0.4,
            categoryPercentage: 0.5,
            borderWidth: 0,
            cutout: '90%',
          },
        ],
      },
    };
    // Aggregate all data into a single object
    data = {
      completedTasks,
      delayedTask,
      tasksByServiceTime,
      averageMiles: {
        averageOfMilesPerTask: analyzeData?.averageOfMilesPerTask,
      }
    };
    return data;// Return the structured data
  }, [analyzeData]);// Recompute when analyzeData changes

  // Generate an array of the last 30 days for labels
  const daysLast30Days = Array.from({ length: 30 }, (_, i) =>
    format(subDays(new Date(), i), 'dd-MMM-yy')
  ).reverse();// Reverse to show the most recent day first

  // Memoized data for completed orders over the last 30 days
  const completedOrdersData = useMemo(() => {
    if (!analyzeGraphData?.ordersCompleted) return;// Return if no data available
    const ordersMap = new Map();// Map to store completed orders by date

    analyzeGraphData?.ordersCompleted.forEach(order => {
      const day = format(new Date(order.date), 'dd-MMM-yy');
      ordersMap.set(day, order.completedOrders); // Store completed orders by date
    });
    // Map over the last 30 days and get the corresponding completed orders
    return daysLast30Days.map(day => ordersMap.get(day) || 0);
  }, [analyzeGraphData?.ordersCompleted]);// Recompute when ordersCompleted data changes

  // Memoized data for failed orders over the last 30 days
  const failedOrdersData = useMemo(() => {
    if (!analyzeGraphData?.ordersCompleted) return;// Return if no data available
    const ordersMap = new Map();// Map to store failed orders by date

    analyzeGraphData?.ordersCompleted.forEach(order => {
      const day = format(new Date(order.date), 'dd-MMM-yy');
      ordersMap.set(day, order.failedOrders);// Store failed orders by date
    });
    // Map over the last 30 days and get the corresponding failed orders
    return daysLast30Days.map(day => ordersMap.get(day) || 0);
  }, [analyzeGraphData?.ordersCompleted]); // Recompute when ordersCompleted data changes

  // Combine completed and failed orders data into a structured object for graphing
  const data = useMemo(() => ({
    labels: daysLast30Days,// X-axis labels
    datasets: [
      {
        label: 'Completed Orders',
        data: completedOrdersData,
        backgroundColor: '#06D7F6',// Color for completed orders
        borderColor: '#06D7F6',
        borderWidth: 1,
        barPercentage: 0.4,
        categoryPercentage: 0.5,
      },
      {
        label: 'Failed Orders',
        data: failedOrdersData,
        backgroundColor: '#4807EA', // Color for failed orders
        borderColor: '#4807EA',
        borderWidth: 1,
        barPercentage: 0.4,
        categoryPercentage: 0.5,
      }
    ]
  }), [daysLast30Days, completedOrdersData, failedOrdersData]);// Recompute when dependencies change

  // Initial fetch of analyze data based on today's date range
  useEffect(() => {
  }, [failedOrdersData]);

  const refreshStats = (filters = {}) => {
    const data = { ...completedTaskGraphFilter, ...filters };
    setDataWithFilter(data);
    dispatch(fetchAnalyzeData(filters));
    dispatch(fetchAnalyzeGraphData(data));
  };
  const today = new Date();
  const next = moment(today).add(1, 'day').toDate();

  useEffect(() => {
    const filter = {
      from: formatTimestampToMapOld(today),
      to: formatTimestampToMapOld(next)// Next day as the end of the range
    };
    dispatch(fetchAnalyzeData(filter)); // Dispatch action to fetch data
  }, []); // Run once on mount

  useEffect(() => {
    const filter = {
      from: formatTimestampToMapOld(today),
      to: formatTimestampToMapOld(next)
    };

    let data = {}; // Define data variable
    if (dataWithFilter === null) {
      data = { ...filter, ...completedTaskGraphFilter }; // Use default filters if dataWithFilter is null
    } else {
      data = { ...dataWithFilter, ...completedTaskGraphFilter };// Combine existing filters with completedTaskGraphFilter
    }

    dispatch(fetchAnalyzeGraphData(data)); // Dispatch action to fetch graph data
  }, [completedTaskGraphFilter]); // Re-run when completedTaskGraphFilter changes

  return (
    <Box p={2} className="pb-0 h-100">
      {
        loading ? <div className='loading-Spinner'>
          <ThreeDot color="#0163FF" size="medium" text="" textColor="" />
        </div> :
          <Grid container spacing={3}>
            <Grid item lg={4}>
              <StatsAnalyzeChart taskStats={meomizeTaskStats} refreshStats={refreshStats} dataWithFilter={dataWithFilter} />
            </Grid>
            <Grid item lg={8}>
              <CompletedTaskChart chartData={data} completedTaskGraphFilter={completedTaskGraphFilter} changeFilterHandler={changeFilterHandler} />
            </Grid>
          </Grid>
      }
    </Box>
  );
}

export default Analyze;
