import { Box, Button, FormControl, FormControlLabel, Menu, Typography, FormGroup, Checkbox } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import DateTask from 'components/dateRangePicker/DateTask';
import moment from 'moment';
import { useSelector } from 'react-redux';
import onBreak from '../../assets/images/break.svg';
import onTransit from '../../assets/images/inProgress.svg';

const FiltersDriverTasks = ({ setAnchorEl, setAnchorTask, anchorEl, anchorTask, open, opentask, sendDataToChild }) => {
    const [formData, setFormData] = useState({
        taskStatus: ['not_started', 'in_progress', 'completed', 'cancelled', 'unassigned'],
        driverStatus: [ 'idle', 'intransit', 'onbreak']
    });
    const { taskloading: taskloading = false } = useSelector(state => state.drivers);

    const today = new Date();
    const nextDate = moment(today).add(1, 'day').toDate();

    const [timeRange, setTimeRange] = useState({
        startDate: today,
        endDate: nextDate,
    });
    const startRangeHandler = (event) => {
        const nextDateEvent = moment(event).add(1, 'day').toDate();
        setTimeRange((prevData) => ({
            ...prevData,
            startDate: event,
            endDate: nextDateEvent
        }));
    };

    useEffect(() => {
        sendDataToChild(timeRange, formData);
    }, [timeRange, formData]);

    const handleClick = useCallback((event) => {
        setAnchorEl(event.currentTarget);
    }, []);

    const handleClickTask = useCallback((event) => {
        setAnchorTask(event.currentTarget);
    }, []);

    const handleCloseTask = useCallback(() => {
        setAnchorTask(null);
    }, []);

    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, []);

    const submithandleChange = useCallback((event) => {
        const { name, value } = event.target;
        setFormData((prevData) => {
            const isSelected = prevData[name].includes(value);
            return {
                ...prevData,
                [name]: isSelected
                    ? prevData[name].filter(item => item !== value)
                    : [...prevData[name], value]
            };
        });
    }, []);

    return (
        <Box display="flex" alignItems="center" flexDirection="row" className="time-task-driver">
            <div className='d-flex align-item-center select-rep-sec'>
                <div className='d-flex align-item-center select-calendar dark-calendar'>
                    <DateTask selected={timeRange?.startDate} onChange={startRangeHandler} name={"startDate"} placeholderText={"Start"} showInput={true} />
                </div>
            </div>
            <div className='d-flex align-item-center select-rep-sec'>
                <div className='d-flex align-item-center select-with-radio'>
                    <Button id="all-drivers" aria-controls={open ? '' : undefined} aria-haspopup="true" aria-expanded={open ? 'true' : undefined} onClick={handleClick}>
                        <span className='d-flex align-item-center radio-colors gap-1'>
                            <span className='driver-off-duty'></span>
                            <span className='driver-idle'></span>
                            <span className='driver-in-transit_' style={{marginRight:"5px"}}>
                            <img src={onTransit}  />
                            </span>
                            <span 
                            // className='driver-in-transit' style={{ backgroundColor: "#fb78e0" }}
                            ><img height={13} src={onBreak}/></span>
                        </span>
                        <Typography>All Drivers</Typography>
                        <svg xmlns="http://www.w3.org/2000/svg" width="11" height="12" viewBox="0 0 11 12" fill="none">
                            <path d="M5.5 8.5L1 4.5H10L5.5 8.5Z" fill="#5B5B65" />
                        </svg>
                    </Button>
                    <Menu id="" anchorEl={anchorEl} open={open} onClose={handleClose} MenuListProps={{ className: "hd-dropdown" }}>
                        <FormControl>
                            <FormGroup className="radio-colors">
                                <FormControlLabel className='field-off-duty'
                                    control={<Checkbox checked={formData.driverStatus.includes('offduty')} onChange={(e) => { !taskloading && submithandleChange(e, 'taskStatus'); }} name="driverStatus" value="offduty" />}
                                    label={<><span className='duty-color driver-off-duty'></span>Off-Duty</>} />
                                <FormControlLabel className='field-idle'
                                    control={<Checkbox checked={formData.driverStatus.includes('idle')} onChange={(e) => { !taskloading && submithandleChange(e, 'taskStatus'); }} name="driverStatus" value="idle" />}
                                    label={<><span className='duty-color driver-idle'></span>Idle</>} />
                                <FormControlLabel className='field-in-transit'
                                    control={<Checkbox checked={formData.driverStatus.includes("intransit")} onChange={(e) => { !taskloading && submithandleChange(e, 'taskStatus'); }} name="driverStatus" value={"intransit"} />}
                                    label={<><span><img src={onTransit} height={12} style={{marginRight:"3px"}}/></span>In Transit</>} />
                                <FormControlLabel className='field-in-transit'
                                    control={<Checkbox checked={formData.driverStatus.includes("onbreak")} onChange={(e) => { !taskloading && submithandleChange(e, 'taskStatus'); }} name="driverStatus" value={"onbreak"} />}
                                    label={<><span><img src={onBreak} /></span> Break</>} />
                            </FormGroup>
                        </FormControl>
                    </Menu>
                </div>
            </div>
            <div className='d-flex align-item-center select-rep-sec'>
                <div className='d-flex align-item-center select-with-radio'>
                    <Button id="all-tasks" aria-controls={opentask ? '' : undefined} aria-haspopup="true" aria-expanded={opentask ? 'true' : undefined} onClick={handleClickTask}>
                        <span className='d-flex align-item-center radio-task-colors gap-1'>
                            <span className='task-unassigned'></span>
                            <span className='task-assigned'></span>
                            <span className='task-intransit'></span>
                            <span className='task-succeeded'></span>
                            <span className='task-failed'></span>
                        </span>
                        <Typography>All Tasks</Typography>
                        <svg xmlns="http://www.w3.org/2000/svg" width="11" height="12" viewBox="0 0 11 12" fill="none">
                            <path d="M5.5 8.5L1 4.5H10L5.5 8.5Z" fill="#5B5B65" />
                        </svg>
                    </Button>
                    <Menu anchorEl={anchorTask} open={opentask} onClose={handleCloseTask} MenuListProps={{ className: "hd-dropdown" }}>
                        <FormControl>
                            <FormGroup className="radio-colors">
                                <FormControlLabel className='field-unassigned'
                                    control={<Checkbox checked={formData.taskStatus.includes('unassigned')} onChange={(e) => { !taskloading && submithandleChange(e, 'taskStatus'); }} name="taskStatus" value="unassigned" />}
                                    label={<><span className='task-color task-unassigned'></span>Unassigned</>} />
                                <FormControlLabel className='field-assigned'
                                    control={<Checkbox checked={formData.taskStatus.includes('not_started')} onChange={(e) => { !taskloading && submithandleChange(e, 'taskStatus'); }} name="taskStatus" value="not_started" />}
                                    label={<><span className='task-color task-assigned'></span>Assigned</>} />
                                <FormControlLabel className='field-intransit'
                                    control={<Checkbox checked={formData.taskStatus.includes('in_progress')} onChange={(e) => { !taskloading && submithandleChange(e, 'taskStatus'); }} name="taskStatus" value="in_progress" />}
                                    label={<><span className='task-color task-intransit'></span>In Transit</>} />
                                <FormControlLabel className='field-succeeded'
                                    control={<Checkbox checked={formData.taskStatus.includes('completed')} onChange={(e) => { !taskloading && submithandleChange(e, 'taskStatus'); }} name="taskStatus" value="completed" />}
                                    label={<><span className='task-color task-succeeded'></span>Succeeded</>} />
                                <FormControlLabel className='field-failed' control={<Checkbox checked={formData.taskStatus.includes('cancelled')} onChange={(e) => { !taskloading && submithandleChange(e, 'taskStatus'); }} name="taskStatus" value="cancelled" />}
                                    label={<><span className='task-color task-failed'></span>Failed</>} />
                            </FormGroup>
                        </FormControl>
                    </Menu>
                </div>
            </div>
        </Box>
    );
};

export default FiltersDriverTasks;