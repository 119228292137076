
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Box, Button, Card, Grid, Stack, Typography } from '@mui/material';
import MDButton from 'components/MDButton';
import EditIcon from 'common/customIcons/edit';
import { useEffect, useState } from 'react';
import colors from 'assets/theme/base/colors';
import CommonLayout from 'layouts/DashboardLayout';
import { useDispatch, useSelector } from 'react-redux';
import CannabinoidsList from './CannabinoidsList';
import ManageMainCannabinoidBanners from './ManageMainCannabinoidBanners';
import { getMainCannabinoid } from '../../../redux/slices/cannabinoids';

export default function ManageCannabinoids() {
  const [currentTab, setCurrentTab] = useState('');
  const dispatch = useDispatch();
  const { mainCannabinoid = {} } = useSelector(state => state.cannabinoids);
  const { search } = useLocation();
  const navigate = useNavigate();
  const [currentCannabinoidsTab, setCurrentCannabinoids] = useState('manage_cannabinoids');
  const { grey } = colors;
  const [searchParams] = useSearchParams();

  const [mount, setMount] = useState(false);
  useEffect(() => {
    if (!mount) return;
    if (search) {
      const selectedTab = search?.split('=')?.[1] || '';
      if (selectedTab !== currentTab) {
        navigate(`/manage-cannabinoids?cannabinoidTab=${selectedTab}`);
        setCurrentTab(selectedTab);
      }
    }
    else {
      navigate('/manage-cannabinoids?cannabinoidTab=main_cannabinoid');
      setCurrentTab('');
    }
  }, [search, mount]);

  useEffect(() => {
    dispatch(getMainCannabinoid());
  }, []);

  useEffect(() => {
    setMount(true);
    return () => {
      setMount(false);
    };
  }, []);

  return (
    <>
      <CommonLayout>
        {
          searchParams.get('cannabinoidTab') === 'main_cannabinoid' && <Box pt={{ xs: 2, sm: 3, lg: 4 }} pl={{ xs: 2, sm: 3, lg: 4 }} pr={{ xs: 2, sm: 3, lg: 4 }} className="cannabinoids-tabs">
            <Stack direction='row' gap={1} sx={{ background: '#ffffff' }}>
              <Button onClick={() => {
                setCurrentCannabinoids('manage_cannabinoids');
              }} variant='outlined' sx={{ color: '#2E2E3A' }} className={currentCannabinoidsTab === 'manage_cannabinoids' ? 'active' : ''} >
                Manage Cannabinoids
              </Button>
              <Button className={currentCannabinoidsTab === 'manage_banners' ? 'active' : ''} onClick={() => {
                setCurrentCannabinoids('manage_banners');
              }} variant='outlined' sx={{ color: '#2E2E3A' }}>
                Manage Banners
              </Button>
            </Stack>
          </Box>
        }
        {
          currentTab && currentTab === 'main_cannabinoid' ?
            <>
              {
                currentCannabinoidsTab === 'manage_cannabinoids' && <Box p={{ xs: 2, sm: 3, lg: 4 }}>
                  <Card sx={{ p: 3, mb: 4, borderRadius: 2.5, boxShadow: 'none' }}>
                    <Stack direction="row" justifyContent="space-between" spacing={3} mb={3}>
                      <Typography variant='h4' fontWeight="semibold" ml={.75}>Manage Cannabinoids</Typography>
                      <MDButton
                        type='button'
                        variant='outlined'
                        color='dark'
                        size="small"
                        startIcon={<EditIcon width='15px' height='15px' />}
                        onClick={() => navigate('/manage-cannabinoids/main-cannabinoid/main-page')}
                      >
                        Edit
                      </MDButton>
                    </Stack>

                    <Grid container alignItems="center" spacing={{ xs: 3, md: 5 }} mb={2}>
                      <Grid item xs={12}>
                        <Typography variant="subtitle2" fontWeight="medium" color={grey[600]} mb={0.5}>Title</Typography>
                        <Typography className="quill-text-editor-view" component="div" fontWeight="medium" variant="h6" dangerouslySetInnerHTML={{
                          __html: mainCannabinoid?.title || '-'
                        }}></Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="subtitle2" fontWeight="medium" color={grey[600]} mb={0.5}>Meta Title</Typography>
                        <Typography className="quill-text-editor-view" component="div" fontWeight="medium" variant="h6" dangerouslySetInnerHTML={{
                          __html: mainCannabinoid?.metaTitle || '-'
                        }}></Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="subtitle2" fontWeight="medium" color={grey[600]} mb={0.5}>Meta Description</Typography>
                        <Typography className="quill-text-editor-view" component="div" fontWeight="medium" variant="h6" dangerouslySetInnerHTML={{
                          __html: mainCannabinoid?.metaDescription || '-'
                        }}></Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="subtitle2" fontWeight="medium" color={grey[600]} mb={1}>Description</Typography>
                        <Typography className="quill-text-editor-view" component="div" fontWeight="medium" variant="h6" dangerouslySetInnerHTML={{
                          __html: mainCannabinoid?.description || '-'
                        }}></Typography>
                      </Grid>
                    </Grid>
                  </Card>
                </Box>
              }
              {
                currentCannabinoidsTab === 'manage_banners' && <ManageMainCannabinoidBanners />
              }

            </> : currentTab && currentTab === 'cannabinoids' ? <CannabinoidsList /> : null
        }
      </CommonLayout>
    </>
  );
}
