import axiosClientHyperDrive from "axiosClient/indexHyperdrive";
import { getQueryString } from "utilities/common";

// Main Setting API
export function fetchAllDriversLocationsApi(filters) {
    return axiosClientHyperDrive.get(`/api/v1/admin/tasks/latestFleetLocation?${getQueryString(filters)}`);
}

export function fetchAllDriversTasksApi(filters) {
    return axiosClientHyperDrive.get(`/api/v1/admin/tasks/map/list?${getQueryString(filters)}`);
}

export function fetchTaskTableApi(filters) {
    return axiosClientHyperDrive.get(`api/v1/admin/tasks/table/list?${getQueryString(filters)}`);
}
export function fetchArchiveTaskTableApi(filters) {
    return axiosClientHyperDrive.get(`api/v1/admin/tasks/archived/list?${getQueryString(filters)}`);
}

export function addTaskArchiveApi(data) {
    return axiosClientHyperDrive.put(`api/v1/admin/tasks/update/accessibility  
`, data);
}

export function fetchTaskDetailsApi(data) {
    return axiosClientHyperDrive.get(`api/v1/admin/tasks/detail?taskId=${data}`);
}

export function fetchAutomaticDriverApi(data) {
    return axiosClientHyperDrive.put(`api/v1/admin/tasks/reassignTask/${data}`);
}

export function fetchRouteTableApi(filters) {
    return axiosClientHyperDrive.get(`api/v1/admin/tasks/route/analytics?${getQueryString(filters)}`);
}

export function fetchDriverNameApi(data) {
    return axiosClientHyperDrive.get(`api/v1/fleet/name/listing?${getQueryString(data)}`);
}

export function tableUnarchiveExportApi(data) {
    return axiosClientHyperDrive.get(`api/v1/admin/tasks/table/list/export?${getQueryString(data)}`);
}

export function tableArchiveExportApi(data) {
    return axiosClientHyperDrive.get(`api/v1/admin/tasks/archived/list/export?${getQueryString(data)}`);
}

export function reassignmentTaskApi(data) {
    return axiosClientHyperDrive.post(`api/v1/admin/tasks/update/reassignment`, data);
}

export function fetchRouteExportApi(data) {
    return axiosClientHyperDrive.get(`api/v1/admin/tasks/route/analytics/export?${getQueryString(data)}`);
}

export function fetchTaskActivityLogsApi(data, taskId) {
    return axiosClientHyperDrive.get(`api/v1/admin/tasks/activity/list?taskId=${taskId}&${getQueryString(data)}`);
}