import { Box, Grid, IconButton, Typography } from '@mui/material';
import AngleRightIcon from 'common/customIcons/angle_right';
import CommonLayout from 'layouts/DashboardLayout';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CatalogProducts from './CatalogProducts';

export default function MasterCatalog({ mode }) {
  const navigate = useNavigate();
  const [selectedCatalogProducts, setSelectedCatalogProducts] = useState({});

  const catalogProductSelectionHandler = (products) => {
    let brandWiseProducts = products?.reduce((prev, curr) => {
      return { ...prev, [curr.brandSlug]: [...prev[curr?.brandSlug] || [], curr] };
    }, {});
    setSelectedCatalogProducts({ ...brandWiseProducts });
  };
  return (
    <CommonLayout>
      <Box padding={3}>
        <Box display="flex" alignItems="center" color='text.main' lineHeight={1} mb={2}>
          <IconButton onClick={() => navigate(-1)}>
            <AngleRightIcon fontSize="14px" />
          </IconButton>
          <Typography variant='h6' fontWeight="semibold" ml={.75}>Products</Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            <CatalogProducts
              mode={mode}
              catalogProductSelectionHandler={catalogProductSelectionHandler}
              selectedCatalogProducts={selectedCatalogProducts}
              setSelectedCatalogProducts={setSelectedCatalogProducts}
            />
          </Grid>
        </Grid>
      </Box>
    </CommonLayout>
  );
}
