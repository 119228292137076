import { CircularProgress, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from '@mui/material';
import MDButton from 'components/MDButton';
import React from 'react';
import { useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';

export default function ChangeOrderStatus({ closeModal }) {
  const { modalData, isLoading = false } = useSelector(state => state.modal);
  const { updateStatusHandler, deleteId } = modalData;
  return (
    <>
      <DialogTitle id="draggable-dialog-title" display="flex" alignItems="center" justifyContent="space-between">
        Update Confirmation
        <IconButton onClick={closeModal} edge='end' size='small'>
          <CloseIcon fontSize='medium' />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <DialogContentText>
          Are you sure you want to update status of this order?
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <MDButton onClick={closeModal} size="small" color="secondary">
          Cancel
        </MDButton>
        <MDButton onClick={() => updateStatusHandler(deleteId)} size="small" color='error'>
          {
            isLoading ? <CircularProgress size={16} color='light' /> : 'Update'
          }
        </MDButton>
      </DialogActions>
    </>
  );
}