import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { fetchHyperwolfOrdersAPI, fetchOrderDetailsbyIdAPI } from "../../../redux/apis/hyperwolf/orders"


export const fetchHyperwolfOrders = createAsyncThunk(
    'hyperwolf/orders',
    async (filters, { rejectWithValue }) => {
        try {
            const res = await fetchHyperwolfOrdersAPI(filters)
            return res
        } catch (error) {
            console.error("erro", error.response)
            return rejectWithValue(error?.response?.data)
        }
    }
)

export const fetchOrderDetailsById = createAsyncThunk(
    'hyperwolf/orderDetails',
    async (id, { rejectWithValue }) => {
        try {
            const res = await fetchOrderDetailsbyIdAPI(id)
            return res
        } catch (error) {
            console.error("erro", error.response)
            return rejectWithValue(error?.response?.data)
        }
    }
)

export const fetchHyperwolfOrdersByCuid = createAsyncThunk(
    'hyperwolf/ordersByCuid',
    
)


const hyperwolfOrderSlice = createSlice({
    name: 'orders',
    initialState: {
    orders: [],
    isLoading: false,
    error: null, 
    order: {},
},
reducers: {
    // You can define additional reducers here if needed
},
extraReducers: (builder) => {
    builder.addCase(fetchHyperwolfOrders.pending, (state) => {
        state.isLoading = true;
        state.error = null;
    })
    .addCase(fetchHyperwolfOrders.fulfilled, (state, action) => {
        state.isLoading = false; // Set isLoading to false when fetching completes
        state.orders = action.payload; // Update orders with the fetched data
    })
    .addCase(fetchHyperwolfOrders.rejected, (state, action) => {
        state.isLoading = false; 
        state.error = action.payload; // Update error in case of failure
    });

    builder.addCase(fetchOrderDetailsById.pending, (state) => {
        state.isLoading = true;
        state.error = null;
    })
    .addCase(fetchOrderDetailsById.fulfilled, (state,action) => {
        state.isLoading = false;
        state.order = action.payload.data.orderDetails;
    } )
    .addCase(fetchOrderDetailsById.rejected, (state,action) => {
        state.isLoading = false;
        state.error = action.payload.data.orderDetails
    })

},
})

export const { /* any additional actions */ } = hyperwolfOrderSlice.actions;
export default hyperwolfOrderSlice.reducer;