import { axiosHyperwolfDevClient } from "axiosClient";
import { getQueryString } from "utilities/common";


export async function fetchHyperwolfMembersAPI(filters) {

    return await axiosHyperwolfDevClient.get(`api/v1/member?${getQueryString(filters)}`)
}


export async function fetchMemberByEmailAPI(email) {
    
    return await axiosHyperwolfDevClient.get(`api/v1/member/${email}`)
}

//fetch Member all orders
export async function fetchHyperwolfMemberOrdersByCuidAPI(cuid, filters) {

    return await axiosHyperwolfDevClient.get(`api/v1/member/orders?cuid=${cuid}&${getQueryString(filters)}`)
}