 

// Material Dashboard 2 React Base Styles
import colors from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";
import typography from "assets/theme/base/typography";

// Material Dashboard 2 React helper functions
import pxToRem from "assets/theme/functions/pxToRem";

const { dark, inputBorderColor, info, grey, transparent, white, primary } = colors;
const { borderRadius } = borders;
const { size } = typography;

const inputOutlined = {
  styleOverrides: {
    root: {
      // backgroundColor: white.main,
      backgroundColor: "#fdfdfd",
      fontSize: size.md,
      borderRadius: borderRadius.section,

      "&:hover .MuiOutlinedInput-notchedOutline": {
        // borderColor: inputBorderColor,
        borderColor: grey[600],
      },

      "&.Mui-focused": {
        backgroundColor: white.main,
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: primary.main,
        },
      },
    },

    notchedOutline: {
      // borderColor: inputBorderColor,
      borderColor: grey[500],
      // borderColor: transparent.main,
      borderRadius: borderRadius.section,
    },

    input: {
      color: dark.main,
      // padding: pxToRem(12),
      padding: `${pxToRem(12)} ${pxToRem(20)}`,
      
      // backgroundColor: white.main,
      backgroundColor: "#fdfdfd",
      borderRadius: borderRadius.section,
      "&:focus": {
        backgroundColor: white.main,
      }
    },

    inputSizeSmall: {
      fontSize: size.xs,
      padding: pxToRem(10),
    },

    multiline: {
      color: dark.main,
      padding: 0,
    },
  },
};

export default inputOutlined;
