import React from 'react';

export default function RibbonBadgeIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.93676 13.5586L0.0781634 16.7773C0.0279671 16.8639 0.000768867 16.9619 -0.000839772 17.0619C-0.00244841 17.1619 0.0215861 17.2607 0.0689737 17.3488C0.116361 17.437 0.185524 17.5115 0.26987 17.5653C0.354217 17.6191 0.450939 17.6504 0.55082 17.6563L2.87426 17.7957L4.15668 19.7379C4.21016 19.8185 4.28277 19.8847 4.36805 19.9304C4.45332 19.9762 4.54859 20.0001 4.64535 20H4.66293C4.76274 19.997 4.86014 19.9686 4.94587 19.9174C5.0316 19.8662 5.10282 19.7939 5.15277 19.7074L6.52973 17.3246C5.29265 16.8428 4.16975 16.1082 3.23285 15.1676C2.74452 14.6792 2.31 14.1397 1.93676 13.5586Z" fill="currentColor"/>
      <path d="M19.9219 16.7773L18.0637 13.5586C17.6903 14.1391 17.2558 14.6778 16.7676 15.1656C15.8307 16.1062 14.7078 16.8409 13.4707 17.3227L14.8476 19.7055C14.8973 19.7922 14.9684 19.8649 15.054 19.9164C15.1397 19.968 15.2372 19.9968 15.3371 20H15.3547C15.4515 19.9999 15.5469 19.9758 15.6322 19.9299C15.7174 19.884 15.79 19.8176 15.8433 19.7367L17.1258 17.7945L19.4492 17.6563C19.5491 17.6504 19.6458 17.6191 19.7302 17.5653C19.8145 17.5115 19.8837 17.437 19.9311 17.3488C19.9784 17.2607 20.0025 17.1619 20.0009 17.0619C19.9993 16.9619 19.9721 16.8639 19.9219 16.7773Z" fill="currentColor"/>
      <path d="M18.3984 8.39833C18.398 6.7376 17.9052 5.11427 16.9823 3.73355C16.0595 2.35284 14.748 1.27673 13.2136 0.641249C11.6793 0.00577164 9.99099 -0.160542 8.36214 0.163333C6.73329 0.487208 5.23702 1.28673 4.06249 2.46083C2.53731 4.04566 1.69473 6.16557 1.71583 8.36498C1.73692 10.5644 2.62 12.6677 4.17528 14.223C5.73057 15.7783 7.83393 16.6614 10.0333 16.6825C12.2328 16.7036 14.3527 15.861 15.9375 14.3358C16.7199 13.558 17.3402 12.6327 17.7626 11.6136C18.1851 10.5944 18.4012 9.50157 18.3984 8.39833ZM14.9949 7.68935L13.1672 9.47763L13.5988 12.0034C13.6285 12.1737 13.6098 12.3489 13.545 12.5091C13.4801 12.6693 13.3717 12.8081 13.2319 12.9098C13.0922 13.0115 12.9267 13.0719 12.7543 13.0843C12.5819 13.0968 12.4095 13.0606 12.2566 12.98L9.99999 11.7874L7.74335 12.9784C7.59047 13.059 7.41806 13.0952 7.24567 13.0828C7.07328 13.0704 6.90782 13.0099 6.76806 12.9082C6.62831 12.8065 6.51985 12.6677 6.455 12.5075C6.39015 12.3473 6.37149 12.1721 6.40116 12.0018L6.83085 9.47607L5.00312 7.68779C4.87985 7.56712 4.79273 7.41443 4.75158 7.24691C4.71042 7.0794 4.71686 6.90372 4.77018 6.73967C4.8235 6.57563 4.92157 6.42973 5.05334 6.31841C5.18511 6.2071 5.34535 6.1348 5.51601 6.10966L8.04179 5.74208L9.17069 3.4456C9.2468 3.29056 9.36485 3.15996 9.51143 3.06862C9.65802 2.97728 9.82728 2.92886 9.99999 2.92886C10.1727 2.92886 10.342 2.97728 10.4885 3.06862C10.6351 3.15996 10.7532 3.29056 10.8293 3.4456L11.9582 5.74208L14.482 6.11044C14.6527 6.13558 14.8129 6.20788 14.9447 6.3192C15.0765 6.43051 15.1745 6.57641 15.2278 6.74046C15.2812 6.9045 15.2876 7.08018 15.2465 7.2477C15.2053 7.41521 15.1182 7.56868 14.9949 7.68935Z" fill="currentColor"/>
      <path d="M10.9641 6.37657L9.99999 4.41407L9.03593 6.375C8.9699 6.50984 8.872 6.62655 8.75071 6.71503C8.62942 6.80352 8.48839 6.86111 8.33984 6.88282L6.17968 7.19766L7.74452 8.72891C7.85149 8.83398 7.93134 8.96346 7.9772 9.10622C8.02307 9.24897 8.03357 9.40073 8.0078 9.54844L7.63905 11.7074L9.56757 10.6918C9.70033 10.6216 9.84824 10.5849 9.99843 10.5849C10.1486 10.5849 10.2965 10.6216 10.4293 10.6918L12.3578 11.7074L11.9922 9.54844C11.9668 9.40078 11.9777 9.24918 12.0238 9.10664C12.0699 8.96409 12.15 8.83488 12.257 8.73008L13.8219 7.19883L11.6617 6.88399C11.5129 6.86256 11.3716 6.80514 11.25 6.71671C11.1284 6.62829 11.0303 6.51153 10.9641 6.37657Z" fill="currentColor"/>
    </svg>    
  );
}