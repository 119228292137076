 

// Material Dashboard 2 React Base Styles
import colors from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";

const { transparent } = colors;
const { borderRadius } = borders;

const textField = {
  styleOverrides: {
    root: {
      backgroundColor: transparent.main,
      borderRadius: borderRadius.section,
      ".Mui-disabled": {
        opacity: .9,
        pointerEvents: "none"
      },
    },
  },
};

export default textField;
