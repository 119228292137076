import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
    addOrUpdateStrainAPI,
    deleteStrainAPI,
    getAllInfoEffectAPI,
    getStrainsAPI,
    uploadImageAPI,
    getFlavorsAPI,
    getMainStrainsAPI,
    updateMainStrainAPI
} from '../../apis/hyperwolf/strains';

// Async Thunks
export const getAllHyperwolfStrains = createAsyncThunk(
    'hyperwolfStrains/getAllHyperwolfStrains',
    async (data, { rejectWithValue }) => {
        try {
            const response = await getStrainsAPI(data);
            return response?.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const addOrUpdateStrain = createAsyncThunk(
    'hyperwolfStrains/addOrUpdateStrain',
    async ({ data, id }, { rejectWithValue }) => {
        try {
            const response = await addOrUpdateStrainAPI(data, id);
            return response?.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const deleteStrain = createAsyncThunk(
    'hyperwolfStrains/deleteStrain',
    async (id, { rejectWithValue }) => {
        try {
            const response = await deleteStrainAPI(id);
            return response?.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getAllInfoEffect = createAsyncThunk(
    'hyperwolfStrains/getAllInfoEffect',
    async (_, { rejectWithValue }) => {
        try {
            const response = await getAllInfoEffectAPI();
            return response?.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const uploadStrainImage = createAsyncThunk(
    'hyperwolfStrains/uploadStrainImage',
    async (data, { rejectWithValue }) => {
        try {
            const response = await uploadImageAPI(data);
            return response?.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getAllFlavors = createAsyncThunk(
    'hyperwolfStrains/getAllFlavors',
    async (data, { rejectWithValue }) => {
        try {
            const response = await getFlavorsAPI(data);
            return response?.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getAllMainStrains = createAsyncThunk(
    'hyperwolfStrains/getAllMainStrains',
    async (data, { rejectWithValue }) => {
        try {
            const response = await getMainStrainsAPI(data);
            return response?.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const updateMainStrain = createAsyncThunk(
    'hyperwolfStrains/updateMainStrain',
    async (data, { rejectWithValue }) => {
        try {
            const response = await updateMainStrainAPI(data);
            return response?.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

// Slice
const hyperwolfStrainsSlice = createSlice({
    name: 'hyperwolfStrains',
    initialState: {
        allStrains: [],
        isLoading: false,
        infoEffects: [],
        allFlavorsData: [],
        allMainStrains: [],
        singleStrain:{},
        error: null,
    },
    reducers: {
        setStrainInformation: (state, action) => {
            state.singleStrain = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            // getAllHyperwolfStrains
            .addCase(getAllHyperwolfStrains.pending, (state) => {
                state.isLoading = true;
                state.error = null;
                state.singleStrain = {}
            })
            .addCase(getAllHyperwolfStrains.fulfilled, (state, action) => {
                state.isLoading = false;
                state.allStrains = action.payload || [];
            })
            .addCase(getAllHyperwolfStrains.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            // addOrUpdateStrain
            .addCase(addOrUpdateStrain.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(addOrUpdateStrain.fulfilled, (state, action) => {
                state.isLoading = false;
            })
            .addCase(addOrUpdateStrain.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            // deleteStrain
            // .addCase(deleteStrain.pending, (state) => {
            //     state.isLoading = true;
            //     state.error = null;
            // })
            // .addCase(deleteStrain.fulfilled, (state, action) => {
            //     state.isLoading = false;
            // })
            // .addCase(deleteStrain.rejected, (state, action) => {
            //     state.isLoading = false;
            //     state.error = action.payload;
            // })
            // getAllInfoEffect
            .addCase(getAllInfoEffect.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(getAllInfoEffect.fulfilled, (state, action) => {
                state.isLoading = false;
                state.infoEffects = action.payload || [];
            })
            .addCase(getAllInfoEffect.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            // uploadStrainImage
            .addCase(uploadStrainImage.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(uploadStrainImage.fulfilled, (state, action) => {
                state.isLoading = false;
            })
            .addCase(uploadStrainImage.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            // getAllFlavors
            .addCase(getAllFlavors.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(getAllFlavors.fulfilled, (state, action) => {
                state.isLoading = false;
                state.allFlavorsData = action.payload?.strainsFlavors || [];
            })
            .addCase(getAllFlavors.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            // getAllMainStrains
            .addCase(getAllMainStrains.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(getAllMainStrains.fulfilled, (state, action) => {
                state.isLoading = false;
                state.allMainStrains = action.payload || [];
            })
            .addCase(getAllMainStrains.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            // updateMainStrain
            .addCase(updateMainStrain.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(updateMainStrain.fulfilled, (state, action) => {
                state.isLoading = false;
            })
            .addCase(updateMainStrain.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            });
    }
});
export const { setStrainInformation } = hyperwolfStrainsSlice.actions;


export default hyperwolfStrainsSlice.reducer;
