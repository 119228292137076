import axios from "axios";
import { axiosHyperwolfDevClient } from "axiosClient";
import { axiosHyperwolfClient } from "axiosClient";
import { getQueryString } from "utilities/common";

export async function fetchApprovalsAPI(filters) {

    return await axiosHyperwolfDevClient.get(`/api/v1/approval/list?${getQueryString(filters)}`, )
}

export async function submitNotificationAPI(data) { 
   return await axiosHyperwolfDevClient.post('/api/v1/approval/submit',data)
}