import CommonLayout from 'layouts/DashboardLayout';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import HyperwolfOrderDetails from 'components/hyperwolf/orders/Orders';
import { fetchOrderDetailsById } from '../../../../redux/slices/hyperwolf/orders';

export default function HyperwolfOrderDetailsPage() {
  const dispatch = useDispatch();
  const { orderId = '' } = useParams();
  useEffect(() => {
    if (orderId) {
      dispatch(fetchOrderDetailsById(orderId));
    }
  }, [orderId]);
  return (
    <CommonLayout>
      <HyperwolfOrderDetails />
    </CommonLayout>
  );
}