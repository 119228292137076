import axiosClient from "axiosClient";
import { getQueryString } from "utilities/common";

export function getBannersListsAPI(data) {
    return axiosClient.get(`api/v1/admin/banner/pagination?${getQueryString(data)}`);
}

export function getsingleBannerAPI( id) {
    return axiosClient.get(`api/v1/admin/banner/${id}`);
}


export function updateBannerListAPI(data, id) {
    if (id) {
        return axiosClient.put(`api/v1/admin/banner/${id}`, data);
    }
    return axiosClient.post('api/v1/admin/banner', data);
}

export function deleteBannerAPI(id) {
    return axiosClient.delete(`api/v1/admin/banner/${id}`)
}
