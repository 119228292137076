import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getEmployeesAPI, addEmployeeAPI, deleteEmployeeAPI } from '../../apis/hyperwolf/employee';

// Async thunks
export const getAllEmployee = createAsyncThunk(
  'employee/getAllEmployee',
  async (data, { rejectWithValue }) => {
    try {
      const response = await getEmployeesAPI(data);
      return response?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addEmployee = createAsyncThunk(
  'employee/addEmployee',
  async ({ data, id }, { rejectWithValue }) => {
    try {
      const response = await addEmployeeAPI(data, id);
      return response?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteEmployee = createAsyncThunk(
  'employee/deleteEmployee',
  async (id, { rejectWithValue }) => {
    try {
      const response = await deleteEmployeeAPI(id);
      return response?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Slice
const employeeSlice = createSlice({
  name: 'employee',
  initialState: {
    employees: {},
    loading: false,
    error: null,
    singleEmployee:{}
  },
  reducers: {
    setEmployeeInformation:(state,action)=>{
      state.singleEmployee = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      // Handle getAllEmployee
      .addCase(getAllEmployee.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.singleEmployee={}
      })
      .addCase(getAllEmployee.fulfilled, (state, action) => {
        state.loading = false;
        state.employees = action.payload;
      })
      .addCase(getAllEmployee.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Handle addEmployee
      .addCase(addEmployee.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addEmployee.fulfilled, (state, action) => {
        state.loading = false;
        // state.employees.push(action.payload);
      })
      .addCase(addEmployee.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Handle deleteEmployee
      // .addCase(deleteEmployee.pending, (state) => {
      //   state.loading = true;
      //   state.error = null;
      // })
      // .addCase(deleteEmployee.fulfilled, (state, action) => {
      //   state.loading = false;
      // })
      // .addCase(deleteEmployee.rejected, (state, action) => {
      //   state.loading = false;
      //   state.error = action.payload;
      // });
  },
});


export const {setEmployeeInformation} = employeeSlice.actions;
export default employeeSlice.reducer;
