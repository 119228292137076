import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axiosClient from "axiosClient"
import { fetchDriversRegionsAPI } from "../../../apis/driverRegions"
import { fetchDriverAssignedRegionAPI, fetchDriverRegionAPI } from "../../../apis/hyperwolf/Driver/regions"

export const fetchDriversRegions = createAsyncThunk(
    'drivers/fetchDriverRegions',
    async( filters, {rejectWithValue}) => {
        try {
            const res = await fetchDriversRegionsAPI(filters)
            return res
        } catch(error) {
            console.error("erro", error.response)
            return rejectWithValue(error?.response?.data)
        }
    }
)


export const fetchDriverAssignedRegion = createAsyncThunk(
    'drivers/fetchDriverRegion',
    async (terminalId, {rejectWithValue}) => {
        try {
            const res = await fetchDriverAssignedRegionAPI(terminalId)
            return res
        } catch(error) {
            console.error("erro", error.response)
            return rejectWithValue(error?.response?.data)
        }
    }
)