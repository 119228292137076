import ProductDetailsPage from 'components/products/productDetails';
import CommonLayout from 'layouts/DashboardLayout';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchSingleProduct } from '../../../redux/slices/products';

export default function ProductDetails() {
  const dispatch = useDispatch();
  const { productId = '' } = useParams();
  useEffect(() => {
    if (productId) {
      dispatch(fetchSingleProduct(productId));

    }
  }, [productId]);
  return (
    <CommonLayout>
      <ProductDetailsPage />
    </CommonLayout>
  );
}