import AddMainCannabinoidsForm from 'components/cannabinoids/addMainCannabinoidsForm';
import CommonLayout from 'layouts/DashboardLayout';
import React from 'react';

export default function MainPageCannbinoid() {
  return (
    <CommonLayout>
      <AddMainCannabinoidsForm/>
    </CommonLayout>
  );
}
