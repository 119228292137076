import { axiosHyperwolfClient } from "axiosClient";
import { getQueryString } from "utilities/common";

export function getStrainsAPI(data) {
    return axiosHyperwolfClient.get(`api/v1/strain?type=admin?${getQueryString(data)}`);
}

export function addOrUpdateStrainAPI(data, id = '') {
    if (id) {
        return axiosHyperwolfClient.put(`api/v1/strain/update/${id}`, data);
    }
    return axiosHyperwolfClient.post('api/v1/strain/create', data);
}

export function deleteStrainAPI(id) {
    return axiosHyperwolfClient.delete(`api/v1/strain/delete/strain/${id}`)
}

export function getAllInfoEffectAPI() {
    return axiosHyperwolfClient.get(`api/v1/admin/products/infoEffects`)
}

export function uploadImageAPI(data) {
    return axiosHyperwolfClient.post(`api/v1/strain/upload/image`, data)
}

export function getFlavorsAPI(data) {
    return axiosHyperwolfClient.get('api/v1/strain/get/all/flavors', data);
}

// Main Strain API
export function getMainStrainsAPI(data) {
    return axiosHyperwolfClient.get('api/v1/strain/main/page', data);
}

export function updateMainStrainAPI(data) {
    return axiosHyperwolfClient.post(`api/v1/strain/update/main/metas`, data);
}