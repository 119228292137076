import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { addTaskArchiveApi, fetchAllDriversLocationsApi, fetchAllDriversTasksApi, fetchArchiveTaskTableApi, fetchAutomaticDriverApi, fetchDriverNameApi, fetchRouteExportApi, fetchRouteTableApi, fetchTaskActivityLogsApi, fetchTaskDetailsApi, fetchTaskTableApi, reassignmentTaskApi, tableArchiveExportApi, tableUnarchiveExportApi } from '../../../redux/apis/hyperdrive/drivers';

const initialState = {
  driversLocation: [],
  loading: false,
  error: null,
  flavors: [],
  getTaskDrive: {},
  liveDriverLocations: {},
  isFetchingLiveDrivers: false
};

export const fetchAllDriversLocations = createAsyncThunk(
  'common/fetchAllDriversLocations',
  async (data, { rejectWithValue }) => {
    try {
      const res = await fetchAllDriversTasksApi(data);
      return res;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const fetchLiveDriversLocations = createAsyncThunk(
  'common/fetchLiveDriversLocations',
  async (data, { rejectWithValue }) => {
    try {
      const res = await fetchAllDriversLocationsApi(data);
      return res?.data;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const fetchTaskDetails = createAsyncThunk(
  'common/fetchTaskDetails',
  async (data, { rejectWithValue }) => {
    try {
      const res = await fetchTaskDetailsApi(data);
      return res;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const fetchAutomaticDriver = createAsyncThunk(
  'common/fetchAutomaticDriver',
  async (data, { rejectWithValue }) => {
    try {
      const res = await fetchAutomaticDriverApi(data);
      return res;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const fetchTaskTable = createAsyncThunk(
  'common/fetchTaskTable',
  async ({ data }, { rejectWithValue }) => {
    try {
      const res = await fetchTaskTableApi(data);
      return res;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const fetchArchiveTaskTable = createAsyncThunk(
  'common/fetchTaskTable',
  async ({ data }, { rejectWithValue }) => {
    try {
      const res = await fetchArchiveTaskTableApi(data);
      return res;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const addTaskArchive = createAsyncThunk(
  'common/addTaskArchive',
  async ({ data }, { rejectWithValue }) => {
    try {
      const res = await addTaskArchiveApi(data);
      return res;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const fetchRouteTable = createAsyncThunk(
  'common/fetchRouteTable',
  async ({ data }, { rejectWithValue }) => {
    try {
      const res = await fetchRouteTableApi(data);
      return res;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const fetchDriverName = createAsyncThunk(
  'common/fetchDriverName',
  async (data, { rejectWithValue }) => {
    try {
      const res = await fetchDriverNameApi(data);
      return res;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const tableUnarchiveExport = createAsyncThunk(
  'common/tableUnarchiveExport',
  async ({data}, { rejectWithValue }) => {
    try {
      const res = await tableUnarchiveExportApi(data);
      return res;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const tableArchiveExport = createAsyncThunk(
  'common/tableArchiveExport',
  async ({data}, { rejectWithValue }) => {
    try {
      const res = await tableArchiveExportApi(data);
      return res;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const reassignmentTask = createAsyncThunk(
  'common/reassignmentTask',
  async ({ data }, { rejectWithValue }) => {
    try {
      const res = await reassignmentTaskApi(data);
      return res;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const fetchRouteExport = createAsyncThunk(
  'common/fetchRouteExport',
  async ({data}, { rejectWithValue }) => {
    try {
      const res = await fetchRouteExportApi(data);
      return res;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const fetchTaskActivityLogs = createAsyncThunk(
  'common/fetchTaskActivityLogs',
  async ({ data, taskId }, { rejectWithValue }) => {
    try {
      const res = await fetchTaskActivityLogsApi(data, taskId);
      return res;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

const driversSlice = createSlice({
  name: 'drivers',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchLiveDriversLocations.pending, (state) => {
        state.isFetchingLiveDrivers = true;
        state.error = null;
      })
      .addCase(fetchLiveDriversLocations.fulfilled, (state, action) => {
        state.isFetchingLiveDrivers = false;
        state.liveDriverLocations = action.payload;
      })
      .addCase(fetchLiveDriversLocations.rejected, (state, action) => {
        state.isFetchingLiveDrivers = false;
        state.error = action.payload;
      })
    builder
      .addCase(fetchAllDriversLocations.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllDriversLocations.fulfilled, (state, action) => {
        state.loading = false;
        state.driversLocation = action.payload;
      })
      .addCase(fetchAllDriversLocations.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

    builder
      .addCase(fetchTaskTable.pending, (state) => {
        state.taskloading = true;
        state.error = null;
      })
      .addCase(fetchTaskTable.fulfilled, (state, action) => {
        state.taskloading = false;
        state.taskTable = action.payload;
      })
      .addCase(fetchTaskTable.rejected, (state, action) => {
        state.taskloading = false;
        state.error = action.payload;
      })


    builder
      .addCase(fetchTaskDetails.pending, (state) => {
        state.taskLoading = true;
        state.error = null;
      })
      .addCase(fetchTaskDetails.fulfilled, (state, action) => {
        state.taskLoading = false;
        state.taskDetails = action.payload;
      })
      .addCase(fetchTaskDetails.rejected, (state, action) => {
        state.taskLoading = false;
        state.error = action.payload;
      })

    builder
      .addCase(fetchRouteTable.pending, (state) => {
        state.tableloading = true;
        state.error = null;
      })
      .addCase(fetchRouteTable.fulfilled, (state, action) => {
        state.tableloading = false;
        state.routeTable = action.payload;
      })
      .addCase(fetchRouteTable.rejected, (state, action) => {
        state.tableloading = false;
        state.error = action.payload;
      })

    builder
      .addCase(fetchDriverName.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDriverName.fulfilled, (state, action) => {
        state.loading = false;
        state.driverName = action.payload;
      })
      .addCase(fetchDriverName.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

    builder
      .addCase(tableUnarchiveExport.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(tableUnarchiveExport.fulfilled, (state, action) => {
        state.loading = false;
        state.tableUnarchive = action.payload;
      })
      .addCase(tableUnarchiveExport.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
    builder
      .addCase(tableArchiveExport.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(tableArchiveExport.fulfilled, (state, action) => {
        state.loading = false;
        state.tableArchive = action.payload;
      })
      .addCase(tableArchiveExport.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })


    builder
      .addCase(reassignmentTask.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(reassignmentTask.fulfilled, (state, action) => {
        state.loading = false;
        state.reassignmentTaskData = action.payload;
      })
      .addCase(reassignmentTask.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

    builder
      .addCase(fetchRouteExport.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchRouteExport.fulfilled, (state, action) => {
        state.loading = false;
        state.routeExport = action.payload;
      })
      .addCase(fetchRouteExport.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

    builder
      .addCase(fetchTaskActivityLogs.pending, (state) => {
        state.isTaskLoading = true;
        state.error = null;
      })
      .addCase(fetchTaskActivityLogs.fulfilled, (state, action) => {
        state.isTaskLoading = false;
        state.taskLoadingData = action.payload;
      })
      .addCase(fetchTaskActivityLogs.rejected, (state, action) => {
        state.isTaskLoading = false;
        state.error = action.payload;
      })

    builder
      .addCase(fetchAutomaticDriver.pending, (state) => {
        state.loadingFetch = true;
        state.error = null;
      })
      .addCase(fetchAutomaticDriver.fulfilled, (state, action) => {
        state.loadingFetch = false;
        state.taskActivityLogs = action.payload;
      })
      .addCase(fetchAutomaticDriver.rejected, (state, action) => {
        state.loadingFetch = false;
        state.error = action.payload;
      })
  },
});

export const { } = driversSlice.actions;

export default driversSlice.reducer;