import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getFlavorsAPI, getStrainsAPI, addOrUpdateStrainAPI, deleteStrainAPI, getMainStrainsAPI, updateMainStrainAPI, getSingleStrainAPI } from '../../redux/apis/strain';

const initialState = {
  strains: [],
  loading: false,
  error: null,
  flavors: [],
  singleStrain: {}

};

export const getStrainList = createAsyncThunk(
  'strain/getStrainList',
  async (filters, { rejectWithValue }) => {
    try {
      const res = await getStrainsAPI(filters);
      return res;
    } catch (error) {
 
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getSingleStrain = createAsyncThunk(
  'strain/getSingleStrain',
  async (strainId, { rejectWithValue }) => {
    try {
      const res = await getSingleStrainAPI(strainId);
      return res;
    } catch (error) {
 
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getMainStrain = createAsyncThunk(
  'strain/getMainStrain',
  async (filters, { rejectWithValue }) => {
    try {
      const res = await getMainStrainsAPI(filters);
      return res;
    } catch (error) {
 
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getFlavoursList = createAsyncThunk(
  'strain/getFlavoursList',
  async (data, { rejectWithValue }) => {
    try {
      const res = await getFlavorsAPI();
      return res;
    } catch (error) {
 
      return rejectWithValue(error.response?.data);
    }
  }
);


export const createStrain = createAsyncThunk(
  'strain/createStrain',
  async (data, { rejectWithValue }) => {
    try {
      const res = await addOrUpdateStrainAPI(data, '');
      return res;
    } catch (error) {
 
      return rejectWithValue(error.response?.data);
    }
  }
);

export const updateStrain = createAsyncThunk(
  'strain/updateStrain',
  async ({ data, strainId }, { rejectWithValue }) => {
    try {
      const res = await addOrUpdateStrainAPI(data, strainId);
      return res;
    } catch (error) {
 
      return rejectWithValue(error.response?.data);
    }
  }
);

export const updateMainStrain = createAsyncThunk(
  'strain/updateMainStrain',
  async (data, { rejectWithValue }) => {
    try {
      const res = await updateMainStrainAPI(data);
      return res;
    } catch (error) {
 
      return rejectWithValue(error.response?.data);
    }
  }
);

export const deleteStrain = createAsyncThunk(
  'strain/deleteStrain',
  async (strainId, { rejectWithValue }) => {
    try {
      const res = await deleteStrainAPI(strainId);
      return res;
    } catch (error) {
 
      return rejectWithValue(error.response?.data);
    }
  }
);



export const fetchSingleStrain = createAsyncThunk(
  'strain/fetchSingleStrain',
  async ({ data, strainId }, { rejectWithValue }) => {
    try {
      const res = await addOrUpdateStrainAPI(data, strainId);
      return res;
    } catch (error) {
 
      return rejectWithValue(error.response?.data);
    }
  }
);

const strainSlice = createSlice({
  name: 'strain',
  initialState,
  reducers: {
    setSingleStrain: (state, action) => {
      return { ...state, singleStrain: action.payload }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStrainList.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.singleStrain = {}
      })
      .addCase(getStrainList.fulfilled, (state, action) => {
        state.loading = false;
        state.strains = action.payload;
      })
      .addCase(getStrainList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getFlavoursList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getFlavoursList.fulfilled, (state, action) => {
        state.loading = false;
        state.flavors = action.payload.strainsFlavors;
      })
      .addCase(getFlavoursList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(createStrain.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createStrain.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(createStrain.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateStrain.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateStrain.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(updateStrain.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getMainStrain.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getMainStrain.fulfilled, (state, action) => {
        state.loading = false;
        state.mainStrainData = action.payload;
      })
      .addCase(getMainStrain.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      }).addCase(updateMainStrain.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateMainStrain.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(updateMainStrain.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getSingleStrain.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getSingleStrain.fulfilled, (state, action) => {
        state.loading = false;
        state.singleStrain = action.payload.strainDetails;
      })
      .addCase(getSingleStrain.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // The error payload from the rejectWithValue
      });

  },
});


export const { setSingleStrain } = strainSlice.actions;

export default strainSlice.reducer;
