import { Badge, Box, List, Grid, ListItem, Typography } from '@mui/material'
import StatusTask from 'common/component/StatusTask'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { getDriverAnalyticsInfo } from '../../../redux/slices/hyperdrive/driverAnalytics';
import { useDispatch } from 'react-redux';
import noDataFound from 'assets/images/nothing-found.svg';
import { openCustomDrawer } from '../../../redux/slices/modalSlice';
import Loader from 'common/Loader';
import { separateWords } from 'utilities/common';
import { formatTimestampToDate24Hours } from 'utilities/common';
import LateOrderIcon from 'common/customIcons/lateorder';
import OnTimeIcon from 'common/customIcons/onTime';
import { convertMinutesToHoursAndMinutes } from 'utilities/common';
import { formatTimestampToMapOld } from 'utilities/common';
import moment from 'moment';

const DriverTask = ({ driverId }) => {
    const dispatch = useDispatch()
    const [posts, setPosts] = useState([]);
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(false);
    const observer = useRef();
    const today = new Date();
    const next = moment(today).add(1, 'day').toDate()
    const todayDate = formatTimestampToMapOld(today)
    const nextDate = formatTimestampToMapOld(next)
    const loadMorePosts = useCallback(async (driverId) => {
        setLoading(true);
        try {
            const limit = 20;
            const skip = page * limit;
            const res = await dispatch(getDriverAnalyticsInfo({
                driverId,
                data: {
                    analyticType: "tasks",
                    skip,
                    limit: 20,
                    from: todayDate ? todayDate : "",
                    to: todayDate ? todayDate : ""
                }
            })).unwrap();
            let newPosts = res?.data?.tasks
            if (newPosts?.length === 0) {
                setHasMore(false);
            } else {
                setPosts((prevPosts) => [...prevPosts, ...newPosts]);
                setHasMore(false);
            }
        } catch (err) {
            setHasMore(false); setLoading(false);
        } finally {
            setHasMore(false);
            setLoading(false);
        }
    }, [dispatch, page]);

    useEffect(() => {
        setPage(0)
        setPosts([])
        setHasMore(true);
    }, [driverId])

    useEffect(() => {
        if (hasMore) {
            loadMorePosts(driverId);
        }
    }, [loadMorePosts, hasMore]);

    const lastPostElementRef = useCallback(
        (node) => {
            if (loading || !hasMore) return;
            if (observer.current) observer.current.disconnect();

            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting) {
                    setPage((prevPage) => prevPage + 1);
                }
            });

            if (node) observer.current.observe(node);
        },
        [loading, hasMore]
    );

    const onRowClicked = useCallback((data) => {
        dispatch(openCustomDrawer({
            drawer: 'TASK_DETAIL',
            drawerData: data?._id
        }))
    }, []);

    return (
        <List className="d-flex flex-column gap-3">
            {
                loading ? <Box className='loader-multi-drawer'>
                    <div className='d-flex align-item-center jc-center h-100'><Loader /></div></Box> :
                    <>
                        {
                            posts?.length === 0 ? <Box textAlign="center" className="w-100 d-flex align-item-center jc-center flex-column gap-2 no-data-found loader-multi-drawer">
                                <img style={{ maxWidth: "120px" }} src={noDataFound} alt="No Results Found" />
                                <p className='text-12'><strong>No Results Found</strong></p>
                            </Box> : <>
                                {
                                    posts?.map((data, idx) => {
                                        return (
                                            <ListItem
                                                onClick={() => onRowClicked(data)}
                                                ref={posts.length === idx + 1 ? lastPostElementRef : null}
                                                key={data?._id} className="d-flex flex-column p-3 bg-grey-900 border-radius-8 border-none gap-3 cur-pointer driver-tasks-tab">
                                                <div className="w-100 d-flex align-item-center">
                                                    <div className="w-100 d-flex flex-column gap-3 driver-tabs-con">
                                                        <div className="w-100 d-flex align-item-center jc-s-btwn">
                                                            <div className="d-flex align-item-center gap-2">
                                                                <Badge className="text-black-400 bg-black-700 text-10 cus-badge">{separateWords(data?.taskType)}</Badge>
                                                                <StatusTask value={data?.taskStatus} />
                                                            </div>
                                                            <Typography className="text-black-400 text-12">{formatTimestampToDate24Hours(data?.createdDate)}</Typography>
                                                        </div>
                                                        <Typography variant='h5' className='text-white-700 text-14 f-wrap line-height-normal'>
                                                            <div className="w-100 d-flex align-item-start gap-2">
                                                                <LocationOnIcon className="text-black-400 mt-1" />
                                                                <span>{data?.address ? `${data?.address?.address1 || data?.address?.address}` : ""}</span>
                                                            </div>
                                                        </Typography>
                                                        <Typography variant='h5' className='d-flex align-item-center jc-s-btwn f-wrap line-height-normal'>
                                                            {(data?.taskType == "deliveryTask") && <>{data?.orderType == "asap" ? <span className='text-yellow-100 text-12'>ASAP</span> : data?.orderType == "schedule" ? <span className='text-yellow-100 text-12'>Schedule</span> : <span className='text-yellow-100 text-12'>-</span>} </>}
                                                            {data?.taskTag == "delayed" || data?.delayed == " critical" ? <span className="d-flex align-item-center gap-2 badge-tag text-10"> <LateOrderIcon /> Late Order</span> : <span className="d-flex align-item-center gap-2 badge-tag text-10 on-time"> <OnTimeIcon /> On Time</span>}
                                                        </Typography>
                                                        <Grid container className='mt-1'>
                                                            {
                                                                (data?.taskType == "deliveryTask") &&
                                                                <Grid item xs={12}>
                                                                    <Typography className='text-black-400 text-12 font-w-600'>
                                                                        Customer Name:
                                                                        <span className='ml-2 text-white-700 font-w-500'>
                                                                            {data?.recipientDetails ? data?.recipientDetails?.name : "-"}
                                                                        </span>
                                                                    </Typography>
                                                                </Grid>
                                                            }
                                                            <Grid item xs={12}>
                                                                <Typography className='text-black-400 text-12 font-w-600'>
                                                                    Total Arrival Time:
                                                                    <span className='ml-2 text-white-700 font-w-500'>{data?.totalArrivalTime ? convertMinutesToHoursAndMinutes(data?.totalArrivalTime) : "-"}</span>
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Typography className='text-black-400 text-12 font-w-600'>
                                                                    Time To Reach:
                                                                    <span className='ml-2 text-white-700 font-w-500'>
                                                                        {data?.fleetDuration ? convertMinutesToHoursAndMinutes(data?.fleetDuration) : "-"}
                                                                    </span>
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                </div>
                                            </ListItem>
                                        )
                                    })
                                }
                            </>
                        }
                    </>
            }
        </List>
    )
}

export default DriverTask