import React, { useEffect } from 'react';
import AddHomepageBanner from '../../components/homepageBanner';
import CommonLayout from 'layouts/DashboardLayout';
import { useParams } from 'react-router-dom';
import { getSingleBannerAPI } from '../../redux/slices/common';
import { useDispatch } from 'react-redux';
export default function AddBanner() {
  const { bannerId } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    if (bannerId) {
      dispatch(getSingleBannerAPI(bannerId)).unwrap().then(() => { }).catch(() => { });
    }
  }, [bannerId]);
  return (
    <CommonLayout>
      <AddHomepageBanner />
    </CommonLayout>
  );
}
