import { Badge, Box, Card, CircularProgress, Stack, Typography, createSvgIcon } from '@mui/material'
import React, { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { closeCustomModalDialog, openCustomModalDialog } from '../../redux/slices/modalSlice'
import { openSnackbar, uploadImage } from '../../redux/slices/common'
import { useSelector } from 'react-redux'
import { deleteOrderProof, fetchOrderProofs, uploadOrderProofs } from '../../redux/slices/orders'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import { formatTimestampToDateView } from 'utilities/common'

const uploadThumbnailImage = {
    borderRadius: '20px',
    width: '160px',
    height: '160px',
    objectFit: 'cover',
    boxShadow: '0 0 1px rgb(0 0 0 / 20%)',
    cursor: 'move'
};

const thumbnailCloseIcon = {
    backgroundColor: '#FD4438',
    borderRadius: 10,
    p: .25,
    mt: -4,
    mr: -4,
    cursor: 'pointer'
};

const PlusIcon = createSvgIcon(
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='currentColor'>
        <path d='M5.47 5.47a.75.75 0 0 1 1.06 0L12 10.94l5.47-5.47a.75.75 0 1 1 1.06 1.06L13.06 12l5.47 5.47a.75.75 0 1 1-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 0 1-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 0 1 0-1.06Z' />
    </svg>,
    'Plus',
);

export default function OrderProof({ orderStatus }) {
    const { user } = useSelector(state => state.auth)
    const { orderProofs, uploadingProof = false, deletingProof = false, isLoading = false } = useSelector(state => state.orders)
    const deleteProofIdRef = useRef('')

    const { orderId = '' } = useParams()
    const dispatch = useDispatch()
    const proofClickHandler = () => {
        dispatch(openCustomModalDialog({
            modal: 'UPLOAD_IMAGE_CAMERA_AND_FILE_UPLOAD', modalData: {
                updateIdHandler, title: <>
                    <Stack direction='column' >
                        <Typography>Order Proof</Typography>
                        <Typography>Uploader Name: {user?.name}</Typography>
                    </Stack>
                </>
            }
        }))
    }
    const updateIdHandler = (uploadedImage) => {
        dispatch(uploadImage(uploadedImage)).unwrap().then((fileUploadRes => {
            dispatch(uploadOrderProofs({
                orderId,
                packageProof: { uploaderName: user?.name, proofImage: fileUploadRes?.fileUrl, createdDate: moment().valueOf(), userId: user?._id }
            })).unwrap().then((res) => {
                showResponseMessage(res?.message, 'success')
                dispatch(closeCustomModalDialog())
                dispatch(fetchOrderProofs(orderId))
            }).catch(() => { })

        })).catch(() => {
            // showResponseMessage(err?.message, 'error')
        })
    }

    const showResponseMessage = (message, errortype) => {
        if (errortype === 'success') {
            dispatch(openSnackbar({ content: message, color: 'success', icon: 'check' }))
        }
        if (errortype === 'error') {
            dispatch(openSnackbar({ content: message, color: 'error', icon: 'warning' }))
        }
    }

    const removeImageHandler = (proofId = '', type) => {
        deleteProofIdRef.current = proofId
        dispatch(deleteOrderProof({ proofId, orderId })).unwrap().then((res) => {
            showResponseMessage(res?.message, 'success')
            deleteProofIdRef.current = ''
            dispatch(fetchOrderProofs(orderId))
        })
    }
    useEffect(() => {
        dispatch(fetchOrderProofs(orderId))
    }, [])
    // imageDragHandler={(updatedImages => setFormData({ ...formData, productImages: updatedImages }))} removeImageHandler={removeImageHandler}
    return (
        <Card sx={{ p: { xs: 2, md: 3 }, mb: 3, borderRadius: 2.5, boxShadow: 'none' }}>
            <Typography mb={2}>Order Proof</Typography>

            {
                isLoading ? <>
                    <Stack  direction='row' justifyContent='center' alignItems='center' minHeight={300}>
                    <CircularProgress />
                    </Stack>
                </> : <>
                    {
                        orderProofs?.length > 0 ? <>
                            <Stack direction='row' flexWrap='wrap' gap={1.25} mb={4.5}>
                                {
                                    orderProofs?.map(((proof, index) => {
                                        return <Stack key={index}>
                                            <Badge
                                                key={index}
                                                className='draggable'
                                                badgeContent={
                                                    orderStatus === 'awaiting_shipment' ? deletingProof && deleteProofIdRef.current === proof?._id ? <CircularProgress size={18} sx={thumbnailCloseIcon} /> : user?._id === proof?.userId ? <PlusIcon onClick={() => removeImageHandler(proof?._id)} color='white' fontSize='small' sx={thumbnailCloseIcon} /> : null : null
                                                }
                                                overlap='circular'
                                                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                            >
                                                <img src={proof?.proofImage} key={index} style={uploadThumbnailImage} />
                                            </Badge>
                                            <Typography fontSize={12}>  Uploader: {proof?.uploaderName}</Typography>
                                            <Typography fontSize={12}>   {proof.createdDate ? formatTimestampToDateView(proof.createdDate) : ''} </Typography>
                                        </Stack>

                                    }))

                                }
                                {
                                    orderStatus === 'awaiting_shipment' && <Box onClick={proofClickHandler}>
                                        <Stack direction='row' flexWrap='wrap' gap={1.25} mb={4.5}>
                                            <label htmlFor='product-image-input'>
                                                <Box bgcolor='#F1F1F1' display='flex' alignItems='center' justifyContent='center' flexDirection='column' minWidth='160px' minHeight='160px' borderRadius='20px' p={2}>
                                                    {
                                                        uploadingProof ? <CircularProgress size={32} color='primary' /> : <Typography variant='h5' fontWeight='medium' textAlign='center'>+<br />  Add <br /> Order Proof</Typography>
                                                    }
                                                </Box>
                                            </label>
                                        </Stack>
                                    </Box>
                                }

                            </Stack>
                        </> : <>
                            {
                                orderStatus === 'awaiting_shipment' && <Stack direction='row' justifyContent='center' alignItems='center' gap={1.25} mb={4.5}>
                                    <Box onClick={proofClickHandler}>
                                        <Stack direction='row' flexWrap='wrap' gap={1.25} mb={4.5}>
                                            <label htmlFor='product-image-input'>
                                                <Box bgcolor='#F1F1F1' display='flex' alignItems='center' justifyContent='center' flexDirection='column' minWidth='160px' minHeight='160px' borderRadius='20px' p={2}>
                                                    {
                                                        uploadingProof ? <CircularProgress size={32} color='primary' /> : <Typography variant='h5' fontWeight='medium' textAlign='center'>+<br />  Add <br /> Order Proof</Typography>
                                                    }
                                                </Box>
                                            </label>
                                        </Stack>
                                    </Box>
                                </Stack>
                            }

                        </>
                    }
                </>
            }


        </Card>
    )
}
