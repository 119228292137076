import React from 'react';

export default function TaskBreakIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_12500_8401)">
        <g clipPath="url(#clip1_12500_8401)">
          <path d="M12.8468 4.76283L13.3335 4.27617C13.5938 4.01584 13.5939 3.59379 13.3336 3.33342C13.0732 3.07298 12.6511 3.07294 12.3907 3.33333L11.8373 3.88667C10.9011 3.22319 9.80861 2.81429 8.66683 2.7V1.33333H9.3335C9.70169 1.33333 10.0002 1.03486 10.0002 0.666667C10.0002 0.298477 9.70169 0 9.3335 0H6.66683C6.29864 0 6.00017 0.298477 6.00017 0.666667C6.00017 1.03486 6.29864 1.33333 6.66683 1.33333H7.3335V2.7C6.19173 2.81429 5.09922 3.22319 4.163 3.88667L3.60967 3.33333C3.34928 3.07294 2.92709 3.07298 2.66675 3.33342C2.40647 3.59379 2.40651 4.01584 2.66683 4.27617L3.1535 4.76283C2.25926 5.70963 1.66193 6.89762 1.43525 8.18008C1.20858 9.46254 1.3625 10.7833 1.87801 11.9793C2.39351 13.1753 3.24802 14.194 4.33602 14.9098C5.42401 15.6256 6.69783 16.0071 8.00017 16.0071C9.30251 16.0071 10.5763 15.6256 11.6643 14.9098C12.7523 14.194 13.6068 13.1753 14.1223 11.9793C14.6378 10.7833 14.7918 9.46254 14.5651 8.18008C14.3384 6.89762 13.7411 5.70963 12.8468 4.76283ZM8.00017 14.6667C6.94533 14.6667 5.91419 14.3539 5.03713 13.7678C4.16006 13.1818 3.47648 12.3489 3.07281 11.3743C2.66914 10.3998 2.56352 9.32742 2.76931 8.29285C2.9751 7.25829 3.48305 6.30798 4.22893 5.5621C4.97481 4.81622 5.92512 4.30827 6.95969 4.10248C7.99425 3.89669 9.06661 4.00231 10.0411 4.40598C11.0157 4.80964 11.8486 5.49323 12.4347 6.37029C13.0207 7.24735 13.3335 8.2785 13.3335 9.33333C13.3319 10.7473 12.7695 12.103 11.7696 13.1028C10.7698 14.1027 9.41417 14.6651 8.00017 14.6667Z" fill="#df8b4e" />
          <path d="M8.19993 5.33849C8.08961 5.33297 8 5.42304 8 5.5335V9.3335H4.2C4.08954 9.3335 3.99948 9.42311 4.005 9.53342C4.04107 10.2544 4.2717 10.9535 4.67412 11.5558C5.11365 12.2136 5.73836 12.7263 6.46927 13.029C7.20017 13.3318 8.00444 13.411 8.78036 13.2566C9.55629 13.1023 10.269 12.7213 10.8284 12.1619C11.3878 11.6025 11.7688 10.8898 11.9231 10.1139C12.0775 9.33793 11.9983 8.53367 11.6955 7.80276C11.3928 7.07186 10.8801 6.44714 10.2223 6.00762C9.62002 5.6052 8.92088 5.37457 8.19993 5.33849Z" fill="#df8b4e" />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_12500_8401">
          <rect width="16" height="16" fill="white" />
        </clipPath>
        <clipPath id="clip1_12500_8401">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>

  );
}