import React, { useState } from "react"
import { TextField, IconButton, InputAdornment } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

export default function CustomInput(props) {
    const { onChange, value, error = '', type = 'text', placeholder = '', id = '', className = '', label, ...rest } = props

    const [show, setShow] = useState(false);

    function toggle() {
        setShow(!show)
    }
    if (type === 'phone') {
        return (
            <React.Fragment>
                <TextField
                    id={id}
                    type="text"
                    value={value}
                    onChange={onChange}
                    label={label}
                    variant="outlined"
                    className={className}
                    placeholder={placeholder}
                    error={Boolean(error)}
                    {...rest}
                />
                {error && <p className="error">{error}</p>}
            </React.Fragment>
        )
    }

    if (type === 'password') {
        return (
            <React.Fragment>
                <TextField
                    id={id}
                    type={show ? 'text' : type}
                    value={value}
                    onChange={onChange}
                    label={label}
                    variant="outlined"
                    className={className}
                    placeholder={placeholder}
                    error={Boolean(error)}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={toggle}
                            >
                                {show ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    }}
                    {...rest}
                />
                {error && <p className="error">{error}</p>}
            </React.Fragment>
        )
    }
    
    return (
        <React.Fragment>
            <TextField
                id={id}
                type={type}
                value={value}
                onChange={onChange}
                label={label}
                variant="outlined"
                className={className}
                placeholder={placeholder}
                error={Boolean(error)}
                {...rest}
            />
            {error && <p className="error">{error}</p>}
        </React.Fragment>
    )
}