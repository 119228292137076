import axios from 'axios';
import { getData } from 'utilities/common';
import { HYPERWOLF } from 'utilities/constants';

// *********************** hyperwolf interceptors start

export const axiosHyperwolfClient = axios.create({
    baseURL: process.env.REACT_APP_HYPERWOLF_API_BASE_URL,
    headers: {
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        'Pragma': 'no-cache',
        'Expires': 0
    }
});


axiosHyperwolfClient.interceptors.request.use(
    (config) => {
        const user = getData('login-user-info')
        if (user) {
            const parseUser = JSON.parse(user)
            config.headers['Authorization'] = 'Bearer ' + parseUser?.access_token
        }
        // config.headers['Content-Type'] = 'application/json';
        return config
    },
    (error) => {
        // Handle request errors here
        return Promise.reject(error);
    }
);

axiosHyperwolfClient.interceptors.response.use(
    (response) => {
        // Modify response data or perform actions before passing it to the calling code
        return response
    },
    (error) => {
        const originalRequest = error.config
        if (
            error?.response?.status === 401 || error?.response?.data?.message === 'Invalid token!'
        ) {
            //   router.push('/login')
            window.location.href = "/login"
            localStorage.clear()
            return Promise.reject(error)
        }
       
        return Promise.reject(error)
    }
);


// hyperwolf interceptors end

// *********************drivers interceptors start


export const axiosHyperwolfDevClient = axios.create({
    baseURL: process.env.REACT_APP_HYPERWOLF_API_DEV_BASE_URL,
    headers: {
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        'Pragma': 'no-cache',
        'Expires': 0
    }
});

axiosHyperwolfDevClient.interceptors.request.use(
    (config) => {
        const user = getData('login-user-info')
        if (user) {
            const parseUser = JSON.parse(user)
            config.headers['Authorization'] = 'Bearer ' + parseUser?.access_token
        }
        // config.headers['Content-Type'] = 'application/json';
        return config
    },
    (error) => {
        // Handle request errors here
        return Promise.reject(error);
    }
);

axiosHyperwolfDevClient.interceptors.response.use(
    (response) => {
        // Modify response data or perform actions before passing it to the calling code
        return response
    },
    (error) => {
        const originalRequest = error.config
        if (
            error?.response?.status === 401 || error?.response?.data?.message === 'Invalid token!'
        ) {
            //   router.push('/login')
            window.location.href = "/login"
            localStorage.clear()
            return Promise.reject(error)
        }
       
        return Promise.reject(error)
    }
);


// drivers interceptors end


//********************************** */ hemp interceptors start


const axiosClient = axios.create({
    // baseURL: JSON.parse(getData('adminsMode')) === HYPERWOLF ? process.env.REACT_APP__HYPERWOLF_PUBLIC_BASE_URL : process.env.REACT_APP_BASE_URL, // Replace with your axiosClient base URL
    baseURL: process.env.REACT_APP_BASE_URL, // Replace with your axiosClient base URL

    headers: {
        ["X-API-KEY"]: process.env.REACT_APP_API_AUTH_KEY,
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        'Pragma': 'no-cache',
        'Expires': 0
    }
});


axiosHyperwolfClient.interceptors.request.use(
    (config) => {
        const user = getData('login-user-info')
        if (user) {
            const parseUser = JSON.parse(user)
            config.headers['Authorization'] = 'Bearer ' + parseUser?.access_token

        }
        // config.headers['Content-Type'] = 'application/json';
        return config
    },
    (error) => {
        // Handle request errors here
        return Promise.reject(error);
    }
);

axiosHyperwolfClient.interceptors.response.use(
    (response) => {
        // Modify response data or perform actions before passing it to the calling code
        return response;
    },
    (error) => {
        const originalRequest = error.config
        if (
            error?.response?.status === 401 || error?.response?.data?.message === 'Invalid token!'
        ) {
            //   router.push('/login')
            window.location.href = "/login"
            localStorage.clear()
            localStorage.setItem('adminsMode', JSON.stringify(HYPERWOLF))
            return Promise.reject(error)
        }
      
        return Promise.reject(error)
    }
);


// Request interceptor: Modify requests before they are sent
axiosClient.interceptors.request.use(
    (config) => {
        const user = getData('login-user-info')
        if (user) {
            config.headers['Authorization'] = 'Bearer ' + user?.access_token
        }
        // config.headers['Content-Type'] = 'application/json';
        return config
    },
    (error) => {
        // Handle request errors here
        return Promise.reject(error);
    }
);

// Response interceptor: Modify responses before they are handled by the calling code
axiosClient.interceptors.response.use(
    (response) => {
        // Modify response data or perform actions before passing it to the calling code
        return response?.data;
    },
    (error) => {
        const originalRequest = error.config
        if (
            error?.response?.status === 401
        ) {
            //   router.push('/login')
            window.location.href = "/login"
            return Promise.reject(error)
        }
       
        return Promise.reject(error)
    }
);

export default axiosClient;

// hemp interceptors end



// ********************* hemp drivers interceptors start


export const axiosHempDevClient = axios.create({
    baseURL: process.env.REACT_APP_HEMP_API_DEV_BASE_URL,
    headers: {
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        'Pragma': 'no-cache',
        'Expires': 0
    }
});

axiosHempDevClient.interceptors.request.use(
    (config) => {
        const user = getData('login-user-info')
        if (user) {
            const parseUser = JSON.parse(user)
            config.headers['Authorization'] = 'Bearer ' + parseUser?.access_token
        }
        // config.headers['Content-Type'] = 'application/json';
        return config
    },
    (error) => {
        // Handle request errors here
        return Promise.reject(error);
    }
);

axiosHempDevClient.interceptors.response.use(
    (response) => {
        // Modify response data or perform actions before passing it to the calling code
        return response
    },
    (error) => {
        const originalRequest = error.config
        if (
            error?.response?.status === 401 || error?.response?.data?.message === 'Invalid token!'
        ) {
            //   router.push('/login')
            window.location.href = "/login"
            localStorage.clear()
            return Promise.reject(error)
        }
       
        return Promise.reject(error)
    }
);

