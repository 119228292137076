import React from 'react';

export default function DeliveredIcon({width = '20px', height = '20px', flex = 'none'}) {
  return (
    <svg style={{width, height, flex}} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.75 3.75C8.83225 3.74952 8.91379 3.76529 8.98994 3.79639C9.06609 3.8275 9.13535 3.87332 9.19375 3.93125L10 4.74375L10.8063 3.93125C10.8644 3.87267 10.9335 3.82617 11.0096 3.79444C11.0858 3.76271 11.1675 3.74638 11.25 3.74638C11.3325 3.74638 11.4142 3.76271 11.4904 3.79444C11.5665 3.82617 11.6356 3.87267 11.6937 3.93125L11.875 4.11875V1.25H8.125V4.11875L8.30625 3.93125C8.36465 3.87332 8.43391 3.8275 8.51006 3.79639C8.58621 3.76529 8.66775 3.74952 8.75 3.75Z" fill="currentColor"/>
      <path d="M16.25 1.25H13.125V5.625C13.1244 5.74839 13.0873 5.86884 13.0183 5.97116C12.9493 6.07349 12.8516 6.15311 12.7375 6.2C12.6633 6.23499 12.582 6.25211 12.5 6.25C12.4177 6.25048 12.3362 6.23471 12.2601 6.20361C12.1839 6.1725 12.1147 6.12668 12.0563 6.06875L11.25 5.25625L10.4437 6.06875C10.3856 6.12733 10.3165 6.17383 10.2404 6.20556C10.1642 6.23729 10.0825 6.25362 10 6.25362C9.91749 6.25362 9.8358 6.23729 9.75964 6.20556C9.68348 6.17383 9.61435 6.12733 9.55625 6.06875L8.75 5.25625L7.94375 6.06875C7.85586 6.15547 7.74425 6.21422 7.62301 6.23758C7.50177 6.26094 7.37632 6.24786 7.2625 6.2C7.14836 6.15311 7.05066 6.07349 6.9817 5.97116C6.91274 5.86884 6.87562 5.74839 6.875 5.625V1.25H3.75C3.08696 1.25 2.45107 1.51339 1.98223 1.98223C1.51339 2.45107 1.25 3.08696 1.25 3.75V16.25C1.25 16.913 1.51339 17.5489 1.98223 18.0178C2.45107 18.4866 3.08696 18.75 3.75 18.75H10C9.28364 18.2127 8.69109 17.5278 8.26248 16.7416C7.83387 15.9554 7.5792 15.0863 7.51572 14.1931C7.45225 13.2999 7.58144 12.4034 7.89456 11.5645C8.20767 10.7256 8.6974 9.96376 9.33058 9.33058C9.96376 8.6974 10.7256 8.20767 11.5645 7.89456C12.4034 7.58144 13.2999 7.45225 14.1931 7.51572C15.0863 7.5792 15.9554 7.83387 16.7416 8.26248C17.5278 8.69109 18.2127 9.28364 18.75 10V3.75C18.75 3.08696 18.4866 2.45107 18.0178 1.98223C17.5489 1.51339 16.913 1.25 16.25 1.25Z" fill="currentColor"/>
      <path d="M13.75 8.75C12.7611 8.75 11.7944 9.04324 10.9722 9.59265C10.1499 10.1421 9.50904 10.923 9.1306 11.8366C8.75217 12.7502 8.65315 13.7555 8.84608 14.7255C9.039 15.6954 9.51521 16.5863 10.2145 17.2855C10.9137 17.9848 11.8046 18.461 12.7745 18.6539C13.7445 18.8469 14.7498 18.7478 15.6634 18.3694C16.577 17.991 17.3579 17.3501 17.9073 16.5278C18.4568 15.7056 18.75 14.7389 18.75 13.75C18.75 12.4239 18.2232 11.1521 17.2855 10.2145C16.3479 9.27678 15.0761 8.75 13.75 8.75ZM16.0688 12.9437L13.5688 15.4437C13.5106 15.5023 13.4415 15.5488 13.3654 15.5806C13.2892 15.6123 13.2075 15.6286 13.125 15.6286C13.0425 15.6286 12.9608 15.6123 12.8846 15.5806C12.8085 15.5488 12.7394 15.5023 12.6813 15.4437L11.4313 14.1937C11.3136 14.0761 11.2474 13.9164 11.2474 13.75C11.2474 13.5836 11.3136 13.4239 11.4313 13.3063C11.5489 13.1886 11.7086 13.1224 11.875 13.1224C12.0414 13.1224 12.2011 13.1886 12.3188 13.3063L13.125 14.1187L15.1813 12.0563C15.2395 11.998 15.3087 11.9517 15.3848 11.9202C15.461 11.8887 15.5426 11.8724 15.625 11.8724C15.7074 11.8724 15.789 11.8887 15.8652 11.9202C15.9413 11.9517 16.0105 11.998 16.0688 12.0563C16.127 12.1145 16.1733 12.1837 16.2048 12.2598C16.2363 12.336 16.2526 12.4176 16.2526 12.5C16.2526 12.5824 16.2363 12.664 16.2048 12.7402C16.1733 12.8163 16.127 12.8855 16.0688 12.9437Z" fill="currentColor"/>
    </svg>
  );
}