import { Close } from '@mui/icons-material'
import React, { useEffect, useState } from 'react'
import { Box, Grid, ListItem, Tab, Typography } from "@mui/material";
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { fetchCheckListDetails } from '../../../../redux/slices/hyperdrive/driverAnalytics';
import Zoom from "react-medium-image-zoom";
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import noDataFound from 'assets/images/nothing-found.svg';
import { Riple } from 'react-loading-indicators'

const DetailsDrawer = ({ closeModal }) => {
    const { drawerData } = useSelector(state => state?.modal)
    const { checkListDetails, detailLoading: detailLoading = false } = useSelector(state => state?.driverAnalyticsHyperDrive)
    const dispatch = useDispatch()
    const [currentMainViewTab, setCurrentMainViewTab] = useState('checkIn')

    const handleSwitchTab = (value) => {
        const data = { ...drawerData, type: value }
        dispatch(fetchCheckListDetails(data))
        setCurrentMainViewTab(value)
    }

    useEffect(() => {
        dispatch(fetchCheckListDetails(drawerData))
    }, [])

    return (
        <>
            <Box className="cus-wrapper-drawer">
                <Box display="flex" alignItems="center" justifyContent="space-between" p={2} style={{ borderBottom: "1px solid  #1E1E24" }} className="drawer-head-sec">
                    <Typography className="text-14 text-white-700">Check in / Check out Details</Typography>
                    <Close onClick={closeModal} style={{ cursor: "pointer", color: "#ffffff" }} />
                </Box>
                <TabContext value={currentMainViewTab}>
                    <Box className="hd-tabs hd-check-tabs">
                        <TabList onChange={(e, value) => { handleSwitchTab(value) }}>
                            <Tab label="Check In" value="checkIn" />
                            <Tab label="Check Out" value="checkOut" />
                        </TabList>
                    </Box>
                    <TabPanel value="checkIn" className='driver-info-tabs'>
                        <Box className="drawer-scroll-sec">
                            <Box>
                                <Grid container spacing={3}>
                                    {
                                        detailLoading ? <Box className='drawer-scroll-sec w-100'>
                                            <div className='d-flex align-item-center jc-center h-100'><Riple color="#3135cc" size="medium" text="" textColor="" /></div></Box> :
                                            <>
                                                {
                                                    checkListDetails?.data?.checkInDetails?.length === 0 ?
                                                        <ListItem>
                                                            <Box textAlign="center" className="w-100 no-data-found mt-5">
                                                                <img style={{ maxWidth: "120px" }} src={noDataFound} alt="No Results Found" />
                                                                <p className='text-12'><strong>No Results Found</strong></p>
                                                            </Box>
                                                        </ListItem>
                                                        :
                                                        <>
                                                            {
                                                                checkListDetails?.data?.checkInDetails?.[0]?.checkInDetail?.map((data) => {
                                                                    return (
                                                                        <>
                                                                            <Grid item xs={12}>
                                                                                <Typography className='text-black-200 text-14 mb-1'>{data?.title}</Typography>
                                                                                {
                                                                                    data?.type == "checkbox" ?
                                                                                        <Typography variant='h5' className='text-18 text-white-700 font-w-500'>{data?.value == "true" ? "Yes" : "No"}</Typography>
                                                                                        :
                                                                                        data?.type == "text" ?
                                                                                            <Typography variant='h5' className='text-18 text-white-700 font-w-500'>{data?.value}</Typography>
                                                                                            :
                                                                                            data?.type == "photo" ?
                                                                                                <Zoom classDialog="custom-zoom">
                                                                                                    <img className='border-radius-8' src={data?.value} height={100} width={100} />
                                                                                                </Zoom>
                                                                                                :
                                                                                                data?.type == "qr" ?
                                                                                                    <Typography variant='h5' className='text-18 text-white-700 font-w-500'>{data?.value}</Typography>
                                                                                                    :
                                                                                                    <Typography variant='h5' className='text-18 text-white-700 font-w-500'>{data?.value}</Typography>
                                                                                }
                                                                            </Grid>
                                                                        </>
                                                                    )
                                                                })
                                                            }
                                                        </>
                                                }
                                            </>
                                    }
                                </Grid>
                            </Box>
                        </Box>
                    </TabPanel>
                    <TabPanel value="checkOut" className='driver-info-tabs'>
                        <Box className="drawer-scroll-sec">
                            <Box>
                                <Grid container spacing={3}>
                                    {
                                        detailLoading ?
                                            <Box className='drawer-scroll-sec w-100'>
                                                <div className='d-flex align-item-center jc-center h-100'> <Riple color="#3135cc" size="medium" text="" textColor="" /></div></Box> :
                                            <>
                                                {
                                                    checkListDetails?.data?.checkOutDetail?.length === 0 ?
                                                        <ListItem>
                                                            <Box textAlign="center" className="w-100 no-data-found mt-5">
                                                                <img style={{ maxWidth: "120px" }} src={noDataFound} alt="No Data Found" />
                                                                <p className='text-12'><strong>No Results Found</strong></p>
                                                            </Box>
                                                        </ListItem> :
                                                        <>
                                                            {
                                                                checkListDetails?.data?.checkOutDetail?.[0]?.checkOutDetail?.map((data) => {

                                                                    return (
                                                                        <>
                                                                            <Grid item xs={12}>
                                                                                <Typography className='text-black-200 text-14 mb-1'>{data?.title}</Typography>
                                                                                <Typography variant='h5' className='text-18 text-white-700 font-w-500'>
                                                                                    {data?.value === "true" ? "Yes" : data?.value === "false" ? "No" : data?.value}
                                                                                </Typography>
                                                                            </Grid>
                                                                        </>
                                                                    )
                                                                })
                                                            }
                                                        </>
                                                }
                                            </>
                                    }
                                </Grid>
                            </Box>
                        </Box>
                    </TabPanel>
                </TabContext>
            </Box>
        </>
    )
}

export default DetailsDrawer