import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { addDriverAPI, deleteDriverAPI, fetchAdminsAPI, fetchDriverDetailsAPI, fetchDriversAPI, getFleetAPI, getTransportationsAPI, updateDriverAPI, exportCsvAPI, importFleetsAPI, bulkDeleteDriverAPI, getReassignDriverListAPI, reassignDriverAPI, getServiceTimeAPI, updateServiceTimeAPI, getPackageTimeAPI, getProcessTimeAPI, updateProcessTimeAPI, updatePackageTimeAPI } from "../../../apis/driver"

export const fetchDrivers = createAsyncThunk(
    'drivers/fetchDrivers',
    async (filters, { rejectWithValue }) => {
        try {
            const res = await fetchDriversAPI(filters)
            return res
        } catch (error) {
            console.error("erro", error.response)
            return rejectWithValue(error?.response?.data)
        }
    }
)

export const addDriver = createAsyncThunk(
    'drivers/addDriver',
    async(data, { rejectWithValue }) => {
        try {
            const res = await addDriverAPI(data)
            return res
        } catch (error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)

export const fetchDriverDetails = createAsyncThunk(
    'driver/details',
    async(fleetId, { rejectWithValue }) => {
        try {
            const res = await fetchDriverDetailsAPI(fleetId)
            return res
        } catch (error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)

export const deleteDriver = createAsyncThunk(
    'drivers/deleteDriver', 
    async(data, { rejectWithValue }) => {
        try {
            const res = await deleteDriverAPI(data)
            return res
        } catch (error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)

export const bulkDeleteDriver = createAsyncThunk(
    'drivers/bulkDeleteDriver', 
    async(data, { rejectWithValue }) => {
        try {
            const res = await bulkDeleteDriverAPI(data)
            return res
        } catch (error) {
            console.error("erro", error)
            return rejectWithValue(error?.response?.data)
        }
    }
)


export const updateDriver = createAsyncThunk(
    'drivers/updateDriver',
    async(data, {rejectWithValue}) => {
        try {
            const res = await updateDriverAPI(data)
            return res
        } catch (error) {
            console.error("erro", error)
            return rejectWithValue(error?.response?.data)
        }
    }
)

export const fetchTransportations = createAsyncThunk(
    'drivers/fetchTransportations',
    async( filters, {rejectWithValue}) => {
        try {
            const res = await getTransportationsAPI()
            return res
        } catch(error) {
            console.error("erro", error.response)
            return rejectWithValue(error?.response?.data)
        }
    }
)

export const fetchAdmins = createAsyncThunk(
    'drivers/fetchAdmins',
    async (filters, { rejectWithValue }) => {
        try {
            const res = await fetchAdminsAPI()
            return res
        } catch(error) {
        console.error("erro", error.response)
        return rejectWithValue(error?.response?.data)
        }
    }
)

export const exportCsv = createAsyncThunk(
    'drivers/exportCsv',
    async (filters, { rejectWithValue }) => {
        try {
            const res = await exportCsvAPI(filters)
            return res
        } catch(error) {
            console.error("erro", error.response)
            return rejectWithValue(error?.response?.data)
        }
    }
)

export const importFleets = createAsyncThunk(
    'drivers/importFleets',
    async( data, { rejectWithValue }) => {
        try {
            const res = await importFleetsAPI(data)
            return res
        } catch (error) {
            console.error("erro", error.message)
            return rejectWithValue(error?.response?.data)
        }
    }
)

export const fetchReassignDriverList = createAsyncThunk(
        'drivers/reassignList',
        async( filters, { rejectWithValue }) => {
            try {
                const res = await getReassignDriverListAPI(filters)
                return res
            } catch (error) {
                console.error("erro", error.message)
                return rejectWithValue(error?.response?.data)
            }
        }
)

export const reassignDriver = createAsyncThunk(
    'drivers/reassignDriver',
    async (data, { rejectWithValue }) => {
        try {
            const res = await reassignDriverAPI(data)
            return res
        } catch(error) {
            console.error("erro", error.message)
            return rejectWithValue(error?.response?.data)
        }
    }
)

const adminDrivers = createSlice({
    name:'admindrivers',
    initialState: {
        adminDrivers:[],
        driverDetails: {},
        loading: false,
        error: null
    },
    reducers:{

    },
    extraReducers: (builder) => {
        builder
        .addCase(fetchDrivers.fulfilled, (state, action) => {
            state.loading = false;
            state.adminDrivers = action.payload;
            state.driverDetails = {}
            state.error = null;
            })
        .addCase(fetchDrivers.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })
        .addCase(fetchDrivers.pending, (state) => {
            state.loading = true;
        })

        builder.
        addCase(addDriver.pending, (state) => {
            state.loading = true;
            state.error = null
        })
        .addCase(addDriver.fulfilled, (state, action) => {
            state.loading = false;
            state.addedDriver = action.payload;
            state.error = null;
        })
        .addCase(addDriver.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload
        })

        builder.
        addCase(updateDriver.pending, (state, action) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(updateDriver.fulfilled, (state, action) => {
            state.loading = false;
            state.error = action.payload
        })
        .addCase(updateDriver.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload
        })

        builder.
        addCase(fetchDriverDetails.fulfilled, (state,action) =>{
            state.loading = false;
            state.driverDetails = action.payload;
            state.error = null;
        })

        builder.addCase(fetchDriverDetails.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })

        builder.addCase(fetchDriverDetails.pending, (state) =>{
            state.loading = true;
        })
    }
});

export const { /* Any additional actions */} = adminDrivers.actions; 

export default adminDrivers.reducer;