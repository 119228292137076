import { DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import CalculateMap from 'components/hyperdrive/calculateMap';
import NearMeIcon from '@mui/icons-material/NearMe';
import { useEffect, useState } from 'react';
import { fetchRoutesDetails } from '../../redux/slices/hyperdrive/driverAnalytics';
import { formatTimestampToDate24Hours } from 'utilities/common';

export default function DriverTaskModal({ closeModal }) {
  const dispatch = useDispatch();
  const { modalData } = useSelector(state => state.modal);
  const [dataSource, setDataSource] = useState([]);

  const fetch = async () => {
    try {
      const res = await dispatch(fetchRoutesDetails(modalData?.data?._id)).unwrap().then(res => {
        setDataSource(res?.data);
      }).catch(() => {
      });

    } catch (err) {
      console.error('Response Error:', err);
      // Handle the error here
    }
  };
  useEffect(() => {
    fetch();
  }, []);
  return (
    <>
      <DialogTitle id="draggable-dialog-title" display="flex" alignItems="center" justifyContent="space-between"  >
        <div className='d-flex align-item-top gap-3'>
          <NearMeIcon style={{ color: '#0163FF', marginTop: '5px' }} />
          <div className='d-flex align-item-top flex-column'>
            <Typography className='text-14 text-white-700' fontWeight="semibold">Driver Location at</Typography>
            <Typography className='text-20 text-black-400' fontWeight="semibold" mb={1}>{modalData?.data?.actualTaskStartTime ? formatTimestampToDate24Hours(modalData?.data?.actualTaskStartTime) : ''}</Typography>
            <Typography className='text-14 text-white-700' fontWeight="semibold">{modalData?.data?.address?.latitude} , {modalData?.data?.address?.longitude}  at {modalData?.data?.actualDistance ? modalData?.data?.actualDistance.toFixed(4) : '0'}  miles elevation</Typography>
          </div>
        </div>
        <IconButton onClick={closeModal} edge='end' size='small' className='modal-close'>
          <CloseIcon fontSize='medium' />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <CalculateMap route={''} routeMap={dataSource} taskAddress={modalData?.data?.address} />
      </DialogContent>
    </>
  );
}