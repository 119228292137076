import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeCustomModalDialog } from '../../redux/slices/modalSlice';
import CustomDialog from './Dialog';
import PropTypes from "prop-types";
import Login from 'layouts/Login';
import DeleteModal from './DeleteModal';
import BulkDeleteModal from './BulkDeleteModal';
import BulkBrandUpdate from './BulkBrandUpdate';
import BulkProductStatusUpdate from './BulkProductStatusUpdate';
import BulkProductPriceUpdate from './BulkProductPriceUpdate';
import BulkSalePriceUpdate from './BulkSalePriceUpdate';
import BulkProductCategoryUpdate from './BulkProductCategoryUpdate';
import BulkProductTagsUpdate from './BulkProductTagsUpdate';
import WarrantyReason from './orders/ReturnReason';
import ChangeOrderStatus from './ChangeOrderStatus';
import CancelOrderReason from './orders/CancelOrderReason';
import BanMemberModal from './member/BanMemberModal';
import DeclineRetailerProductReason from './approvals/DeclineRetailerProductReason';
import OverrideApproval from './ApprovalOverride';
import ImportDriversModal from './Hyperwolf/ImportDriversModal';
import DeclineDriverRequestReason from './Hyperwolf/DeclineDriverRequestReason';
import BulkDeleteDriver from './BulkDeleteDriver';
import DriverTaskModal from './DriverTaskModal';
import FetchDriveMessage from './FetchDriveMessage';
import UploadIdImageModal from './UploadIdImageModal';

export default function CustomModal() {
    const dispatch = useDispatch();
    const { modal, modalData } = useSelector(state => state.modal);
    const closeModal = () => {
        dispatch(closeCustomModalDialog());
    }

    switch (modal) {

        case "CREATE_USER":
            return <RenderModalWithComponent component={Login} customFunction={closeModal} />
        case "DELETE_MODAL":
            return <RenderModalWithComponent component={DeleteModal} customFunction={closeModal} />
        case "BULK_DELETE_PRODUCT":
            return <RenderModalWithComponent component={BulkDeleteModal} customFunction={closeModal} />

        case "BULK_UPDATE_BRAND":
            return <RenderModalWithComponent component={BulkBrandUpdate} customFunction={closeModal} />

        case "BULK_UPDATE_STATUS":
            return <RenderModalWithComponent component={BulkProductStatusUpdate} customFunction={closeModal} />
        case "BULK_UPDATE_PRICE":
            return <RenderModalWithComponent component={BulkProductPriceUpdate} customFunction={closeModal} />

        case "BULK_UPDATE_SALE_PRICE":
            return <RenderModalWithComponent component={BulkSalePriceUpdate} customFunction={closeModal} />

        case "BULK_UPDATE_CATEGORY":
            return <RenderModalWithComponent component={BulkProductCategoryUpdate} customFunction={closeModal} />


        case "BULK_UPDATE_TAGS":
            return <RenderModalWithComponent component={BulkProductTagsUpdate} customFunction={closeModal} />

        case "CHANGE_ORDER_STATUS":
            return <RenderModalWithComponent component={ChangeOrderStatus} customFunction={closeModal} />

        case "WARRANTY_REASON":
            return <RenderModalWithComponent component={WarrantyReason} customFunction={closeModal} />

        case "CANCEL_ORDER_REASON":
            return <RenderModalWithComponent component={CancelOrderReason} customFunction={closeModal} />

        case "BAN_MEMBER":
            return <RenderModalWithComponent component={BanMemberModal} customFunction={closeModal} />
        case "DECLINE_APPROVAL_REASON":
            return <RenderModalWithComponent component={DeclineRetailerProductReason} customFunction={closeModal} />

        case "OVERRIDE_APPROVAL":
            return <RenderModalWithComponent component={OverrideApproval} customFunction={closeModal} />

        case "IMPORT_DRIVERS":
            return <RenderModalWithComponent component={ImportDriversModal} customFunction={closeModal} maxWidth="xs" />

        case "DECLINE_DRIVER_REQUEST_REASON":
            return <RenderModalWithComponent component={DeclineDriverRequestReason} customFunction={closeModal} />

        case "BULK_DELETE_DRIVER":
            return <RenderModalWithComponent component={BulkDeleteDriver} customFunction={closeModal} />

        case "TASK_ROUTE":
            return <RenderModalWithComponent component={DriverTaskModal} customFunction={closeModal} className="bg-black task-route-modal" maxWidth="xs" />

        case "DRIVER_FETCH":
            return <RenderModalWithComponent component={FetchDriveMessage} customFunction={closeModal} className="bg-black task-route-modal" maxWidth="xs" />

        case "UPLOAD_IMAGE_CAMERA_AND_FILE_UPLOAD":
            return <RenderModalWithComponent component={UploadIdImageModal} customFunction={closeModal} className="bg-black task-route-modal" maxWidth="xs" />

        default:
            return null
    }
}
const RenderModalWithComponent = ({ component: Component, maxWidth = "sm", className, customFunction, ...rest }) => {
    return (
        <>
            <CustomDialog fullWidth maxWidth={maxWidth} customFunction={customFunction} className={className}>
                <Component {...rest} closeModal={customFunction} />
            </CustomDialog>
        </>
    )
}
RenderModalWithComponent.propTypes = {
    component: PropTypes.elementType.isRequired, // expects a React component type
    maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
    customFunction: PropTypes.func,
    // Add any other PropTypes you need for your component's props
};