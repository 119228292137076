import MainFAQForm from 'components/MainFAQ';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getMainFAQ } from '../../redux/slices/faq';

export default function MainFAQ() {
  const dispatch = useDispatch();
  useEffect(()=>{
    dispatch(getMainFAQ());
  },[]);
  return (
    <MainFAQForm/>
  );
}
