import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { 
    deleteBannerAPI, 
    getBannerTimingAPI, 
    getBannersListsAPI, 
    updateBannerListAPI, 
    updateBannerTimingAPI, 
    updateFhlStatusAPI, 
    getFhlStatusAPI 
} from '../../apis/hyperwolf/banners';

// Async thunks
export const getBannerTiming = createAsyncThunk(
    'hyperwolfBanners/getBannerTiming',
    async (data, { rejectWithValue }) => {
        try {
            const response = await getBannerTimingAPI(data);
            return response?.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const updateBannerTiming = createAsyncThunk(
    'hyperwolfBanners/updateBannerTiming',
    async (data, { rejectWithValue }) => {
        try {
            const response = await updateBannerTimingAPI(data);
            return response?.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getBanners = createAsyncThunk(
    'hyperwolfBanners/getBanners',
    async (data, { rejectWithValue }) => {
        try {
            const response = await getBannersListsAPI(data);
            return response?.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const updateBanner = createAsyncThunk(
    'hyperwolfBanners/updateBanner',
    async ({ data, id }, { rejectWithValue }) => {
        try {
            const response = await updateBannerListAPI(data, id);
            return response?.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const deleteBanner = createAsyncThunk(
    'hyperwolfBanners/deleteBanner',
    async (id, { rejectWithValue }) => {
        try {
            const response = await deleteBannerAPI(id);
            return response?.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const updateFhlStatus = createAsyncThunk(
    'hyperwolfBanners/updateFhlStatus',
    async (data, { rejectWithValue }) => {
        try {
            const response = await updateFhlStatusAPI(data);
            return response?.data;
        } catch (error) {
            toast.error(error.message || "Failed to update FHL status");
            return rejectWithValue(error);
        }
    }
);

export const getFhlStatus = createAsyncThunk(
    'hyperwolfBanners/getFhlStatus',
    async (data, { rejectWithValue }) => {
        try {
            const response = await getFhlStatusAPI(data);
            return response?.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

// Slice
const hyperwolfBannersSlice = createSlice({
    name: 'hyperwolfBanners',
    initialState: {
        bannerTiming: null,
        bannersList: [],
        fhlStatus: null,
        loading: false,
        error: null,
        singleBanner:{}
    },
    reducers: {
        setBannerInformation:(state,action)=>{
          state.singleBanner = action.payload
        }
      },
    extraReducers: (builder) => {
        builder
            .addCase(getBannerTiming.pending, (state) => {
                state.loading = true;
            })
            .addCase(getBannerTiming.fulfilled, (state, action) => {
                state.loading = false;
                state.bannerTiming = action.payload;
            })
            .addCase(getBannerTiming.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(updateBannerTiming.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateBannerTiming.fulfilled, (state, action) => {
                state.loading = false;
                state.bannerTiming = action.payload;
            })
            .addCase(updateBannerTiming.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(getBanners.pending, (state) => {
                state.loading = true;
                state.singleBanner = {}
            })
            .addCase(getBanners.fulfilled, (state, action) => {
                state.loading = false;
                state.bannersList = action.payload;
            })
            .addCase(getBanners.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(updateBanner.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateBanner.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(updateBanner.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(deleteBanner.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteBanner.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(deleteBanner.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(updateFhlStatus.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateFhlStatus.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(updateFhlStatus.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(getFhlStatus.pending, (state) => {
                state.loading = true;
            })
            .addCase(getFhlStatus.fulfilled, (state, action) => {
                state.loading = false;
                state.fhlStatus = action.payload;
            })
            .addCase(getFhlStatus.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});
export const {setBannerInformation} = hyperwolfBannersSlice.actions;

export default hyperwolfBannersSlice.reducer;
