import { Grid, IconButton, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { AgGridTable } from 'components/tables/AgGridTable';
import { fetchRouteExport, fetchRouteTable } from '../../../redux/slices/hyperdrive/drivers';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { formatTimestampToWithoutTime } from 'utilities/common';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import MDButton from 'components/MDButton';
import { CSVLink } from 'react-csv';
import Papa from 'papaparse';
import { customApiSwitch, openCustomDrawer } from '../../../redux/slices/modalSlice';
import { formatTimestampToMapOld } from 'utilities/common';
import { formatTime } from 'utilities/common';
import SearchIcon from 'common/customIcons/search';
import { CloseRounded } from '@mui/icons-material';
import { useDebounce } from 'hooks/useDebounce';

export const RouteAnalyticsTable = ({ routeFilter }) => {
  const dispatch = useDispatch();
  const mainGridApiRef = useRef('');
  const pageRef = useRef(0);
  const gridRefApi = useRef(null);
  const [csv, setCsv] = useState([]);
  const { routeTable = {}, tableloading: tableloading = false } = useSelector(state => state.drivers);
  const { routeData } = useSelector(state => state.modal);
  const searchTermRef = useRef('');
  const [searchTerm, setSearchTerm] = useState('');

  const getSortInfo = useCallback((data) => {
    const result = {};
    data.forEach(item => {
      if (item?.colId === 'driverNameSorting') {
        result[item.colId] = item.sort === 'asc' ? 'asc' : 'desc';
      } else if (item?.colId === 'createdDateSorting') {
        result[item.colId] = item.sort === 'asc' ? 'asc' : 'desc';
      } else if (item?.colId === 'taskIdSorting') {
        result[item.colId] = item.sort === 'asc' ? 'asc' : 'desc';
      } else if (item?.colId === 'transactionNumberSorting') {
        result[item.colId] = item.sort === 'asc' ? 'asc' : 'desc';
      }
      else {
        return false;
      }
    });
    return result;
  }, []);

  const getRows = useCallback(async (params, filters) => {
    const start = filters?.startDate === undefined ? routeFilter?.startDate : filters?.startDate === null ? '' : formatTimestampToMapOld(filters?.startDate);
    const end = filters?.endDate === undefined ? routeFilter?.endDate : filters?.endDate === null ? '' : formatTimestampToMapOld(filters?.endDate);
    gridRefApi.current = params;
    let filter = {};
    if (params?.sortModel.length) {
      filter = { ...getSortInfo(params?.sortModel) };
    }
    try {
      const res = await dispatch(fetchRouteTable({
        data: {
          skip: searchTermRef.current ? 0 : pageRef.current,
          limit: 20,
          ...filter,
          searchTerm: searchTermRef.current,
          startDate: formatTimestampToMapOld(filters?.startDate) !== 'Invalid Date' ? start : '',
          endDate: formatTimestampToMapOld(filters?.endDate) !== 'Invalid Date' ? end : '',
          fleetName: filters?.driverName ? filters?.driverName : '',
        },
      })).unwrap();
      const { tasks } = res?.data;
      const { totalTasks } = res;
      const driverListArray = tasks?.map((item) => ({
        ...item,
      }));
      params.successCallback([...driverListArray], totalTasks);
      pageRef.current += 20;
    } catch {
      params.successCallback([], 0);
    }
  }, [dispatch]);

  useEffect(() => {
    if (mainGridApiRef?.current) {
      if (tableloading) {
        mainGridApiRef.current.showLoadingOverlay();
      } else if (routeTable?.totalTasks === 0) {
        mainGridApiRef.current.showNoRowsOverlay();
      } else {
        mainGridApiRef.current.hideOverlay();
      }
    }
  }, [routeTable, tableloading]);

  useEffect(() => {
    if (routeFilter) {
      pageRef.current = 0;
      searchFn(routeFilter);
    }
  }, [routeFilter]);

  useEffect(() => {
    if (routeData === 'route_analytics' && routeFilter) {
      pageRef.current = 0;
      searchFn(routeFilter);
      dispatch(customApiSwitch({
        dataSwitch: '',
        routeData: ''
      }));
    }
  }, [routeData]);

  const handleSearch = useDebounce(() => {
    pageRef.current = 0;
    searchFn(routeFilter);
  }, 500);

  const searchHandler = useCallback((searchValue) => {
    setSearchTerm(searchValue);
    searchTermRef.current = searchValue;
    handleSearch();
  }, [handleSearch]);

  const searchFn = useCallback((routeFilter) => {
    pageRef.current = 0;
    const dataSource = {
      getRows: (params) => getRows(params, routeFilter)  // Pass status to getRows
    };
    if (mainGridApiRef.current) {
      mainGridApiRef.current.ensureIndexVisible(0, null);
      mainGridApiRef.current.setGridOption('datasource', dataSource);
    }
  }, [getRows]);

  const columnDefs = [
    {
      headerName: 'Task ID',
      field: 'taskIdSorting',
      minWidth: 120,
      cellRenderer: useCallback((props) => (
        <>
          {props.data?.taskDisplayId
            ? props?.data?.taskDisplayId
            : '-'}
        </>
      ), []),
    },
    {
      headerName: 'Transaction Number',
      field: 'transactionNumberSorting',
      suppressMenu: false,
      minWidth: 180,
      flex: 1,
      cellStyle: { whiteSpace: 'normal', lineHeight: '1.5' },
      cellRenderer: useCallback((props) => (
        <>
          {props.data?.transactionNumber
            ? props?.data?.transactionNumber
            : '-'}
        </>
      ), []),
    },
    {
      headerName: 'Driver',
      field: 'driverNameSorting',
      cellRenderer: useCallback((props) => (
        props?.data?.fleet?.fleetName ? props?.data?.fleet?.fleetName : '-'
      ), []),
      minWidth: 180,
    },
    {
      headerName: 'Date',
      field: 'createdDateSorting',
      cellRenderer: useCallback((props) => (
        props?.data?.createdDate ? formatTimestampToWithoutTime(props?.data?.createdDate) : '-'
      ), []),
    },
    {
      headerName: 'Delivery Speed',
      field: 'orderType',
      suppressMenu: false,
      minWidth: 120,
      flex: 1.5,
      cellStyle: { whiteSpace: 'normal', lineHeight: '1.5' },
      cellRenderer: useCallback((props) => (
        props?.data?.orderType ? <p style={{ textTransform: props?.data?.orderType === 'asap' ? 'uppercase' : 'capitalize' }}>{props?.data?.orderType}</p> : '-'),
        []),
      sortable: false
    },
    {
      headerName: 'Vehicle Type',
      field: 'vehicle',
      cellRenderer: useCallback((props) => (
        props?.data?.vehicle?.name ? <p style={{ textTransform: 'uppercase' }}>{props?.data?.vehicle?.name}</p> : '-'
      ), []),
      sortable: false
    },
    {
      headerName: 'Planned Completion',
      field: 'plannedCompletion',
      cellRenderer: useCallback((props) => (
        props?.value ? props?.value : '-'
      ), []),
      minWidth: 180,
      sortable: false
    },
    {
      headerName: 'Actual Completion',
      field: 'actualCompletion',
      cellRenderer: useCallback((props) => (
        props?.value ? props?.value : '-'
      ), []),
      minWidth: 180,
      sortable: false
    },
    {
      headerName: 'Planned Duration',
      field: 'plannedDuration',
      cellRenderer: useCallback((props) => (
        formatTime(props?.value)
      ), []),
      minWidth: 180,
      sortable: false
    },
    {
      headerName: 'Actual Duration',
      field: 'actualDuration',
      suppressMenu: false,
      cellRenderer: useCallback((props) => (
        formatTime(props?.value)
      ), []),
      minWidth: 180,
      sortable: false
    },
    {
      headerName: 'Relative Difference',
      field: 'relativeDifference',
      suppressMenu: false,
      cellRenderer: (props) => {
        return <span className={props?.value?.sign === '-' ? 'relativeDifferenceDark' : ''}>{formatTime(props?.value)}</span>;
      },
      cellStyle: { textAlign: 'right', justifyContent: 'end' },
      headerClass: 'right-aligned-cell-header',
      sortable: false
    },
  ];

  const parseCsv = (csv) => {
    return new Promise((resolve, reject) => {
      Papa.parse(csv, {
        header: true,
        skipEmptyLines: true,
        complete: (result) => {
          resolve(result.data);
        },
        error: (error) => {
          console.error('Error parsing CSV:', error); // Log any parsing error
          reject(error);
        }
      });
    });
  };

  const handleExport = async (filters) => {
    const start = filters?.startDate === undefined ? routeFilter?.startDate : filters?.startDate === null ? '' : formatTimestampToMapOld(filters?.startDate);
    const end = filters?.endDate === undefined ? routeFilter?.endDate : filters?.endDate === null ? '' : formatTimestampToMapOld(filters?.endDate);
    try {
      const res = await dispatch(fetchRouteExport({
        data: {
          startDate: formatTimestampToMapOld(filters?.startDate) !== 'Invalid Date' && start !== null ? start : '',
          endDate: formatTimestampToMapOld(filters?.endDate) !== 'Invalid Date' && end !== null ? end : '',
          fleetName: filters?.driverName ? filters?.driverName : '',
        }
      }
      )).unwrap();
      const parseData = await parseCsv(res);
      setCsv(parseData);
    } catch (error) {
    }
  };

  useEffect(() => {
    handleExport(routeFilter);
  }, [routeFilter]);

  const onRowClicked = useCallback((row) => {
    dispatch(openCustomDrawer({
      drawer: 'TASK_DETAIL_ROUTE',
      drawerData: row?.data?._id,
    }));
  }, []);

  return (
    <>
      <Grid container alignItems="center" rowSpacing={2} columnSpacing={2} mt={0} mb={3}>
        <Grid item xs={12} sm={2}>
          <Typography variant='h4' className='text-24' color='#ffffff'>Route Analytics</Typography>
        </Grid>
        <Grid item xs={12} sm={10}>
          <Stack direction="row" justifyContent={{ xs: 'start', sm: 'end' }} flexWrap="wrap" alignItems="stretch" gap={1.5}>
            <TextField autoComplete="off" className="custom-search-field dark-field" placeholder="Search" label=""
              value={searchTerm} onChange={(e) => searchHandler(e.target.value)}
              InputProps={{
                startAdornment: <InputAdornment position="start">
                  <IconButton size='small'>
                    <SearchIcon width='18px' height='18px' />
                  </IconButton>
                </InputAdornment>,
                endAdornment: searchTerm?.length > 0 ? <InputAdornment>
                  <IconButton onClick={() => searchHandler('')} size='small'>
                    <CloseRounded />
                  </IconButton>
                </InputAdornment> : null
              }}
            />
            <CSVLink data={csv} style={{ color: '#ffffff', textTransform: 'none' }}>
              <MDButton onClick={() => { handleExport(); }} style={{ textTransform: 'none', minWidth: '180px', borderRadius: '30px', minHeight: '40px' }}
                startIcon={<FileDownloadOutlinedIcon fontSize='large' />}
                variant="contained" color="primary">
                Export as .CSV
              </MDButton>
            </CSVLink>
          </Stack>
        </Grid>
      </Grid>
      <div className="ag-theme-quartz-dark row-pointer">
        <AgGridTable
          col={columnDefs}
          mainGridApiRef={mainGridApiRef}
          getRows={getRows}
          pageRef={pageRef}
          isLoading={tableloading}
          style={{ height: '70vh' }}
          gridOptions={{ rowHeight: 64 }}
          onRowClicked={onRowClicked}
          maxLimit={20}
        />
      </div>
    </>
  );
};

export default RouteAnalyticsTable;