import axios from "axios";
import axiosClient from "axiosClient";
import { axiosHyperwolfDevClient } from "axiosClient";
import { axiosHyperwolfClient } from "axiosClient";
import { getQueryString } from "utilities/common";

export async function fetchBreaksAPI(filters) {
    return await axiosHyperwolfDevClient.get(`/api/v1/break/list?${getQueryString(filters)}`, )
}

export async function AddBreakAPI(data){
  return await axiosHyperwolfDevClient.post('/api/v1/break/create',data,
  )
}

export async function DeleteBreakAPI(id){
  return await axiosHyperwolfDevClient.delete(`/api/v1/break/delete/${id}`,)
}

export async function UpdateBreakAPI(id,data){
  return await axiosHyperwolfDevClient.put(`/api/v1/break/edit/${id}`,data,
    )
}

export async function fetchBreakDetailsAPI(id) {
  return await axiosHyperwolfDevClient.get(`/api/v1/break/show/${id}`,
  )
}

export async function exportBreaksAPI() {
  return await axiosHyperwolfDevClient.get('/api/v1/break/export')
}
