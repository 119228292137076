import {  Box, Button, FormControl, Grid, InputLabel, MenuItem,  Select, Stack } from '@mui/material';
import React, {  useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import MDButton from 'components/MDButton';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

function hasAnyValue(obj) {
  for (const key in obj) {
    if (obj.hasOwnProperty(key) && (
      (typeof obj[key] === 'object' && hasAnyValue(obj[key])) ||
        (typeof obj[key] !== 'object' && obj[key] !== '')
    )
    ) {
      return true;
    }
  }
  return false;
}

export default function FilterMembers(props={}) {
  const navigate = useNavigate();
  const {retailerId} = useParams();
  const [formData, setFormData] = useState({ isVerified:'', memberPlatform:'', status:''});
  const { applyMemberFilter } = props;

  const applyFiltersHandler = () => {
    const { isVerified, memberPlatform, status } = formData || {};
    let queryPayload = {
      isVerified: isVerified,
      memberPlatform: memberPlatform,
      status: status
    };
    if (queryPayload.isVerified === 'all'){
      queryPayload = { ...queryPayload, isVerified: ''};
    }
    if (queryPayload.memberPlatform === 'all'){
      queryPayload = { ...queryPayload, memberPlatform:''};
    }
    if (queryPayload.status === 'all'){
      queryPayload = { ...queryPayload, status:''};
    }

    const searchParams = new URLSearchParams();
    let filterValue = { ...queryPayload, filter: 'retailerMembers' } || {};
    Object.keys(filterValue).forEach((key => {
      searchParams.append(key, filterValue[key]);
    }));
    navigate({ search: searchParams.toString() });
    applyMemberFilter({queryPayload});
  };


  const clearAllHandler = () => {
    setFormData({ isVerified:'', memberPlatform:'', status:''});
    navigate(`/retailers/detail/${retailerId}?filter=retailerMembers`);
    applyMemberFilter({});
  };

  const showApplyButton = useMemo(() => {
    return hasAnyValue(formData);
  },[formData]);

  return (
    <Box className="cus-filter-sec" mb={1}>
      <Grid className='filter-box' container alignItems="center" spacing={1.5}>
        <Grid item xs={12} sm={12} md={12} xxl={12}>
          <Box className="filter-price-unit">

            <Stack direction='row' justifyContent='flex-end' width='100%' gap={2} >
              <Grid item xs={12} sm={4} md={2} xxl={2}>
                <Box className="filter-pro-status">
                  <FormControl fullWidth>
                    <InputLabel>User Verified</InputLabel>
                    <Select size='small' value={formData?.isVerified} onChange={(e) => setFormData({ ...formData, isVerified: e.target.value })} fullWidth className='form-select' name='status' label='Product Status' >
                      <MenuItem value='all'>All</MenuItem>
                      <MenuItem value='true'>Verified</MenuItem>
                      <MenuItem value='false'>Unverified</MenuItem>
                    </Select>

                  </FormControl>
                </Box>
              </Grid>

              <Grid item xs={12} sm={4} md={2} xxl={2}>
                <Box className="filter-pro-status">
                  <FormControl fullWidth>
                    <InputLabel>Available</InputLabel>
                    <Select size='small' value={formData?.memberPlatform} onChange={(e) => setFormData({ ...formData, memberPlatform: e.target.value })} fullWidth className='form-select' name='status' label='Product Status' >
                      <MenuItem value='all'>All</MenuItem>
                      <MenuItem value='ecommerce'>Ecommerce</MenuItem>
                      <MenuItem value='store'>Store</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Grid>

              <Grid item xs={12} sm={4} md={2} xxl={2}>
                <Box className="filter-pro-status">
                  <FormControl fullWidth>
                    <InputLabel>Status</InputLabel>
                    <Select size='small' value={formData?.status} onChange={(e) => setFormData({ ...formData, status: e.target.value })} fullWidth className='form-select' name='status' label='Product Status' >
                      <MenuItem value='all'>All</MenuItem>
                      <MenuItem value='true'>Active</MenuItem>
                      <MenuItem value='false'>Inactive</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Grid>

              <Grid item xs={12} sm={4} md={4} xxl={1.5}>
                <Box className="filter-btns">
                  <Stack direction='row' alignItems="center" gap={1} className='w-100'>
                    <Button className="filter-clear-all" onClick={clearAllHandler} size="small" variant="text" color="secondary" style={{ width: '100%' }}>
                                        Clear All
                    </Button>
                    <MDButton disabled={!showApplyButton} className="filter-apply" onClick={applyFiltersHandler} size="small" variant="contained" color="primary" style={{ width: '100%' }}>
                                        Apply
                    </MDButton>
                  </Stack>
                </Box>
              </Grid>
            </Stack>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}