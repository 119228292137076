import axiosClient from "axiosClient";
import { getQueryString } from "utilities/common";

export async function getApprovalsAPI(filters) {
    return await axiosClient.get(`/api/v1/admin/retailer/products/approvals/details?${getQueryString(filters)}`)
}

export async function updateApprovalAPI(data){
    return await axiosClient.post(`/api/v1/admin/retailer/products/approvals/update`, data)
}

export async function getApprovalHistoryAPI(){
    return await axiosClient.get('/api/v1/admin/retailer/products/approvals/history/details')
}

export async function getApprovalDetailAPI(approvalId){
    return await axiosClient.get(`/api/v1/admin/retailer/products/approvals/${approvalId}`)
}

export async function overrideApprovalAPI(approvalId, filters, data){
    return await axiosClient.post(`/api/v1/admin/retailer/products/override/${approvalId}?${getQueryString(filters)}`, data)
}