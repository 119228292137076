import { Box } from '@mui/material';
import React from 'react';
import noDataFound from 'assets/images/nothing-found.svg';

export default function NoDataFound({ text = 'No Data Found' }) {
  return (
    <Box textAlign='center' mt={{ xs: 4, sm: 4, md: 6 }} className='no-data-found'>
      <img style={{ maxWidth: '180px' }} src={noDataFound} alt='No Data Found' />
      <p><strong> {text}</strong></p>
    </Box>
  );
}