import axiosClient from "axiosClient";
import { getQueryString } from "utilities/common";

export async function memberStatsDataAPI() {
    return await axiosClient.get('/api/v1/admin/dashboard/member/data');
}


export async function categorySoldStatsAPI(filters) {
    return await axiosClient.get(`/api/v1/admin/dashboard/category/sold?${getQueryString(filters)}`);
}


export async function taxCalculationsStatsAPI(filters) {
    return await axiosClient.get(`/api/v1/admin/dashboard/tax/calculation?${getQueryString(filters)}`);
}


export async function memberCalculationsStatsAPI(filters) {
    return await axiosClient.get(`/api/v1/admin/dashboard/category/sold?${getQueryString(filters)}`);
}


export async function creditCalculationsStatsAPI(filters) {
    return await axiosClient.get(`/api/v1/admin/dashboard//total/credit?${getQueryString(filters)}`);
}


export async function totalOrdersStatsAPI(filters) {
    return await axiosClient.get(`/api/v1/admin/dashboard/total/orders?${getQueryString(filters)}`);
}

export async function totalRevenueStatsAPI(filters) {
    return await axiosClient.get(`/api/v1/admin/dashboard/total/revenue?${getQueryString(filters)}`);
}


export async function totalProductsSoldStatsAPI(filters) {
    return await axiosClient.get(`/api/v1/admin/dashboard/product/sold?${getQueryString(filters)}`);
}

export async function totalProductsSoldAPI(filters) {
    return await axiosClient.get(`/api/v1/admin/dashboard/total/products/sold?${getQueryString(filters)}`);
}


export async function completedOrderStatsAPI(filters) {
    return await axiosClient.get(`/api/v1/admin/dashboard/total/orders/completed?${getQueryString(filters)}`);
}

export async function cancelledOrderStatsAPI(filters) {
    return await axiosClient.get(`/api/v1/admin/dashboard/total/order/canceled?${getQueryString(filters)}`);
}


export async function totalNewCustomersAPI(filters) {
    return await axiosClient.get(`/api/v1/admin/dashboard/customers/graph?${getQueryString(filters)}`);
}
