import { Archive, CloseRounded } from '@mui/icons-material';
import { Box, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Select, Stack, TextField, Tooltip, Typography, FormGroup, Checkbox, FormControlLabel, Button } from '@mui/material';
import SearchIcon from 'common/customIcons/search';
import MDButton from 'components/MDButton';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDebounce } from 'hooks/useDebounce';
import { AgGridTable } from 'components/tables/AgGridTable';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { fetchTaskTable, addTaskArchive, tableUnarchiveExport } from '../../../redux/slices/hyperdrive/drivers';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import StatusTask from 'common/component/StatusTask';
import ArchiveIcon from 'common/customIcons/archive';
import { CSVLink } from 'react-csv';
import Papa from 'papaparse';
import moment from 'moment';
import { openSnackbar } from '../../../redux/slices/common';
import { customApiSwitch, openCustomDrawer } from '../../../redux/slices/modalSlice';
import { formatTimestampToMapOld } from 'utilities/common';
import StartTaskIcon from 'common/customIcons/starttask';
import ReturnHQTaskIcon from 'common/customIcons/returnhqtask';
import BreakTaskIcon from 'common/customIcons/BreakTask';

export const Table = ({ dataPassComponent }) => {
  if (!dataPassComponent || Object.keys(dataPassComponent).length === 0) {
    return <div>Loading...</div>;
  }

  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState('');
  const [csv, setCsv] = useState([]);
  const mainGridApiRef = useRef('');
  const pageRef = useRef(0);
  const gridRefApi = useRef(null);
  const searchTermRef = useRef('');
  const { taskTable = {}, taskloading: taskloading = false } = useSelector(state => state.drivers);
  const { routeData } = useSelector(state => state.modal);
  const [formData, setFormData] = useState({ activeDriver: '', taskType: [] });
  const activeTask = useRef('');
  const today = new Date();
  const nextDate = moment(today).add(1, 'day').toDate();
  const dataPassRef = useRef(dataPassComponent);

  useEffect(() => {
    dataPassRef.current = dataPassComponent;
  }, [dataPassComponent]);


  const searchHandler = (searchValue) => {
    setSearchTerm(searchValue);
    searchTermRef.current = searchValue;
    handleSearch(searchValue);
  };

  const columnDefs= [
    {
      headerName: 'Task ID',
      field: 'taskDisplayId',
      minWidth: 180,
      sortable: false,
      cellRenderer: useCallback((props) => (
        <>
          {
            props?.value ?
              <div className='d-flex align-item-top jc-center flex-column gap-1'>
                <Typography className='text-12'>{`${props?.value}`}</Typography>
                {props?.data?.taskType === 'startTask' ? <Typography className='d-flex align-item-center gap-2 text-12 color-start-task'>
                  <StartTaskIcon /> <span>Start Task</span>
                </Typography>
                  : props?.data?.taskType === 'returnToHeadquarterTask' ? <Typography className='d-flex align-item-center gap-2 text-12 color-return-to-hq'>
                    <ReturnHQTaskIcon /> <span>Return To HQ</span>
                  </Typography>
                    : props?.data?.taskType === 'breakTask' ? <Typography className='d-flex align-item-center gap-2 text-12 color-break-task'>
                      <BreakTaskIcon /> <span>Break Task</span>
                    </Typography>
                      : ''}
              </div>
              : '-'
          }
        </>
      ), []),
    },
    {
      headerName: 'Transaction Number',
      field: 'transactionNumber',
      sortable: false,
      suppressMenu: false,
      minWidth: 200,
      flex: 1,
      cellStyle: { whiteSpace: 'normal', lineHeight: '1.5' },
      cellRenderer: useCallback((props) => (
        <>
          {props.value
            ? props?.value
            : '-'}
        </>
      ), []),
    },
    {
      headerName: 'Assigned To',
      field: 'driverNameSorting',
      cellRenderer: useCallback((props) => (
        props?.data?.fleet?.fleetName ? props?.data?.fleet?.fleetName : '-'
      ), []),
      minWidth: 120
    },
    {
      headerName: 'Destination',
      sortable: false,
      field: 'address',
      cellRenderer: useCallback((props) => (
        <>
          {props?.data?.address?.address1 ?
            <Tooltip className="hd-tooltip" title={props?.data?.address?.address1} arrow placement="top-end" >
              <Box mr={2} className='text-truncate'>{props?.data?.address?.address1}</Box>
            </Tooltip>
            : '-'
          }
        </>
      ), []),
      minWidth: 260
    },
    {
      headerName: 'Recipient',
      field: 'recipientDetails',
      sortable: false,
      cellRenderer: useCallback((props) => (
        <>
          {props.data?.recipientDetails?.name
            ? <Box mr={2} className='text-truncate'>{props.data.recipientDetails.name}</Box>
            : <Box mr={2} className='text-truncate'>-</Box>}
        </>
      ), []),
      minWidth: 180,
    },
    {
      headerName: 'Status',
      field: 'taskStatus',
      sortable: false,
      suppressMenu: false,
      minWidth: 120,
      flex: 1,
      cellStyle: { whiteSpace: 'normal', lineHeight: '1.5' },
      cellRenderer: useCallback((props) => (
        <StatusTask value={props?.data?.taskStatus} icon={'showIcon'} />
      ), []),
    },
    {
      headerName: 'Delivery Speed',
      field: 'orderType',
      suppressMenu: false,
      minWidth: 180,
      flex: 1.5,
      cellStyle: { whiteSpace: 'normal', lineHeight: '1.5' },
      cellRenderer: (props) => (
        <Box>{props?.data?.orderType ? <p style={{ textTransform: props?.data?.orderType === 'asap' ? 'uppercase' : 'capitalize' }}>{props?.data?.orderType} </p> : '-'}
          <p> {props?.data?.orderType === 'schedule' && `${props?.data?.slotDetails?.start} - ${props?.data?.slotDetails?.end}`}</p>
        </Box>
      ),
      sortable: false
    },
    {
      headerName: 'Task Created',
      field: 'createdDateSorting',
      suppressMenu: false,
      cellRenderer: useCallback((props) => (
        props?.data?.createdDate ? props?.data?.createdDate : '-'
      ), []),
      minWidth: 180
    },
    {
      headerName: 'Last Updated',
      field: 'updatedDateSorting',
      suppressMenu: false,
      cellRenderer: useCallback((props) => (
        props?.data?.updatedDate ? props?.data?.updatedDate : '-'
      ), []),
      minWidth: 180
    },
    {
      headerName: 'Actions',
      suppressMenu: true,
      sortable: false,
      cellRenderer: (props) => {
        const taskId = props?.data?._id;
        const data =
                {
                  taskId: taskId,
                  taskArchived: true
                };
        return <Box display="inline-flex" alignItems="center">
          <IconButton color='warning' size='small' variant="primary" sx={{ marginLeft: 1, color: '#FFDB1E' }}
            onClick={(e) => {
              e.stopPropagation();
              dispatch(addTaskArchive({ data })).unwrap()
                .then(res => {
                  searchFn(dataPassComponent);
                  showResponseMessage(res?.message, 'success');
                })
                .catch(err => {
                  showResponseMessage(err?.message, 'error');
                });
            }}
          >
            <Archive width='16px' height='16px' />
          </IconButton>
        </Box>;
      },
      cellStyle: { textAlign: 'right', justifyContent: 'end' },
      headerClass: 'right-aligned-cell-header',
      minWidth: 120,
      maxWidth: 100,
    },
  ];

  const rowClassRules = useMemo(() => {
    return {
      'task-start': (params) => {
        return params?.data?.taskType === 'startTask';
      },
      'task-return': (params) => {
        return params?.data?.taskType === 'returnToHeadquarterTask';
      },
      'task-break': (params) => {
        return params?.data?.taskType === 'breakTask';
      },
    };
  }, []);

  const onRowClicked = (row) => {
    const data = {
      skip: 0,
      limit: 20,
      searchTerm: searchTermRef.current,
      startDate: dataPassComponent?.startDate ? formatTimestampToMapOld(dataPassComponent?.startDate) : formatTimestampToMapOld(today),
      endDate: dataPassComponent?.endDate ? formatTimestampToMapOld(dataPassComponent?.endDate) : formatTimestampToMapOld(nextDate),
      taskStatus: dataPassComponent?.taskStatus ? dataPassComponent?.taskStatus : 'not_started,in_progress,unassigned,completed,cancelled',
      driverStatus: dataPassComponent?.driverStatus ? dataPassComponent?.driverStatus : 'offduty,intransit,idle'
    };
    if (row?.colDef?.headerName !== 'Actions') {
      dispatch(openCustomDrawer({
        drawer: 'TASK_DETAIL',
        drawerData: row?.data._id,
        drawerApi: data,
        activeScreen: 'table'
      }));
    }
  };

  const getSortInfo = useCallback((data) => {
    const result = {};
    data.forEach(item => {
      if (item?.colId === 'driverNameSorting') {
        result[item.colId] = item.sort === 'asc' ? 'asc' : 'desc';
      } else if (item?.colId === 'createdDateSorting') {
        result[item.colId] = item.sort === 'asc' ? 'asc' : 'desc';
      } else if (item?.colId === 'updatedDateSorting') {
        result[item.colId] = item.sort === 'asc' ? 'asc' : 'desc';
      }
    });
    return result;
  }, []);

  const showResponseMessage = (message, errortype) => {
    if (errortype === 'success') {
      dispatch(openSnackbar({ content: message, color: 'success', icon: 'check' }));
    }
    if (errortype === 'error') {
      dispatch(openSnackbar({ content: message, color: 'error', icon: 'warning' }));
    }
  };

  const getRows = useCallback(async (params, filters) => {
    gridRefApi.current = params;
    let filter = {};
    if (params?.sortModel.length) {
      filter = { ...getSortInfo(params?.sortModel) };
    }
    try {
      const res = await dispatch(fetchTaskTable({
        data: {
          skip: searchTermRef.current ? 0 : pageRef.current,
          limit: 20,
          ...filter,
          searchTerm: searchTermRef.current,
          orderType: activeTask?.current ? activeTask?.current : '',
          taskType: formData?.taskType ? formData?.taskType : '',
          startDate: dataPassRef.current?.startDate ? formatTimestampToMapOld(dataPassRef.current.startDate) : formatTimestampToMapOld(today),
          endDate: dataPassRef.current?.endDate ? formatTimestampToMapOld(dataPassRef.current.endDate) : formatTimestampToMapOld(nextDate),
          taskStatus: dataPassRef.current?.taskStatus ? dataPassRef.current.taskStatus : 'not_started,in_progress,unassigned,completed,cancelled',
          driverStatus: dataPassRef.current?.driverStatus ? dataPassRef.current.driverStatus : 'offduty,intransit,idle',
        },
      })).unwrap();
      const { tasks } = res?.data;
      const { totalTasks } = res;
      const driverListArray = tasks?.map((item) => ({
        ...item,
      }));
      params.successCallback([...driverListArray], totalTasks);
      pageRef.current += 20;
    } catch {
      params.successCallback([], 0);
    }
  }, [dispatch, dataPassComponent, formData]);

  useEffect(() => {
    if (dataPassComponent) {
      searchFn(dataPassComponent);
    }
  }, [dataPassComponent, formData]);

  useEffect(() => {
    if (routeData === 'table') {
      searchFn(dataPassComponent);
      dispatch(customApiSwitch({
        dataSwitch: '',
        routeData: ''
      }));
    }
  }, [routeData]);

  useEffect(() => {
    if (mainGridApiRef?.current) {
      if (taskloading) {
        mainGridApiRef.current.showLoadingOverlay();
      } else if (taskTable?.totalTasks === 0) {
        mainGridApiRef.current.showNoRowsOverlay();
      } else {
        mainGridApiRef.current.hideOverlay();
      }
    }
  }, [taskTable, taskloading, dataPassComponent]);

  const searchFn = useCallback((dataPassComponent) => {
    pageRef.current = 0;
    const dataSource = {
      getRows: (params) => getRows(params, dataPassComponent)  // Pass status to getRows
    };
    if (mainGridApiRef.current) {
      mainGridApiRef.current.ensureIndexVisible(0, null);
      mainGridApiRef.current.setGridOption('datasource', dataSource);
    }
  }, [getRows]);

  const handleSearch = useDebounce(() => {
    pageRef.current = 0;
    searchFn(dataPassComponent);
  }, 500);

  const parseCsv = (csv) => {
    return new Promise((resolve, reject) => {
      Papa.parse(csv, {
        header: true,
        skipEmptyLines: true,
        complete: (result) => {
          resolve(result.data);
        },
        error: (error) => {
          reject(error);
        }
      });
    });
  };

  const handleExport = async (filters) => {
    try {
      const res = await dispatch(tableUnarchiveExport({
        data: {
          searchTerm: searchTermRef.current,
          taskType: formData?.taskType ? formData?.taskType : '',
          orderType: activeTask?.current ? activeTask?.current : '',
          startDate: filters?.startDate ? formatTimestampToMapOld(filters?.startDate) : formatTimestampToMapOld(today),
          endDate: filters?.endDate ? formatTimestampToMapOld(filters?.endDate) : formatTimestampToMapOld(nextDate),
          taskStatus: filters?.taskStatus ? filters?.taskStatus : 'not_started,in_progress,intransit,completed,cancelled',
          driverStatus: filters?.driverStatus ? filters?.driverStatus : 'offduty,intransit,idle'
        },
      })).unwrap();
      const parseData = await parseCsv(res);
      setCsv(parseData);
    } catch (error) {
    }
  };

  useEffect(() => {
    handleExport(dataPassComponent);
  }, [dataPassComponent, formData]);

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    setFormData((prevData) => {
      if (checked) {
        return { taskType: [...prevData.taskType, value] };
      } else {
        return { taskType: prevData.taskType.filter((task) => task !== value) };
      }
    });
  };

  return (
    <Box p={2} className="h-100 row-pointer table-pg">
      <Grid container alignItems="center" rowSpacing={2} columnSpacing={2} mt={0} mb={3}>
        <Grid item xs={12} sm={1}>
          <Typography variant='h4' className='text-24' color='#ffffff'>Table</Typography>
        </Grid>
        <Grid item xs={12} sm={11}>
          <Stack direction="row" justifyContent={{ xs: 'start', sm: 'end' }} flexWrap="wrap" alignItems="stretch" gap={1.5}>
            <FormGroup className="multi-checkbox-sec gap-3">
              <FormControlLabel control={<Checkbox value="deliveryTask" onChange={handleCheckboxChange} />} label="Delivery Task" />
              <FormControlLabel control={<Checkbox value="startTask" onChange={handleCheckboxChange} />} label="Start Task" />
              <FormControlLabel control={<Checkbox value="returnToHeadquarterTask" onChange={handleCheckboxChange} />} label="Return to HQ" />
              <FormControlLabel control={<Checkbox value="breakTask" onChange={handleCheckboxChange} />} label="Break Task" />
            </FormGroup>
            <TextField autoComplete="off" className="custom-search-field dark-field" placeholder="Search" label=""
              value={searchTerm} onChange={(e) => searchHandler(e.target.value)}
              InputProps={{
                startAdornment: <InputAdornment position="start">
                  <IconButton size='small'>
                    <SearchIcon width='18px' height='18px' />
                  </IconButton>
                </InputAdornment>,
                endAdornment: searchTerm?.length > 0 ? <InputAdornment>
                  <IconButton onClick={() => searchHandler('')} size='small'>
                    <CloseRounded />
                  </IconButton>
                </InputAdornment> : null
              }}
              style={{ width: '270px' }}
            />
            <Stack direction='row' alignItems="center" gap={1} className="delivery-speed-field dark-field">
              <FormControl fullWidth sx={{ width: 270 }}>
                <InputLabel>Delivery Speed</InputLabel>
                <Select size='small' value={activeTask.current}
                  onChange={(e) => { setFormData((prevData) => ({ ...prevData, activeDriver: e.target.value })); activeTask.current = e.target.value; }}
                  fullWidth className='form-select' name='status' label='Product Status' MenuProps={{ className: 'cus-popover' }}>
                  <MenuItem value='asap'>ASAP</MenuItem>
                  <MenuItem value='schedule'>Schedule</MenuItem>
                  <MenuItem className="dropdown-reset">
                    <Button onClick={() => { setFormData((prevData) => ({ ...prevData, activeDriver: '' })); activeTask.current = ''; }}>Reset</Button>
                  </MenuItem>
                </Select>
              </FormControl>
            </Stack>
            <CSVLink data={csv} style={{ color: '#ffffff', textTransform: 'none' }}>
              <MDButton onClick={() => { handleExport(dataPassComponent); }} style={{ height: '100%', textTransform: 'none', minWidth: '180px', borderRadius: '30px', minHeight: '40px' }}
                startIcon={<FileDownloadOutlinedIcon fontSize='large' />} variant="contained" color="primary"  >
                                Export as .CSV
              </MDButton>
            </CSVLink>
          </Stack>
        </Grid>
      </Grid>
      <div className="ag-theme-quartz-dark">
        <AgGridTable
          col={columnDefs}
          mainGridApiRef={mainGridApiRef}
          getRows={getRows}
          pageRef={pageRef}
          isLoading={taskloading}
          style={{ height: '70vh' }}
          gridOptions={{ rowHeight: 64 }}
          maxLimit={20}
          onRowClicked={onRowClicked}
          rowClassRules={rowClassRules}
        />
      </div>
      <Stack direction='row' alignItems="center" justifyContent="end" mt={3} className='w-100 yellow-btn'>
        <MDButton style={{ height: '100%', textTransform: 'none', minWidth: '120px' }}
          variant="contained" color="primary"
          onClick={() => setSearchParams({ roleTab: 'archive' })}
          className="d-flex align-item-center gap-2"  >
          <ArchiveIcon />
          <span>Archive</span>
        </MDButton>
      </Stack>
    </Box>
  );
};

export default Table;