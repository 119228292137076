import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import the styles

const CommonQuillEditor = ({ value, disabled = false, onChange, allowImage = true }) => {

  const [editorHtml, setEditorHtml] = useState('');

  const handleChange = (html) => {
    setEditorHtml(html);
    onChange && onChange(html);
  };

  let editor = '';

  useEffect(() => {
    setEditorHtml(value);
  }, [value]);

  return (
    <div>
      <ReactQuill
        className="quill-text-editor"
        theme="snow"
        value={editorHtml}
        readOnly={disabled}
        onChange={handleChange}
        modules={{
          toolbar: {
            container: [
              [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
              ['bold', 'italic', 'underline', 'strike', 'blockquote'],
              [{ list: 'ordered' }, { list: 'bullet' }],
              allowImage ? ['link', 'image'] : ['link'],
              ['clean'],
              [{ 'color': [] }, { 'background': [] }],
              [{ 'font': [] }],
              [{ 'size': ['small', false, 'large', 'huge'] }],
              [{ 'align': [] }]
            ],
          },
        }}
        ref={(quill) => {
          if (quill) {
            editor = quill;
          }
        }}
      />
    </div>
  );
};

export default CommonQuillEditor;
