import React, { useEffect,  useState } from 'react';
import {
  TextField,
  Grid,
  Card,
  Typography,
  Box,
  Stack,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  FormHelperText,
  IconButton,

} from '@mui/material';
import MDButton from 'components/MDButton';
import { useDispatch, useSelector } from 'react-redux';
import { openSnackbar } from '../../redux/slices/common';
import { useNavigate, useParams } from 'react-router-dom';
import { isEmptyObject } from 'utilities/common';
import { closeCustomModalDialog, openCustomModalDialog, setModalLoader } from '../../redux/slices/modalSlice';
import {  deleteAdmin } from '../../redux/slices/admin';
import { roleFormValidation } from 'validations/roleAndPermissions';
import { addRole, updateRole } from '../../redux/slices/roleAndPermissions';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {  RemoveRedEye } from '@mui/icons-material';
const AddRoleForm = () => {
  const { roles = {},isLoading: loading = false ,roleDetails } = useSelector(state => state.roleAndPermissions);
  const {roleId} = useParams();
  const { isUploading = false } = useSelector(state => state.common);
  
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    roles: [],
    confirmPassword: '',
    password: '',
    status:'active'
  });
  const [showEyeIcon, setShowEyeIcon] = useState({
    confirmPassword: false,
    password: false
  });
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'phone' && value?.length > 10) return;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { errors, isValid } = roleFormValidation(formData, roleId? false : true);
    if (!isValid) {
      setErrors(errors);
      return;
    }
    setErrors({});
    const payload = {
      firstName: formData?.firstName,
      lastName: formData?.lastName,
      email: formData?.email,
      status: formData?.status,
      userRoles: formData?.roles,
      password: formData?.password,
    };
    if(roleId){
      delete payload.password;
    }
    if (roleId) {
      dispatch(updateRole({ data: payload, adminId:roleId })).unwrap().then((res) => {
        showResponseMessage(res?.message, 'success');
        navigate('/user-roles');
      }).catch((err) => {
        showResponseMessage(err?.message, 'error');
      });
    } else {
      dispatch(addRole(payload)).unwrap().then((res) => {
        showResponseMessage(res?.message, 'success');
        navigate('/user-roles');
      }).catch((err) => {
        showResponseMessage(err?.message, 'error');

      });
    }
  };

  const showResponseMessage = (message, errortype) => {
    if (errortype === 'success') {
      dispatch(openSnackbar({ content: message, color: 'success', icon: 'check' }));
    }
    if (errortype === 'error') {
      dispatch(openSnackbar({ content: message, color: 'error', icon: 'warning' }));
    }
  };
  

  const deleteHandler = (roleId) => {
    dispatch(setModalLoader(true));
    dispatch(deleteAdmin(roleId)).unwrap().then((res) => {
      showResponseMessage(res?.message, 'success');
      dispatch(closeCustomModalDialog());
      navigate('/admins');
    }).catch((err) => {
      dispatch(setModalLoader(false));
      showResponseMessage(err?.message, 'error');
    });
  };

  useEffect(() => {
    if (!isEmptyObject(roleDetails)) {
      let payload = {
        ...roleDetails,roles:roleDetails?.userRoles || []
      };
      setFormData(payload);
    }
  }, [roleDetails]);

  return (
    <Box p={{ xs: 2, sm: 3, lg: 4 }}>
      <Card sx={{ p: { xs: 2, md: 3 }, borderRadius: 2.5, boxShadow: 'none' }}>
        <Stack direction={{ xs: 'column', sm: 'row' }} alignItems={{ sm: 'center' }} justifyContent="space-between" gap={2} mb={4.5}>
          <Typography variant='h4' fontWeight="semibold" color="secondary">{roleId ? 'Update' : 'Add'} User</Typography>
          <Box display="flex" alignItems="center" gap={1.25}>
            <Typography variant='h5' fontWeight="semibold" color="secondary">Status</Typography>
            <Stack direction="row" alignItems="center" gap={.75} lineHeight={1} p={.75} borderRadius={2} bgcolor="#F2F3F7">
              <MDButton
                sx={{ minWidth: '92px', borderRadius: 1.75, minHeight: '28px', p: .25, fontWeight: 500 }}
                type='button'
                variant={formData?.status === 'active' ? 'contained' : 'text'}
                color={formData?.status === 'active' ? 'success' : 'dark'}
                size="small"
                onClick={() => setFormData({ ...formData, status: 'active' })}
              >
                                Active
              </MDButton>
              <MDButton
                sx={{ minWidth: '92px', borderRadius: 1.75, minHeight: '28px', p: .25, fontWeight: 500 }}
                type='button'
                variant={formData?.status !== 'active' ? 'contained' : 'text'}
                color={formData?.status !== 'active' ? 'error' : 'dark'}
                size="small"
                onClick={() => setFormData({ ...formData, status: 'inactive' })}
              >
                                Inactive
              </MDButton>
            </Stack>
          </Box>
        </Stack>

        <Grid container spacing={2.5} mb={4.5}>
          <Grid item xs={12} md={6} lg={6}>
            <TextField className="form-input" error={errors?.firstName} helperText={errors.firstName} fullWidth label="First Name" name="firstName" value={formData?.firstName || ''} onChange={handleChange} />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <TextField className="form-input" error={errors?.lastName} helperText={errors.lastName} fullWidth label="Last Name" name="lastName" value={formData?.lastName} onChange={handleChange} />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <TextField className="form-input" error={errors?.email} helperText={errors.email} fullWidth label="Email" name="email" value={formData?.email} onChange={handleChange} />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <FormControl fullWidth>
              <InputLabel>User Roles</InputLabel>
              <Select
                multiple
                value={formData?.roles}
                onChange={(e) => setFormData({ ...formData, roles: e.target.value })}
                name='role'
                label='User Role'
                fullWidth
                className='form-select days-form-select'
                error={Boolean(errors.roles)}
              >
                {
                  roles?.map(((role,idx)=>{
                    return <MenuItem value={role} key={idx}>{role}</MenuItem>;
                  }))
                }
              </Select>
              {
                Boolean(errors.roles) && <FormHelperText error>{errors.roles}</FormHelperText>
              }
            </FormControl>
          </Grid>
          {
            !roleId && <>
              <Grid item xs={12} md={6} lg={6}>
                <TextField className="form-input" type={showEyeIcon?.password ? 'text' : 'password'} fullWidth error={errors?.password} helperText={errors.password} label="Password" name="password" value={formData.password} onChange={handleChange}
                  InputProps={{
                    endAdornment: <>
                      {!showEyeIcon?.password ? <IconButton onClick={() => setShowEyeIcon({ ...showEyeIcon, password: !showEyeIcon.password })}>
                        <VisibilityOffIcon />
                      </IconButton> :
                        <IconButton onClick={() => setShowEyeIcon({ ...showEyeIcon, password: !showEyeIcon.password })} >
                          <RemoveRedEye />
                        </IconButton>
                      }
                    </>
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <TextField className="form-input" fullWidth type={showEyeIcon?.confirmPassword ? 'text' : 'password'} error={errors?.confirmPassword} helperText={errors?.confirmPassword} label="Confirm Password" name="confirmPassword" value={formData?.confirmPassword} onChange={handleChange} InputProps={{
                  endAdornment: <>
                    {!showEyeIcon?.confirmPassword ? <IconButton onClick={() => setShowEyeIcon({ ...showEyeIcon, confirmPassword: !showEyeIcon.confirmPassword })}>
                      <VisibilityOffIcon />
                    </IconButton> :
                      <IconButton onClick={() => setShowEyeIcon({ ...showEyeIcon, confirmPassword: !showEyeIcon.confirmPassword })} >
                        <RemoveRedEye />
                      </IconButton>
                    }
                  </>
                }} />
              </Grid>
            </>
          }

        </Grid>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1.25} mt={4} mb={2}>
          <MDButton
            sx={{ minWidth: '140px' }}
            type='button'
            variant='contained'
            color='primary'
            onClick={handleSubmit}
            disabled={isUploading || loading}
            loading={isUploading || loading}

          >
            {roleId ? 'Update' : 'Save'}
          </MDButton>
          {
            roleId && <MDButton
              sx={{ minWidth: '140px', backgroundColor: '#F1F1F1', color: '#FD4438' }}
              type='button'
              variant='contained'
              color='error'
              onClick={() => dispatch(openCustomModalDialog({ modalData: { deleteHandler, deleteId: roleId }, modal: 'DELETE_MODAL' }))}
            >
                            Delete
            </MDButton>
          }
          <MDButton
            sx={{ minWidth: '140px' }}
            type='button'
            variant='outlined'
            color='dark'
            onClick={() => navigate('/user-roles')}
          >
                        Cancel
          </MDButton>
        </Stack>
      </Card>
    </Box>
  );
};

export default AddRoleForm;