import axiosClient from "axiosClient";
import { axiosHyperwolfDevClient } from "axiosClient";
import { getQueryString } from "utilities/common";

export async function fetchRegionsAPI(filters) {
    return await axiosClient.get(`/api/v1/admin/region/get/?${getQueryString(filters)}`);
}
export async function addRegionAPI(data) {
    return await axiosClient.post(`/api/v1/admin/region`,data);
}

export async function updateRegionId(data,regionId) {
    return await axiosClient.put(`/api/v1/admin/region/update/${regionId}`,data);
}

export async function deleteRegionAPI(regionId) {
    return await axiosClient.delete(`/api/v1/admin/region/${regionId}`);
}

export async function fetchRegionDetailsAPI(regionId) {
    return await axiosClient.get(`/api/v1/admin/region/${regionId}`);
}

export async function fetchDriverAssignedRegionAPI(terminalId) {
    return await axiosHyperwolfDevClient.get(`/api/v1/partner/region/details/${terminalId}`)
}