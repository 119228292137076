// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

import { IconButton, InputAdornment } from '@mui/material';
import MDInput from 'components/MDInput'
import React from 'react'
import SearchIcon from 'common/customIcons/search'

const Search = ({ onChange=()=>{},value,onSearch=()=>{} , ...rest}) => {
    const handleSearch = () => {
        // Perform search action with the search term
        if (onSearch) {
            onSearch(searchTerm);
        }
    };

    const handleInputChange = (event) => {
        onChange(event)
    };
    
    return (
        <MDInput
            label="Search..."
            value={value}
            id="search"
            className="search-field"
            onChange={handleInputChange}
            autoComplete='off'
            InputProps={{
                endAdornment: (
                    <InputAdornment position="start">
                        <IconButton size='small'>
                            <SearchIcon width='18px' height='18px' />
                        </IconButton>
                    </InputAdornment>
                ),
            }}
            {...rest}
            />
    )
}
Search.propTypes = {
    onSearch: PropTypes.func
}

export default Search