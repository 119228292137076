import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { addProductAPI, assignInventoryAPI, createProductBatchAPI, deleteProductAPI, deleteProductBatchAPI, fetchProductBatchsAPI, fetchProductDetailsAPI, fetchProductInstructionsAPI, fetchProductInventoriesAPI, fetchProductTerpenoidAPI, fetchProductsAPI, productActivityLogsAPI, productFiltersAPI, updateProductAPI, updateProductBatchAPI } from '../apis/products'

const initialState = {
  products: [],
  loading: false,
  error: null,
  singleProduct: null,
  productTerpenoids: {}
};

// Define your asynchronous thunk
export const fetchProducts = createAsyncThunk(
  'product/fetchProducts',
  async ({ filters, payload }, { rejectWithValue }) => {
    try {
      const res = await fetchProductsAPI(filters, payload);
      return res;
    } catch (error) {
      
      return rejectWithValue(error.response?.data);
    }
  }
);

export const fetchSingleProduct = createAsyncThunk(
  'product/fetchSingleProduct',
  async (productId, { rejectWithValue }) => {
    try {
      const res = await fetchProductDetailsAPI(productId);
      return res;
    } catch (error) {
      
      return rejectWithValue(error.response?.data);
    }
  }
);

export const fetchProductBatches = createAsyncThunk(
  'product/fetchProductBatches',
  async ({ filters, productId }, { rejectWithValue }) => {
    try {
      const res = await fetchProductBatchsAPI(filters, productId);
      return res;
    } catch (error) {
      
      return rejectWithValue(error.response?.data);
    }
  }
);

export const updateProductBatch = createAsyncThunk(
  'product/updateProductBatch',
  async ({ data, productBatchId }, { rejectWithValue }) => {
    try {
      const res = await updateProductBatchAPI(data, productBatchId);
      return res;
    } catch (error) {
      
      return rejectWithValue(error.response?.data);
    }
  }
);



export const deleteProductBatch = createAsyncThunk(
  'product/deleteProductBatch',
  async (data, { rejectWithValue }) => {
    try {
      const res = await deleteProductBatchAPI(data);
      return res;
    } catch (error) {
      
      return rejectWithValue(error.response?.data);
    }
  }
);

export const fetchProductInventories = createAsyncThunk(
  'product/fetchProductInventories',
  async ({ filters, productId }, { rejectWithValue }) => {
    try {
      const res = await fetchProductInventoriesAPI(filters, productId);
      return res;
    } catch (error) {
      
      return rejectWithValue(error.response?.data);
    }
  }
);

export const addProduct = createAsyncThunk(
  'product/addProduct',
  async (data, { rejectWithValue }) => {
    try {
      const res = await addProductAPI(data);
      return res;
    } catch (error) {
      
      return rejectWithValue(error.response?.data);
    }
  }
);

export const updateProduct = createAsyncThunk(
  'product/updateProduct',
  async ({ data, productId }, { rejectWithValue }) => {
    try {
      const res = await updateProductAPI(data, productId);
      return res;
    } catch (error) {
      
      return rejectWithValue(error.response?.data);
    }
  }
);

export const assignInventory = createAsyncThunk(
  'product/assignInventory',
  async (data, { rejectWithValue }) => {
    try {
      const res = await assignInventoryAPI(data);
      return res;
    } catch (error) {
      
      return rejectWithValue(error.response?.data);
    }
  }
);

export const createProductBatch = createAsyncThunk(
  'product/createProductBatch',
  async (data, { rejectWithValue }) => {
    try {
      const res = await createProductBatchAPI(data);
      return res;
    } catch (error) {
      
      return rejectWithValue(error.response?.data);
    }
  }
);

export const deleteProduct = createAsyncThunk(
  'product/deleteProduct',
  async (productId, { rejectWithValue }) => {
    try {
      const res = await deleteProductAPI(productId);
      return res;
    } catch (error) {
      
      return rejectWithValue(error.response?.data);
    }
  }
);

export const fetchProductTerpenoids = createAsyncThunk(
  'product/fetchProductTerpenoids',
  async ({ filters, productId }, { rejectWithValue }) => {
    try {
      const res = await fetchProductTerpenoidAPI(filters, productId);
      return res;
    } catch (error) {
      
      return rejectWithValue(error.response?.data);
    }
  }
);
export const fetchFilterProducts = createAsyncThunk(
  'product/fetchFilterProducts',
  async ({ filters, productId }, { rejectWithValue }) => {
    try {
      const res = await productFiltersAPI(filters, productId);
      return res;
    } catch (error) {
      
      return rejectWithValue(error.response?.data);
    }
  }
);


export const fetchProductActivityLogs = createAsyncThunk(
  'product/fetchProductActivityLogs',
  async (filters, { rejectWithValue }) => {
    try {
      const res = await productActivityLogsAPI(filters);
      return res;
    } catch (error) {
      
      return rejectWithValue(error.response?.data);
    }
  }
);

export const fetchProductInstructions = createAsyncThunk(
  'product/fetchProductInstructions', 
  async ({categoryName}, { rejectWithValue }) => {
    try {
      const res = await fetchProductInstructionsAPI(categoryName);
      return res;
    } catch (error) {
      
      return rejectWithValue(error.response?.data);
    }
  }
)

// Create a slice
const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    // Define extra reducers here
    clearProducts: (state) => {
      state.products = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProducts.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.singleProduct = {}
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.loading = false;
        state.products = action.payload;
      })
      .addCase(fetchProducts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
    builder
      .addCase(fetchSingleProduct.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSingleProduct.fulfilled, (state, action) => {
        state.loading = false;
        state.singleProduct = action.payload;
      })
      .addCase(fetchSingleProduct.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });

    builder
      .addCase(deleteProduct.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteProduct.fulfilled, (state, action) => {
        state.loading = false;
        // Handle successful deletion if needed
      })
      .addCase(deleteProduct.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // Handle the error: store the error payload in state
      });

    builder
      // Handling pending action for fetching inventories
      .addCase(fetchProductInventories.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      // Handling fulfilled action after successfully fetching inventories
      .addCase(fetchProductInventories.fulfilled, (state, action) => {
        state.loading = false;
        state.inventories = action.payload; // Assuming the payload contains the inventories
      })
      // Handling rejected action if fetching inventories encounters an error
      .addCase(fetchProductInventories.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // Assuming the payload contains the error details
      });

    builder
      .addCase(updateProduct.pending, (state) => {
        state.isUpdating = true;
        state.error = null;
        state.updatedProduct = null;
      })
      .addCase(updateProduct.fulfilled, (state, action) => {
        state.isUpdating = false;
        state.updatedProduct = action.payload;
      })
      .addCase(updateProduct.rejected, (state, action) => {
        state.isUpdating = false;
        state.error = action.payload;
      });


    builder
      .addCase(addProduct.pending, (state) => {
        state.isUpdating = true;
        state.error = null;
      })
      .addCase(addProduct.fulfilled, (state, action) => {
        state.isUpdating = false;
      })
      .addCase(addProduct.rejected, (state, action) => {
        state.isUpdating = false;
      });

    builder
      .addCase(deleteProductBatch.pending, (state) => {
        state.isDeleting = true;
        state.error = null;
        state.deletedBatch = null;
      })
      .addCase(deleteProductBatch.fulfilled, (state, action) => {
        state.isDeleting = false;
        state.deletedBatch = action.payload;
      })
      .addCase(deleteProductBatch.rejected, (state, action) => {
        state.isDeleting = false;
        state.error = action.payload;
      });

    builder
      .addCase(fetchProductBatches.pending, (state) => {
        state.isFetching = true;
        state.error = null;
        state.productBatches = null;
      })
      .addCase(fetchProductBatches.fulfilled, (state, action) => {
        state.isFetching = false;
        state.productBatches = action.payload;
      })
      .addCase(fetchProductBatches.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action.payload;
      });

    builder
      .addCase(fetchProductTerpenoids.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchProductTerpenoids.fulfilled, (state, action) => {
        state.isFetching = false;
        state.productTerpenoids = action.payload; // Assuming your API response is the data you want to store
      })
      .addCase(fetchProductTerpenoids.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action.payload; // The rejection value from the async thunk
      });

    builder
      .addCase(fetchFilterProducts.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchFilterProducts.fulfilled, (state, action) => {
        state.isFetching = false;
      })
      .addCase(fetchFilterProducts.rejected, (state, action) => {
        state.isFetching = false;
      });
      builder
      .addCase(createProductBatch.pending, (state) => {
        state.isUpdating = true;
      })
      .addCase(createProductBatch.fulfilled, (state, action) => {
        state.isUpdating = false;
      })
      .addCase(createProductBatch.rejected, (state, action) => {
        state.isUpdating = false;
      });

      builder
      .addCase(updateProductBatch.pending, (state) => {
        state.isUpdating = true;
      })
      .addCase(updateProductBatch.fulfilled, (state, action) => {
        state.isUpdating = false;
      })
      .addCase(updateProductBatch.rejected, (state, action) => {
        state.isUpdating = false;
      });

      builder
      .addCase(fetchProductActivityLogs.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchProductActivityLogs.fulfilled, (state, action) => {
        state.loading = false;
        state.activityLogs = action.payload

      })
      .addCase(fetchProductActivityLogs.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

// Export actions and reducer
export const { clearProducts } = productsSlice.actions;
export default productsSlice.reducer;