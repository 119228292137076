import React, { useEffect, useState } from 'react';
import { Card, CardContent, Grid, Stack } from '@mui/material';
import { fetchNotificationCount, fetchReadSingleNotification } from '../../redux/slices/notifications';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import MDButton from 'components/MDButton';
import { fetchApprovalDetails } from '../../redux/slices/approvals';
import { useNavigate } from 'react-router-dom';

const NotificationCard = ({ fetchAllNotificationsCountsData }) => {
  const dispatch = useDispatch();
  const { addProductNotifications = [] } = useSelector(state => state.notifications);
  // const [newNotification, setNewNotification] = useState([]);
  // const [notification, setNotification] = useState(false);
  const navigate = useNavigate();

  const [hiddenNotifications, setHiddenNotifications] = useState(() => {
    // Initialize from local storage or empty array if not present
    const storedHiddenNotifications = localStorage.getItem('hiddenNotifications');
    return storedHiddenNotifications ? JSON.parse(storedHiddenNotifications) : [];
  });

  useEffect(() => {
    const fetchData = () => {
      try {
        const responseNewNotification = dispatch(fetchAllNotificationsCountsData({ notificationType: 'approvals' })).unwrap()
          .then(() => {
            // setNewNotification(addProductNotifications);
            // setNotification(true);
          });
      } catch (error) {
      };
      fetchData();
    };
  }, [addProductNotifications.length]);

  useEffect(() => {
    // setNotification(true);
  }, [addProductNotifications.length]);

  const handleView = (approvalId, notificationId) => {
    dispatch(fetchApprovalDetails(approvalId));
    navigate(`/approvals/${approvalId}/details`);
    dispatch(fetchReadSingleNotification({ notificationId })).unwrap().then(() => {
      dispatch(fetchNotificationCount({ notificationType: 'approvals' }));
    });
  };

  const handleHideNotification = (notificationId) => {
    setHiddenNotifications([...hiddenNotifications, notificationId]);
  };

  const isNotificationHidden = (notificationId) => {
    return hiddenNotifications.includes(notificationId);
  };

  useEffect(() => {
    const timeouts = [];
    addProductNotifications.filter(notification => notification.readStatus === false)?.forEach(notification => {
      if (!hiddenNotifications.includes(notification.notificationId)) {
        const notificationTimeout = setTimeout(() => {
          setHiddenNotifications([...hiddenNotifications, notification.notificationId]);
        }, 6000);
        timeouts.push(notificationTimeout);
      }
    });
  }, [addProductNotifications, hiddenNotifications]);

  useEffect(() => {
    localStorage.setItem('hiddenNotifications', JSON.stringify(hiddenNotifications));
  }, [hiddenNotifications]);


  return (
    <Grid container justifyContent="flex-end" spacing={2} style={{
      position: 'fixed', top: 88, zIndex: 999,
      display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'flex-end', gap: 16,
    }} >
      {addProductNotifications?.filter(notification => notification.readStatus === false).map((notification) => (
        <>
          <Card className="align-items-start flex-column notification-contents notification-pop" key={notification.notificationId} style={{ display: isNotificationHidden(notification.notificationId) ? 'none' : 'block' }}>
            <CardContent >
              <h4>{notification?.notificationData?.title}</h4>
              <p><strong>Product Name:</strong> {notification.notificationData.productName}</p>
              <p><strong>Retailer Name:</strong> {notification.notificationData?.retailerName}</p>
              <Stack direction="row" justifyContent='space-between' gap={4}>
                <MDButton onClick={() => handleView(notification.notificationMetaData[0].approvalId,
                  notification.notificationId)} className="view-btn" type="">View</MDButton>
                <MDButton onClick={() => handleHideNotification(notification.notificationId)}>Hide</MDButton>
              </Stack>
            </CardContent>
          </Card>
        </>

      ))}
    </Grid>
  );
};

export default NotificationCard;