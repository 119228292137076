import CommonLayout from 'layouts/DashboardLayout';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid, Slide, Stack, Typography, IconButton, Drawer } from '@mui/material';
import { AgGridTable } from 'components/tables/AgGridTable';
import MDButton from 'components/MDButton';
import { formatDate } from 'utilities/common';
import { RemoveRedEyeRounded } from '@mui/icons-material';
import Search from 'examples/Search';
import { useDebounce } from 'hooks/useDebounce';
import CloseIcon from '@mui/icons-material/Close';
import { fetchDriverApprovals } from '../../../redux/slices/hyperwolf/driver/approvals';
import DriverApprovalDrawer from './driverApprovalDetail';
import FilterApproval from 'common/component/Hyperwolf/ApprovalFilters';
import HistoryStatus from 'common/component/HistoryStatus';
import ActiveIcon from 'common/customIcons/active';
import InActiveIcon from 'common/customIcons/inactive';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default function Approval() {
  const dispatch = useDispatch();
  const pageRef = useRef(0);
  const searchTermRef = useRef('');
  const mainGridApiRef = useRef('');
  const gridRefApi = useRef(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [notesData, setNotesData] = useState('');
  const approvalsFilterPayload = useRef({});

  const handleSearch = useDebounce(() => {
    pageRef.current = 0;
    searchFn();
  }, 500);

  const { approvals = {}, loading = false } = useSelector(state => state.driverApprovalSlice);

  const applyApprovalFilter = (filterPayload) => {
    pageRef.current = 0;
    approvalsFilterPayload.current = filterPayload;
    const dataSource = {
      getRows: (params) => {
        dispatch(fetchDriverApprovals({ ...filterPayload?.queryPayload, skip: pageRef.current, search:searchTermRef.current, limit: 20})).unwrap()
          .then(res =>  {
            const { data, filteredTotal } = res.data;
            const approvalsArray = data?.map((item => {
              const date = item?.createdDate ? new Date(item?.createdDate) : '';
              return { ...item, fullName:item?.fleetDetails?.fleetName,
                joinedDate: date ? formatDate(date) : '-', id: item.memberId, address: `${item?.deliveryAddress?.address} ${item?.deliveryAddress?.city} ${item?.deliveryAddress?.state} ${item?.deliveryAddress?.zipcode}` };
            }));
            params.successCallback([...approvalsArray], filteredTotal);
            pageRef.current = pageRef.current + 20;
          });
      }
    };
    if (mainGridApiRef?.current) {
      mainGridApiRef.current.ensureIndexVisible(0, null);
      mainGridApiRef.current.setGridOption('datasource', dataSource);
    }
  };

  const columnDefs = useMemo(()=>{
    return  [
      {
        headerName: 'Driver Id',
        field: 'fleetDisplayId',
        key: 'fleetDisplayId',
        suppressMenu: false,
        cellRenderer: (props) => {
          return <Typography variant="caption" display="block" fontWeight="regular" sx={{ cursor: 'pointer', whiteSpace: 'normal', textTransform: 'capitalize' }}>{props?.data?.fleetDetails?.fleetDisplayId}</Typography>;
        },
        minWidth: 240,
        sortable: false
      },
      {
        headerName: 'Driver Name',
        field: 'fullName',
        key: 'fullName',
        suppressMenu: false,
        cellRenderer: (props) => {
          return <Typography variant="caption" display="block" fontWeight="regular" sx={{ cursor: 'pointer', whiteSpace: 'normal', textTransform: 'capitalize' }}>{props?.data?.fullName}</Typography>;
        },
        minWidth: 240,
        sortable: false
      },
      {
        headerName: 'Request Type',
        field: 'notificationType',
        suppressMenu: false,
        minWidth: 140,
        sortable: false,
        cellRenderer: (props) => {
          return <Typography variant="caption" display="block" fontWeight="regular" sx={{ cursor: 'pointer', whiteSpace: 'normal', textTransform: 'capitalize' }}>{props?.data?.notificationType=== 'vehicleUpdate' ? 'Vehicle Update' : ''}</Typography>;
        }
      },
      {
        headerName: 'Date',
        field: 'joinedDate',
        suppressMenu: false,
        minWidth: 150,
        sortable: false
      },
      {
        headerName: 'Status',
        field: 'notificationStatus',
        suppressMenu: false,
        cellRenderer: (props) => {
          if (props?.data?.notificationStatus === 'pending'){
            return  <> 
              <Box color="#8E0CF5" display="flex" alignItems="center">
                <AccessTimeFilledIcon fontSize="" style={{fontSize: '18px', marginTop: '-1px'}} />
              </Box>
              <HistoryStatus status={props?.data?.notificationStatus} />  </>;
          }
          else if (props?.data?.notificationStatus === 'approved') {
            return  <> 
              <Box color="#24CA49" display="flex" alignItems="center">
                <ActiveIcon width="18px" height="18px" flex="none" style={{color: '#FD4438' }} />
              </Box>
              <HistoryStatus status={props?.data?.notificationStatus} />  </>;
          }
          else if (props?.data?.notificationStatus === 'declined') {
            return  <> 
              <Box color="#FD4438" display="flex" alignItems="center">
                <InActiveIcon width="18px" height="18px" flex="none" />
              </Box>
              <HistoryStatus status={props?.data?.notificationStatus} />  </>;
          }
        },
        minWidth: 100,
        sortable: false
      },
      {
        headerName: '',
        cellRenderer: (props) => {
          return props?.data?.fleetId ? <MDButton onClick={() => {
            setNotesData(props.data);
          }} size="small" startIcon={<RemoveRedEyeRounded fontSize='small' />} variant="contained" color="primary" className="btn-primary-light">
                View
          </MDButton> : null;
        },
        sortable: false
      }
    ];
  },[]);

  const getRows = async (params) => {
    gridRefApi.current = params;
    dispatch(fetchDriverApprovals({skip: pageRef.current, search:searchTermRef.current, limit: 20, ...approvalsFilterPayload.current.queryPayload})).unwrap().then(res => {
      const { data, filteredTotal } = res.data;
      const approvalsArray = data?.map((item => {
        const date = item?.createdDate ? new Date(item?.createdDate) : '';
        return { ...item, fullName:item?.fleetDetails?.fleetName,
          joinedDate: date ? formatDate(date) : '-', id: item.memberId, address: `${item?.deliveryAddress?.address} ${item?.deliveryAddress?.city} ${item?.deliveryAddress?.state} ${item?.deliveryAddress?.zipcode}` };
      }));
      params.successCallback([...approvalsArray], filteredTotal);
      pageRef.current = pageRef.current + 20;
    });
  };

  const searchFn = () => {
    const dataSource = {
      getRows: (params) => getRows(params, approvalsFilterPayload.current) // Pass the current filter payload
    };
    mainGridApiRef.current.ensureIndexVisible(0, null);
    mainGridApiRef.current.setGridOption('datasource', dataSource);
  };

  const searchHandler = (event) => {
    const { value } = event.target;
    setSearchTerm(value);
    searchTermRef.current = value;
    handleSearch(value);
  };

  useEffect(() => {
    if (mainGridApiRef?.current) {
      if (loading) {
        mainGridApiRef.current.showLoadingOverlay();
      } else if (approvals?.data.filteredTotal === 0) {
        mainGridApiRef.current.showNoRowsOverlay();
      }
      else {
        mainGridApiRef.current.hideOverlay();
      }
    }

  }, [approvals, loading]);

  const resetData = () => {
    pageRef.current = 0;
    if (mainGridApiRef.current) {
      mainGridApiRef.current.forEachNode((node) => {
        node.setSelected(false);
      });
    }

    searchFn();
    setNotesData('');
  };

  return (
    <CommonLayout>
      <Box p={{ xs: 2, sm: 3, lg: 4 }}>
        <Grid container alignItems="center" rowSpacing={2} columnSpacing={2} mt={1} mb={3}>
          <Grid item xs={12} sm={4}>
            <Typography variant='h4'>Approvals</Typography>
          </Grid>

          <Grid item xs={12} sm={8}>
            <Stack direction="row" justifyContent={{ xs: 'start', sm: 'end' }} flexWrap="wrap" alignItems="stretch" gap={2}>
              <Search placeholder="Search" label="" value={searchTerm} onChange={searchHandler} />
            </Stack>
          </Grid>
        </Grid>

        <FilterApproval applyApprovalFilter={applyApprovalFilter}/>
        <AgGridTable col={columnDefs} getRows={getRows} mainGridApiRef={mainGridApiRef} pageRef={pageRef} isLoading={loading} style={{ height: '58vh' }} 
          maxLimit={20}/>
      </Box>
      {
        Boolean(notesData) && 
            <Drawer open={Boolean(notesData)} TransitionComponent={Transition} keepMounted maxWidth='xs' fullWidth className="cus-drawer approval-drawer"
              onClose={() => setNotesData('')}
              anchor='right'
            >
              <IconButton onClick={() => setNotesData('')} edge='end' size='small'>
                <CloseIcon fontSize='medium' />
              </IconButton>

              <DriverApprovalDrawer details={notesData} resetData={resetData}/>
  
            </Drawer >
      }
    </CommonLayout>

  );
}