import React from 'react';

export default function ReturnApprovedIcon({width = '20px', height = '20px', flex = 'none'}) {
  return (
    <svg style={{width, height, flex}} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path  d="M17.5861 11.2937C17.7484 11.0698 18.061 11.0201 18.2845 11.1826C18.9832 11.691 19.5 12.3545 19.5 13.1534C19.5 14.186 18.6496 14.9924 17.5722 15.5643C16.4575 16.1559 14.9188 16.6001 13.1608 16.8454C12.8873 16.8835 12.6347 16.6923 12.5966 16.4182C12.5586 16.1441 12.7494 15.891 13.0229 15.8529C14.7108 15.6174 16.1263 15.1978 17.1041 14.6787C18.1193 14.1399 18.5 13.5888 18.5 13.1534C18.5 12.8222 18.2838 12.4205 17.697 11.9935C17.4735 11.831 17.4239 11.5176 17.5861 11.2937Z" fill="currentColor"/>
      <path  d="M2.0798 11.4941C2.26393 11.7003 2.24637 12.0171 2.04057 12.2016C1.64548 12.5558 1.5 12.8788 1.5 13.1532C1.5 13.4287 1.64604 13.7503 2.03998 14.1018C2.4335 14.4529 3.03132 14.7919 3.81285 15.0868C5.37227 15.6752 7.55997 16.0492 10.0005 16.0492C10.2766 16.0492 10.5005 16.2736 10.5005 16.5503C10.5005 16.827 10.2766 17.0513 10.0005 17.0513C7.47144 17.0513 5.1589 16.6654 3.46047 16.0246C2.61307 15.7049 1.89256 15.312 1.37498 14.8502C0.857826 14.3887 0.5 13.8164 0.5 13.1532C0.5 12.4924 0.855974 11.9191 1.37378 11.4548C1.57957 11.2703 1.89567 11.2879 2.0798 11.4941Z" fill="currentColor"/>
      <path  d="M7.72054 14.2461C7.9166 14.0512 8.23318 14.0525 8.42764 14.249L10.3563 16.1976C10.5496 16.3929 10.5496 16.7079 10.3563 16.9033L8.42763 18.8518C8.23317 19.0483 7.91659 19.0496 7.72053 18.8547C7.52447 18.6598 7.52317 18.3426 7.71763 18.1461L9.29707 16.5504L7.71763 14.9546C7.52317 14.7582 7.52447 14.4409 7.72054 14.2461Z" fill="currentColor"/>
      <path  d="M7.43789 1H9.50009V3.59816H10.5001V1H12.5746C13.1394 1 13.6738 1.27124 14.0117 1.72931L15.8121 4.17951C15.8993 4.27484 15.9764 4.37959 16.0417 4.492L16.0502 4.50355C16.0855 4.55152 16.1109 4.60371 16.127 4.6577C16.229 4.88407 16.2858 5.13496 16.2858 5.39825V11.8935C16.2858 12.8808 15.4873 13.6936 14.5001 13.6936H5.49956C4.51236 13.6936 3.71378 12.8808 3.71378 11.8935V5.39825C3.71378 4.91079 3.90845 4.46587 4.22437 4.14032L6.00353 1.71905C6.34028 1.27138 6.86615 1 7.43789 1ZM12.9557 6.29972C12.7793 6.1367 12.5148 6.1367 12.3384 6.29972L9.03216 9.3563L7.66556 8.09291C7.48922 7.9299 7.22472 7.9299 7.04838 8.09291C6.87206 8.25593 6.87206 8.50046 7.04838 8.66348L8.72358 10.2122C8.81175 10.2937 8.89991 10.3344 9.03216 10.3344C9.16441 10.3344 9.25259 10.2937 9.34075 10.2122L12.9557 6.87028C13.132 6.70726 13.132 6.46273 12.9557 6.29972Z" fill="currentColor"/>
    </svg>
  );
}