import axiosClient from "axiosClient";
import { getQueryString } from "utilities/common";

export async function fetchMemberShipAPI(filters) {
    return await axiosClient.get(`/api/v1/admin/promotion/all/membership?${getQueryString(filters)}`);
}

export async function fetchMemberShipDetailsAPI(membershipId) {
    return await axiosClient.get(`/api/v1/admin/promotion/membership/${membershipId}`);
}

export async function addMemberShipAPI(data) {
    return await axiosClient.post(`/api/v1/admin/promotion/membership`, data);
}

export async function updateMemberShipAPI(data, membershipId) {
    return await axiosClient.put(`/api/v1/admin/promotion/membership/${membershipId}`, data);
}

export async function deleteMemberShipAPI(membershipId) {
    return await axiosClient.delete(`/api/v1/admin/promotion/membership/${membershipId}`);
}
