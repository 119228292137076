import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, BarElement, CategoryScale, Tooltip, Legend } from 'chart.js';
import { Card, Paper, Stack, Typography } from '@mui/material';
import MDButton from 'components/MDButton';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { saveAs } from 'file-saver';
import { handleFileDownload } from 'utilities/common';
ChartJS.register(BarElement, CategoryScale, Tooltip, Legend);

const CompletedTaskChart = ({ chartData, completedTaskGraphFilter, changeFilterHandler }) => {
  const datasets = chartData?.datasets || [];

  const filteredLabels = chartData?.labels?.filter((_, index) => {
    return datasets?.some(dataset => dataset?.data?.[index] > 0);
  }) || [];
  const filteredData = datasets.map((dataset) => {
    // Initialize data array filled with zeros based on the length of filteredLabels
    const adjustedDataPoints = Array(filteredLabels.length).fill(0);    
    // Populate the adjustedDataPoints based on the original dataset's data
    filteredLabels.forEach((label, idx) => {
      const index = chartData.labels.indexOf(label);
      if (index >= 0 && dataset.data[index] > 0) {
        adjustedDataPoints[idx] = dataset.data[index];
      }
    });
    
    return {
      ...dataset,
      data: adjustedDataPoints,
    };
  });

  const options = {
    responsive: true,
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    scales: {
      x: {
        labels: filteredLabels,
        grid: {
          display: false,
        },
        stacked: true,
      },
      y: {
        beginAtZero: true,
        stacked: true,
        grid: {
          color: 'rgba(200, 200, 200, 0.2)',
        },
      },
    },
    plugins: {
      legend: {
        position: 'bottom',
      },
    },
  };

  const handleDownload = async () => {
    try {
      const blobData = await handleFileDownload();
      const blob = new Blob([blobData], { type: 'application/octet-stream' });
      saveAs(blob, 'files.zip');
    } catch (error) {
    }
  };

  return (
    <div className="cus-inner-height">
      <Card component={Paper} sx={{ padding: 3, backgroundColor: '#000000' }}>
        <Stack direction='row' justifyContent='space-between' alignItems='center' mb={2}>
          <Typography fontWeight={600} className='text-16 text-white-900'>Completed Tasks</Typography>
          <Stack direction='row' alignItems='center' gap={2} className='group-by'>
            <Typography className='text-14 text-black-600' fontWeight={600}>Group By:</Typography>
            <Stack direction='row' gap={1.5} alignItems='center'>
              <MDButton variant='contained' color={completedTaskGraphFilter?.groupBy === 'lastWeek' ? 'primary' : 'light'} size="small" className="line-height-normal"
                onClick={() => changeFilterHandler('lastWeek')} >
                                Last Week
              </MDButton>
              <MDButton variant='contained' size="small" color={completedTaskGraphFilter?.groupBy === 'last24hour' ? 'primary' : 'light'} className="line-height-normal"
                onClick={() => changeFilterHandler('last24hour')}  >
                                Last 24hours
              </MDButton>
              <MDButton variant='contained' color={completedTaskGraphFilter?.groupBy === 'last30days' ? 'primary' : 'light'} size="small" className="line-height-normal"
                onClick={() => changeFilterHandler('last30days')} >  Last 30days
              </MDButton>
            </Stack>
            <MDButton onClick={() => handleDownload()} style={{ textTransform: 'none', minWidth: '180px', borderRadius: '30px', minHeight: '40px' }}
              startIcon={<FileDownloadOutlinedIcon fontSize='large' />} variant="contained" color="primary"> Export as .CSV
            </MDButton>
          </Stack>
        </Stack>
        <Bar data={{ ...chartData, datasets: filteredData }} options={{ ...options, elements: { bar: { borderRadius: 5, } } }} />
      </Card>
    </div>
  );
};

export default CompletedTaskChart;
