
// @mui material components
import Grid from "@mui/material/Grid";
import RevenueCustomersAndOrdersStats from "components/dashboard/RevenueCustomersAndOrdersStats";
import ProductsAndCategoriesSoldStats from "components/dashboard/ProductsAndCategoriesSoldStats";
import CompleteAndCancelledOrdersStats from "components/dashboard/CompleteAndCancelledOrdersStats";
import CardRevenueAndTaxesStats from "components/dashboard/CardRevenueAndTaxesStats";
import NewCustomersStats from "components/dashboard/NewCustomersStats";
import CommonLayout from "layouts/DashboardLayout";
import { Box } from "@mui/material";
// import useSocket from "../../HOC/useSocket";
// import { socket } from "../../socket";
// import { useEffect } from "react";

function Dashboard() {
  // const {isConnected} = useSocket()

  // useEffect(()=>{
  //   if(isConnected){
  //     socket.emit('message.send',{name:"vipin"})

  //     socket.on('newOrder',(data)=>{
  //     })
  //   }
  // },[isConnected])

  return (
    <CommonLayout>
      <Box p={{ xs: 2, sm: 3, lg: 4 }}>
        <Grid container spacing={2.5}>
          <RevenueCustomersAndOrdersStats />

          <ProductsAndCategoriesSoldStats />

          <CompleteAndCancelledOrdersStats />

          <CardRevenueAndTaxesStats />

          <NewCustomersStats />
        </Grid>
      </Box>
    </CommonLayout>
  );
}

export default Dashboard;
