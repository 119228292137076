import axiosClient from "axiosClient";

export async function fetchMemberAPI() {
    return await axiosClient.get('/api/v1/member');
}

export async function fetchMemberDetailsAPI(memberId) {
    return await axiosClient.get(`/api/v1/member/${memberId}`);
}

export async function addMemberAPI(data) {
    return await axiosClient.post(`/api/v1/member`, data);
}

export async function updateMemberAPI(data,memberId) {
    return await axiosClient.put(`/api/v1/member/update/${memberId}`, data);
}

export async function deleteMemberAPI(memberId) {
    return await axiosClient.delete(`/api/v1/member/${memberId}`);
}

export async function fetchMemberOrdersAPI(data,memberId) {
    return await axiosClient.get(`/api/v1/member/orders?memberId=${memberId}`,data);
}