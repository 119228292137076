import CommonLayout from 'layouts/DashboardLayout';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import CreateDriverHemp from '../createDriver';
import { fetchDriverDetailsHemp } from '../../../redux/slices/driverHemp';

export default function AddDriverHemp() {
  const dispatch = useDispatch();
  const {driverId = ''} = useParams();

  useEffect(() => {
    if (driverId){
      dispatch(fetchDriverDetailsHemp(driverId));
    }
  }, [driverId]);

  return (
    <CommonLayout>
      <CreateDriverHemp />
    </CommonLayout>
  );
}