import React, { useEffect, useMemo, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Box, Card, Paper, Stack, Typography, Button, Menu } from '@mui/material';
import MilesIcon from 'common/customIcons/miles';
import FilterIcon from 'common/customIcons/filter';
import DateRange from 'components/dateRangePicker/DateRange';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import InfiniteScrollList from 'common/component/Hyperwolf/InfiniteScrollList';
import { fetchDrivers } from '../../../redux/slices/hyperdrive/driverAnalytics';
import { useSelector } from 'react-redux';
import { formatTimestampToMapOld } from 'utilities/common';
import moment from 'moment-timezone';

dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

ChartJS.register(ArcElement, Tooltip, Legend);

const CustomPieChart = ({ chartData, total, title = 'Mins per task' }) => {
  const data = {
    datasets: [
      {
        data: [30, 20, 25, 25], // The values for each segment
        backgroundColor: [
          '#FC7D1A', // Bright Orange
          '#C444FF', // Bright Purple
          '#3A49F9', // Bright Blue
          '#60EDEF', // Bright Aqua/Green
        ],
        barPercentage: 0.4,
        categoryPercentage: 0.5,
        borderWidth: 0, // No border between segments
        cutout: '90%', // Adjust the thickness of the doughnut
      },
    ],
  };

  const options = {
    plugins: {
      tooltip: { enabled: true }, // Disable tooltips
    },
    rotation: 270, // Start the first segment from the top
  };

  return (
    <div style={{ position: 'relative', width: '200px', height: '200px' }}>
      <Doughnut style={{ position: 'relative', zIndex: '9' }} data={chartData || data} options={options} />
      <div
        style={{
          position: 'absolute',
          top: 'calc(50% + 6px)',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          fontSize: '20px',
          textAlign: 'center',
          color: '#ffffff',
          backgroundColor: '#0F0F13',
          width: 'calc(100% - 50px)',
          height: 'calc(100% - 50px)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          borderRadius: '50%',
          border: '15px solid #000000',
        }}
      >
        <div>{total}</div>
        <div style={{ fontSize: '12px' }}>{title}</div>
      </div>
    </div>
  );
};

export default function StatsAnalyzeChart({ opentask, taskStats = {}, refreshStats = () => { }, dataWithFilter }) {
  const { driverList = {} } = useSelector(state => state.driverAnalyticsHyperDrive);
  const [anchorTask, setAnchorTask] = useState(false);
  const [filterData, setFilterData] = useState({
    fleetId: '',
    regionId: ''
  });
  const [error, setError] = useState('');
  const today = new Date();
  const next = moment(today).add(1, 'day').toDate();
  const [dateRange, setDateRange] = useState([today, next]);

  const handleClickTask = (event) => {
    setAnchorTask(event.currentTarget);
  };

  const optionsList = useMemo(() => {
    return driverList?.data?.map((driver => {
      return {
        value: driver?._id,
        label: driver?.driverName
      };
    }));
  }, [driverList]);

  const applyFilterHandler = () => {
    if (dateRange?.[0]) {
      if (!dateRange?.[1]) {
        setError('Please select end date');
        return false;
      }
    }

    refreshStats({ fleetId: filterData?.fleetId, from: dateRange?.[0] ? formatTimestampToMapOld(dateRange?.[0]) : '', to: dateRange?.[1] ? formatTimestampToMapOld(dateRange?.[1]) : '' });
    setAnchorTask(null);
  };
  useEffect(() => {
    if (dateRange?.[1]) {
      setError('');
    }
  }, [dateRange?.[1]]);

  const driverSelectHandler = (driver) => {
    setFilterData({ ...filterData, fleetId: driver });
  };

  const clearAllHandler = () => {
    refreshStats({
      fleetId: '',
      from: '',
      to: ''
    });
    setDateRange([null, null]);
  };

  useEffect(() => {
    if (dataWithFilter) {
      if (dataWithFilter?.from === '' && dataWithFilter?.to === '') {
        setDateRange([null, null]);
      }
      else {
        setFilterData({ ...filterData, fleetId: dataWithFilter?.fleetId });
        const startDate = moment(dataWithFilter?.from).format();
        const endDate = moment(dataWithFilter?.to).format();
        setDateRange([startDate, endDate]);
      }

    }

  }, [dataWithFilter]);


  return (
    <div className="cus-inner-height pr-2">
      <Stack justifyContent="end" alignItems="end">
        <div className='d-flex align-item-center select-filter-sec mb-3'>
          <Button id="" aria-controls={opentask ? '' : undefined} aria-haspopup="true" aria-expanded={opentask ? 'true' : undefined} onClick={handleClickTask}>
            <div className="d-flex align-item-center gap-2">
              <FilterIcon />
              <Typography className="text-14 text-white-700 text-transform-none" fontWeight="semibold">Filters</Typography>
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="12" viewBox="0 0 11 12" fill="none">
              <g clipPath="url(#clip0_7876_6825)">
                <path d="M5.5 8.5L1 4.5H10L5.5 8.5Z" fill="#5B5B65" />
              </g>
              <defs>
                <clipPath id="clip0_7876_6825">
                  <rect width="11" height="11" fill="white" transform="translate(0 0.5)" />
                </clipPath>
              </defs>
            </svg>
          </Button>
          <Menu id="" onClose={() => setAnchorTask(null)} anchorEl={anchorTask} open={Boolean(anchorTask)}
            MenuListProps={{ className: 'hd-dropdown hd-filter-dropdown-sec' }}   >
            <Stack direction='column' alignItems="center" gap={2}>
              <Box className="w-100 dark-field">
                <Typography className="text-14 text-white-700 mb-1">Drivers</Typography>
                <InfiniteScrollList dataFetcher={fetchDrivers} optionSelectHandler={driverSelectHandler} options={optionsList} value={filterData?.fleetId} label='Select Driver' />
              </Box>
              <Box className="w-100 drawer-date-field dark-field dark-calendar filter-date-sec">
                <Typography className="text-14 text-white-700 mb-1">Date</Typography>
                <DateRange setDateRange={setDateRange} dateRange={dateRange} />
                {error && <p className='error_class rem-pos'>{error}</p>}
              </Box>
              <Box className="w-100 filter-btns">
                <Stack direction='row' alignItems="end" justifyContent="end" gap={1}>
                  <Button size="small" onClick={clearAllHandler} variant="text" color="secondary" style={{ width: '90px', borderRadius: '30px' }}>
                    Clear All
                  </Button>
                  <Button onClick={applyFilterHandler} size="small" variant="contained" color="primary" style={{ width: '90px', borderRadius: '30px' }}>
                    Apply
                  </Button>
                </Stack>
              </Box>
            </Stack>
          </Menu>
        </div>
      </Stack>
      <Stack direction='column' gap={1}>
        <Card component={Paper} sx={{ padding: 2, backgroundColor: '#1E1E24' }}>
          <Stack direction='row' gap={2} justifyContent='space-between'>
            <Box width='100%'>
              <Typography className="text-14 text-black-400">Completed Tasks</Typography>
              <Typography className="text-20 text-black-500" fontWeight={600}>{taskStats?.completedTasks?.total}</Typography>
              <Stack mt={2} direction='column' gap={1}>
                <Stack direction='row' justifyContent='space-between' alignItems='center'>
                  <Typography className="text-14 d-flex align-item-center gap-2 text-black-400">
                    <span className="task-dot successfull"></span>
                    <span>Successful Task</span>
                  </Typography>
                  <Typography className="text-14 text-black-500" fontWeight={600}>{taskStats?.completedTasks?.succeeded}</Typography>
                </Stack>
                <Stack direction='row' justifyContent='space-between' alignItems='center'>
                  <Typography className="text-14 d-flex align-item-center gap-2 text-black-400">
                    <span className="task-dot failed"></span>
                    <span>Failed</span>
                  </Typography>
                  <Typography className="text-14 text-black-500" fontWeight={600}>{taskStats?.completedTasks?.failed}</Typography>
                </Stack>
              </Stack>
            </Box>
            <CustomPieChart chartData={taskStats?.completedTasks?.taskChart} total={taskStats?.completedTasks?.tasksPerHour} title="Task per hour" />
          </Stack>
        </Card>
        <Card component={Paper} sx={{ padding: 2, backgroundColor: '#1E1E24' }}>
          <Stack direction='row' gap={2} justifyContent='space-between'>
            <Box width='100%' >
              <Typography className="text-14 text-black-400">Delayed Tasks</Typography>
              <Typography className="text-20 text-black-500" fontWeight={600}>{taskStats?.delayedTask?.total}</Typography>
              <Stack mt={2} direction='column' gap={1}>
                <Stack direction='row' justifyContent='space-between' alignItems='center'>
                  <Typography className="text-14 d-flex align-item-center gap-2 text-black-400">
                    <span className="task-dot on-time"></span>
                    <span>On Time</span>
                  </Typography>
                  <Typography className="text-14 text-black-500" fontWeight={600}>{taskStats?.delayedTask?.onTime}</Typography>
                </Stack>
                <Stack direction='row' justifyContent='space-between' alignItems='center'>
                  <Typography className="text-14 d-flex align-item-center gap-2 text-black-400">
                    <span className="task-dot delayed-10"></span>
                    <span>{'Delayed<10 min'}</span>
                  </Typography>
                  <Typography className="text-14 text-black-500" fontWeight={600}>{taskStats?.delayedTask?.delayedLessThan10}</Typography>
                </Stack>
                <Stack direction='row' justifyContent='space-between' alignItems='center'>
                  <Typography className="text-14 d-flex align-item-center gap-2 text-black-400">
                    <span className="task-dot delayed-10-60"></span>
                    <span>{'Delayed 10-60 mins'}</span>
                  </Typography>
                  <Typography className="text-14 text-black-500" fontWeight={600}>{taskStats?.delayedTask?.delayed10To60}</Typography>
                </Stack>
                <Stack direction='row' justifyContent='space-between' alignItems='center'>
                  <Typography className="text-14 d-flex align-item-center gap-2 text-black-400">
                    <span className="task-dot delayed-60"></span>
                    <span>{'Delayed> 60 mins'}</span>
                  </Typography>
                  <Typography className="text-14 text-black-500" fontWeight={600}>{taskStats?.delayedTask?.delayedMoreThan60}</Typography>
                </Stack>
              </Stack>
            </Box>
            <CustomPieChart chartData={taskStats?.delayedTask?.taskChart} total={taskStats?.delayedTask?.averageDelayTimePerTask} title="Per task" />
          </Stack>
        </Card>
        <Card component={Paper} sx={{ padding: 2, backgroundColor: '#1E1E24' }}>
          <Stack direction='row' gap={2} justifyContent='space-between'>
            <Box width='100%' >
              <Typography className="text-14 text-black-400">Tasks by Service Time</Typography>
              <Typography className="text-20 text-black-500" fontWeight={600}>{taskStats?.tasksByServiceTime?.total}</Typography>
              <Stack mt={2} direction='column' gap={1}>
                <Stack direction='row' justifyContent='space-between' alignItems='center'>
                  <Typography className="text-14 d-flex align-item-center gap-2 text-black-400">
                    <span className="task-dot on-time"></span>
                    <span>On Time</span>
                  </Typography>
                  <Typography className="text-14 text-black-500" fontWeight={600}>{taskStats?.tasksByServiceTime?.onTime}</Typography>
                </Stack>
                <Stack direction='row' justifyContent='space-between' alignItems='center'>
                  <Typography className="text-14 d-flex align-item-center gap-2 text-black-400">
                    <span className="task-dot delayed-10"></span>
                    <span>{'Delayed<10 min'}</span>
                  </Typography>
                  <Typography className="text-14 text-black-500" fontWeight={600}>{taskStats?.tasksByServiceTime?.delayedLessThan10}</Typography>
                </Stack>
                <Stack direction='row' justifyContent='space-between' alignItems='center'>
                  <Typography className="text-14 d-flex align-item-center gap-2 text-black-400">
                    <span className="task-dot delayed-10-60"></span>
                    <span>{'Delayed 10-20 mins'}</span>
                  </Typography>
                  <Typography className="text-14 text-black-500" fontWeight={600}>{taskStats?.tasksByServiceTime?.delayed10To20}</Typography>
                </Stack>
                <Stack direction='row' justifyContent='space-between' alignItems='center'>
                  <Typography className="text-14 d-flex align-item-center gap-2 text-black-400">
                    <span className="task-dot delayed-60"></span>
                    <span>{'Delayed> 30 mins'}</span>
                  </Typography>
                  <Typography className="text-14 text-black-500" fontWeight={600}>{taskStats?.tasksByServiceTime?.delayedMoreThan30}</Typography>
                </Stack>
              </Stack>
            </Box>
            <CustomPieChart chartData={taskStats?.tasksByServiceTime?.taskChart} total={taskStats?.tasksByServiceTime?.serviceTimePerTask} title="Per task" />
          </Stack>
        </Card>
        <Card component={Paper} sx={{ padding: 2, backgroundColor: '#1E1E24' }}>
          <Stack direction='row' gap={2} justifyContent='space-between' alignItems='center'>
            <Box width='100%' >
              <Typography className="text-14 text-black-400">Average of miles per task</Typography>
              <Typography className="text-black-500" fontWeight={600} >{taskStats?.averageMiles?.averageOfMilesPerTask}</Typography>
            </Box>
            <div className="d-flex align-item-center mr-4">
              <MilesIcon />
            </div>
          </Stack>
        </Card>
      </Stack>
    </div>
  );
}