import { getToken } from "firebase/messaging";
import { getMessaging } from "firebase/messaging";
import { initializeApp } from "firebase/app";
// import { onBackgroundMessage } from "firebase/messaging/sw";
// Initialize Firebase
export const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Messaging service
export const messaging = getMessaging(app);

export async function requestPermission() {
  //requesting permission using Notification API
  const permission = await Notification.requestPermission();
  if (permission === "granted") {
    const token = await getToken(messaging, {
      vapidKey: process.env.REACT_APP_VAPID_KEY,
    });
    //We can send token to server
    localStorage.setItem("firebaseDeviceToken", JSON.stringify(token));
  } else if (permission === "denied") {
    //notifications are blocked
    console.log(
      "No registration token available. Request permission to generate one."
    );
  }
}