import React, { useEffect, useMemo, useState } from 'react';
import { Box, IconButton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import MDTypography from 'components/MDTypography';
import { getData } from 'utilities/common';
import AngleRightIcon from 'common/customIcons/angle_right';
import { useNavigate, useParams } from 'react-router-dom';
import MDButton from 'components/MDButton';
import { isValidNumber } from 'utilities/common';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { removeData } from 'utilities/common';
import { openCustomDrawer } from '../../redux/slices/modalSlice';
import { openSnackbar } from '../../redux/slices/common';
import CommonLayout from 'layouts/DashboardLayout';
import { addRetailerProductsFromMasterCatalog } from '../../redux/slices/retailerProductSlice';
import { validateCatalogProducts } from 'validations/masterCatalogProducts';
import { getMarginToSale } from 'utilities/common';
import { getSaleToMargin } from 'utilities/common';

export default function MasterCatalogProductList() {
  const dispatch = useDispatch();
  const { loading = false } = useSelector(state => state.products);
  const { retailerId } = useParams();
  const [errors, setErrors] = useState({});

  let pColumns = [
    { Header: 'product', accessor: 'products', align: 'left' },
    { Header: 'brand', accessor: 'brand', align: 'left' },
    { Header: 'suggested Price', accessor: 'suggestedPrice', align: 'left' },
    { Header: 'quantity', accessor: 'quantity', align: 'center' },
    { Header: 'purchase Price', accessor: 'purchasePrice', align: 'center' },
    { Header: 'margin', accessor: 'margin', align: 'center' },
    { Header: 'Sale price', accessor: 'salePrice', align: 'center' },
  ];

  const [selectedCatalogProducts, setSelectedCatalogProducts] = useState([]);
  const navigate = useNavigate();

  const onChangeProductFields = (event, productId) => {
    if (event?.target?.value && !isValidNumber(event?.target?.value)) return;
    let product = selectedCatalogProducts?.find((productItem => productItem?.productId === productId));
    const allCatalogProducts = [...selectedCatalogProducts];
    const productIdx = selectedCatalogProducts?.indexOf(product);
    if (product) {
      if (event.target.name === 'margin') {
        product = {
          ...product,
          [event?.target?.name]: event?.target?.value,
          salePrice: event?.target?.value ? getMarginToSale(product.purchasePrice, event?.target?.value) : ''
        };
      }else if (event.target.name === 'salePrice') {
        product = {
          ...product,
          [event?.target?.name]: event.target.value,
          margin: event.target.value ? getSaleToMargin(product.purchasePrice, event.target.value) : ''
        };
      } else {
        product = {
          ...product,
          [event?.target?.name]: Number(event?.target?.value)
        };
      }
      allCatalogProducts[productIdx] = product;
      setSelectedCatalogProducts(allCatalogProducts);
    }

  };

  const showMessage = (message, errortype) => {
    if (errortype === 'success') {
      dispatch(openSnackbar({ content: message, color: 'success', icon: 'check' }));
    }
    if (errortype === 'error') {
      dispatch(openSnackbar({ content: message, color: 'error', icon: 'warning' }));
    }
  };

  const addCatalogProductsHandler = () => {
    const { isError = false, errors = {} } = validateCatalogProducts(selectedCatalogProducts);
    if (isError) {
      setErrors(errors);
      return;
    }
    setErrors({});

    dispatch(addRetailerProductsFromMasterCatalog({ products: selectedCatalogProducts, 'retailerId': retailerId, })).unwrap().then((res) => {
      showMessage(res?.message, 'success');
      navigate(`/retailers/detail/${retailerId}?filter=retailerProducts`);
      removeData('catalogProducts');
    }).catch(err => {
      showMessage(err?.message, 'error');
    });
  };


  useEffect(() => {
    if (getData('catalogProducts')) {
      const parseCatalogProducts = JSON.parse(getData('catalogProducts'));
      let catalogSelectedProducts = Object.values(parseCatalogProducts)?.reduce(((pre, curr) => [...pre, ...curr]), []);
      catalogSelectedProducts = catalogSelectedProducts?.map((proudctItem => {
        return {
          ...proudctItem,
          quantity: '',
          margin: '',
          purchasePrice: '',
          salePrice: ''
        };
      }));

      setSelectedCatalogProducts(catalogSelectedProducts);
    }
  }, []);

  const rows = useMemo(() => {
    if (JSON.stringify(selectedCatalogProducts)?.length > 0) {
      return selectedCatalogProducts?.map(((productItem, indx) => {
        return {
          id: productItem?.productId,
          products: (
            <Box>
              <Stack direction='row' gap={2}>
                <img alt={`product-${productItem?.productName}`} src={productItem?.productImages[0]} style={{ height: 40, width: 50, borderRadius: '50%' }} />
                <Stack direction='column'>
                  <MDTypography href="#" variant="button" color="text" fontWeight="medium">
                    {productItem?.productName || ''}
                  </MDTypography>
                  <MDTypography href="#" variant="button" color="text" fontWeight="medium" sx={{ color: '#9A9AAF' }}>
                    {productItem?.sku || ''}
                  </MDTypography>
                </Stack>
              </Stack>
            </Box>
          ),
          brand: (
            <MDTypography href="#" variant="button" color="text" fontWeight="medium">
              {productItem?.brandName || ''}
            </MDTypography>
          ),
          suggestedPrice: (
            <MDTypography href="#" variant="button" color="text" fontWeight="medium">
              ${productItem?.unitPrice || ''}
            </MDTypography>
          ),
          totalQuantity: (
            <Stack direction='column' justifyContent='center' alignItems='center'>
              <TextField
                label="Add Quantity*"
                className="form-input"
                fullWidth
                name="totalQuantity"
                disabled={productItem?.productBatches?.length > 0 ? true : false}
                onChange={(e) => onChangeProductFields(e, productItem?.productId)}
                value={productItem?.totalQuantity > 0 ? productItem?.totalQuantity : ''}
                helperText={errors[indx]?.totalQuantity}
                error={Boolean(errors[indx]?.totalQuantity)}
              />
              {
                (productItem?.productBatches?.length === 0 && Number(productItem?.totalQuantity) === 0) && <Typography color='#0163FF' sx={{ cursor: 'pointer' }} fontSize={12} onClick={() => {
                  dispatch(openCustomDrawer({ drawer: 'ADD_BATCH', drawerData: { product: productItem, setSelectedCatalogProducts } }));
                }}>+Add Batch</Typography>
              }
            </Stack>
          ),
          purchasePrice: (
            <TextField
              label="Add Purchase Price*"
              className="form-input"
              fullWidth
              name="purchasePrice"
              onChange={(e) => onChangeProductFields(e, productItem?.productId)}
              value={productItem?.purchasePrice || ''}
              helperText={errors[indx]?.purchasePrice}
              error={Boolean(errors[indx]?.purchasePrice)}
            />
          ),
          margin: (
            <TextField
              label="Add Margin(%)*"
              className="form-input"
              fullWidth
              name="margin"
              onChange={(e) => onChangeProductFields(e, productItem?.productId)}
              value={productItem?.margin}
              helperText={errors[indx]?.margin}
              error={Boolean(errors[indx]?.margin)}
            />
          ),
          salePrice: (
            <TextField
              label="Add Sale price*"
              className="form-input"
              fullWidth
              name="salePrice"
              onChange={(e) => onChangeProductFields(e, productItem?.productId)}
              value={productItem?.salePrice || ''}
              helperText={errors[indx]?.salePrice}
              error={Boolean(errors[indx]?.salePrice)}
            />
          ),
        };
      }));
    }
    return [];
  }, [selectedCatalogProducts, errors]);

  return (
    <CommonLayout>
      <Box p={{ xs: 2, sm: 3, lg: 4 }} pb={{ lg: 2 }}>
        <Stack direction='row' alignItems='center' justifyContent='space-between' mb={2}>
          <Box display="flex" alignItems="center" color='text.main' lineHeight={1} mb={2}>
            <IconButton onClick={() => navigate(-1)}>
              <AngleRightIcon fontSize="14px" />
            </IconButton>
            <Typography variant='h6' fontWeight="semibold" ml={.75}>Master Catalog Products</Typography>
          </Box>
          <Box onClick={addCatalogProductsHandler}>
            <MDButton loading={loading} variant='contained' color='primary'>Add</MDButton>
          </Box>
        </Stack>
        <TableContainer>
          <Table className='cs--table-design'>
            <TableHead>
              <TableRow>
                {
                  pColumns?.map((column => {
                    return <><TableCell key={column?.accessor}>{column?.Header}</TableCell></>;
                  }))
                }
              </TableRow>
            </TableHead>

            <TableBody>
              {
                rows?.map((row => {
                  return <TableRow key={row?.id}>
                    <TableCell>{row?.products}</TableCell>
                    <TableCell>{row?.brand}</TableCell>
                    <TableCell>{row?.suggestedPrice}</TableCell>
                    <TableCell>{row?.totalQuantity}</TableCell>
                    <TableCell>{row?.purchasePrice}</TableCell>
                    <TableCell>{row?.margin}</TableCell>
                    <TableCell>{row?.salePrice}</TableCell>
                  </TableRow>;
                }))
              }
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </CommonLayout>
  );
}
