import { axiosHyperwolfClient } from "axiosClient";
import { getQueryString } from "utilities/common";

export function getAllProductsAPI(data) {
    const { limit = 10, skip = 0, searchTerm = '', sortBy = 'sale', categoryId = '', productTags = '', brandId = '', active = true } = data || {}
    const newData = { limit, skip, searchTerm, sortBy, categoryId, productTags, brandId, active }
    return axiosHyperwolfClient.get(`api/v1/admin/products?${getQueryString(newData)}`);
}

export function getSingleProductDetailsAPI(data) {
    return axiosHyperwolfClient.get(`api/v1/admin/products/${data}`);
}


export function getInfoAndEffectAPI(data) {
    return axiosHyperwolfClient.get(`api/v1/admin/products/infoEffects`);
}

export function updateSingleProductAPI(id, data) {
    return axiosHyperwolfClient.post(`api/v1/admin/products/edit/${id}`, data);
}

export function addCategoryAPI(data, id) {
    if (id) {
        return axiosHyperwolfClient.put(`api/v1/admin/category/${id}`, data);
    }
    return axiosHyperwolfClient.post(`api/v1/admin/category`, data);
}


export function getAllCategoryAPI(data) {
    return axiosHyperwolfClient.get(`api/v1/admin/category`, data);
}


export function updateCategoriesAPI(data) {
    return axiosHyperwolfClient.post(`api/v1/admin/category/multi`, data);
}

export function deleteCategoryAPI(id) {
    return axiosHyperwolfClient.delete(`api/v1/admin/category/${id}`);
}


export function getAllBlazeCategoryAPI(data) {
    return axiosHyperwolfClient.get(`api/v1/admin/category/blaze`, data);
}

export function getAllCategoryImagesAPI(data) {
    return axiosHyperwolfClient.get(`api/v1/admin/category/images`, data);
}

export function getAllBrandsByProductAPI() {
    return axiosHyperwolfClient.get(`api/v1/admin/products/brand/blaze`);
}

export function getAllBmwBrandsAPI() {
    return axiosHyperwolfClient.get(`api/v1/admin/products/brand`);
}

export function addBrandToBmwAPI(data) {
    return axiosHyperwolfClient.post(`api/v1/admin/products/brand`, data);
}

export function updateBrandFromBmwAPI(data) {
    return axiosHyperwolfClient.put(`api/v1/admin/products/brand`, data);
}

export function deleteBrandFromBmwAPI(id) {
    return axiosHyperwolfClient.delete(`api/v1/admin/products/brand/${id}`);
}

export function syncProductAdminAPI() {
    return axiosHyperwolfClient.post(`api/v1/admin/products/syncProducts`);
}

// Threshold API
export function getThresholdAdminAPI(data) {
    return axiosHyperwolfClient.get(`api/v1/admin/product/threshold`, data);
}

export function updateThresholdAPI(data) {
    return axiosHyperwolfClient.put(`api/v1/admin/update/product/threshold`, data);
}