import axiosClient from "axiosClient";
import { getQueryString } from "utilities/common";

export async function uploadImageAPI(data) {
    return await axiosClient.post('/api/v1/upload/image', data);
}

export async function uploadProductImageAPI(data) {
    return await axiosClient.post('api/v1/upload/product/image', data);
}


export async function fetchInfoEffectsAPI() {
    return await axiosClient.get('/api/v1/product/infoeffects');
}

export async function fetchProductCategoriesAPI(filters) {
    return await axiosClient.get(`/api/v1/admin/category/get?${getQueryString(filters)}`);
}

export async function fetchAllRegionsAPI() {
    return await axiosClient.get(`/api/v1/admin/region/get`);
}

export async function BulkProductUpdateAPI(data) {
    return await axiosClient.post(`/api/v1/product/bulk/update`, data);
}

export async function getSignupStatusAPI(data) {
    return await axiosClient.get(`/api/v1/signup/status`, data);
}

export async function updateSignupStatusAPI(data) {
    return await axiosClient.post(`/api/v1/signup/status/update`, data);
}


export async function getPaymentPlatformStatusAPI(data) {
    return await axiosClient.get(`/api/v1/payment/status`, data);
}



export async function getProductDisclaimersAPI() {
    return await axiosClient.get('/api/v1/product/disclaimer');
}


export async function updateDisclaimerAPI(data) {
    return await axiosClient.post('/api/v1/update/product/disclaimer', data);
}

export async function updatePaymentPlatformStatusAPI(data) {
    return await axiosClient.post(`/api/v1/payment/status/update`, data);
}