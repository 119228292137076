import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { addStartTaskAPI, fetchAddressHQApi, fetchHQRegionsApi, fetchHyperWolfFirstRenderApi, fetchHyperWolfFirstRenderHQApi, fetchHyperwolfTasksRegionsAPI, updateAddressReturnHQApi, updateHQRegionInformationApi, updateTaskRegionInformationAPI } from '../../../redux/apis/hyperdrive/createTask';

const initialState = {
  taskDrive: [],
  loading: false,
  error: null,
  flavors: [],
  getTaskDrive: {}
};

export const addStartTask = createAsyncThunk(
  'common/addStartTask',
  async ({data}, { rejectWithValue }) => {
    try {
      const res = await addStartTaskAPI(data); 
      return res;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);


export const fetchHyperwolfTasksRegions = createAsyncThunk(
  'regions/fetchHyperwolfTasksRegions',
  async (filters, { rejectWithValue }) => {
    try {
      const res = await fetchHyperwolfTasksRegionsAPI(filters);
      return res;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const fetchHQRegions = createAsyncThunk(
  'regions/fetchHQRegions',
  async (filters, { rejectWithValue }) => {
    try {
      const res = await fetchHQRegionsApi(filters);
      return res;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);
export const fetchAddressHQ = createAsyncThunk(
  'regions/fetchAddressHQ',
  async (filters, { rejectWithValue }) => {
    try {
      const res = await fetchAddressHQApi(filters);
      return res;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const fetchHyperWolfFirstRender = createAsyncThunk(
  'regions/fetchHyperWolfFirstRender',
  async (filters, { rejectWithValue }) => {
    try {
      const res = await fetchHyperWolfFirstRenderApi(filters);
      return res;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);
export const fetchHyperWolfFirstRenderHQ = createAsyncThunk(
  'regions/fetchHyperWolfFirstRenderHQ',
  async (filters, { rejectWithValue }) => {
    try {
      const res = await fetchHyperWolfFirstRenderHQApi(filters);
      return res;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const updateTaskRegionInformation = createAsyncThunk(
  'regions/updateTaskRegionInformation',
  async (data, { rejectWithValue }) => {
    try {
      const res = await updateTaskRegionInformationAPI(data,data?._id);
      return res;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const updateHQRegionInformation = createAsyncThunk(
  'regions/updateHQRegionInformation',
  async (data, { rejectWithValue }) => {
    try {
      const res = await updateHQRegionInformationApi(data,data?._id);
      return res;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const updateAddressReturnHQ = createAsyncThunk(
  'regions/updateAddressReturnHQ',
  async (data, { rejectWithValue }) => {
    try {
      const res = await updateAddressReturnHQApi(data);
      return res;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

const tasksDriveSlice = createSlice({
  name: 'taskHyperDrive',
  initialState,
  reducers: {
    setCommonHyperdrive: (state, action) => {
      return { ...state, taskDrive: action.payload }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addStartTask.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addStartTask.fulfilled, (state, action) => {      
        state.loading = false;
        state.mainStartTaskData = action.payload;
      })
      .addCase(addStartTask.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      builder
      .addCase(fetchHyperwolfTasksRegions.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchHyperwolfTasksRegions.fulfilled, (state, action) => {      
        state.loading = false;
        state.taskRegions = action.payload;
      })
      .addCase(fetchHyperwolfTasksRegions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      builder
      .addCase(fetchAddressHQ.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAddressHQ.fulfilled, (state, action) => {      
        state.loading = false;
        state.addressHQ = action.payload;
      })
      .addCase(fetchAddressHQ.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      builder
      .addCase(updateAddressReturnHQ.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateAddressReturnHQ.fulfilled, (state, action) => {      
        state.loading = false;
        state.updateAddressReturnHQData = action.payload;
      })
      .addCase(updateAddressReturnHQ.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      
      builder
      .addCase(fetchHQRegions.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchHQRegions.fulfilled, (state, action) => {      
        state.loading = false;
        state.fetchHQRegions = action.payload;
      })
      .addCase(fetchHQRegions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      builder
      .addCase(updateHQRegionInformation.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateHQRegionInformation.fulfilled, (state, action) => {      
        state.loading = false;
        state.updateHQRegionInformation = action.payload;
      })
      .addCase(updateHQRegionInformation.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  },
});

export const { taskDrive } = tasksDriveSlice.actions;

export default tasksDriveSlice.reducer;