import axiosClient from "axiosClient";
import { getQueryString } from "utilities/common";

export async function fetchAdminAPI(filters) {
    return await axiosClient.get(`/api/v1/admin/get?${getQueryString(filters)}`);
}

export async function fetchAdminDetailsAPI(adminId) {
    return await axiosClient.get(`/api/v1/admin/${adminId}`);
}

export async function addAdminAPI(data) {
    return await axiosClient.post(`/api/v1/admin/create`, data);
}

export async function updateAdminAPI(data,adminId) {
    return await axiosClient.put(`/api/v1/admin/edit/${adminId}`, data);
}

export async function deleteAdminAPI(adminId) {
    return await axiosClient.delete(`/api/v1/admin/delete/${adminId}`);
}
