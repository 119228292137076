import AddRoleForm from 'components/roleAndPermission/AddRoleForm';
import CommonLayout from 'layouts/DashboardLayout';
import React, { useEffect } from 'react';
import { fetchRoleDetails, fetchRoles } from '../../../redux/slices/roleAndPermissions';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

export default function AddRole() {
  const dispatch = useDispatch();
  const {roleId=''} = useParams();
  useEffect(()=>{
    dispatch(fetchRoles());
  },[]);

  useEffect(()=>{
    if(roleId){
      dispatch(fetchRoleDetails(roleId));
    }
  },[roleId]);
  return (
    <CommonLayout>
      <AddRoleForm/>
    </CommonLayout>
  );
}