import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getAllAdminAPI, addUserAPI, deleteUserAPI } from "../../apis/hyperwolf/users";

// Async thunks
export const getAllUsers = createAsyncThunk(
    'user/getAllUsers',
    async (filters, { rejectWithValue }) => {
        try {
            const response = await getAllAdminAPI(filters);
            return response?.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const addUser = createAsyncThunk(
    'user/addUser',
    async ({ data, id }, { rejectWithValue }) => {
        try {
            const response = await addUserAPI(data, id);
            return response?.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const deleteUser = createAsyncThunk(
    'user/deleteUser',
    async (id, { rejectWithValue }) => {
        try {
            const response = await deleteUserAPI(id);
            return response?.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

// Slice
const userSlice = createSlice({
    name: 'user',
    initialState: {
        userList: [],
        loading: false,
        error: null,
        singleUser:{}
    },
    reducers: {
        setUserInformation:(state,action)=>{
            state.singleUser = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllUsers.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAllUsers.fulfilled, (state, action) => {
                state.loading = false;
                state.userList = action.payload;
                state.singleUser = {}
            })
            .addCase(getAllUsers.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(addUser.pending, (state) => {
                state.loading = true;
            })
            .addCase(addUser.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(addUser.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(deleteUser.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteUser.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(deleteUser.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});
export const {setUserInformation} = userSlice.actions;

export default userSlice.reducer;
