import axiosClient from "axiosClient";
import { axiosHyperwolfClient } from "axiosClient";
import axiosClientHyperDrive from "axiosClient/indexHyperdrive";

export async function userLoginAPI(cred) {
    return await axiosClient.post(`/api/v1/admin/login`,cred);
}

export async function hyperWolfUserLoginAPI(cred) {
    return await axiosHyperwolfClient.post(`/api/v1/admin/login`,cred);
}


export async function userSignupAPI(cred) {
    return await axiosClient.post(`/api/v1/admin/create`,cred);
}

export async function forgotPasswordAPI(cred) {
    return await axiosClient.post(`/api/v1/admin/forgot`,cred);
}


export async function resetPasswordAPI(data) {
    return await axiosClient.post(`/api/v1/admin/reset`,data);
}

export async function resetHyerdrivePasswordAPI(data) {
    return await axiosClientHyperDrive.post(`/api/v1/fleet/reset/password`,data);
}


export async function getUserInfoAPI(userId='') {
    return await axiosClient.get(`/api/v1/admin/${userId}`);
}

export async function userLogoutAPI(data){
    return await axiosClient.post('api/v1/admin/retailer/logout', data)
}