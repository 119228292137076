import { axiosHempDevClient } from "axiosClient";
import { getQueryString } from "utilities/common";

export async function fetchApprovalsHempAPI(filters) {

    return await axiosHempDevClient.get(`/api/v1/approval/list?${getQueryString(filters)}`, )
}

export async function submitNotificationHempAPI(data) { 
   return await axiosHempDevClient.post('/api/v1/approval/submit',data)
}