import axiosClient from "axiosClient";
import { getQueryString } from "utilities/common";

export async function fetchProductsAPI(filters, data) {

    return await axiosClient.post(`/api/v1/product/list?${getQueryString(filters)}`, data);
}

export async function fetchProductDetailsAPI(productId) {
    return await axiosClient.get(`/api/v1/product/${productId}?userType=admin`);
}

export async function addProductAPI(data) {
    return await axiosClient.post(`/api/v1/product`, data);
}


export async function updateProductAPI(data, productId) {
    return await axiosClient.put(`/api/v1/product/update/${productId}`, data);
}

export async function updateProductBatchAPI(data, batchId) {
    return await axiosClient.put(`/api/v1/productBatch/${batchId}`, data);
}

export async function assignInventoryAPI(data) {
    return await axiosClient.post(`/api/v1/product/inventory/assigned`, data);
}



export async function fetchProductBatchsAPI(filters, productId) {
    return await axiosClient.get(`/api/v1/productBatch/${productId}?${getQueryString(filters)}`);
}

export async function fetchProductInventoriesAPI(filters, productId) {
    return await axiosClient.get(`/api/v1/product/inventory/${productId}?${getQueryString(filters)}`);
}

export async function createProductBatchAPI(data) {
    return await axiosClient.post(`/api/v1/productBatch`, data);
}

export async function deleteProductAPI(productId) {
    return await axiosClient.delete(`/api/v1/product/${productId}`);
}

export async function deleteProductBatchAPI(data) {
    return await axiosClient.post(`/api/v1/productBatch/delete`, data);
}



export async function fetchProductTerpenoidAPI(filters, productId) {
    return await axiosClient.get(`/api/v1/product/terpenoids/details/${productId}?${getQueryString(filters)}`);
}


export async function productFiltersAPI(filters, data) {
    return await axiosClient.post(`/api/v1/product/terpenoids/details/${productId}?${getQueryString(filters)}`, data);
}

export async function productActivityLogsAPI(filters) {
    return await axiosClient.get(`/api/v1/product/inventory/activity/logs?${getQueryString(filters)}`)
}


export async function fetchProductInstructionsAPI(categoryName){
    return await axiosClient.get(`api/v1/product/fetch/instructions?categoryName=${categoryName}`)
}