import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { AddBreakAPI, DeleteBreakAPI, UpdateBreakAPI, exportBreaksAPI, fetchBreakDetailsAPI, fetchBreaksAPI } from "../../../apis/hyperwolf/Driver/breaks"


export const fetchBreaks = createAsyncThunk(
    'breaks/fetchBreaks',
    async (filters, { rejectWithValue }) => {
        try {
            const res = await fetchBreaksAPI(filters)
            return res
        } catch (error) {
            console.error("erro", error.response)
            return rejectWithValue(error?.response?.data)
        }
    }
)

export const addBreaks = createAsyncThunk(
    'breaks/addBreak',
    async (data, {rejectWithValue}) => {
        try {
            const res = await AddBreakAPI(data)
            return res
        } catch (error) {
            console.error("erro", error.response)
            return rejectWithValue(error?.response?.data)
        }
    }
)

export const deleteBreak = createAsyncThunk(
    'breaks/deleteBreak',
    async(id, {rejectWithValue}) => {
        try {
            const res = await DeleteBreakAPI(id)
            return res
        } catch (error) {
            console.error("erro", error.response)
            return rejectWithValue(error?.response?.data)
        }
    }
)

export const updateBreak = createAsyncThunk(
    'breaks/updateBreak',
    async({id,data}, { rejectWithValue}) => {
        try {
            const res = await UpdateBreakAPI(id,data)
            return res
        } catch (error) {
            console.error("erro", error.response)
            return rejectWithValue(error?.response?.data)
        }
    }
)

export const fetchBreakDetails = createAsyncThunk(
    'breaks/fetchBreakDetails',
    async(id, { rejectWithValue }) => {
        try {
            const res = await fetchBreakDetailsAPI(id)
            return res
        } catch(error) {
            console.error("erro", error.response)
            return rejectWithValue(error?.response?.data)
    }
}
)

export const exportBreaks = createAsyncThunk(
    'breaks/exportBreaks',
    async ( filters, { rejectWithValue }) => {
        try{ 
            const res = await exportBreaksAPI(filters)
            return res
        } catch (error) {
            console.error("erro", error.response)
            return rejectWithValue(error?.response?.data)
        }
    }
)

const breaksSlice = createSlice({
    name: 'breaks',
    initialState: {
        breaks:[],
        loading: false,
        error: null
    },
    reducers: {

    },
    extraReducers: (builder) => {
        builder
        .addCase(fetchBreaks.fulfilled, (state,action) => {
            state.loading = false;
            state.breaks = action.payload;
            state.error = null;
        })
        .addCase(fetchBreaks.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })
        .addCase(fetchBreaks.pending , (state) => {
            state.loading = true;
        })

        builder
        .addCase(addBreaks.pending, (state) => {
            state.loading = true;
            state.error = null
        })
        .addCase(addBreaks.fulfilled, (state,action) => {
            state.loading = false;
            state.addedBreak = action.payload;
            state.error = null;
        })
        .addCase(addBreaks.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload
        })

        builder
        .addCase(updateBreak.pending, (state, action) => {
            state.loading = true;
            state.error = null
        })
        .addCase(updateBreak.fulfilled, (state, action) => {
            state.loading = false;
            state.error = action.payload
        })
        .addCase(updateBreak.rejected, (state,action) => {
            state.loading = false;
            state.error = action.payload
        })

        builder
        .addCase(fetchBreakDetails.fulfilled, (state,action) => {
            state.loading = false;
            state.breakDetails = action.payload;
            state.error = null;
        })
        .addCase(fetchBreakDetails.rejected, (state,action) => {
            state.loading = false;
            state.error = action.payload
        })
        .addCase(fetchBreakDetails.pending, (state) => {
            state.loading = true;
        })


    }
})

export const { /* Any additional actions */} = breaksSlice.actions; 

export default breaksSlice.reducer;
