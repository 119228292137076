import { ClickAwayListener, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import React from 'react';
import { fetchAllNotificationsCountsData, fetchNotificationCount, fetchReadAllNotifications, fetchReadSingleNotification } from '../../redux/slices/notifications';
import { useDispatch } from 'react-redux';
import CustomDrawer from 'common/CommonDrawer/Drawer';
import { fetchApprovalDetails } from '../../redux/slices/approvals';
import { useNavigate } from 'react-router-dom';
import SkeletonNotificationDrawer from './NotificationSkeleton';

const NotificationDrawer = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const [notificationData, setNotificationData] = useState([]);
  const [notificationsRead, setNotificationsRead] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchAllNotificationsCountsData({ notificationType: 'approvals' })).unwrap().then((res) => {
      setNotificationData(res.data);
    });
  }, [notificationsRead]);

  const handleReadAll = () => {
    dispatch(fetchReadAllNotifications({ notificationType: 'approvals' })).unwrap().then(() => {
      setNotificationsRead(true);
      dispatch(fetchAllNotificationsCountsData({ notificationType: 'approvals' }));
    });
  };

  const handleView = (approvalId, notificationId) => {
    dispatch(fetchApprovalDetails(approvalId));
    navigate(`/approvals/${approvalId}/details`);
    dispatch(fetchReadSingleNotification({ notificationId })).unwrap().then(() => {
      dispatch(fetchNotificationCount({ notificationType: 'approvals' }));
    });
  };

  return (
    <>
      {!notificationsRead &&

        <Grid>
          <ClickAwayListener onClickAway={onClose}>
            <CustomDrawer
              anchor="right" open={open} onClose={onClose} style={{ width: 600 }} className="cus-noti-drawer">
              <Grid container direction="column" justifyContent="space-between" alignItems="center">
                <Grid item className="cus-notification-card-main">
                  <p className="jc-end d-flex font-w-600 text-12" style={{ cursor: 'pointer' }} onClick={handleReadAll}>
                    <span className="mr-1">
                      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.157948 4.51613L2.15385 2.52013V2.1755C2.15385 0.975742 3.03908 0 4.12821 0H9.87179C10.9609 0 11.8462 0.975742 11.8462 2.1755V2.46915L13.8421 4.46515C13.9433 4.56639 14 4.70281 14 4.84568V12.1361C14 13.1643 13.1148 14 12.0256 14H1.97436C0.88523 14 0 13.1643 0 12.1361V4.89666C0 4.75378 0.0567169 4.61665 0.157948 4.51613ZM1.37918 4.81768L2.15385 5.42007V4.04298L1.37918 4.81768ZM10.7692 2.17478C10.7692 1.57957 10.3586 1.07626 9.87179 1.07626H4.12821C3.64144 1.07626 3.23077 1.57957 3.23077 2.17478V6.25724L6.44862 8.76014C6.77313 9.01287 7.22615 9.01287 7.55067 8.76014L10.7685 6.25724L10.7692 2.17478ZM11.8462 5.42007L12.6495 4.79543L11.8462 3.992V5.42007ZM1.97436 12.923H12.0256C12.5203 12.923 12.9231 12.5705 12.9231 12.1361V5.94636L8.2119 9.61024C7.85508 9.8881 7.42718 10.0267 7 10.0267C6.57282 10.0267 6.14492 9.8881 5.7881 9.61024L1.07692 5.94636V12.1361C1.07692 12.5698 1.47969 12.923 1.97436 12.923Z" fill="#9A9AAF" />
                      </svg></span>Mark all as read
                  </p>
                  {!notificationData.length > 0 ? (
                    Array.from({ length: 4 }).map((_, index) => (
                      <SkeletonNotificationDrawer key={index} />
                    ))
                  ) :

                    notificationData?.filter(notification => notification.readStatus === false).map(notification => (
                      <Grid item key={notification.id}>
                        <div className="cus-notification-card card-notification">
                          <div className="w-100 d-flex align-items-start gap-2 notification-inner">
                            <div className="d-flex align-items-center justify-content-center notification-icon">
                              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                <g clipPath="url(#clip0_2467_16577)">
                                  <path d="M12.4712 9.86592C11.5892 9.12042 11.0834 8.03075 11.0834 6.87633V5.25C11.0834 3.19725 9.55858 1.498 7.58342 1.21333V0.583333C7.58342 0.26075 7.32208 0 7.00008 0C6.67808 0 6.41675 0.26075 6.41675 0.583333V1.21333C4.441 1.498 2.91675 3.19725 2.91675 5.25V6.87633C2.91675 8.03075 2.411 9.12042 1.52375 9.87058C1.29683 10.0648 1.16675 10.3472 1.16675 10.6458C1.16675 11.2088 1.62466 11.6667 2.18758 11.6667H11.8126C12.3755 11.6667 12.8334 11.2088 12.8334 10.6458C12.8334 10.3472 12.7033 10.0648 12.4712 9.86592Z" fill="#376FFF" />
                                  <path d="M7.0001 14C8.05652 14 8.94027 13.2469 9.14327 12.25H4.85693C5.05993 13.2469 5.94368 14 7.0001 14Z" fill="#376FFF" />
                                </g>
                                <defs>
                                  <clipPath id="clip0_2467_16577">
                                    <rect width="14" height="14" fill="white" />
                                  </clipPath>
                                </defs>
                              </svg>
                            </div>
                            <div className="align-items-start flex-column notification-contents">
                              <Typography variant="h4">{notification?.notificationData?.title}</Typography>
                              <p>
                                <span className="white-space-nowrap">Product Name: </span>
                                <span>{notification?.notificationData?.productName}</span>
                              </p>
                              <p>
                                <span className="white-space-nowrap">Retailer Name: </span>
                                <span>{notification?.notificationData?.retailerName}</span>
                              </p>
                              <p className="mt-2 mb-0">
                                <button className="view-btn" type="" onClick={() => handleView(notification.notificationMetaData[0].approvalId, notification.notificationId)}>View</button>
                              </p>
                            </div>
                          </div>
                        </div>
                      </Grid>
                    ))}

                </Grid>
              </Grid>
            </CustomDrawer>
          </ClickAwayListener>
        </Grid>
      }
    </>);

};

export default NotificationDrawer;