 

// Material Dashboard 2 React Base Styles
import colors from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";
import boxShadows from "assets/theme/base/boxShadows";

// Material Dashboard 2 React Helper Function
import rgba from "assets/theme/functions/rgba";

const { black, white } = colors;
const { borderWidth, borderRadius } = borders;
const { md } = boxShadows;

const card = {
  styleOverrides: {
    root: {
      display: "flex",
      flexDirection: "column",
      position: "relative",
      minWidth: 0,
      wordWrap: "break-word",
      backgroundColor: white.main,
      backgroundClip: "border-box",
      border: `${borderWidth[0]} solid ${rgba(black.main, 0.125)}`,
      borderRadius: borderRadius.xl,
      boxShadow: md,
      overflow: "visible",
    },
  },
  variants: [
    {
      props: { variant: 'productCard' },
      style: {
        borderRadius: borderRadius.xxl,
        boxShadow: '0px 0.387px 2.214px 0px rgba(95, 110, 204, 0.01), 0px 0.931px 5.32px 0px rgba(95, 110, 204, 0.02), 0px 1.753px 10.017px 0px rgba(95, 110, 204, 0.03), 0px 3.127px 17.869px 0px rgba(95, 110, 204, 0.03), 0px 5.849px 33.422px 0px rgba(95, 110, 204, 0.04), 0px 14px 80px 0px rgba(95, 110, 204, 0.05)',
      }
    },
    {
      props: { variant: 'detailsCard' },
      style: {
        borderRadius: borderRadius.xxl,
        boxShadow: '0px 1.55px 3.155px 0px rgba(68, 70, 78, 0.01), 0px 3.724px 7.581px 0px rgba(68, 70, 78, 0.01), 0px 7.012px 14.275px 0px rgba(68, 70, 78, 0.01), 0px 12.508px 25.463px 0px rgba(68, 70, 78, 0.01), 0px 23.395px 47.626px 0px rgba(68, 70, 78, 0.01), 0px 56px 114px 0px rgba(68, 70, 78, 0.02)',
      }
    },
    {
      props: { variant: 'dashboardCard' },
      style: {
        boxShadow: '0px 2.767px 2.214px 0px rgba(0, 0, 0, 0.01), 0px 6.65px 5.32px 0px rgba(0, 0, 0, 0.01), 0px 12.522px 10.017px 0px rgba(0, 0, 0, 0.01)',
        justifyContent: 'space-between'
      }
    }
  ]
};

export default card;
