import { Box, Button, Checkbox, CircularProgress, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, OutlinedInput, Select, Stack } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MDButton from 'components/MDButton';
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchAdmins, fetchTransportations } from '../../../redux/slices/hyperwolf/driver/driver';
import { fetchDriversRegions } from '../../../redux/slices/hyperwolf/driver/driverRegion';
import DateRange from 'components/dateRangePicker/DateRange';
import { formattedDate } from 'utilities/common';

function hasAnyValue(obj) {
  for (const key in obj) {
    if (obj.hasOwnProperty(key) && (
      (typeof obj[key] === 'object' && hasAnyValue(obj[key])) ||
            (typeof obj[key] !== 'object' && obj[key] !== '')
    )
    ) {
      return true;
    }
  }
  return false;
}

export default function FilterDriver(props = {}) {
  const location = useLocation();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({ transportation:'', regions:'', startDate:'', endDate:'', createdBy:'', status:''});
  const { applyDriverFilter } = props;
  const dispatch = useDispatch();
  const [regionData,setRegionData] = useState([]);
  const [transportations, setTransportations] = useState([]);
  const [admins, setAdmins] = useState([]);
  const [showCross, setShowCross] = useState(false);

  useEffect(()=> {
    dispatch(fetchDriversRegions())
      .unwrap()
      .then((res) => {
        setRegionData(res?.data.regions || []);
      });
  },[location.search]);
      
  useEffect(() => {
    dispatch(fetchTransportations())
      .unwrap()
      .then((res) => {
        setTransportations(res?.data.transportations);
      });},[]);

  useEffect(() => {
    dispatch(fetchAdmins())
      .unwrap()
      .then(res => {
        setAdmins(res?.data.data);
      });
  },[]);

  const clearAllHandler = () => {
    setFormData({  transportation:'', regions:'', startDate:'', endDate:'', createdBy:'', status:'' });
    navigate('/hyperwolf/driver?driverFilter=');
    applyDriverFilter({});
    setShowCross(false);
  };

  const showApplyButton = useMemo(() => {
    return hasAnyValue(formData);
  },[formData]);

  const isAllDriverSelected = useMemo(() => {
    return regionData?.length > 0 && formData?.regions?.length === regionData?.length;
  }, [regionData?.length, formData?.regions?.length]);
      

  const applyFiltersHandler = () => {
    const { transportation, regions, startDate, endDate, createdBy, status} = formData || {};
    let queryPayload = {
      fleetTransportationTypeId: transportation,
      regionIds:regions,
      startDate:startDate && formattedDate(startDate) || '',
      endDate: endDate && formattedDate(endDate) || '',
      createdBy:createdBy,
      fleetStatus:status
    };
    if (queryPayload.fleetTransportationTypeId === 'all') {
      queryPayload = { ...queryPayload, fleetTransportationTypeId: '' };
    }
    if (queryPayload.fleetStatus === 'all') {
      queryPayload = { ...queryPayload, fleetStatus: '' };
    }
    if (queryPayload.createdBy === 'all') {
      queryPayload = { ...queryPayload, createdBy: '' };
    }
    const currentParams = new URLSearchParams(location.search);
    
    // Add the new query parameters to the URLSearchParams instance
    Object.keys(queryPayload).forEach((key) => {
      if (typeof queryPayload[key] === 'object') {
        currentParams.set(key, JSON.stringify(queryPayload[key]));
      } else {
        currentParams.set(key, queryPayload[key]);
      }
    });
        
    // Ensure the driverFilter is preserved
    currentParams.set('driverFilter', '');
        
    // Construct the URL with updated parameters
    const urlWithParams = `${location.pathname}?${currentParams.toString()}`;
    navigate(urlWithParams);
    applyDriverFilter({ queryPayload });
  };


  const selectAll = {
    m: 0,
    mb: 0.5,
    padding: '4.5px 8px',
    borderRadius: '0.375rem',
    transition: 'background-color 300ms ease, color 300ms ease',
    
    ':hover': {
      backgroundColor: '#f0f2f5'
    }
  };

  const setDateRange = (dates) => {
    if (dates && dates.length ===2 ) {
      setFormData({
        ...formData,
        startDate: dates[0],
        endDate: dates[1],
      });
      setShowCross(true);
    } 
  };

  const ITEM_HEIGHT = 96;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      },
    },
  };

  const [selectedRegionNames, setSelectedRegionNames] = useState([]);


  useEffect(() => {
    // Update selectedRegionNames whenever formData.regions changes
    const regionNames = regionData
      .filter(region => formData.regions.includes(region.id))
      .map(region => region.name);
    setSelectedRegionNames(regionNames);
  }, [formData.regions, regionData]);

 
  const boxClass = showCross && 'selected-dates' ;
  
  return (
    <Box className="cus-filter-sec" mb={1}>
      <Grid className='filter-box' container alignItems="center" justifyContent="flex-end" spacing={1.5}>
        <Grid item xs={12} sm={4} md={4} xxl={2}>
          <Box className="filter-pro-status">
            <FormControl fullWidth>
              <InputLabel>Transportation</InputLabel>
              <Select   size='small' value={formData?.transportation} onChange={(e)=> setFormData({ ...formData, transportation: e.target.value})} fullWidth className='form-select' name='status' label='Product Status' >
                <MenuItem value='all'>All</MenuItem>
                {transportations.map(vehicle => (                              
                  <MenuItem key={vehicle.value} value={vehicle._id}>{vehicle.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>

        <Grid item xs={12} sm={4} md={4} xxl={2}>
          <Box className="filter-brands">
            <FormControl fullWidth>
              <InputLabel id="demo-multiple-checkbox-label">Regions</InputLabel>
              <Select size='small'
                className='form-select'
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={formData.regions || []}
                fullWidth
                MenuProps={MenuProps}
                onChange={(event, selectedOption, details) => {
                  setFormData({ ...formData, regions: event.target.value });
                }}
                input={<OutlinedInput label="Regions" />}
                renderValue={() => isAllDriverSelected ? 'All' : selectedRegionNames.join(', ')}
              >
                <FormControlLabel sx={{ ...selectAll, backgroundColor: isAllDriverSelected ? '#f0f2f5' : '' }} control={<Checkbox sx={{ p: 0.4, mr: 0.5 }}
                  checked={isAllDriverSelected}
                  indeterminate={
                    regionData?.length > 0 && formData?.regions?.length === regionData?.length
                  }
                  onChange={(e) => {
                    if (e.target.checked) {
                      setFormData({ ...formData, regions: regionData?.map((item => item?.id)) });
                    } else {
                      setFormData({ ...formData, regions: [] });
                    }
                  }}
                />}
                label= {regionData.length ? 'Select All' : 
                  <>
                                            Fetching regions..
                    <CircularProgress style={{marginTop: '-4px', marginLeft: '6px'}} size={12} color='primary' justifyContent="flex-end"/>
                  </>
                }
                heig
                >
                </FormControlLabel>
                {regionData?.map((region) => (
                  <MenuItem sx={{ px: 1 }} key={region.id} value={region?.id}>
                    <Checkbox sx={{ p: 0.4, mr: 1 }} checked={formData?.regions?.indexOf(region?.id) > -1} />
                    {region?.name}
                  </MenuItem>
                ))}                                
              </Select>
            </FormControl>
          </Box>
        </Grid>

        <Grid item xs={12} sm={4} md={4} xxl={2.5}>
          <Box className={`filter-date-range ${boxClass}`}>
            <FormControl fullWidth>
              <DateRange setDateRange={setDateRange} name='range' dateRange={[formData.startDate, formData.endDate]}
              />
            </FormControl>
          </Box>
        </Grid>

        <Grid item xs={12} sm={4} md={4} xxl={2}>
          <Box className="filter-pro-status">
            <FormControl fullWidth>
              <InputLabel>Created By</InputLabel>
              <Select size='small' MenuProps={MenuProps} value={formData.createdBy} onChange={(e)=> setFormData({ ...formData, createdBy: e.target.value})} fullWidth className='form-select' name='status' label='Created By'>
                <MenuItem value='All'>All</MenuItem>
                {admins.map(admin => (
                  <MenuItem  key={admin._id} value={admin._id}>{admin.name}</MenuItem>
                ))
                }
              </Select>
            </FormControl>
          </Box>
        </Grid>

        <Grid item xs={12} sm={4} md={4} xxl={2}>
          <Box className="filter-pro-status">
            <FormControl fullWidth>
              <InputLabel>Status</InputLabel>
              <Select size='small' value={formData.status} onChange={(e)=>setFormData({...formData, status: e.target.value})} fullWidth className='form-select' name='status' label='Status' >
                <MenuItem value='all'>All</MenuItem>
                <MenuItem value='active'>Active</MenuItem>
                <MenuItem value='inactive'>Inactive</MenuItem>
                <MenuItem value='pending'>Pending</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Grid>

        <Grid item xs={12} sm={4} md={4} xxl={1.5}>
          <Box className="filter-btns">
            <Stack direction='row' alignItems="center" gap={1} className='w-100'>
              <Button className="filter-clear-all" size="small" variant="text" color="secondary" style={{ width: '100%' }} onClick={clearAllHandler}>
                            Clear All
              </Button>
              <MDButton className="filter-apply" size="small" variant="contained" color="primary" style={{ width: '100%' }}
                disabled={!showApplyButton}
                onClick={applyFiltersHandler}>
                            Apply
              </MDButton>
            </Stack>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}