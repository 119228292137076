import AddBrandForm from 'components/brands/addBrandForm';
import CommonLayout from 'layouts/DashboardLayout';
import React, { useEffect } from 'react';
import { fetchSingleBrand } from '../../../redux/slices/brand';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

export default function AddBrand() {
  const { brandId = '' } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    if (brandId) {
      dispatch(fetchSingleBrand(brandId));

    }
  }, [brandId]);
  return (
    <CommonLayout>
      <AddBrandForm />
    </CommonLayout>
  );
}
