import axiosClient from "axiosClient";
import { getQueryString } from "utilities/common";

export async function fetchTerpenoidsAPI(filters) {
    return await axiosClient.get(`/api/v1/product/terpenoids/?${getQueryString(filters)}`);
}

export async function fetchTerpenoidDetailsAPI(terpenoidId) {
    return await axiosClient.get(`/api/v1/product/terpenoids/${terpenoidId}`);
}

export async function addTerpenoidAPI(data) {
    return await axiosClient.post(`/api/v1/product/terpenoids`, data);
}

export async function updateTerpenoidAPI(data, terpenoidId) {
    return await axiosClient.put(`/api/v1/product/terpenoids/${terpenoidId}`, data);
}
export async function updateMemberAPI(data, terpenoidId) {
    return await axiosClient.put(`/api/v1/product/terpenoids/update/${terpenoidId}`, data);
}

export async function deleteTerpenoidAPI({ terpeneId, productId }) {
    return await axiosClient.post(`/api/v1/product/delete/terpenoids/${productId}`, { terpeneId });
}

export async function deleteMainTerpenoidAPI(terpeneId) {
    return await axiosClient.delete(`/api/v1/product/terpenoids/${terpeneId}`);
}

export async function fetchMemberOrdersAPI(data, terpenoidId) {
    return await axiosClient.get(`/api/v1/product/terpenoids/?terpenoidId=${terpenoidId}`, data);
}


export async function addTerpenoidToProductAPI(data, productId) {
    return await axiosClient.post(`/api/v1/product/assign/terpenoids/${productId}`, { terpenoidsData: data });
}

export async function updateTerpenoidToProductAPI(data, productId) {
    return await axiosClient.put(`/api/v1/product/update/terpenoids/${productId}`, { ...data});
}
