import TabList from '@mui/lab/TabList';
import { Box, Tab } from '@mui/material'
import Breaks from 'layouts/Hyperdrive/DriverAnalytics/Breaks';
import CheckInOut from 'layouts/Hyperdrive/DriverAnalytics/CheckInOut';
import DriverInfo from 'layouts/Hyperdrive/DriverAnalytics/DriverInfo';
import DriverTask from 'layouts/Hyperdrive/DriverAnalytics/DriverTask';
import React, {  useMemo } from 'react'
import { useSearchParams } from 'react-router-dom';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

const RoleComponent = ({ roleTab, driverId }) => {
    const componentPatterns = [
        { pattern: "driver_analytics/detailsDriver/Driver-Info", component: (id) => <DriverInfo driverId={id} /> },
        { pattern: "driver_analytics/detailsDriver/Driver-Task", component: (id) => <DriverTask driverId={id} /> },
        { pattern: "driver_analytics/detailsDriver/Breaks", component: (id) => <Breaks driverId={id} /> },
        { pattern: "driver_analytics/detailsDriver/CheckIn-CheckOut", component: (id) => <CheckInOut driverId={id} /> },
    ];
    const matchedPattern = componentPatterns.find(({ pattern }) => roleTab?.includes(pattern));
    const ComponentToRender = matchedPattern ? matchedPattern.component(driverId) : <DriverInfo driverId={driverId} />;
    return <>{ComponentToRender}</>;
};

const DriverAnalyticsDetails = ({ driverId }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    let roleTab = useMemo(() => {
        return searchParams.get('roleTab')
    }, [searchParams])
    return (
        <Box className="hd-tabs hd-sub-tabs h-100 p-3">
            <div className='d-flex align-item-center gap-2 text-white-700'>
                <ArrowBackIosNewIcon
                    style={{ fontSize: "16px !important", marginRight: "8px", cursor: "pointer" }}
                    onClick={(e, value) => { setSearchParams({ roleTab: `driver_analytics` }) }}
                />
                <TabList onChange={(e, value) => { setSearchParams({ roleTab: `driver_analytics/detailsDriver/${value}/id=${driverId}` }) }} aria-label="">
                    <Tab value="Driver-Info" label="Driver Info" className={roleTab?.includes(`Driver-Info`) && 'active'} />
                    <Tab value="Driver-Task" label="Driver Task" className={roleTab?.includes(`Driver-Task`) && 'active'} />
                    <Tab value="Breaks" label="Breaks" className={roleTab?.includes(`Breaks`) && 'active'} />
                    <Tab value="CheckIn-CheckOut" label="Check In / Check Out" className={roleTab?.includes(`CheckIn-CheckOut`) && 'active'} />
                </TabList>
            </div>
            <div className='driver-tab-wrapper'>
                <RoleComponent roleTab={roleTab} driverId={driverId} />
            </div>
        </Box>
    )
}

export default DriverAnalyticsDetails