import { CloseRounded } from '@mui/icons-material';
import { Avatar, Box, Checkbox, Grid, IconButton, InputAdornment, Stack, TextField, Tooltip, Typography } from '@mui/material';
import { AgGridTable } from 'components/tables/AgGridTable';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { formatTimestampToDate } from 'utilities/common';
import MDBox from 'components/MDBox';
import { useDebounce } from 'hooks/useDebounce';
import { addCommas } from 'utilities/common';
import defaultProductImage from '../../assets/images/placeholderproduct.svg';
import { capitalizeText } from 'utilities/common';
import SearchIcon from 'common/customIcons/search';
import { getData } from 'utilities/common';
import { isEmptyObject } from 'utilities/common';
import FilterProducts from 'common/CommonDrawer/products/FilterProducts';
import { fetchMasterCatalogProducts } from '../../redux/slices/masterCatalogSlice';

export default function CatalogProducts({ catalogProductSelectionHandler = () => { }, selectedCatalogProducts = {}, setSelectedCatalogProducts = () => { }, mode }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading = false, products = {} } = useSelector(state => state.products);
  const pageRef = useRef(0);
  const searchTermRef = useRef('');
  const mainGridApiRef = useRef('');
  const gridRefApi = useRef(null);
  const [searchTerm, setSearchTerm] = useState('');
  const productFiltersPayload = useRef({});
  const [isLoading, setIsLoading] = useState(true);

  const { retailerId } = useParams();

  const handleSearch = useDebounce(() => {
    // Perform search operation with the debounced term
    pageRef.current = 0;
    searchFn();
  }, 500);

  const selectAllHandler = (e) => {
    if (e.target.checked) {
      mainGridApiRef.current.forEachNode((node) => {
        node.setSelected(true);
      });
    } else {
      mainGridApiRef.current.forEachNode((node) => {
        node.setSelected(false);
      });
    }
  };

  const myComponent = (props) => {
    return <>
      <Stack direction='row' gap={2}>
        <Checkbox size='medium' sx={{ margin: '0px -6px' }} onChange={selectAllHandler} />
        <Typography variant='subtitle1' sx={{ fontSize: '12px', color: '#656575', lineHeight: 16, fontWeight: 400 }}>{props?.displayName}</Typography>
      </Stack>
    </>;

  };

  const columnDefs= [
    {
      headerName: 'Product Name/SKU',
      field: 'Product Name/SKU',
      checkboxSelection: mode === 'view' ? false : true,
      suppressMenu: true,
      wrapText: true,
      autoHeight: true,
      headerComponent: mode === 'view' ? null : myComponent,
      cellRenderer: (props) => {
        return <Tooltip title={capitalizeText(props?.data?.productName)} arrow placement="top">
          <Link to={`/products/details/${props?.data?.productId}?filter=products`}>
            <Box display="flex" alignItems="center" sx={{ cursor: 'pointer' }}>
              <Avatar
                alt="Admin"
                src={props?.data?.productImages?.[0] || defaultProductImage}
                sx={{ width: 32, height: 32 }}
              />
              <MDBox textAlign="left" lineHeight={1.75} ml={1.25} mt={.125}>
                <Typography variant="h6" fontSize={14} color="dark" lineHeight={1.25} fontWeight="semibold" className="truncate line-1">{capitalizeText(props?.data?.productName)}</Typography>
                <Typography variant="p" fontSize={12} color="secondary" lineHeight={1.25}>{props?.data?.sku}</Typography>
              </MDBox>
            </Box>
          </Link>
        </Tooltip>;
      },
      minWidth: 400,
      flex: 2
    },
    {
      headerName: 'Brand',
      field: 'brandName',
      suppressMenu: false,
      minWidth: 220,
      flex: 1.5
    },
    {
      headerName: 'Strain Type',
      field: 'strainType',
      suppressMenu: false,
      minWidth: 220,
      flex: 1.5
    },
    {
      headerName: 'Category',
      field: 'categoryName',
      key: 'fullName',
      suppressMenu: false,
      minWidth: 120,
      flex: 1,
      cellStyle: {
        whiteSpace: 'normal',
        lineHeight: 1.5
      }
    },
    {
      headerName: 'Suggested Price',
      field: 'productPrice',
      suppressMenu: true,
      cellRenderer: (props) => {
        if (!props?.value?.toString()) return;
        if (!props?.value) return '-';
        return < Typography variant='body2' fontWeight="medium" lineHeight={1} > ${props?.value || '0'}</Typography >;
      },
      minWidth: 220,
      flex: 1.2,
      cellStyle: { textAlign: 'right', justifyContent: 'end' },
      headerClass: 'right-aligned-cell-header',
    },
  ];

  const getRows = useCallback(async (params) => {
    gridRefApi.current = params;
    const sortModel = params?.sortModel;
    let sortedColumnName = '';
    let sortDirection = '';
    if (sortModel.length > 0) {
      const sortedColumn = sortModel[0];
      sortedColumnName = sortedColumn.colId;
      sortDirection = sortedColumn.sort === 'asc' ? 'low' : 'high';
    }
    dispatch(fetchMasterCatalogProducts({ filters: { skip: pageRef.current, retailerId: retailerId, limit: 1000, searchTerm: searchTermRef.current, [sortedColumnName]: sortDirection, status: '', ...productFiltersPayload?.current?.queryPayload }, payload: productFiltersPayload.current?.payload })).unwrap().then(res => {
      const { filteredProducts, total } = res;
      const productsArray = filteredProducts?.map((item => {
        return { ...item, id: item._id, totalQuantity: addCommas(item?.totalQuantity), createdDate: formatTimestampToDate(item?.createdDate) };
      }));
      params.successCallback(productsArray, total);
    });
  }, [productFiltersPayload.current]);

  const searchFn = () => {
    const dataSource = {
      getRows
    };
    if (mainGridApiRef.current) {
      mainGridApiRef.current.ensureIndexVisible(0, null);
      mainGridApiRef.current.setGridOption('datasource', dataSource);
    }
  };

  const searchHandler = (searchValue) => {
    setSearchTerm(searchValue);
    searchTermRef.current = searchValue;
    handleSearch(searchValue);
  };


  const onRowClicked = (row) => {
    if (row?.colDef?.headerName !== 'Action' && row?.colDef?.headerName !== 'Product Name/SKU') {
      navigate(`/products/details/${row?.data.productId}?filter=products`);
    }
  };

  const onSelectionChanged = () => {
    const selectedRows = mainGridApiRef.current.getSelectedRows();
    if (selectedRows?.length > 0) {
      catalogProductSelectionHandler(selectedRows);
    } else {
      catalogProductSelectionHandler([]);

    }
  };

  const applyProductFilter = (filterPayload) => {
    pageRef.current = 0;
    productFiltersPayload.current = filterPayload;
    const dataSource = {
      getRows: (params) => {
        dispatch(fetchMasterCatalogProducts({ filters: { skip: pageRef.current, retailerId: retailerId, limit: 1000, searchTerm: searchTermRef.current, ...filterPayload?.queryPayload }, payload: filterPayload?.payload })).unwrap()
          .then(res => {
            const { filteredProducts, total } = res;
            const productsArray = filteredProducts?.map((item => {
              return { ...item, id: item._id, totalQuantity: addCommas(item?.totalQuantity), createdDate: formatTimestampToDate(item?.createdDate) };
            }));
            params.successCallback(productsArray, total);
          }).catch(() => {
            params.successCallback([], 0);
          });
      }
    };
    if (mainGridApiRef?.current) {
      mainGridApiRef.current.ensureIndexVisible(0, null);
      mainGridApiRef.current.setGridOption('datasource', dataSource);
    }
  };

  const getQuryValues = (data) => {
    productFiltersPayload.current = data;
    setIsLoading(false);
  };

  useEffect(() => {
    if (getData('catalogProducts')) {
      setSelectedCatalogProducts(JSON.parse(getData('catalogProducts')));
    }
  }, []);

  useEffect(() => {
    if (mainGridApiRef?.current && !loading && !isEmptyObject(selectedCatalogProducts)) {
      setTimeout(() => {
        const catalogSelectedProducts = Object.values(selectedCatalogProducts)?.reduce(((pre, curr) => [...pre, ...curr]), []);
        mainGridApiRef.current.forEachNode((node) => {
          if (catalogSelectedProducts?.find((catalogProduct => catalogProduct?.productId === node?.data?.productId))) {
            node.setSelected(true);
          }
        });
      }, 1000);
    }
  }, [loading, mainGridApiRef]);

  useEffect(() => {
    if (mainGridApiRef?.current) {
      if (loading) {
        mainGridApiRef.current.showLoadingOverlay();
      } else if (products?.total === 0) {
        mainGridApiRef.current.showNoRowsOverlay();
      }
      else {
        mainGridApiRef.current.hideOverlay();
      }
    }

  }, [products, loading]);

  return (
    <Box >
      <Grid container alignItems="center" rowSpacing={2} columnSpacing={2} mt={0} mb={1}>
        <Grid item xs={12} sm={10} lg={12}>
          <Stack direction="row" justifyContent={{ xs: 'start', sm: 'end' }} alignItems="stretch" gap={2} >
            <TextField fullWidth className="custom-search-field" placeholder="Search" label="" value={searchTerm} onChange={(e) => searchHandler(e.target.value)}
              InputProps={{
                startAdornment: <InputAdornment position="start">
                  <IconButton size='small'>
                    <SearchIcon width='18px' height='18px' />
                  </IconButton>
                </InputAdornment>,
                endAdornment: searchTerm?.length > 0 ? <InputAdornment>
                  <IconButton onClick={() => searchHandler('')} size='small'>
                    <CloseRounded />
                  </IconButton>
                </InputAdornment> : null
              }}
            />
     
          </Stack>
        </Grid>
        <Grid item xs={12} lg={12}>
          <FilterProducts applyProductFilter={applyProductFilter} setIsLoading={setIsLoading} getQuryValues={getQuryValues} productFiltersPayload={productFiltersPayload?.current} clearFilterRedirectUrl={'/master-catalog'} filterMode="master" />
        </Grid>
      </Grid>
      {
        !isLoading && <AgGridTable
          col={columnDefs}
          mainGridApiRef={mainGridApiRef}
          getRows={getRows}
          pageRef={pageRef}
          isLoading={loading}
          onRowClicked={onRowClicked}
          style={{ height: '60vh' }}
          gridOptions={{
            rowHeight: 64,
          }}
          maxLimit={1000}
          onSelectionChanged={onSelectionChanged}
        />
      }

    </Box>

  );

}