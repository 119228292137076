import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { 
    getLastSyncDateAPI, 
    loginAPI, 
    shopOpenTimeAPI, 
    shopUpdateTimeAPI 
} from '../../apis/hyperwolf/auth';
import { setData } from 'utilities/common';
import { isSuperAdmin } from 'utilities/constants';
import { hyperwolfAccessTokenId } from 'utilities/constants';
import { hyperwolfAccessTokenName } from 'utilities/constants';
import { removeData } from 'utilities/common';
import { getLocaUserInfo } from 'utilities/common';
import { isLoggedIn } from 'utilities/common';

// Async thunks
export const login = createAsyncThunk(
  'auth/login',
  async (data, { rejectWithValue }) => {
    try {
      const response = await loginAPI(data);
      setData(hyperwolfAccessTokenName, response);
      setData(hyperwolfAccessTokenId, response.access_token);
      const superAdmin = response?.isSuperAdmin || false;
      setData(isSuperAdmin, superAdmin);
      return response?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getLastSyncDate = createAsyncThunk(
  'auth/getLastSyncDate',
  async (data, { rejectWithValue }) => {
    try {
      const response = await getLastSyncDateAPI(data);
      return response?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const shopOpenTiming = createAsyncThunk(
  'auth/shopOpenTiming',
  async (data, { rejectWithValue }) => {
    try {
      const response = await shopOpenTimeAPI(data);
      return response?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const shopUpdateTime = createAsyncThunk(
  'auth/shopUpdateTime',
  async (data, { rejectWithValue }) => {
    try {
      const response = await shopUpdateTimeAPI(data);
      return response?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Slice
const hyperwolfAuthSlice = createSlice({
    name: 'hyperwolfAuth',
    initialState: {
        user: getLocaUserInfo() || {},
        isLoading: false,
        isLoggedIn: !!isLoggedIn(),
        userPhone: "",
        lastSyncDate: "",
        shopeTimeData: {},
        error: null,
    },
    reducers: {
        logoutUser(state) {
            removeData(AccessTokenId);
            removeData(AccessTokenName);
            removeData(isSuperAdmin);
            removeData("adminLastSync");
            state.isLoggedIn = false;
        },
    },
    extraReducers: (builder) => {
        builder
            // Handle login
            .addCase(login.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(login.fulfilled, (state, action) => {
                state.isLoading = false;
                state.user = action.payload;
                state.isLoggedIn = true;
            })
            .addCase(login.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            // Handle getLastSyncDate
            .addCase(getLastSyncDate.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(getLastSyncDate.fulfilled, (state, action) => {
                state.isLoading = false;
                state.lastSyncDate = action.payload?.lastSync || "";
            })
            .addCase(getLastSyncDate.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            // Handle shopOpenTiming
            .addCase(shopOpenTiming.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(shopOpenTiming.fulfilled, (state, action) => {
                state.isLoading = false;
                state.shopeTimeData = action.payload;
            })
            .addCase(shopOpenTiming.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            // Handle shopUpdateTime
            .addCase(shopUpdateTime.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(shopUpdateTime.fulfilled, (state, action) => {
                state.isLoading = false;
            })
            .addCase(shopUpdateTime.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            });
    }
});

export const { logoutUser } = hyperwolfAuthSlice.actions;

export default hyperwolfAuthSlice.reducer;
