import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { isEmptyObject } from 'utilities/common';
import CommonLayout from 'layouts/DashboardLayout';
import { addCategory, getAllCategory, getAllCategoryImages } from '../../../../redux/slices/hyperwolf/products';
import { useNavigate } from 'react-router-dom';
import CustomInput from 'components/common/CustomInput';
import { BASE_URL } from 'utilities/constants';
import { Grid, Button, Typography, Box, Card } from '@mui/material';


function AddHyperwolfCategory() {
  const dispatch = useDispatch();
  const { categoryImages, singleCategory,loading=false } = useSelector(state => state.hyperwolfProducts);
  const [category, setCategory] = useState({ categoryName: '', description: '' });
  const [selectedImage, setSelectedImage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if (!isEmptyObject(singleCategory)) {
      setCategory({
        categoryName: singleCategory.categoryName || '',
        description: singleCategory.description || ''
      });
      setSelectedImage(singleCategory.image || '');
    }
  }, []);

  const handleChangeCategory = (e) => {
    const { name, value } = e.target;

    setCategory({ ...category, [name]: value });
  };

  const addCategoryHandler = () => {
    let updatedData = { ...category };
    if (selectedImage) {
      updatedData = {
        ...updatedData,
        image: selectedImage
      };
    }
    dispatch(addCategory({ data: updatedData, id: ((singleCategory && singleCategory._id) || '') })).then(() => {
      dispatch(getAllCategory());
      navigate('/hyperwolf/manage-categories');
    }).catch(() => { });
  };

  const onClickSelectImage = (url) => {
    setSelectedImage(url);
  };

  useEffect(() => {
    dispatch(getAllCategoryImages());
  }, []);

  return (
    <CommonLayout>
      <Box p={{ xs: 2, sm: 3, lg: 4 }}>
        <Card sx={{ p: { xs: 2, md: 3 }, mb: 4, borderRadius: 2.5, boxShadow: 'none' }}>
          <Typography variant="h4" className="d-flex jc-ctr">{singleCategory?._id ? 'Update' : 'Add'} Category</Typography>
          <Grid mt={2} container component="main" className={' add-category-modal-container'}>
            <Grid item xs={12}>
              <h4 className="cus-label">Please Select Image</h4>
            </Grid>
            <Grid item xs={4} className="add-category-input">
              <Grid container>
                <Grid item xs={12} className="category-image-item-2">
                  <div className="select-category-image selected">
                    <img src={BASE_URL.concat(selectedImage)} alt="" />
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={8} className="add-category-input">
              <Grid container>
                <Grid item xs={12} className="category-images-container">
                  <Grid container>
                    <Grid item xs={12} className="scrollable-sec">
                      <Grid container>
                        {
                          categoryImages?.map((image, indx) => {
                            const isSelected = image.url === selectedImage;
                            return <Grid key={indx} item xs={3} className="inner-select-image-item">
                              {isSelected ? <div className="tick-placement"><CheckCircleIcon /></div> : null}
                              <div className={`select-category-image ${isSelected ? 'disable-select' : ''}`} onClick={() => onClickSelectImage(image.url)}>
                                <img src={BASE_URL.concat(image.url)} alt="" />
                              </div>
                            </Grid>;
                          })
                        }
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12}>
                  <CustomInput
                    name="categoryName"
                    value={category.categoryName || ''}
                    onChange={(e) => { handleChangeCategory(e); }}
                    label="Category Name"
                    className="w-100"
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomInput
                    name="description"
                    value={category.description || ''}
                    onChange={(e) => { handleChangeCategory(e); }}
                    label="Description"
                    className="w-100"
                    margin="normal"
                  />
                </Grid>

              </Grid>
            </Grid>
          </Grid >
          <Box textAlign='end' mt={2}>
            <Button disabled={loading} onClick={() => { addCategoryHandler(); }} variant="contained" color="primary" >Submit</Button>
          </Box>
        </Card>
      </Box>

    </CommonLayout>
  );
}



export default AddHyperwolfCategory;