import { Checkbox, FormControlLabel } from '@mui/material';
import React from 'react';

export default function CustomCheckbox(props) {
    const { value, onChange, name, label } = props

    return (
        <FormControlLabel
            control={
                <Checkbox
                    checked={value}
                    onChange={onChange}
                    name={name}
                    color="primary"
                />
            }
            label={label}
        />
    );
}