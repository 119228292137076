import axiosClient from "axiosClient";
import { getQueryString } from "utilities/common";

export async function fetchBrandsAPI(filters={}) {
    return await axiosClient.get(`/api/v1/brand/?${getQueryString(filters)}`);
}
export async function deletBrandAPI(brandId) {
    return await axiosClient.delete(`/api/v1/brand/${brandId}`);
}

export async function createBrandAPI(data) {
    return await axiosClient.post(`/api/v1/brand`, data);
}

export async function fetchBrandDetailsAPI(brandId) {
    return await axiosClient.get(`/api/v1/brand/${brandId}?userType=admin`);
}

export async function updateBrandAPI(data,brandId) {
    return await axiosClient.put(`/api/v1/brand/update/${brandId}`, data);
}

export function getMainBrandsAPI() {
    return axiosClient.get('api/v1/brand/main/page');
}

export function updateMainBrandAPI(data) {
    return axiosClient.post(`api/v1/brand/update/main/metas`, data);
}