import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import "./styles/index.scss"
import 'react-medium-image-zoom/dist/styles.css'
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

import { store } from "./redux/store";
import { Provider } from "react-redux";
const container = document.getElementById("app");
const root = createRoot(container);

root.render(
  <BrowserRouter>
      <Provider store={store}>
        <App />
      </Provider>
  </BrowserRouter>
);