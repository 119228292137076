import { axiosHempDevClient } from "axiosClient";
import { getQueryString } from "utilities/common";

export async function getDriversHempAPI(filters) {
    return await axiosHempDevClient.get(`/api/v1/fleet/list?${getQueryString(filters)}`)
}

export async function addDriverHempAPI(data) {
    return await axiosHempDevClient.post('/api/v1/fleet/register',data)
}

export async function fetchDriverDetailsHempAPI(fleetId){
    return await axiosHempDevClient.get(`/api/v1/fleet/details?fleetId=${fleetId}`,
    )
  }

export async function getTransportationsHempAPI(){
    return await axiosHempDevClient.get(`/api/v1/transportationTypes/get`, 
    )
  }

export async function updateDriverHempAPI(data){
  return await axiosHempDevClient.put('/api/v1/fleet/edit',data,)
}

export async function deleteDriverHempAPI(data) {
    return await axiosHempDevClient.delete('/api/v1/fleet/delete', {data: data})
}

export async function bulkDeleteDriverHempAPI(data) {
    return await axiosHempDevClient.delete('/api/v1/fleet/bulkDelete', {data: data})
  }