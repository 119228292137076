import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import MenuIcon from '@mui/icons-material/Menu';
import CropOriginalIcon from '@mui/icons-material/CropOriginal';
import MDInput from "components/MDInput";
import CustomMultiSelect from "components/common/CustomMultiSelect";

const getItemStyle = (isDragging, draggableStyle) => ({
    background: isDragging ? "" : "",
    border: isDragging ? "none" : "",
    ...draggableStyle
});


export default function DraggableList(props) {

    const {
        category = [],
        handleChangeSubCategory,
        handleDeleteCategory,
        values,
        onUpdateCategoryOrder,
        handleEditCategory
    } = props;

    const onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const items = reorder(
            props.category,
            result.source.index,
            result.destination.index
        );
        onUpdateCategoryOrder(items)
    }

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
      
        return result;
      };

      return (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <ul
                className="category-ul-list"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {category &&
                  category.map((item, index) => {
                    let imgUrl = item && item.image || '';
                    const isDealsCategory = item.categoryName.includes('Deals');
    
                    return (
                      <Draggable
                        key={item._id}
                        draggableId={item._id}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <li
                            className="custon-list"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            <MenuIcon className="mr-2" />
                            <div className="category-img-sec">
                              {imgUrl ? (
                                <img
                                  src={`${process.env.REACT_APP_HYPERWOLF_API_BASE_URL}${imgUrl}`}
                                  className="category-img"
                                  alt=""
                                />
                              ) : (
                                <CropOriginalIcon />
                              )}
                            </div>
                            <div className="left-section">
                              <MDInput
                                name="categoryName"
                                fullWidth
                                value={item.categoryName || ''}
                                // onChange={(e) => { handleChangeCategory(e, index) }}
                                onChange={() => {}}
                                label="Category Name"
                              />
                            </div>
                            {isDealsCategory ? (
                             
                            <MDInput
                              name="subCategoryName"
                              id={item._id} 
                              fullWidth
                              value={item.blazeCategories.length ? item.blazeCategories.map((blaze=>blaze?.name))?.join(',') : ''}
                              onChange={(e) => handleChangeSubCategory(e, null, index)}
                              label="Sub Category"
                              className="w-50"
                            />  
                            ) : (
                              <CustomMultiSelect
                                label="Sub Category"
                                name="name"
                                fullWidth
                                options={values || []}
                                handleChange={(e, val) =>
                                  handleChangeSubCategory(e, val, index)
                                }
                                value={item.blazeCategories || []}
                                className="w-50"
                              />
                            )}
                            <EditIcon
                              className="material-ui-icons ml-1"
                              onClick={() => {
                                handleEditCategory(item);
                              }}
                              sx={{cursor:'pointer'}}

                            />
                            <DeleteOutlineIcon
                              className="material-ui-icons ml-1"
                              sx={{cursor:'pointer'}}

                              onClick={() => {
                                handleDeleteCategory(item._id);
                              }}
                            />
                          </li>
                        )}
                      </Draggable>
                    );
                  })}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
    );
}
