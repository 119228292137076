import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { createCategoryAPI, createWebCategoryAPI, deleteCategoryAPI, deleteWebCategoryAPI, fetchCategoryDetailsAPI, fetchWebCategoriesAPI, fetchWebCategoryDetailsAPI, updateCategoryAPI, updateWebCategoryOrdersAPI } from "../../redux/apis/category";
import { fetchProductCategoriesAPI } from "../../redux/apis/common";

const initialState = {
    loading: false,
    error: null,
    singleCategory: {},
    singleWebCategory: {},
    isDeleting: false,
    isFetching: false,
    categoriesList: {},
    webCategoriesList: []
    // Other initial state properties related to categories if needed
};

// Define your asynchronous thunk
export const deleteCategory = createAsyncThunk(
    'categories/deleteCategory',
    async (categoryID, { rejectWithValue }) => {
        try {
            const res = await deleteCategoryAPI(categoryID);
            return res;
        } catch (error) {
            
            return rejectWithValue(error?.response?.data);
        }
    }
);

export const createCategory = createAsyncThunk(
    'categories/createCategory',
    async (data, { rejectWithValue }) => {
        try {
            const res = await createCategoryAPI(data);
            return res;
        } catch (error) {
            
            return rejectWithValue(error?.response?.data);
        }
    }
);

export const updateCategory = createAsyncThunk(
    'categories/updateCategory',
    async ({ data, categoryID }, { rejectWithValue }) => {
        try {
            const res = await updateCategoryAPI(data, categoryID);
            return res;
        } catch (error) {
            
            return rejectWithValue(error?.response?.data);
        }
    }
);

export const fetchCategoriesList = createAsyncThunk(
    'categories/fetchCategoriesList',
    async (filters, { rejectWithValue }) => {
        try {
            const res = await fetchProductCategoriesAPI(filters);
            return res
        } catch (error) {
             
            return rejectWithValue(error)
        }
    }
)


export const fetchSingleCategory = createAsyncThunk(
    'categories/fetchSingleCategory',
    async (categoryID, { rejectWithValue }) => {
        try {
            const res = await fetchCategoryDetailsAPI(categoryID);
            return res;
        } catch (error) {
            
            return rejectWithValue(error?.response?.data);
        }
    }
);

export const fetchWebCategories = createAsyncThunk(
    'categories/fetchWebCategories',
    async (filters, { rejectWithValue }) => {
        try {
            const res = await fetchWebCategoriesAPI(filters);
            return res
        } catch (error) {
             
            return rejectWithValue(error)
        }
    }
)

export const createWebCategory = createAsyncThunk(
    'categories/createWebCategory',
    async (filters, { rejectWithValue }) => {
        try {
            const res = await createWebCategoryAPI(filters);
            return res
        } catch (error) {
             
            return rejectWithValue(error)
        }
    }
)


export const updateCategoriesOrder = createAsyncThunk(
    'categories/updateCategoriesOrder',
    async (data, { rejectWithValue }) => {
        try {
            const res = await updateWebCategoryOrdersAPI(data);
            return res
        } catch (error) {
             
            return rejectWithValue(error)
        }
    }
)

export const fetchSingleWebCategory = createAsyncThunk(
    'categories/fetchSingleWebCategory',
    async (webCategoryID, { rejectWithValue }) => {
        try {
            const res = await fetchWebCategoryDetailsAPI(webCategoryID);
            return res;
        } catch (error) {
            
            return rejectWithValue(error?.response?.data);
        }
    }
);



export const deleteWebCategory = createAsyncThunk(
    'categories/deleteWebCategory',
    async (webCategoryId, { rejectWithValue }) => {
        try {
            const res = await deleteWebCategoryAPI(webCategoryId);
            return res;
        } catch (error) {
            
            return rejectWithValue(error?.response?.data);
        }
    }
);


const categorySlice = createSlice({
    name: 'categories',
    initialState,
    reducers: {
        // Other reducers can be added here if needed
    },
    extraReducers: (builder) => {
        builder
            .addCase(deleteCategory.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(deleteCategory.fulfilled, (state, action) => {
                state.loading = false;
                // Handle successful deletion if needed
            })
            .addCase(deleteCategory.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload; // Handle the error: store the error payload in state
            });

        builder
            .addCase(createCategory.pending, (state) => {
                state.loading = true;
            })
            .addCase(createCategory.fulfilled, (state, action) => {
                state.loading = false;
                state.category = action.payload;
            })
            .addCase(createCategory.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });

        builder
            .addCase(updateCategory.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateCategory.fulfilled, (state, action) => {
                state.loading = false;
                state.updatedCategory = action.payload;
            })
            .addCase(updateCategory.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });

        builder
            .addCase(fetchSingleCategory.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchSingleCategory.fulfilled, (state, action) => {
                state.loading = false;
                state.singleCategory = action.payload.categoryDetails;
            })
            .addCase(fetchSingleCategory.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });

        builder
            .addCase(updateCategoriesOrder.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateCategoriesOrder.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(updateCategoriesOrder.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
        builder
            .addCase(fetchCategoriesList.pending, (state) => {
                state.singleCategory = {}
                state.loading = true;
            })
            .addCase(fetchCategoriesList.fulfilled, (state, action) => {
                state.loading = false;
                state.singleCategory = {}
                state.categoriesList = action.payload;
            })
            .addCase(fetchCategoriesList.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });

        builder
            .addCase(fetchWebCategories.pending, (state) => {
                state.isFetching = true;
                state.error = null;
                state.singleWebCategory = {}
                state.webCategoriesList = [];
            })
            .addCase(fetchWebCategories.fulfilled, (state, action) => {
                state.isFetching = false;
                state.webCategoriesList = action.payload;
            })
            .addCase(fetchWebCategories.rejected, (state, action) => {
                state.isFetching = false;
                state.error = action.payload;
            });

        builder
            .addCase(createWebCategory.pending, (state) => {
                state.isCreating = true;
                state.error = null;
                state.createdWebCategory = null;
            })
            .addCase(createWebCategory.fulfilled, (state, action) => {
                state.isCreating = false;
                state.createdWebCategory = action.payload;
            })
            .addCase(createWebCategory.rejected, (state, action) => {
                state.isCreating = false;
                state.error = action.payload;
            });

        builder
            .addCase(fetchSingleWebCategory.pending, (state) => {
                state.isFetching = true;
                state.error = null;
                state.singleWebCategory = {};
            })
            .addCase(fetchSingleWebCategory.fulfilled, (state, action) => {
                state.isFetching = false;
                state.singleWebCategory = action.payload;
            })
            .addCase(fetchSingleWebCategory.rejected, (state, action) => {
                state.isFetching = false;
                state.error = action.payload;
            });

        builder
            .addCase(deleteWebCategory.pending, (state) => {
                state.isDeleting = true;
                state.errorDelete = null;
                state.deletedWebCategoryId = null;
            })
            .addCase(deleteWebCategory.fulfilled, (state, action) => {
                state.isDeleting = false;
                state.deletedWebCategoryId = action.payload;
            })
            .addCase(deleteWebCategory.rejected, (state, action) => {
                state.isDeleting = false;
                state.errorDelete = action.payload;
            });

    },
});

export const { /* Other action creators */ } = categorySlice.actions;

export default categorySlice.reducer;