import { axiosHyperwolfDevClient } from "axiosClient";
import { getQueryString } from "utilities/common";


export async function fetchHyperwolfOrdersAPI(filters) {

    return await axiosHyperwolfDevClient.get(`api/v1/orders/listing?${getQueryString(filters)}`)
}

export async function fetchOrderDetailsbyIdAPI(id) {

    return await axiosHyperwolfDevClient.get(`api/v1/orders/${id}`)

}

