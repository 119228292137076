import { CloseRounded } from '@mui/icons-material';
import { Avatar, Box,  Chip,  Grid, IconButton, InputAdornment, Stack, TextField, Tooltip, Typography } from '@mui/material';
import { AgGridTable } from 'components/tables/AgGridTable';
import CommonLayout from 'layouts/DashboardLayout';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import {  fetchProducts } from '../../redux/slices/products';
import { formatTimestampToDate } from 'utilities/common';
import MDBox from 'components/MDBox';
import { useDebounce } from 'hooks/useDebounce';
import { openSnackbar } from '../../redux/slices/common';
import { addCommas } from 'utilities/common';
import defaultProductImage from '../../assets/images/placeholderproduct.svg';
import { capitalizeText } from 'utilities/common';
import SearchIcon from 'common/customIcons/search';
import ApprovalsHistory from './approvalHistory';
import { fetchApprovals,  updateApproval } from '../../redux/slices/approvals';
import { closeCustomModalDialog, openCustomModalDialog } from '../../redux/slices/modalSlice';

export default function Approvals() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const approvalRef = useRef();
  const { isLoading = false, approvals = {} } = useSelector(state => state.approvalSlice);
  const { products: productsData } = useSelector(state => state.products);
  const pageRef = useRef(0);
  const searchTermRef = useRef('');
  const mainGridApiRef = useRef('');
  const gridRefApi = useRef(null);
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = useDebounce(() => {
    pageRef.current = 0;
    searchFn();
  }, 500);

  const myComponent = (props) => {
    return <>
      <Stack direction='row' gap={2}>
        <Typography variant='subtitle1' sx={{ fontSize: '12px', color: '#656575', lineHeight: 16, fontWeight: 400 }}>
          {props?.displayName}
        </Typography>
      </Stack>
    </>;
  };

  const showResponseMessage = (message, errortype) => {
    if (errortype === 'success') {
      dispatch(openSnackbar({ content: message, color: 'success', icon: 'check' }));
    }
    if (errortype === 'error') {
      dispatch(openSnackbar({ content: message, color: 'error', icon: 'warning' }));
    }
  };

  const approvalStatusHandler = (approvalId, reason) => {
    dispatch(updateApproval({ isDeclined: true, isAccept: false, isOverride: false, approvalId: approvalId, declinedReason: reason })).unwrap().then((res) => {
      showResponseMessage(res?.message, 'success');
      dispatch(closeCustomModalDialog());
      searchFn();
      approvalRef.current();
    }).catch((err) => {
      showResponseMessage(err?.message, 'error');
    });
  };

  const approvalDialogHandler = (approvalId) => {
    dispatch(openCustomModalDialog({
      modal: 'DECLINE_APPROVAL_REASON', modalData: {
        approvalId: approvalId, approvalStatusHandler: (reason) => {
          approvalStatusHandler(approvalId, reason);
        }
      }
    }));
  };

  const approveRetailerProduct = (approvalId) => {
    dispatch(updateApproval({ isDeclined: false, isAccept: true, isOverride: false, approvalId: approvalId })).unwrap().then(res => {
      showResponseMessage(res?.message, 'success');
      searchFn();
      approvalRef.current();
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
      await dispatch(fetchProducts({ filters: { skip: 0, limit: 1000, userType: 'admin' } })).
          unwrap(res => res.data);
      } catch (error) {
        // Handle error if needed
      }
    };

    fetchData();
  }, []);

  const approvalOverrideDialogHandler = async (approvalId, retailerId, productId) => {
    try {
      await dispatch(openCustomModalDialog({
        modal: 'OVERRIDE_APPROVAL',
        modalData: { approvalId, retailerId, productId, searchFn, approvalRef }
      }));
    } catch (error) {
    }
  };

  const columnDefs = [
    {
      headerName: 'Product Name/SKU',
      field: 'Product Name/SKU',
      checkboxSelection: false,
      wrapText: true,
      autoHeight: true,
      headerComponent: myComponent,
      cellRenderer: (props) => {
        return <Tooltip title={capitalizeText(props?.data?.productName)} arrow placement='top'>
          <Link to={`/products/details/${props?.data?.productId}?filter=products`}>
            <Box display="flex" alignItems="center" sx={{ cursor: 'pointer' }}>
              <Avatar
                alt="Admin"
                src={props?.data?.productImage || defaultProductImage}
                sx={{ width: 32, height: 32 }}
              />
              <MDBox textAlign="left" lineHeight={1.75} ml={1.25} mt={.125}>
                <Typography variant="h6" fontSize={14} color="dark" lineHeight={1.25} fontWeight="semibold" className="truncate line-1">{capitalizeText(props?.data?.productName)}</Typography>
                <Typography variant="p" fontSize={12} color="secondary" lineHeight={1.25}>{props?.data?.sku}</Typography>
              </MDBox>
            </Box>
          </Link>
        </Tooltip>;
      },
      minWidth: 280
    },
    {
      headerName: 'Brand',
      field: 'brandName',
      suppressMenu: false,
      minWidth: 140
    },
    {
      headerName: 'Category',
      field: 'categoryName',
      key: 'fullName',
      suppressMenu: false,
      minWidth: 180,
      cellStyle: {
        whiteSpace: 'normal',
        lineHeight: 1.5
      }
    },
    {
      headerName: 'Price',
      field: 'unitPrice',
      suppressMenu: false,
      cellRenderer: (props) => {
        if (!props?.value?.toString()) return;
        if (!props?.value) return '-';
        return < Typography variant='body2' fontWeight="medium" lineHeight={1} > ${props?.value || '0'}</Typography >;
      },
      minWidth: 140
    },
    {
      headerName: '',
      cellRenderer: (props) => {
        return props.data?.approvalId ? (
          <Box display="inline-flex" alignItems="center">
            <Box display="inline-flex" gap={1} className="invite-action">
              <Chip
                label='Accept'
                size='small'
                color='success'
                variant="outlined"
                onClick={() => approveRetailerProduct(props?.data?.approvalId)}
              />
              <Chip
                label='Decline'
                size='small'
                color='error'
                variant="outlined"
                onClick={() => approvalDialogHandler(props?.data?.approvalId)}
              />
              <Chip
                label='Override'
                size='small'
                color='warning'
                variant="outlined"
                onClick={() => {
                  approvalOverrideDialogHandler(props?.data?.approvalId, props?.data?.retailerId, props?.data?.productId,productsData);
                }}
              />
            </Box>
          </Box>
        ) : null;
      },
      cellStyle: { textAlign: 'right', justifyContent: 'flex-end' },
      minWidth: 340
    }

  ];

  const getRows = useCallback(async (params) => {
    gridRefApi.current = params;
    const sortModel = params?.sortModel;
    let sortedColumnName = '';
    let sortDirection = '';
    if (sortModel?.length > 0) {
      const sortedColumn = sortModel[0];
      sortedColumnName = sortedColumn.colId;
      sortDirection = sortedColumn.sort === 'asc' ? 'low' : 'high';
    }
    dispatch(fetchApprovals({ skip: pageRef.current, limit: 1000, userType: 'admin', searchTerm: searchTermRef.current, [sortedColumnName]: sortDirection, status: '' })).unwrap().then(res => {
      const { approvals, total } = res;
      const approvalsArray = approvals?.map((item => {
        return { ...item, id: item._id, totalQuantity: addCommas(item?.totalQuantity), createdDate: formatTimestampToDate(item?.createdDate) };
      }));
      params?.successCallback(approvalsArray, total);
    });

  }, [productsData.length]);

  const searchFn = () => {
    const dataSource = {
      getRows
    };
    if (mainGridApiRef.current) {
      mainGridApiRef.current.ensureIndexVisible(0, null);
      mainGridApiRef.current.setGridOption('datasource', dataSource);
    }
  };

  const searchHandler = (searchValue) => {
    setSearchTerm(searchValue);
    searchTermRef.current = searchValue;
    handleSearch(searchValue);
  };

  const onRowClicked = (row) => {
    if (row?.colDef?.headerName !== '') {
      navigate(`/approvals/${row?.data.approvalId}/details`);
    }
  };


  useEffect(() => {
    if (mainGridApiRef?.current) {
      if (isLoading) {
        mainGridApiRef.current.showLoadingOverlay();
      } else if (approvals?.total === 0) {
        mainGridApiRef.current.showNoRowsOverlay();
      }
      else {
        mainGridApiRef.current.hideOverlay();
      }
    }

  }, [approvals, isLoading]);

  return (<>
    <CommonLayout>
      <Box p={{ xs: 2, sm: 3, lg: 4 }} pb={{ lg: 2 }}>
        <Grid container alignItems="center" rowSpacing={2} columnSpacing={2}  mb={3}>
          <Grid item xs={12} sm={2}>
            <Typography variant='h4'>Approvals</Typography>
          </Grid>
          <Grid item xs={12} sm={10}>
            <Stack direction="row" justifyContent={{ xs: 'start', sm: 'end' }} flexWrap="wrap" alignItems="stretch" gap={2}>
              <TextField className="custom-search-field" placeholder="Search" label="" value={searchTerm} onChange={(e) => searchHandler(e.target.value)}
                InputProps={{
                  startAdornment: <InputAdornment position="start">
                    <IconButton size='small'>
                      <SearchIcon width='18px' height='18px' />
                    </IconButton>
                  </InputAdornment>,
                  endAdornment: searchTerm?.length > 0 ? <InputAdornment>
                    <IconButton onClick={() => searchHandler('')} size='small'>
                      <CloseRounded />
                    </IconButton>
                  </InputAdornment> : null
                }}
              />
            </Stack>
          </Grid>
        </Grid>


        <AgGridTable
          col={columnDefs}
          mainGridApiRef={mainGridApiRef}
          getRows={getRows}
          pageRef={pageRef}
          isLoading={isLoading}
          onRowClicked={onRowClicked}
          style={{ height: '34vh' }}
          gridOptions={{
            rowHeight: 64,
          }}
          maxLimit={1000}
        />
      </Box>

      <ApprovalsHistory approvalRef={approvalRef} />
    </CommonLayout>
  </>
  );

}