import axiosClient from "axiosClient";
import { getQueryString } from "utilities/common";

export function getStrainsAPI(data) {
    return axiosClient.get(`api/v1/strain?${getQueryString(data)}`);
}

export function addOrUpdateStrainAPI(data, id = '') {
    if (id) {
        return axiosClient.put(`api/v1/strain/update/${id}`, data);
    }
    return axiosClient.post('api/v1/strain/create', data);
}

export function deleteStrainAPI(id) {
    return axiosClient.delete(`api/v1/strain/delete/strain/${id}`)
}

export function getAllInfoEffectAPI() {
    return axiosClient.get(`api/v1/admin/products/infoEffects`)
}


export function getSingleStrainAPI(id) {
    return axiosClient.get(`api/v1/strain/${id}`)
}

// Main Strain API
export function getMainStrainsAPI() {
    return axiosClient.get('api/v1/strain/main/page');
}

export function updateMainStrainAPI(data) {
    return axiosClient.post(`api/v1/strain/update/main/metas`, data);
}

export function getFlavorsAPI() {
    return axiosClient.get('api/v1/strain/get/all/flavors');
}