import axiosClient from "axiosClient";
import { getQueryString } from "utilities/common";

export async function fetchRolesAPI(filters) {
    return await axiosClient.get(`api/v1/admin/user/rules`);
}

export async function fetchRolesListAPI(filters) {
    return await axiosClient.get(`/api/v1/admin/get?${getQueryString(filters)}`);
}


export async function fetchRoleDetailsAPI(roleId) {
    return await axiosClient.get(`/api/v1/admin/${roleId}`);
}

export async function addRoleAPI(data) {
    return await axiosClient.post(`/api/v1/admin/create`, data);
}

export async function updateRoleAPI(data,roleId) {
    return await axiosClient.put(`/api/v1/admin/edit/${roleId}`, data);
}

export async function deleteRoleAPI(roleId) {
    return await axiosClient.delete(`/api/v1/admin/delete/${roleId}`);
}

export async function fetchRoleAndPermissionsListAPI(filters) {
    return await axiosClient.get(`/api/v1/admin/roles/permission?${getQueryString(filters)}`);
}
