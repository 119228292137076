import React, { useEffect, useRef, useState } from 'react';
import {
  TextField,
  FormControl,
  Grid,
  Card,
  Typography,
  Box,
  Tooltip,
  Stack,
  InputAdornment,
  IconButton,
  FormHelperText,
} from '@mui/material';
import MDButton from 'components/MDButton';
import { openSnackbar, uploadImage } from '../../redux/slices/common';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import MapWithPolygon from 'components/googleMaps';
import { addRegion, updateRegion } from '../../redux/slices/regions';
import { useNavigate, useParams } from 'react-router-dom';
import { UploadFile } from '@mui/icons-material';
import { regionValidation } from 'validations/region';
import { isEmptyObject } from 'utilities/common';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { isValidFileExtension } from 'utilities/common';

export default function AddRegionForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { regionId } = useParams();
  const [regionName, setRegionName] = useState('');
  const [uploadedKML, setUploadedKML] = useState('');
  const [status, setStatus] = useState('active');
  const [openingHours, setOpeningHours] = useState(null);
  const [closingHours, setClosingHours] = useState(null);
  const [errors, setErrors] = useState({});
  const kmlRef = useRef();
  const { loading = false, regionDetails } = useSelector(state => state.regions);
  const { isUploading=false} = useSelector(state => state.common);

  const handleRegionNameChange = (event) => {
    setRegionName(event.target.value);
  };

  const handleOpeningHoursChange = (newTime) => {
    setOpeningHours(newTime);
  };

  const handleClosingHoursChange = (newTime) => {
    setClosingHours(newTime);
  };

  const uploadKMLHandler = async (e) => {
    if (e.target.files.length) {
      if(!isValidFileExtension(e.target.files[0],'.kml')){
        showMessage('Upload only KML file','error');
        return;
      }
      const file = e.target.files[0];
      const body = new FormData();
      body.append('filePath', file);
      dispatch(uploadImage(body)).unwrap().then((res => {
        e.target = '';
        setUploadedKML(res?.fileUrl);
      })).catch(() => { });
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const { isValid, errors } = regionValidation({ openingHours: openingHours, closingHours: closingHours, uploadedKML: uploadedKML, regionName: regionName });
    if (!isValid) {
      setErrors(errors);
      return;
    }
    setErrors({});
    const payload = {
      regionName,
      status,
      openingHours: dayjs(openingHours).valueOf(),
      closingHours: dayjs(closingHours).valueOf(),
      kml: uploadedKML

    };
    if (regionId) {
      dispatch(updateRegion({ data: payload, regionId })).unwrap().then((res) => {
        showMessage(res?.message, 'success');
        navigate('/regions');
      }).catch((err) => {
        showMessage(err?.message, 'error');
      });
    } else {
      dispatch(addRegion(payload)).unwrap().then((res) => {
        showMessage(res?.message, 'success');
        navigate('/regions');
      }).catch((err) => {
        showMessage(err?.message, 'error');
      });
    }
  };

  const showMessage = (message, errortype) => {
    if (errortype === 'success') {
      dispatch(openSnackbar({ content: message, color: 'success', icon: 'check' }));
    }
    if (errortype === 'error') {
      dispatch(openSnackbar({ content: message, color: 'error', icon: 'warning' }));
    }
  };

  useEffect(() => {
    if (!isEmptyObject(regionDetails)) {
      const { closingHours, openingHours, kml, regionName, status } = regionDetails;
      setClosingHours(closingHours ?  dayjs(closingHours) : null);
      setOpeningHours(openingHours ? dayjs(openingHours) : null);
      setRegionName(regionName);
      setUploadedKML(kml);
      setStatus(status);

    }
  }, [regionDetails]);

  return (
    <Box p={{xs: 2, sm: 3, lg: 4}}>
      <Grid spacing={4} container>
        <Grid item xs={12} lg={6}>
          <Card sx={{ p: { xs: 2, md: 3 }, borderRadius: 2.5, boxShadow: 'none', height: '100%' }}>
            <Stack direction={{ xs: 'column', sm: 'row' }} alignItems={{ sm: 'center' }} justifyContent="space-between" gap={2} mb={4.5}>
              <Typography variant='h4' fontWeight="semibold" color="secondary">{!regionId ? 'Add' : 'Update'} Region</Typography>
              <Box display="flex" alignItems="center" gap={1.25}>
                <Typography variant='h5' fontWeight="semibold" color="secondary">Status</Typography>
                <Stack direction="row" alignItems="center" gap={.75} lineHeight={1} p={.75} borderRadius={2} bgcolor="#F2F3F7">
                  <MDButton
                    sx={{ minWidth: '92px', borderRadius: 1.75, minHeight: '28px', p: .25, fontWeight: 500 }}
                    type='button'
                    variant={status === 'active' ? 'contained' : 'text'}
                    color={status === 'active' ? 'success' : 'dark'}
                    size="small"
                    onClick={() => setStatus('active')}
                  >
                                        Active
                  </MDButton>
                  <MDButton
                    sx={{ minWidth: '92px', borderRadius: 1.75, minHeight: '28px', p: .25, fontWeight: 500 }}
                    type='button'
                    variant={status !== 'active' ? 'contained' : 'text'}
                    color={status !== 'active' ? 'error' : 'dark'}
                    size="small"
                    onClick={() => setStatus('inactive')}
                  >
                                        Inactive
                  </MDButton>
                </Stack>
              </Box>
            </Stack>

            <form onSubmit={handleSubmit}>
              <Grid container alignItems="center" spacing={2.5} mb={4.5}>
                <Grid item xs={12} sm={12}>
                  <TextField
                    label="Region Name*"
                    value={regionName}
                    onChange={handleRegionNameChange}
                    fullWidth
                    error={Boolean(errors.regionName)}
                    helperText={errors.regionName}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <MobileTimePicker
                      className="mobile-time-picker"
                      label="Opening Hours"
                      value={openingHours}
                      onChange={handleOpeningHoursChange}
                      // ampm={false}
                      renderInput={(params) => <TextField
                        {...params}
                        error={Boolean(errors.openingHours)}
                        helperText={errors.openingHours}
                      />}
                    />
                    {Boolean(errors.openingHours) && <FormHelperText error>{errors.openingHours}</FormHelperText>}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <MobileTimePicker
                      className="mobile-time-picker"
                      label="Closing Hours"
                      value={closingHours}
                      onChange={handleClosingHoursChange}
                      renderInput={(params) =>
                        <TextField {...params}
                          error={Boolean(errors.closingHours)}
                          helperText={errors.closingHours}
                        />}
                    />
                    {Boolean(errors.closingHours) && <FormHelperText error>{errors.closingHours}</FormHelperText>}
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Box display='flex' justifyContent='space-between'>
                    <Tooltip title={uploadedKML} sx={{
                      whiteSpace: 'pre-wrap',
                    }} arrow placement="bottom">
                      <TextField
                        fullWidth
                        label="Upload KML*"
                        disabled={isUploading}
                        className="form-input-upload"
                        value={uploadedKML}
                        error={Boolean(errors.uploadedKML)}
                        helperText={errors.uploadedKML}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton color='secondary' onClick={
                                (e) => {
                                  e.stopPropagation();
                                  kmlRef.current.click();
                                }
                              }>
                                <UploadFile />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Tooltip>

                    <input
                      id="product-image-input"
                      type="file"
                      name="productImageFile"
                      onChange={uploadKMLHandler}
                      ref={kmlRef}
                      accept='.kml'
                      style={{ display: 'none' }}
                    />
                  </Box>
                </Grid>
              </Grid>

              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1.25} mt={4} mb={2}>
                                
                <MDButton
                  sx={{ minWidth: '140px' }}
                  type='submit'
                  variant='contained'
                  color='primary'
                  disabled={loading || isUploading}
                  loading={loading || isUploading}
                >
                                    Submit
                </MDButton>
                {
                  regionId &&      <MDButton
                    sx={{ minWidth: '140px' }}
                    type='button'
                    variant='outlined'
                    color='dark'
                    onClick={()=>navigate('/regions')}
                  >
                                    Cancel
                  </MDButton>
                }
              </Stack>
            </form>
          </Card>
        </Grid>
        {
          uploadedKML && <Grid item xs={12} lg={6}>
            <Box sx={{ borderRadius: 4, overflow: 'hidden' }}>
              <MapWithPolygon kmlUrl={uploadedKML} />
            </Box>
          </Grid>
        }
      </Grid>
    </Box>
  );
}