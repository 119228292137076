import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { UpdateBreakHempAPI } from "../apis/breaks"
import { exportBreaksHempAPI } from "../apis/breaks"
import { fetchBreakDetailsHempAPI } from "../apis/breaks"
import { DeleteBreakHempAPI } from "../apis/breaks"
import { AddBreakHempAPI } from "../apis/breaks"
import { fetchBreaksHempAPI } from "../apis/breaks"


export const fetchBreaksHemp = createAsyncThunk(
    'breaks/fetchBreaks',
    async (filters, { rejectWithValue }) => {
        try {
            const res = await fetchBreaksHempAPI(filters)
            return res
        } catch (error) {
            console.error("erro", error.response)
            return rejectWithValue(error?.response?.data)
        }
    }
)

export const addBreaksHemp = createAsyncThunk(
    'breaks/addBreak',
    async (data, {rejectWithValue}) => {
        try {
            const res = await AddBreakHempAPI(data)
            return res
        } catch (error) {
            console.error("erro", error.response)
            return rejectWithValue(error?.response?.data)
        }
    }
)

export const deleteBreakHemp = createAsyncThunk(
    'breaks/deleteBreak',
    async(id, {rejectWithValue}) => {
        try {
            const res = await DeleteBreakHempAPI(id)
            return res
        } catch (error) {
            console.error("erro", error.response)
            return rejectWithValue(error?.response?.data)
        }
    }
)

export const updateBreakHemp = createAsyncThunk(
    'breaks/updateBreak',
    async({id,data}, { rejectWithValue}) => {
        try {
            const res = await UpdateBreakHempAPI(id,data)
            return res
        } catch (error) {
            console.error("erro", error.response)
            return rejectWithValue(error?.response?.data)
        }
    }
)

export const fetchBreakDetailsHemp = createAsyncThunk(
    'breaks/fetchBreakDetails',
    async(id, { rejectWithValue }) => {
        try {
            const res = await fetchBreakDetailsHempAPI(id)
            return res
        } catch(error) {
            console.error("erro", error.response)
            return rejectWithValue(error?.response?.data)
    }
}
)

export const exportBreaksHemp = createAsyncThunk(
    'breaks/exportBreaks',
    async ( filters, { rejectWithValue }) => {
        try{ 
            const res = await exportBreaksHempAPI(filters)
            return res
        } catch (error) {
            console.error("erro", error.response)
            return rejectWithValue(error?.response?.data)
        }
    }
)

const breaksSliceHemp = createSlice({
    name: 'breaksHemp',
    initialState: {
        breaks:[],
        loading: false,
        error: null
    },
    reducers: {

    },
    extraReducers: (builder) => {
        builder
        .addCase(fetchBreaksHemp.fulfilled, (state,action) => {
            state.loading = false;
            state.breaks = action.payload;
            state.error = null;
        })
        .addCase(fetchBreaksHemp.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })
        .addCase(fetchBreaksHemp.pending , (state) => {
            state.loading = true;
        })

        builder
        .addCase(addBreaksHemp.pending, (state) => {
            state.loading = true;
            state.error = null
        })
        .addCase(addBreaksHemp.fulfilled, (state,action) => {
            state.loading = false;
            state.addedBreak = action.payload;
            state.error = null;
        })
        .addCase(addBreaksHemp.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload
        })

        builder
        .addCase(updateBreakHemp.pending, (state, action) => {
            state.loading = true;
            state.error = null
        })
        .addCase(updateBreakHemp.fulfilled, (state, action) => {
            state.loading = false;
            state.error = action.payload
        })
        .addCase(updateBreakHemp.rejected, (state,action) => {
            state.loading = false;
            state.error = action.payload
        })

        builder
        .addCase(fetchBreakDetailsHemp.fulfilled, (state,action) => {
            state.loading = false;
            state.breakDetails = action.payload;
            state.error = null;
        })
        .addCase(fetchBreakDetailsHemp.rejected, (state,action) => {
            state.loading = false;
            state.error = action.payload
        })
        .addCase(fetchBreakDetailsHemp.pending, (state) => {
            state.loading = true;
        })


    }
})

export const { /* Any additional actions */} = breaksSliceHemp.actions; 

export default breaksSliceHemp.reducer;
