import CommonLayout from 'layouts/DashboardLayout';
import React, { useEffect, useState } from 'react';
import { TextField, Grid, Typography, Card, Box, Stack, FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@mui/material';
import MDButton from 'components/MDButton';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { openSnackbar } from '../../redux/slices/common';
import { MobileTimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { isEmptyObject } from 'utilities/common';
import { breakValidation } from 'validations/hyperwolf/break';
import timezone from 'dayjs/plugin/timezone';
import { formatTimestampToTimeWithTimezone } from 'utilities/common';
import { addBreaksHemp, updateBreakHemp } from '../../redux/slices/breaks';
dayjs.extend(timezone);


export default function CreateBreakHemp() {
  const navigate = useNavigate();
  const { breakDetails } = useSelector(state => state.breaksSliceHemp || {});
  const { breakId } = useParams();
  const timeZoneData = ['PST'];
  const [fromTime, setFromTime] = useState(null);
  const [toTime, setToTime] = useState(null);
  const [formData, setFormData] = useState({
    breakName: '',
    timezone: '',
    fromTime: fromTime,
    toTime: toTime,
    duration: '',
    status: 'active'
  });

  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };


  useEffect(() => {
    if (!isEmptyObject(breakDetails) && breakId) {

      if (breakDetails && breakDetails.data && breakDetails.data.data) {
        const { fromTime, toTime } = breakDetails.data.data;
        const formatTime = (timeStr, tz) => {
          const [hours, minutes] = timeStr.split(':').map(Number);
          const utcDate = dayjs.utc().set('hour', hours).set('minute', minutes);
          const localDate = utcDate.tz(tz).local(); // Convert to local time
          return localDate.format('HH:mm');
        };

        const convertTimeToTimestamp = (timeString) => {
          if (!timeString) return null;
          const dateTimeString = `${moment().format('YYYY-MM-DD')}T${timeString}`;
          return moment(dateTimeString, 'YYYY-MM-DDTHH:mm').valueOf();
        };

        const fromTimeStamp = convertTimeToTimestamp(formatTime(fromTime));
        const toTimeStamp = convertTimeToTimestamp(formatTime(toTime));

        let payload = {
          ...breakDetails?.data.data,
          fromTime: setFromTime(dayjs(fromTimeStamp)),
          toTime: setToTime(dayjs(toTimeStamp)),
        };

        setFormData({ ...payload });

      }
    }
  }, [breakDetails]);


  const handleSubmit = (e) => {
    e.preventDefault();
    const { errors, isValid } = breakValidation({ ...formData, fromTime: fromTime, toTime: toTime });
    if (!isValid) {
      setErrors(errors);
      return;
    }
    setErrors({});

    const payload = {
      breakName: formData.breakName,
      timezone: formData.timezone,
      fromTime: formatTimestampToTimeWithTimezone(dayjs(fromTime).valueOf(), fromTime.timezone),
      toTime: formatTimestampToTimeWithTimezone(dayjs(toTime).valueOf(), toTime.timezone),
      duration: calculateTimeDifferenceinminutes(fromTime, toTime),
      status: formData.status
    };

    if (breakId) {
      dispatch(updateBreakHemp({ id: breakId, data: { ...payload } })).unwrap().then((res) => {
        showError(res?.data?.message, 'success');
        navigate('/drivers?driverFilter=breaks');
      }).catch((err) => {
        showError(err?.message, 'error');
      });
    }
    else {
      dispatch(addBreaksHemp(payload)).unwrap().then((res) => {
        showError(res?.data.message, 'success');
        navigate('/drivers?driverFilter=breaks');
      }).catch((err) => {
        showError(err?.message, 'error');
      });
    }
  };

  const showError = (message, errortype) => {
    if (errortype === 'success') {
      dispatch(openSnackbar({ content: message, color: 'success', icon: 'check' }));
    }
    if (errortype === 'error') {
      dispatch(openSnackbar({ content: message, color: 'error', icon: 'warning' }));
    }
  };


  const handleFromTimeChange = (newTime) => {
    setFromTime(newTime);
    let errorsObj = { ...errors };
    delete errorsObj.fromTime;
  };

  const handleToTimeChange = (newTime) => {
    setToTime(newTime);
  };

  const calculateTimeDifferenceinminutes = (fromTime, toTime) => {
    const diffInMiliSec = toTime?.diff(fromTime);
    const diffInMinutes = moment.duration(diffInMiliSec).asMinutes();
    return diffInMinutes;
  };

  return (
    <CommonLayout>
      <Box p={{ xs: 2, sm: 3, lg: 4 }} className="create-break">
        <form style={{ marginTop: 0 }}>
          <Card sx={{ p: { xs: 2, md: 3 }, mb: 4, borderRadius: 2.5, boxShadow: 'none' }}>
            <Stack direction={{ xs: 'column', sm: 'row' }} alignItems={{ sm: 'center' }} justifyContent="space-between" gap={2} mb={4.5}>
              <Typography variant='h4' fontWeight="semibold" color="secondary">{breakId ? 'Update Break' : 'Add Break'}</Typography>
              <Box display="flex" alignItems="center" gap={1.25}>
                <Stack direction="row" alignItems="center" gap={.75} lineHeight={1} p={.75} borderRadius={2} bgcolor="#F2F3F7">
                  <MDButton
                    sx={{ minWidth: '92px', borderRadius: 1.75, minHeight: '28px', p: .25, fontWeight: 500 }}
                    type='button'
                    variant={formData?.status === 'active' ? 'contained' : 'text'}
                    color={formData?.status === 'active' ? 'success' : 'dark'}
                    size="small"
                    onClick={() => {
                      setFormData({ ...formData, status: 'active' });
                    }}
                  >
                    Active
                  </MDButton>
                  <MDButton
                    sx={{ minWidth: '92px', borderRadius: 1.75, minHeight: '28px', p: .25, fontWeight: 500 }}
                    type='button'
                    variant={formData?.status !== 'active' ? 'contained' : 'text'}
                    color={formData?.status !== 'active' ? 'error' : 'dark'}
                    size="small"
                    onClick={() => {
                      setFormData({ ...formData, status: 'inactive' });
                    }}
                  >
                    Inactive
                  </MDButton>
                </Stack>
              </Box>
            </Stack>


            <Grid container spacing={3} mb={1}>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  label=""
                  className="form-input"
                  fullWidth
                  placeholder="Break Name"
                  name="breakName"
                  value={formData.breakName}
                  error={errors.breakName}
                  helperText={errors.breakName}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <FormControl fullWidth>
                  <InputLabel>Time Zone</InputLabel>
                  <Select
                    className="form-select"
                    error={Boolean(errors?.strainType)}
                    onChange={handleChange} label="Time Zone" name="timezone" value={formData.timezone}>
                    {
                      timeZoneData?.map(((timezone, index) => {
                        return <MenuItem key={index} value={timezone}>{timezone}</MenuItem>;
                      }))
                    }
                  </Select>
                  {Boolean(errors?.timezone) && <FormHelperText error>{errors?.timezone}</FormHelperText>}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <FormControl fullWidth>
                  <MobileTimePicker
                    className="mobile-time-picker"
                    label="From Time"
                    value={fromTime}
                    onChange={handleFromTimeChange}
                    // ampm={false}
                    renderInput={(params) => <TextField
                      {...params}
                      error={Boolean(errors.fromTime)}
                      helperText={errors.fromTime}
                    />}
                  />
                  {Boolean(errors.fromTime) && <FormHelperText error>{errors.fromTime}</FormHelperText>}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <FormControl fullWidth>
                  <MobileTimePicker
                    className="mobile-time-picker"
                    label="To Time"
                    value={toTime}
                    onChange={handleToTimeChange}
                    renderInput={(params) => <TextField
                      {...params}
                      error={Boolean(errors.toTime)}
                      helperText={errors.toTime}
                    />}
                  />
                  {Boolean(errors.toTime) && <FormHelperText error>{errors.toTime}</FormHelperText>}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  label="Break Duration Minutes"
                  className="form-input"
                  fullWidth
                  placeholder="Brake Duration Minutes"
                  name="duration"
                  value={calculateTimeDifferenceinminutes(fromTime, toTime)}
                  onChange={handleChange}
                  disabled
                />
              </Grid>
            </Grid>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1.25} mt={4} mb={2}>
              <MDButton
                sx={{ minWidth: '140px' }}
                type='submit'
                variant='contained'
                color='primary'
                onClick={handleSubmit}
              >
                Save
              </MDButton>
              <MDButton
                sx={{ minWidth: '140px' }}
                type='button'
                variant='outlined'
                color='dark'
                onClick={() => navigate('/drivers?driverFilter=breaks')}
              >
                Cancel
              </MDButton>
            </Stack>
          </Card>
        </form>
      </Box>
    </CommonLayout>
  );
}