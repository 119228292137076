import axios from "axios";
import { axiosHyperwolfDevClient } from "axiosClient";
import { axiosHyperwolfClient } from "axiosClient";
import axiosClientHyperDrive from "axiosClient/indexHyperdrive";
import { getQueryString } from "utilities/common";


export async function getServiceTimeAPI(){
    return await axiosHyperwolfDevClient.get('api/v1/get/service/time')
  }
  
  export async function updateServiceTimeAPI(data) {
    return await axiosHyperwolfDevClient.post('api/v1/update/service/time', data)
  }
  
  export async function getProcessTimeAPI() {
    return await axiosHyperwolfDevClient.get('api/v1/get/process/time')
  }
  
  export async function updateProcessTimeAPI(data) {
    return await axiosHyperwolfDevClient.post('api/v1/update/process/time', data )
  }
  
  export async function getPackageTimeAPI() {
    return await axiosHyperwolfDevClient.get('api/v1/get/package/time')
  }
  
  export async function updatePackageTimeAPI(data) {
    return await axiosHyperwolfDevClient.post('api/v1/update/package/time', data)
  }