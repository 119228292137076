import { Typography, Box } from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react'
import EastIcon from '@mui/icons-material/East';
import { AgGridTable } from 'components/tables/AgGridTable';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { fetchTaskActivityLogs } from '../../../redux/slices/hyperdrive/drivers';
import { formatTimestampToDate24Hours } from 'utilities/common';

const TaskLogs = ({ sendDataToParent = () => { }, taskId }) => {
    const dispatch = useDispatch()
    const mainGridApiRef = useRef('')
    const pageRef = useRef(0)
    const gridRefApi = useRef(null)
    const { taskLoadingData = {}, isTaskLoading: isTaskLoading = false } = useSelector(state => state.drivers)

    const handleDriverDetails = () => {
        sendDataToParent(null);
    }
    
    const [columnDefs, setColumnDefs] = useState([
        {
            headerName: 'Name',
            field: 'updatedBy',
            minWidth: 80,
            cellRenderer: useCallback((props) => (
                props?.value ? props?.value : "--"
            ), []),
            flex: 0.8,
        },
        {
            headerName: 'Date',
            field: 'createdDate',
            minWidth: 140,
            cellRenderer: useCallback((props) => (
                props?.value ? formatTimestampToDate24Hours(props?.value) : "-"
            ), []),
            flex: 1.3,
        },
        {
            headerName: 'Status',
            field: 'createdDate',
            minWidth: 220,
            cellRenderer: useCallback((props) => (
                <> {
                    props?.value ? `${props?.data?.source}    ${props?.data?.previousChanges?.fleetName}  ->  ${props?.data?.newChanges?.fleetName} ` : "-"
                }</>

            ), []),
            flex: 2,
        },

    ]); 

    const getRows = useCallback(async (params, filters) => {
        gridRefApi.current = params;
        try {
            const res = await dispatch(fetchTaskActivityLogs({
                data: {
                    skip: pageRef.current,
                    limit: 20,
                },
                taskId
            })).unwrap();
            const { taskdetails, total } = res
            const driverListArray = taskdetails?.map((item) => ({
                ...item,
            }));
            params.successCallback([...driverListArray], total);
            pageRef.current += 20;
        } catch (err) {
            params.successCallback([], 0);
        }
    }, [dispatch]);

    useEffect(() => {
        if (mainGridApiRef?.current) {
            if (isTaskLoading) {
                mainGridApiRef.current.showLoadingOverlay();
            } else if (taskLoadingData?.total === 0) {
                mainGridApiRef.current.showNoRowsOverlay();
            } else {
                mainGridApiRef.current.hideOverlay();
            }
        }
    }, [taskLoadingData, isTaskLoading]);

    return (
        <div className="next-drawer activity-log-drawer">
            <Box p={2} className='d-flex align-item-center jc-s-btwn gap-2 drawer-head'>
                <div className='d-flex flex-row gap-3'>
                    <Typography variant='h6' className='text-14 text-white-700'>Activity Log</Typography>
                </div>
                <Typography style={{ cursor: "pointer" }} onClick={() => handleDriverDetails()} className='d-flex align-item-center gap-1 text-14 text-white-700'>
                    Close <EastIcon />
                </Typography>
            </Box>
            <Box className='drawer-scroll-sec'>
                <div className="ag-theme-quartz-dark">
                    <AgGridTable
                        col={columnDefs}
                        mainGridApiRef={mainGridApiRef}
                        getRows={getRows}
                        pageRef={pageRef}
                        isLoading={isTaskLoading}
                        style={{ height: "70vh" }}
                        gridOptions={{ rowHeight: 64 }}
                        maxLimit={20}
                    />
                </div>
            </Box>
        </div >
    )
}

export default TaskLogs